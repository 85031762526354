/**
 * @file Deprecated Helper methods.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ix, HelperMain */

/**
 * @ignore
 * @deprecated use ix.text.hexEncode
 */
HelperMain.prototype.hexEncodeString = function (input) {
    return ix.text.hexEncode(input);
};

/**
 * @ignore
 * @deprecated use ix.text.hexDecode
 */
HelperMain.prototype.hexDecodeString = function (input) {
    return ix.text.hexDecode(input);
};

/**
 * @ignore
 * @deprecated use ix.util.getBaseUrl
 */
HelperMain.prototype.getBaseUrl = function (path) {
    return ix.util.getBaseUrl(path);
};

/**
 * @ignore
 * @deprecated use ix.util.setUrlValue
 */
HelperMain.prototype.setUrlValueByParam = function (param, value, url) {
    return ix.util.setUrlValue(param, value, url);
};

/**
 * @ignore
 * @deprecated use ix.util.getQsValues
 */
HelperMain.prototype.getQsValues = function (queryString) {
    return ix.util.getQsValues(queryString);
};

/**
 * @ignore
 * @deprecated use ix.util.getQsValues
 */
HelperMain.prototype.getUrlValues = function (url) {
    return ix.util.getQsValues(url);
};

/**
 * @ignore
 * @deprecated use ix.util.getUrlValue
 */
HelperMain.prototype.getUrlValueByParam = function (param, url) {
    return ix.util.getUrlValue(param, url);
};

/**
 * @ignore
 * @deprecated use ix.util.setQsValue
 */
HelperMain.prototype.setQsValueByParam = function (param, value, queryString, hexEnc) {
    if (arguments.length < 4) {
        return ix.util.setUrlValue(param, value, queryString);
    } else {
        return ix.util.setUrlValue(param, value, queryString, !hexEnc);
    }
};

/**
 * @ignore
 * @deprecated use ix.util.containsParam
 */
HelperMain.prototype.containsQsParam = function (param, queryString) {
    return ix.util.containsParam(param, queryString);
};

/**
 * @ignore
 * @deprecated use ix.util.deleteParamFromUrl
 */
HelperMain.prototype.deleteParamFromUrl = function (param, url) {
    return ix.util.deleteParamFromUrl(param, url);
};

/**
 * @ignore
 * @deprecated use ix.util.deleteParamFromQs
 */
HelperMain.prototype.deleteParamFromQs = function (param, queryString) {
    return ix.util.deleteParamFromQs(param, queryString);
};

/**
 * @ignore
 * @deprecated use ix.util.mergeQueryStrings
 */
HelperMain.prototype.mergeQueryStrings = function (string1, string2) {
    return ix.util.mergeQueryStrings(string1, string2);
};

/**
 * @ignore
 * @deprecated use ix.cookie.getValue
 */
HelperMain.prototype.getCookieValueByParam = function (param, fallback) {
    return ix.cookie.getValue(param, fallback);
};

/**
 * @ignore
 * @deprecated use ix.cookie.deleteCookie
 */
HelperMain.prototype.deleteCookie = function (param) {
    return ix.cookie.deleteCookie(param);
};

/**
 * @ignore
 * @deprecated Use navigator.cookieEnabled instead.
 */
HelperMain.prototype.isCookieEnabled = function () {
    return navigator.cookieEnabled;
};

/**
 * @ignore
 * @deprecated use ix.cookie.setValue
 */
HelperMain.prototype.setCookie = function (param, value, expires, timeOut) {
    return ix.cookie.setValue(param, value, expires, timeOut);
};

/**
 * @ignore
 * @deprecated use ix.text.trim
 */
HelperMain.prototype.trim = function (value) {
    return value.trim();
};

/**
 * @ignore
 * @deprecated use ix.util.getQsValue
 */
HelperMain.prototype.getQsValueByParam = function (param, queryString) {
    return ix.util.getQsValue(param, queryString);
};
