/**
 * @file Global functions for checking types and duck-typing.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals ix */
/* exported
   isString, isDate, isHTMLElement, isSVGElement, isUndefinedOrNull, isIxContainer, upObject
*/
/**
 * @private
 * @ignore
 * @param {Object} obj
 * @return {Boolean}
 * @description Helper-Function to check for Date
 */
function isDate(obj) {
    return ix.util.isDate(obj);
}

/**
 * @public
 * @param {Object} obj
 * @return {Boolean}
 * @description Returns true if an Object is undefined or Null, else false.
 */
function isUndefinedOrNull(obj) {
    return ix.util.isUndefinedOrNull(obj);
}
/**
 * @public
 * @param {Object} obj
 * @return {Boolean}
 * @description Returns true if obj is an HTML element, else false.
 */
function isHTMLElement(obj) {
    return ix.util.isHTMLElement(obj);
}
/**
 * @public
 * @param {Object} obj
 * @return {Boolean}
 * @description Returns true if obj is an SVG element, else false.
 */
function isSVGElement(obj) {
    return ix.util.isSVGElement(obj);
}
/**
* @private
* @param {Object} element
* @return {Boolean} result
* @description
 check if the current element is a String
*/
function isString(element) {
    return ix.util.isString(element);
}
