/* globals upContainer */
/**
 * Creates a clone of itself
 *
 * @private
 * @param oHtml Reference to NEW html control.
 * @param oCloneContainer CloneContainer to clone.
 */
upContainer.prototype.clone = function (oHtml, oCloneContainer) {
    var oUp = this._clone({}, oHtml, oCloneContainer);
    oUp = this.biDirectUpHtml2(oUp, oHtml);
    if (oCloneContainer.oHtml) {
        oUp.parentCloneContainerId = oCloneContainer.changeId(oCloneContainer.oHtml.id);
    }
    return oUp;
};

/**
 * @private
 */
upContainer.prototype._clone = function (oUp, oHtml, oCloneContainer) {
    for (var i in this) {
        if (i == "id") {
            oUp["id"] = oCloneContainer.changeId(this["id"]);
        } else if (i == "userName") {
            if (this["userName"].indexOf(self.oUp.oMessage.CLONER_COPY) == -1)
                oUp["userName"] = this["userName"] + ": " + self.oUp.oMessage.CLONER_COPY;
            else
                oUp["userName"] =
                    this["userName"].substring(0, this["userName"].lastIndexOf(":")) +
                    ": " +
                    self.oUp.oMessage.CLONER_COPY;
        } else if (i != "oHtml") {
            oUp[i] = this[i];
        }
    }
    return oUp;
};
/**
 * Initialises a CloneContainer.
 *
 * @private
 * @param p_strSep - Separator, depends on the level of the CloneContainer.
 * @param oCloneContainer CloneContainer to clone.
 */
upContainer.prototype.initCloneContainer = function (p_strSep, oCloneContainer) {
    for (var i in this) {
        if (i == "id") {
            this["id"] = this["id"] + p_strSep + "0";
        } else if (i == "userName") {
            if (this["userName"].indexOf(":") == -1)
                this["userName"] = oCloneContainer.userName + ": " + this["userName"];
            else
                this["userName"] =
                    this["userName"].substring(0, this["userName"].indexOf(":")) +
                    " - " +
                    oCloneContainer.userName +
                    ": " +
                    this["userName"].substring(this["userName"].indexOf(":") + 1);
        }
    }
    if (oCloneContainer.oHtml) this.parentCloneContainerId = oCloneContainer.oHtml.id;
};
/**
 * Deletes JS objects of a CloneContainer.
 *
 * @private
 */
upContainer.prototype.removeClone = function () {
    this.oHtml.oUp = null;
    this.oHtml = null;
};
