/**
 * @file Table API
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals
   Notifier, ix,
   getElement,
*/

/* exported upTableReg, getTableColumns, getTableRecordByRecordHtml, getTableByRecordHtml */

/**
 * @description Registry for tables (with attribut jsobject=true)
 * @ignore
 * @see upTable
 * @see upTableCol
 * @class
 */
function upTableReg() {
    /**
     * @ignore
     */
    this.aTables = [];

    /**
     * @description Registers a table<br>
     *
     * Variant 1:
     * controlName, level, name, guid, recordname, recordguid, tabType
     * tabtype:<br>
     * tc - tablecontrol<br>
     * st - shapedtable<br>
     * gd - generic datarange<br>
     *
     * Variant 2:
     * tableObj
     *
     * @ignore
     * @return {upTableReg}
     */
    this.register = function () {
        var oTable;

        if (arguments.length === 1 && typeof arguments[0] === "object") {
            oTable = arguments[0];
            if (typeof this.getTableByGuid(oTable.guid) !== "undefined" && this.getTableByGuid(oTable.guid) !== null) {
                this.unregister(oTable.guid);
            }
        } else {
            Notifier.status.error("Table could not be registered. Wrong number of arguments.");
        }

        this.aTables.push(oTable);

        return oTable;
    };

    /**
     * @description Unregisters a table.
     * @ignore
     * @param {String} guid
     */
    this.unregister = function (guid) {
        var oTable = null;
        var newTableReg = [];
        for (var i = 0; i < this.aTables.length; i++) {
            oTable = this.aTables[i];
            if (oTable.guid !== guid) {
                newTableReg.push(oTable);
            }
        }
        this.aTables = newTableReg;
    };

    /**
     * @description Returns a table by a guid.
     * @ignore
     * @param {String} guid
     * @return {upTable|null}
     */
    this.getTableByGuid = function (guid) {
        //if(getElement(guid)) {
        return getElement(guid).oUp;
        //}
    };

    /**
     * @description Returns a table by a controlname.
     * @ignore
     * @param {String} controlName
     * @return {upTable|null}
     */
    this.getTableByControlName = function (controlName) {
        var oTable = null;
        for (var i = 0; i < this.aTables.length; i++) {
            oTable = this.aTables[i];
            if (oTable.controlName === controlName) {
                return oTable;
            }
        }
        return null;
    };
}

/**
 * @ignore
 * @deprecated
 * @param {String} tableGuid
 * @param {String} columnGuid
 * @return {upTableColumn|null}
 */
function getTableColumns(tableGuid, columnGuid, context) {
    var ret = null;

    if (getElement(tableGuid, context)) {
        var oTable = getElement(tableGuid, context).oUp;

        if (oTable) {
            var oCol = oTable.getColByGuid(columnGuid);

            if (typeof oCol === "object" && oCol !== null) {
                return oCol;
            }
        }
    }
    return ret;
}

/**
 * @ignore
 * @description Get the shaped table record which the given element belongs to.
 * @param {Object} oHtml
 * @return {upShapedTableRecord}
 * @example
 * alert(getTableRecordByRecordHtml(this).getRecId());
 * @example
 * alert($(this).getTableRecord().getRecId());
 */
function getTableRecordByRecordHtml(oHtml) {
    var ret = null;

    if (typeof oHtml === "object" && oHtml !== null) {
        var tableRecInfo = ix.util.tableRecordInfo(oHtml);

        if (typeof tableRecInfo !== "undefined" && tableRecInfo.isBelongsToTable()) {
            ret = tableRecInfo.getRecord();
        }
    }
    return ret;
}

/**
 * @ignore
 * @description Get the shaped table which the given element belongs to.
 * @param {Object} oHtml
 * @return {upShapedTable}
 */
function getTableByRecordHtml(oHtml) {
    var ret = null;

    if (typeof oHtml === "object" && oHtml !== null) {
        var tableRecInfo = ix.util.tableRecordInfo(oHtml);

        if (typeof tableRecInfo !== "undefined" && tableRecInfo.isBelongsToTable()) {
            ret = tableRecInfo.getTable();
        }
    }
    return ret;
}
