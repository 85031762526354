/* globals ObjectFactory: true, Loader */
/* exported ObjectFactory, upOnDemandPrototype */
/**
 * @file Prototype upObjectFactory.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/**
 * @class
 * The static instance ObjectFactory provides method for constructing objects.
 * @private
 */
function upObjectFactory() {}
upObjectFactory.prototype.construct = upObjectFactoryConstruct;
ObjectFactory = new upObjectFactory();
/**
 * Constructs objects of type p_strType. If the prototype for this type is not available, a file including this prototype is loaded.
 * @param {String} p_strType is the type of the object, for example "upAppMenu" or "upCloneContainer".
 * @param {String} p_strParams Parameters of the constructor function.
 * @return {Object} Instance of type p_strType
 */
function upObjectFactoryConstruct(p_strType, p_param1, p_param2, p_param3, p_param4, p_param5) {
    // eslint-disable-next-line no-unused-vars
    var strParams = "";
    for (var i = 1; i < arguments.length; i++) {
        if (i > 1) {
            strParams += ",";
        }
        if (typeof arguments[i] == "string") {
            strParams += "'" + arguments[i] + "'";
        } else {
            // eslint-disable-next-line no-unused-vars
            strParams += arguments[i];
        }
    }
    try {
        // eslint-disable-next-line no-unused-vars
        var test = eval(p_strType);
    } catch (e) {
        var oPrototypeInfo = null;

        var pInfo = null;
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < Loader.onDemandPrototypeList.length; i++) {
            pInfo = Loader.onDemandPrototypeList[i];
            if (pInfo.strName == p_strType) {
                oPrototypeInfo = pInfo;
                break;
            }
        }
        for (var j = 0; j < oPrototypeInfo.aFileNames.length; j++) {
            if (oPrototypeInfo.aFileNames[j].substr(oPrototypeInfo.aFileNames[j].length - 4) == ".css")
                Loader.loadCssFileOnDemand(oPrototypeInfo.aFileNames[j]);
            else Loader.loadJsFileOnDemand(oPrototypeInfo.aFileNames[j]);
        }
    }
    var f = null;
    switch (arguments.length) {
        case 1:
            f = new Function("return new " + p_strType + "();");
            return f();
        case 2:
            f = new Function("param1", "return new " + p_strType + "(param1);");
            return f(p_param1);
        case 3:
            f = new Function("param1", "param2", "return new " + p_strType + "(param1, param2);");
            return f(p_param1, p_param2);
        case 4:
            f = new Function("param1", "param2", "param3", "return new " + p_strType + "(param1, param2, param3);");
            return f(p_param1, p_param2, p_param3);
        case 5:
            f = new Function(
                "param1",
                "param2",
                "param3",
                "param4",
                "return new " + p_strType + "(param1, param2, param3, param4);"
            );
            return f(p_param1, p_param2, p_param3, p_param4);
        case 6:
            f = new Function(
                "param1",
                "param2",
                "param3",
                "param4",
                "param5",
                "return new " + p_strType + "(param1, param2, param3, param4, param5);"
            );
            return f(p_param1, p_param2, p_param3, p_param4, p_param5);
        default:
            return null;
    }
}
/**
 * @class
 * contains informations about a prototype, which is loaded on demand.
 * @private
 */
function upOnDemandPrototype(p_strName, p_aFileNames, p_strUse) {
    this.strName = p_strName;
    this.aFileNames = p_aFileNames;
    // PRELOAD
    this.strUse = p_strUse;
}
