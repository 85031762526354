/*
    globals upLoader: true, oHtmlRoot, debug, oFupLoader: true, debug, oRequest, NS, IDCOUNTERSEP, Browser, ix, ActiveXObject, ErrorHandler,  xhrNo, ResponseHandler,
    isStopwatch, iStopIncludes1: true, iStopIncludes2: true, iStopHtml1: true, iStopHtml2: true, iStopEval1: true, iStopEval2: true, strOnUnload: true,
    iStopOnload1: true, iStopOnload2: true, oXmlHttpRequestTimer: true, iStartResponse: true,
    Loader: true,
    iEnd: true ,iStart: true,iStopSend: true,Notifier,TooltipMeasure,Helper, upPositioningAbsolute, upOnDemandPrototype: true, isString,

*/
/*
    exported isXmlHttpInProgress, abortXmlHttpRequest, Trim
*/
/**
 * @file Prototypes for Loading and Ajax.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/**
 * Ajax constants
 * @final
 */
var State = {
    unsent: 0,
    opened: 1,
    headersReceived: 2,
    loading: 3,
    done: 4,
};
var Status = {
    ok: 200,
    notModified: 304,
    notFound: 404,
    internalServerError: 500,
};
/**
 * @class
 * The upXmlHttp object provides properties and methods for using ajax requests.
 * @param p_oWin A reference to the actual window object. If p_oWin is <code>null</code>, self is treated as actual window.
 */
function upXmlHttp(p_oWin) {
    /**
     * Reference to the actual window.
     * @private
     */
    this.oWin = arguments.length >= 1 && typeof p_oWin == "object" ? p_oWin : self;
    /**
     * Shall a request refresh the browserside session timeout?
     * @private
     */
    this.bResetTimeOut = true;
    /**
     * Is the request asynchron (which means that the browser does not wait for response)?
     */
    this.bAsync = true;
    /**
     * Shall the response be added to upBrowserHistory?
     * @see BrowserHistoryEntry
     * @private
     */
    this.bAddHistory = false;
    /**
     * Http method GET or POST
     */
    this.strMethod = "GET";
    /**
     * Shall a responsehandler work?
     */
    this.bProcessResponse = false;
    /**
     * Reference to responehandler function.
     */
    this.processResponse = null;
    /**
     * Request URL
     * @private
     */
    this.strUrl = null;
    /**
     * POST specific.
     * Should form data be added?
     */
    this.bAddFormData = false;
    /**
     * POST specific.
     * List of controls, which are posted.
     * @private
     */
    this.aPostControls = null;
    /**
     * POST specific.
     * Data for posting.
     */
    this.aRequestPostData = new Array();
    /**
     * POST specific.
     * Data for posting as string ( as parameter for send() ), filled by addPostData()
     */
    this.strPostData = "";
    /**
     * List of request headers.
     */
    this.aRequestHeader = new Array();
    /**
     * POST specific.
     * Counter for bpee dynamic data.
     * @private
     */
    this.iBpeeControlsCounter = 0;
    /**
     * Behavior in case of a xml http timeout.
     */
    this.strTimeoutBehavior = "";
    /**
     * Milliseconds for xml http timeout.
     */
    /**
     * loads stage/appmain
     */
    this.bLoadStage = false;
    /**
     * priority for xml http queue
     * 1 (lowest) - 100 (highest)
     */
    this.iPriority = 50;
    this.context = self;
}
upXmlHttp.prototype.send = XmlHttp_Send;
upXmlHttp.prototype._send = XmlHttp__Send;
upXmlHttp.prototype.addHeader = XmlHttp_AddHeader;
upXmlHttp.prototype.addPostControls = XmlHttp_AddPostControls;
upXmlHttp.prototype._addPostControls = XmlHttp__AddPostControls;
upXmlHttp.prototype.addPostControl = XmlHttp_AddPostControl;
upXmlHttp.prototype.addPostControlBpeeDynamic = XmlHttp_AddPostControlBpeeDynamic;
upXmlHttp.prototype.addPostData = XmlHttp_AddPostData;
upXmlHttp.prototype.addBpeeDynamicData = XmlHttp_AddBpeeDynamicData;
upXmlHttp.prototype.addForm = XmlHttp_AddForm;
upXmlHttp.prototype.canSubmitForm = XmlHttp_CanSubmitForm;
/**
 * Sends a xmlhttp request.
 * @return {Deferred} deferred jQuery deferred, http://api.jquery.com/category/deferred-object/
 */
function XmlHttp_Send() {
    return this._send();
}

function XmlHttp__Send() {
    this.xhrNo = Math.floor(Math.random() * 65535);

    // TimeOut fuer webseitiges Logout zuruecksetzen
    if (this.bResetTimeOut && oHtmlRoot && oHtmlRoot.oUp && !oHtmlRoot.oUp.bIsAnonymous) {
        oHtmlRoot.oUp.resetTimeOut();
    }
    window.syncReturnValueHack;

    var ajaxSettings = {
        async: this.bAsync,
        url: this.strUrl,
        type: this.strMethod,
        cache: false,
    };

    // due to massive wrong use of enctype="multipart/form-data"
    // we can not use form attribute enctype for now
    // sorry lads
    /*
    if (typeof this.form !== "undefined" && this.form.nodeName === "FORM") {
        ajaxSettings.contentType = this.form.enctype;
    }
    */

    if ($.isArray(this.aRequestPostData)) ajaxSettings.data = this.aRequestPostData;

    if ($.isFunction(this.success)) {
        ajaxSettings.success = this.success;
    } else if (this.bProcessResponse && this.processResponse !== null && $.isFunction(this.processResponse)) {
        ajaxSettings.success = function (userData, data, textStatus, jqXHR) {
            // aus Kompatibilitätsgründen
            window.oRequest = jqXHR;
            window.oRequest.strUrl = userData.oUpXmlHttp.strUrl;
            // aus Kompatibilitätsgründen
            if (typeof userData.fupLoader !== "undefined" && userData.fupLoader !== null) {
                // Hinweis in Konsole wenn aktuell
                if (
                    typeof window.oFupLoader !== "undefined" &&
                    window.oFupLoader !== null &&
                    typeof window.oFupLoader.status === "string" &&
                    window.oFupLoader.status.indexOf("loading") > -1 &&
                    window.oFupLoader !== userData.fupLoader
                ) {
                    debug("Detected not finished FupLoader!");
                    debug(window.oFupLoader);
                }
                window.oFupLoader = userData.fupLoader;
            }

            window.syncReturnValueHack = userData.oUpXmlHttp.processResponse(data, textStatus, jqXHR);
        }.bind(this.context, {
            oUpXmlHttp: this,
            // #44275 deprecated groupselection in usermanagement gives oFupLoader === undefined, when opened in popup
            fupLoader: typeof oFupLoader !== "undefined" ? oFupLoader : oHtmlRoot.oFupLoader,
        });
    }

    if ($.isFunction(this.error)) {
        ajaxSettings.error = this.error;
    } else if (typeof this.strTimeoutBehavior === "string" && this.strTimeoutBehavior.length > 0) {
        eval("var timeoutCallback = function(){" + this.strTimeoutBehavior + "}");
        ajaxSettings.error = function (userData, jqXHR, textStatus, errorThrown) {
            try {
                if (textStatus === "timeout") {
                    userData.timeoutCallback(jqXHR, textStatus, errorThrown);
                } else {
                    // If the user hit Esc or navigated away from the current page before an AJAX call was done.
                    // (The response headers will be null or empty, depending on the browser.)
                    if (!jqXHR.getAllResponseHeaders()) {
                        debug("Request aborted by user action.");
                        return;
                    }

                    ErrorHandler.notify(errorThrown.message, textStatus, "upxmlhttp");
                    debug(jqXHR);
                }
            } catch (e) {
                if (window.DEBUG) {
                    console.log(e);
                }
            }
        }.bind(this.context, {
            // eslint-disable-next-line no-undef
            timeoutCallback: timeoutCallback,
            fupLoader: oFupLoader,
        });
    }

    var promise = $.ajax(ajaxSettings);

    if (
        !ajaxSettings.async &&
        typeof window.syncReturnValueHack !== "undefined" &&
        window.syncReturnValueHack !== null
    ) {
        return window.syncReturnValueHack;
    } else {
        return promise;
    }
}

/**
 * Is a xmlhttp request still pending?
 * @param p_oXmlHttpRequest Actual xmlhttp request.
 * @param p_xhrNo Random number per xmlhttp request; xhrNo is a global variable.
 * @return boolean <code>true</code>, if the request is still pending, otherwise <code>false</code>.
 * @private
 */
function isXmlHttpInProgress(p_oXmlHttpRequest, p_xhrNo) {
    if (p_oXmlHttpRequest == null || p_xhrNo != xhrNo) return false;
    switch (p_oXmlHttpRequest.readyState) {
        case 1:
        case 2:
        case 3:
            return true;
        // break;
        default:
            return false;
        // break;
    }
    // return false;
}
/**
 * Aborts a xmlhttp request.
 * @param p_strUrl The url of the request, which shall be aborted.
 * @private
 */
function abortXmlHttpRequest(p_strUrl) {
    oRequest.onreadystatechange = function () {};
    debug("Abort xmlHttpRequest. Function: abortXmlHttpRequest", "info");
    debug(oRequest);
    oRequest.abort();
    if (self.strTimeoutBehavior && self.strTimeoutBehavior != "") {
        eval(self.strTimeoutBehavior);
    } else {
        alert("XmlHttp Timeout\n" + p_strUrl);
    }
    self.strTimeoutBehavior = "";
}
/**
 * Adds an header entry to aRequestHeader.
 * @param p_strName The name of a request variable as string.
 * @param p_strValue The value of a request variable as string.
 * @return boolean <code>true</code>, if the method is called correctly, otherwise <code>false</code>.
 */
function XmlHttp_AddHeader(p_strName, p_strValue) {
    if (arguments.length == 2) {
        var newHeader = new Array();
        newHeader.name = p_strName;
        newHeader.value = p_strValue;
        this.aRequestHeader.push(newHeader);
        return true;
    } else return false;
}
/**
 * Adds an post entry to aRequestPostData.
 * @param p_strName The name of a post variable as string.
 * @param p_strValue The value of a post variable as string.
 * @return boolean <code>true</code>, if the method is called correctly, otherwise <code>false</code>
 */
function XmlHttp_AddPostData(p_strName, p_strValue) {
    if (arguments.length == 2) {
        for (var i = 0, l = this.aRequestPostData.length; i < l; i++) {
            if (this.aRequestPostData[i].name === p_strName) {
                this.aRequestPostData[i].value = p_strValue;
                return true;
            }
        }

        var newFormData = new Array();
        newFormData.name = p_strName;
        newFormData.value = p_strValue;
        this.aRequestPostData.push(newFormData);
        return true;
    } else return false;
}
/**
 * Adds an bpee dynamic post entry for webservices to aRequestPostData.
 * 3 form fields are posted: qname, type and value.
 * @param p_strName The name of the post entry as string.
 * @param p_strDataType The datatype of the post entry as string.
 * @param p_strValue The value of the post entry as string.
 * @return boolean <code>true</code>, if the method is called correctly, otherwise <code>false</code>.
 * @private
 */
function XmlHttp_AddBpeeDynamicData(p_strName, p_strDataType, p_strValue) {
    if (arguments.length == 3) {
        var newFormData = new Array();
        newFormData.name = "qname_" + this.iBpeeControlsCounter;
        newFormData.value = "{" + NS.VARIABLE_BPEE_DYNAMIC.URI + "}" + p_strName;
        this.aRequestPostData.push(newFormData);

        newFormData = new Array();
        newFormData.name = "type_" + this.iBpeeControlsCounter;
        newFormData.value = "{" + NS.DATATYPE_IX.URI + "}" + p_strDataType;
        this.aRequestPostData.push(newFormData);

        newFormData = new Array();
        newFormData.name = "value_" + this.iBpeeControlsCounter;
        newFormData.value = p_strValue;
        this.aRequestPostData.push(newFormData);

        this.iBpeeControlsCounter++;
        return true;
    } else {
        return false;
    }
}
/**
 * Adds post controls(aPostControls) for webservices to a xmlhttp request.
 * @return boolean <code>true</code> or <code>false</code>
 * @private
 */
function XmlHttp_AddPostControls() {
    var aPostControls = this.aPostControls;
    this.iBpeeControlsCounter = 0;
    var strControlName = "";
    var strPostName = "";
    for (var c in aPostControls) {
        var oJSONControl = aPostControls[c];
        // Werte aus CloneContainern
        if (oJSONControl.level != null) {
            if (oJSONControl.no_multiple_container != oJSONControl.no_multiple_elements) {
                alert(
                    self.oUp.oMessage.WS_ERROR_LEVEL1 +
                        "\n" +
                        self.oUp.oMessage.WS_ERROR_LEVEL2 +
                        " " +
                        oJSONControl.no_multiple_container +
                        "\n" +
                        self.oUp.oMessage.WS_ERROR_LEVEL3 +
                        " " +
                        oJSONControl.no_multiple_elements
                );
                return false;
            }
            var oLevels = oJSONControl.level;
            // obersten CloneContainer finden
            var strPostNamePrefix = oJSONControl.controlName_org + ".";
            // CloneContainer von oben nach unten durchgehen
            this._addPostControls(oJSONControl, 0, oLevels[0], strPostNamePrefix, null);
        } else {
            // einfache Werte
            strControlName = oJSONControl.controlName;
            strPostName = oJSONControl.wsname;
            this.addPostControl(strControlName, strPostName);
        }
    }
    return true;
}
/**
 * Adds post controls from clonecontainer to xmlhttp request.
 * @private
 */
function XmlHttp__AddPostControls(p_oJSONControl, p_iLevel, p_oLevel, p_strPostNamePrefix, p_oHtmlParentContainer) {
    var oJSONControl = p_oJSONControl;
    var iLevel = p_iLevel;
    var oLevel = p_oLevel;
    var oHtmlParentContainer = p_oHtmlParentContainer;
    var strPostNamePrefix = p_strPostNamePrefix;
    var strPostNamePrefix2 = "";
    var strPostName = "";
    var strControlName = "";
    var strWsName = "";
    var counter = 0;
    var strDataType;

    if (oLevel.wsname.indexOf(":") > -1) strWsName = oLevel.wsname.substring(oLevel.wsname.indexOf(":") + 1);
    else strWsName = oLevel.wsname;
    strWsName = strWsName.replace(/\./g, "..");
    strWsName = strWsName.replace(/_/g, "__");

    if (oLevel.controlName == null) {
        strPostNamePrefix2 = strPostNamePrefix + strWsName + "_0.";
        this._addPostControls(
            oJSONControl,
            iLevel + 1,
            oJSONControl.level[iLevel + 1],
            strPostNamePrefix2,
            oHtmlParentContainer
        );
        return;
    } else if (oLevel.type == "simple-control") {
        // Kontrollen mit maxOccurs=1 - kein Durchlaufen von CloneContainern, da bereits unten angekommen
        strControlName =
            oJSONControl.controlName_org +
            oHtmlParentContainer.id.substring(oHtmlParentContainer.id.indexOf(IDCOUNTERSEP));
        strPostNamePrefix2 = strPostNamePrefix + strWsName;
        strPostName = strPostNamePrefix2 + "_0";
        strDataType = oJSONControl.datatype;
        this.addPostControlBpeeDynamic(strControlName, strPostName, strDataType);
        return;
    }
    // CloneContainer durchlaufen
    var oCloneCont = eval("o" + oLevel.controlName_org);
    // Array aus HtmlContainern dieses CloneContainers
    var aHtmlContainer;
    if (oCloneCont.level == 0 || oHtmlParentContainer == null) {
        aHtmlContainer = oCloneCont.aContainer;
    } else {
        aHtmlContainer = oCloneCont.aContainerSubGroups[oHtmlParentContainer.id];
    }
    var oHtmlContainer = null;
    // HtmlContainer dieses CloneContainers durchlaufen
    for (var i = 0; i < aHtmlContainer.length; i++) {
        oHtmlContainer = aHtmlContainer[i];
        if (oLevel.type == "multiple-container-cc") {
            // CloneContainer Hierarchie nach unten gehen
            if (oJSONControl.level[iLevel + 1] && oJSONControl.level[iLevel + 1].type == "multiple-container-cc") {
                if (oJSONControl.level[iLevel + 1].wsname.indexOf(":") > -1)
                    strPostNamePrefix2 =
                        strPostNamePrefix +
                        oJSONControl.level[iLevel + 1].wsname.substring(
                            oJSONControl.level[iLevel + 1].wsname.indexOf(":") + 1
                        );
                else strPostNamePrefix2 = strPostNamePrefix + oJSONControl.level[iLevel + 1].wsname;
                strPostNamePrefix2 = strPostNamePrefix2 + "_" + counter + ".";
                // logische KindContainer durchlaufen
                for (var j = 0; j < oCloneCont.aChildContainer.length; j++) {
                    this._addPostControls(
                        oJSONControl,
                        iLevel + 1,
                        oJSONControl.level[iLevel + 1],
                        strPostNamePrefix2,
                        oHtmlContainer
                    );
                }
            } else {
                // Kontrollen innerhalb dieses CloneContainers
                strPostNamePrefix2 = strPostNamePrefix + strWsName;
                strPostNamePrefix2 = strPostNamePrefix2 + "_" + counter + ".";
                this._addPostControls(
                    oJSONControl,
                    iLevel + 1,
                    oJSONControl.level[iLevel + 1],
                    strPostNamePrefix2,
                    oHtmlContainer
                );
            }
        } else if (oLevel.type == "multiple-control-cc") {
            // Kontrollen mit maxOccurs > 1
            strPostNamePrefix2 = strPostNamePrefix + strWsName + "_" + counter;
            strControlName =
                oJSONControl.controlName_org + oHtmlContainer.id.substring(oHtmlContainer.id.indexOf(IDCOUNTERSEP));
            strPostName = strPostNamePrefix2;
            strDataType = oJSONControl.datatype;
            this.addPostControlBpeeDynamic(strControlName, strPostName, strDataType);
        }
        counter += 1;
    }
    return;
}
/**
 * Adds a post control for webservices to a xmlhttp request.
 * @return boolean <code>true</code> or <code>false</code>
 * @private
 */
function XmlHttp_AddPostControl(p_strControlName, p_strPostName) {
    var strControlName = p_strControlName;
    var strPostName = p_strPostName;
    var oControl = document.getElementById("ID_" + strControlName);
    if (!oControl) return;
    if (oControl.oUp && oControl.oUp.upType == "upRadioControlGroup") {
        this.addPostData(strPostName, Browser.getValue(oControl.oUp));
    } else if (/(button|file|image|submit)/.test(oControl.type)) {
        return;
    } else if (/textarea/.test(oControl.type)) {
        this.addPostData(strPostName, oControl.value);
    } else {
        if (typeof oControl.oUp == "undefined") {
            if (/(radio|checkbox)/.test(oControl.type) && oControl.checked)
                this.addPostData(strPostName, oControl.value);
            else if (!/(radio|checkbox)/.test(oControl.type)) this.addPostData(strPostName, oControl.value);
        } else {
            if (/(radio|checkbox)/.test(oControl.type) && oControl.checked) {
                this.addPostData(strPostName, Browser.getValue(oControl.oUp));
            } else if (/(select)/.test(oControl.type)) {
                if (!Browser.getValue(oControl.oUp)) this.addPostData(strPostName, oControl.oUp.toWsInputString(""));
                else this.addPostData(strPostName, oControl.oUp.toWsInputString(Browser.getValue(oControl.oUp)));
            } else if (!/(radio|checkbox|select)/.test(oControl.type)) {
                this.addPostData(strPostName, oControl.oUp.toWsInputString(Browser.getValue(oControl.oUp)));
            }
        }
    }
}
/**
 * Adds a post control from clonecontainer to a xmlhttp request.
 * @return boolean <code>true</code> or <code>false</code>
 * @private
 */
function XmlHttp_AddPostControlBpeeDynamic(p_strControlName, p_strPostName, p_strDataType) {
    var strControlName = p_strControlName;
    var strPostName = p_strPostName;
    var strDataType = p_strDataType;
    var oControl = document.getElementById("ID_" + strControlName);
    if (!oControl) return;
    if (oControl.oUp && oControl.oUp.upType == "upRadioControlGroup") {
        this.addBpeeDynamicData(strPostName, strDataType, Browser.getValue(oControl.oUp));
    } else if (/(button|file|image|submit)/.test(oControl.type)) {
        return;
    } else if (/textarea/.test(oControl.type)) {
        this.addBpeeDynamicData(strPostName, strDataType, oControl.value);
    } else {
        if (typeof oControl.oUp == "undefined") {
            if (/checkbox/.test(oControl.type) && oControl.checked)
                this.addBpeeDynamicData(strPostName, strDataType, "true");
            else if (/checkbox/.test(oControl.type) && !oControl.checked)
                this.addBpeeDynamicData(strPostName, strDataType, "false");
            else this.addBpeeDynamicData(strPostName, strDataType, oControl.value);
        } else {
            if (/checkbox/.test(oControl.type) && oControl.checked)
                this.addBpeeDynamicData(strPostName, strDataType, "true");
            else if (/checkbox/.test(oControl.type) && !oControl.checked)
                this.addBpeeDynamicData(strPostName, strDataType, "false");
            else if (/radio/.test(oControl.type))
                this.addBpeeDynamicData(strPostName, strDataType, Browser.getValue(oControl.oUp));
            else
                this.addBpeeDynamicData(
                    strPostName,
                    strDataType,
                    oControl.oUp.toWsInputString(Browser.getValue(oControl.oUp))
                );
        }
    }
}
/**
 * Adds post controls from a form to a xmlhttp request.
 * @param p_oHtml A reference to a html object (form or funcpart).
 * @param p_bResetFormData <code>True</code>, if aRequestPostData is initialized.
 * @return boolean <code>true</code>, if the method is called correctly, otherwise <code>false</code>.
 */
function XmlHttp_AddForm(p_oHtml, p_bResetFormData) {
    if (arguments.length < 1 || typeof p_oHtml === "undefined" || p_oHtml === null) return false;
    if (arguments.length < 2 || p_bResetFormData) this.aRequestPostData = new Array();

    if (p_oHtml.nodeName === "FORM") {
        this.form = p_oHtml;
    }

    var aData;
    if (typeof p_oHtml.oUp === "object" && p_oHtml.oUp !== null) {
        aData = p_oHtml.oUp.serializeArray();
    } else if (p_oHtml.tagName.toLowerCase() === "form") {
        aData = ix.Ajax.serializeArray({
            formNode: p_oHtml,
        });
    } else {
        aData = ix.Ajax.serializeArray({
            containerNode: p_oHtml,
        });
    }

    if (aData) {
        for (var i = 0, l = aData.length; i < l; i++) {
            this.addPostData(aData[i].name, aData[i].value);
        }
    }

    return true;
}

/*
 * Checks, if the form can be submitted.
 * @param p_oForm A reference to a html form object
 * @return boolean <code>true</code>, if the form can be submitted, otherwise <code>false</code>.
 * @private
 */
function XmlHttp_CanSubmitForm(p_oForm) {
    var childElements = p_oForm.childNodes;
    if (typeof childElements != "undefined") {
        for (var i = 0; i < childElements.length; i++) {
            if (
                /(INPUT|TEXTAREA|SELECT)/.test(childElements[i].nodeName) &&
                /(file|image)/.test(childElements[i].type)
            ) {
                return false;
            }
            if (childElements[i].childNodes.length >= 1) {
                if (this.canSubmitForm(childElements[i]) == false) return false;
            }
        }
    }
    return true;
}
/**
 * Crossbrowser constructor for a xmlhttp object.
 */
function XmlHttp_RequestObject() {
    var xmlHttp = null;
    try {
        // IE7, Mozilla, Opera und Safari
        if (typeof window.XMLHttpRequest != "undefined" && window.XMLHttpRequest) {
            xmlHttp = new XMLHttpRequest();
        } else if (window.ActiveXObject) {
            // Internet Explorer bis 6 oder natives XmlHttp ist deaktiviert
            // MSXML 3.0
            try {
                xmlHttp = new ActiveXObject("Msxml2.XMLHTTP");
            } catch (excNotLoadable) {
                xmlHttp = null;
                alert("Could not instantiate Msxml2.3.0");
                return;
            }
        }
    } catch (excNotLoadable) {
        // init des xmlhttp objekts nicht moeglich
        xmlHttp = null;
        ErrorHandler.warn(excNotLoadable, "XmlHttp_RequestObject");
    }
    return xmlHttp;
}
/**
 * @class
 * The static instance ResponseHandler contains response handler for ajax calls.
 * @see upFupLoader
 * @see upLoader
 * @see upSimpleAjax
 */
function upResponseHandler() {}
upResponseHandler.prototype.processJson = ResponseHandlerProcessJson;
upResponseHandler.prototype.processJs = ResponseHandlerProcessJs;
upResponseHandler.prototype.processXml = ResponseHandlerProcessXml;
upResponseHandler.prototype.processJsFile = ResponseHandlerProcessJsFile;
upResponseHandler.prototype.loadFup = ResponseHandlerLoadFup;
upResponseHandler.prototype.processFup = ResponseHandleProcessFup;
upResponseHandler.prototype.loadToolTipPopup = ResponseHandlerLoadToolTipPopup;

// eslint-disable-next-line no-global-assign
ResponseHandler = new upResponseHandler();
/**
 * Responsehandler for xmlhttp requests which load a new functional part.
 * @see upFupLoader
 * @private
 */
function ResponseHandleProcessFup(data, textStatus, jqXHR) {
    /************* TODO's ******************
    globalen oFupLoader entfernen
    */

    // Timing Problem
    if (typeof oFupLoader == "undefined" || oFupLoader == null) {
        ErrorHandler.log("FupLoader is null - timing problem?", "ResponseHandler.loadFup()");
        return false;
    }
    oFupLoader.status = "ResponseHandler";

    var oXMLDocumentElement = data.documentElement;
    // unload des zuletzt geladenen Functional Parts
    // eslint-disable-next-line no-unused-vars
    var strOnUnLoad = "";

    if (oFupLoader.oHtmlTargetWnd.oLoadAction) oFupLoader.oHtmlTargetWnd.oLoadAction = null;
    if (oFupLoader.oHtmlTargetWnd.startFupLoad) oFupLoader.oHtmlTargetWnd.startFupLoad = null;
    var strFupId = "";
    if (oFupLoader.oStage.fupId) strFupId = oFupLoader.oStage.fupId;
    if (strFupId != "") {
        var fupId = strFupId;
        if (fupId != null && isNaN(fupId) && fupId.length > 0) {
            var oFup = ix.util.getUp(fupId);
            if (oFup) {
                // trigger unload event callbacks
                try {
                    oFup.triggerEvent("onunload");
                } catch (e) {
                    ErrorHandler.notify(
                        "Unexpected Exception while processing page onunload.",
                        "Unload Error",
                        "intrexx:fup:unload",
                        null,
                        e
                    );
                    debug(e);
                }

                try {
                    oFup.unload();
                } catch (e) {
                    console.log(
                        'intrexx error (unhook upFuncPart) in function "ResponseHandlerLoadFup":\n' + e,
                        "error"
                    );
                    ErrorHandler.Ajax.handleJsException("ResponseHandlerLoadFup", jqXHR, e);
                }
            }
            oFup = null;
        }
    }

    // neue Seite
    if (isStopwatch) {
        iStopIncludes1 = parseInt(new Date().getTime());
    }
    if (isStopwatch) {
        iStopIncludes2 = parseInt(new Date().getTime());
    }

    var strContent = oXMLDocumentElement.getElementsByTagName("htmlcode")[0].textContent;
    var strJsCode = ""; // Js-Code der geladenen Seite
    var strIncludes;
    if (
        oXMLDocumentElement.getElementsByTagName("javascript")[0] &&
        oXMLDocumentElement.getElementsByTagName("javascript")[0].firstChild
    ) {
        strJsCode = oXMLDocumentElement.getElementsByTagName("javascript")[0].textContent;
    }

    if (
        oXMLDocumentElement.getElementsByTagName("includes")[0] &&
        oXMLDocumentElement.getElementsByTagName("includes")[0].firstChild
    ) {
        strIncludes = oXMLDocumentElement.getElementsByTagName("includes")[0].textContent;
        oFupLoader.oHtmlTargetWnd.Loader.loadJsFilesOnDemand(strIncludes);
    }

    // Html setzen
    //Stopwatch
    if (isStopwatch) {
        iStopHtml1 = parseInt(new Date().getTime());
    }

    oFupLoader.oHtmlContainer.innerHTML = strContent;

    if (
        oXMLDocumentElement.getElementsByTagName("includes")[0] &&
        oXMLDocumentElement.getElementsByTagName("includes")[0].firstChild
    ) {
        strIncludes = oXMLDocumentElement.getElementsByTagName("includes")[0].textContent;
        oFupLoader.oHtmlTargetWnd.Loader.loadCssFilesOnDemand(strIncludes);
    }

    //Stopwatch
    if (isStopwatch) {
        iStopHtml2 = parseInt(new Date().getTime());
    }
    // fupid merken
    strFupId = "";
    strFupId = oXMLDocumentElement.getElementsByTagName("fupguid")[0].textContent;
    oFupLoader.oStage.fupId = strFupId;
    // Javascript evaluieren
    //Stopwatch
    if (isStopwatch) {
        iStopEval1 = parseInt(new Date().getTime());
    }

    var oFupLoaderBackup = oFupLoader;
    var aReturn;
    var strOnLoad = (strOnUnload = "");
    aReturn = oFupLoader.oHtmlTargetWnd.evalResponseJs(strContent);
    strOnUnload += aReturn[0];
    strOnLoad += aReturn[1];
    aReturn = oFupLoader.oHtmlTargetWnd.evalResponseJs('<script type="text/javascript">' + strJsCode + "</script>");
    strOnUnload += aReturn[0];
    strOnLoad += aReturn[1];

    oFupLoader = oFupLoaderBackup;

    //Stopwatch
    if (isStopwatch) {
        iStopEval2 = parseInt(new Date().getTime());
    }

    // unload merken
    oFupLoader.oStage.strOnUnLoad = strOnUnload;
    oFupLoader.status = "loaded";
    if (isStopwatch) {
        iStopOnload1 = parseInt(new Date().getTime());
    }

    oFupLoader.onAfterLoad();

    // cleanup before onload
    if (self.startFupLoad) self.startFupLoad = null;
    oFupLoader.oActionControl = null;
    oFupLoader.oHtmlContainer = null;
    oFupLoader = null;
    // evtl. onLoad ausfuehren
    if (strOnLoad != "") {
        try {
            eval(strOnLoad);
        } catch (e) {
            ErrorHandler.Javascript.handleEvalError("ResponseHandler.loadFup()", e, "in page onload", strOnLoad);
        }
    }
    if (isStopwatch) iStopOnload2 = parseInt(new Date().getTime());
}

function ResponseHandlerLoadFup() {
    try {
        // Timer loeschen
        if (typeof oXmlHttpRequestTimer == "number") {
            window.clearTimeout(oXmlHttpRequestTimer);
        }
        if (oRequest.readyState == State.done) {
            // error response
            if (!ErrorHandler.Ajax.checkXMLResponse("ResponseHandlerLoadFup", oRequest)) {
                return;
            }

            //Stopwatch
            if (isStopwatch) {
                iStartResponse = parseInt(new Date().getTime());
            }

            if (oRequest.status == Status.ok) {
                ResponseHandler.processFup(oRequest.responseXML, "success", oRequest);
            } else {
                ErrorHandler.Http.handleStatus("ResponseHandlerLoadFup", oRequest);
            }

            if (Browser.ie) {
                setTimeout("self.oUp.setFocus();", 100);
            } else self.oUp.setFocus();

            //Stopwatch
            if (isStopwatch) {
                iEnd = parseInt(new Date().getTime());
                console.log("Gesamt                           : " + (iEnd - iStart));
                console.log("Gesamt Browser                   : " + (iStopSend - iStart + (iEnd - iStartResponse)));
                console.log("Loader (bis send)                : " + (iStopSend - iStart));
                console.log("ResponseHandler gesamt           : " + (iEnd - iStartResponse));
                console.log("   ***Includes aus Xml           : " + (iStopIncludes2 - iStopIncludes1));
                console.log("   Responseverarbeitung bis hier : " + (iStopIncludes2 - iStartResponse));
                console.log("   ***InnerHtml                  : " + (iStopHtml2 - iStopHtml1));
                console.log("   Responseverarbeitung bis hier : " + (iStopHtml2 - iStartResponse));
                console.log("   ***Javascript der Seite       : " + (iStopEval2 - iStopEval1));
                console.log("   Responseverarbeitung bis hier : " + (iStopEval2 - iStartResponse));
                console.log("   ***Javascript im onload       : " + (iStopOnload2 - iStopOnload1));
                console.log("   Responseverarbeitung bis hier : " + (iStopOnload2 - iStartResponse));
            }
        }
        return;
    } catch (oExc) {
        if (typeof oFupLoader != "undefined" && oFupLoader) {
            oFupLoader.onAfterLoad();
        }
        ErrorHandler.Ajax.handleJsException("ResponseHandlerLoadFup", oRequest, oExc);
        if (oFupLoader) {
            oFupLoader.oActionControl = null;
            oFupLoader.oHtmlContainer = null;
        }
        throw oExc;
        // return;
    }
}
/**
 * Responsehandler for xmlhttp requests which load a new functional part into a tooltip.
 * @see upFupLoader
 * @private
 */
function ResponseHandlerLoadToolTipPopup() {
    try {
        // Timer loeschen
        if (typeof oXmlHttpRequestTimer == "number") window.clearTimeout(oXmlHttpRequestTimer);
        if (oRequest.readyState == State.done) {
            if (oRequest.status == Status.ok) {
                if (typeof oFupLoader == "undefined" || oFupLoader == null) return false;

                oFupLoader.status = "ResponseHandlerTooltipPopup";

                if (
                    typeof oFupLoader.oActionControl === "undefined" ||
                    oFupLoader.oActionControl === null ||
                    typeof oFupLoader.oActionControl.oToolTipPopup === "undefined" ||
                    oFupLoader.oActionControl.oToolTipPopup === null
                ) {
                    oFupLoader.onAfterLoad();
                    return;
                }
                // error response

                if (!ErrorHandler.Ajax.checkXMLResponse("ResponseHandlerLoadToolTipPopup", oRequest)) {
                    return;
                }
                var oXMLDocumentElement = oRequest.responseXML.documentElement;
                var oActionControl = oFupLoader.oActionControl;
                // Ziel-Tooltippopup, in dem die Zielseite geoeffnet wird
                var oToolTipPopup = oFupLoader.oActionControl.oToolTipPopup;
                // wird die Zielseite in demselben Tooltippopup geoeffnet?
                var bInsideToolTipPopup = false;

                var oNotifierEntry =
                    oToolTipPopup && oToolTipPopup != null && oToolTipPopup.oNotifierEntry
                        ? oToolTipPopup.oNotifierEntry
                        : false;
                var strContent = "";
                var strIncludes = "";
                // eslint-disable-next-line no-unused-vars
                var aReturn = new Array();
                var runIn = oActionControl.oFup ? oActionControl.oFup.runIn : "";

                if (oNotifierEntry) {
                    oNotifierEntry.oToolTipPopup = oToolTipPopup;
                }

                // WarningCode verarbeiten
                if (oXMLDocumentElement != null && typeof oXMLDocumentElement == "object") {
                    var l_strWarning = oXMLDocumentElement.getAttribute("warningCode");
                    if (typeof l_strWarning == "string" && l_strWarning.length > 0) {
                        Notifier.status.warn(l_strWarning, "Warning", "WARNING_CODE");
                    }
                }
                var oToolTipPopupRunIn = null;
                // arbeitet die ausloesende Aktionskontrolle in einem Tooltippopup?
                if (
                    oActionControl.oFup &&
                    oActionControl.oFup.oToolTipPopup &&
                    oActionControl.oFup.oToolTipPopup != null
                ) {
                    oToolTipPopupRunIn = oActionControl.oFup.oToolTipPopup;
                } else if (oActionControl.oTarget.bInsideToolTipPopup) {
                    // falls keine Fup da ist: wird die Zielseite in dasselbe vorhandene TooltipPopup geladen?
                    oToolTipPopupRunIn = oToolTipPopup;
                }

                if (oActionControl.close == "0" || runIn != "ToolTipPopup") {
                    var oNodesHtmlCode = oXMLDocumentElement.getElementsByTagName("htmlcode");
                    var oNodesInclude = oXMLDocumentElement.getElementsByTagName("includes");
                    var strJsCode = ""; // Js-Code der geladenen Seite
                    if (
                        oXMLDocumentElement.getElementsByTagName("javascript")[0] &&
                        oXMLDocumentElement.getElementsByTagName("javascript")[0].firstChild
                    ) {
                        strJsCode = oXMLDocumentElement.getElementsByTagName("javascript")[0].textContent;
                    }

                    if (oNodesHtmlCode.length > 0 && oNodesHtmlCode[0].firstChild != null) {
                        strContent = oNodesHtmlCode[0].textContent;
                    }
                    if (oNodesInclude.length > 0 && oNodesInclude[0].firstChild != null)
                        strIncludes = oNodesInclude[0].textContent;

                    var title = "";
                    if (typeof oToolTipPopup.bHasHeader == "boolean" && !oToolTipPopup.bHasHeader) {
                        title = null;
                    } else {
                        if (
                            oXMLDocumentElement.getElementsByTagName("title").length == 1 &&
                            oXMLDocumentElement.getElementsByTagName("title")[0].firstChild != null
                        ) {
                            title = oXMLDocumentElement.getElementsByTagName("title")[0].textContent;
                        }
                    }
                    // eslint-disable-next-line no-unused-vars
                    var l_bReloadNotifier = false;

                    if (typeof oNotifierEntry.strKey == "undefined" || oNotifierEntry.strKey == null) {
                        oNotifierEntry.strKey =
                            typeof oActionControl.oToolTipPopup.objectName == "string" &&
                            oActionControl.oToolTipPopup.objectName.length > 0
                                ? oActionControl.oToolTipPopup.objectName
                                : null;
                    }

                    // Adopt Tooltip Measures (neue Seite in ein bereits geöffnetes Tooltip-Popup laden)
                    if (
                        runIn == "ToolTipPopup" &&
                        oToolTipPopup != null &&
                        oActionControl.oTarget.bInsideToolTipPopup &&
                        oToolTipPopup.loaded
                    ) {
                        // alte AdoptMeasures entfernen
                        var oMeasure;
                        for (var i = 0, l = oNotifierEntry.aMeasures.length; i < l; i++) {
                            oMeasure = oNotifierEntry.aMeasures[i];
                            if (typeof oMeasure !== "undefined" && oMeasure.bAdoptMeasure) {
                                oNotifierEntry.undoMeasure(oMeasure);
                                oNotifierEntry.removeMeasure(oMeasure);
                            }
                        }

                        // neue AdoptMeasures setzen
                        if (typeof oActionControl.oTarget.strAdoptTooltipMeasures == "string") {
                            // fix-both | fix-width | fix-height | max-both | max-width | max-height | min-both | min-width | min-height
                            var l_aAdopt = oActionControl.oTarget.strAdoptTooltipMeasures.split("-");
                            if (l_aAdopt.length == 2) {
                                var l_oMeasure = new TooltipMeasure({
                                    strMeasure: l_aAdopt[0],
                                });
                                var l_oHtmlScroll =
                                    typeof oToolTipPopup.oNotifierEntry.hasBody == "function" &&
                                    oToolTipPopup.oNotifierEntry.hasBody()
                                        ? oToolTipPopup.oNotifierEntry.oHtml.oHtmlBody
                                        : oToolTipPopup.oNotifierEntry.oHtml;
                                var l_iPaddingLeft = Math.max(
                                    Helper.style.getMeasureInfos(
                                        Helper.style.getComputedStylePropByElement(l_oHtmlScroll, "padding-left")
                                    ).iMeasureValue,
                                    0
                                );
                                var l_iPaddingRight = Math.max(
                                    Helper.style.getMeasureInfos(
                                        Helper.style.getComputedStylePropByElement(l_oHtmlScroll, "padding-right")
                                    ).iMeasureValue,
                                    0
                                );
                                var l_iPaddingTop = Math.max(
                                    Helper.style.getMeasureInfos(
                                        Helper.style.getComputedStylePropByElement(l_oHtmlScroll, "padding-top")
                                    ).iMeasureValue,
                                    0
                                );
                                var l_iPaddingBottom = Math.max(
                                    Helper.style.getMeasureInfos(
                                        Helper.style.getComputedStylePropByElement(l_oHtmlScroll, "padding-bottom")
                                    ).iMeasureValue,
                                    0
                                );

                                if (l_aAdopt[1] == "both" || l_aAdopt[1] == "width") {
                                    l_oMeasure.iWidth = l_oHtmlScroll.offsetWidth - l_iPaddingLeft - l_iPaddingRight;
                                }
                                if (l_aAdopt[1] == "both" || l_aAdopt[1] == "height") {
                                    l_oMeasure.iHeight = l_oHtmlScroll.offsetHeight - l_iPaddingTop - l_iPaddingBottom;
                                }
                                l_oMeasure.bAdoptMeasure = true;
                                oNotifierEntry.addMeasure(l_oMeasure); //new TooltipMeasure({strMeasure:"fix", iWidth:100, iHeight:100})
                            }
                        }
                    }

                    // Bewegen innerhalb desselben ToolTipPopup
                    if (
                        runIn == "ToolTipPopup" &&
                        oToolTipPopup != null &&
                        oActionControl.oTarget.bInsideToolTipPopup
                    ) {
                        //oToolTipPopup.loaded
                        if (oToolTipPopup.loaded) {
                            if (oActionControl.processReloadAndClose != null) {
                                oActionControl.processReloadAndClose({
                                    oToolTipPopup: oToolTipPopup,
                                });
                            }

                            oToolTipPopup.unload();
                            bInsideToolTipPopup = true;
                            l_bReloadNotifier = true;
                        } else {
                            // Tooltippopup wird noch geladen -> Abbruch
                            ErrorHandler.notify(
                                "Could not load tooltip because target tooltip container is not yet finished loading."
                            );
                            return false;
                        }
                    } else if (oToolTipPopup != null && runIn != "ToolTipPopup" && oToolTipPopup.loaded) {
                        // falls es ToolTipPopup schon gibt
                        oToolTipPopup.unload();
                        // eslint-disable-next-line no-unused-vars
                        l_bReloadNotifier = true;
                    }

                    // TTP-Titel setzen
                    if (
                        typeof oNotifierEntry.strTitle != "string" ||
                        (runIn == "ToolTipPopup" && oToolTipPopup != null && bInsideToolTipPopup)
                    ) {
                        if (typeof oNotifierEntry.strTitle != "boolean" || oNotifierEntry.strTitle) {
                            oNotifierEntry.strTitle = title;
                        }
                    }

                    oNotifierEntry.strText = strContent + '<script type="text/javascript">' + strJsCode + "</script>";

                    Loader.loadCssFilesOnDemand(strIncludes);
                    Loader.loadJsFilesOnDemand(strIncludes);

                    // Falls das TTP bereits waehrend der Abarbeitung wieder geschlossen wurde Vorgang abbrechen
                    // Hinweis: Dieser Fall kann durch die asynchrone Abarbeitung auftreten
                    if (
                        !bInsideToolTipPopup &&
                        (oFupLoader.oActionControl == null ||
                            oFupLoader.oActionControl.oToolTipPopup == null ||
                            oToolTipPopup.oActionControl == null)
                    ) {
                        oFupLoader.onAfterLoad();
                        return;
                    }

                    // eine neue Seite im aktuellen TTP laden, Mauspositionierung auf Absolute Positionierung aendern um ein wandern des TTP zu verhindern
                    if (
                        bInsideToolTipPopup &&
                        oToolTipPopup.oNotifierEntry &&
                        oToolTipPopup.oNotifierEntry.oPosition &&
                        oToolTipPopup.oNotifierEntry.oPosition.upType == "TooltipPositionMouse"
                    ) {
                        var oHtml = oToolTipPopup.oNotifierEntry.oHtml;
                        var oPos;
                        if (oHtml.style.position == "fixed") {
                            oPos = Browser.getPosition2Page(oHtml);
                        } else {
                            oPos = Browser.getPosition2Container(oHtml, false); // Position (inklusive der Scrollposition)
                        }

                        var bOverflowWindow = oToolTipPopup.oNotifierEntry.oPosition.bOverflowWindow;
                        oToolTipPopup.oNotifierEntry.oPosition = new upPositioningAbsolute(
                            "below_right",
                            oPos.posX,
                            oPos.posY,
                            bOverflowWindow,
                            false
                        );
                    }

                    // TTP anzeigen und JS evaluieren
                    oToolTipPopup.showPopup();

                    // TTP Ladevorgang wurde abgebrochen
                    if (oFupLoader == null) {
                        return;
                    }

                    oFupLoader.status = "loadedToolTipPopup";
                }
                // eslint-disable-next-line no-unused-vars
                var l_oFupLoader = oFupLoader;

                if (!bInsideToolTipPopup && oActionControl.processReloadAndClose != null) {
                    oActionControl.processReloadAndClose({
                        oToolTipPopup: oToolTipPopupRunIn,
                    });
                }

                // Dirty: Falls oFupLoader nach dem processReloadAndClose auf NULL gesetzt ist
                if (oFupLoader == null) {
                    return;
                }

                oFupLoader.onAfterLoad();

                // falls FupLoader vorhanden und das Laden beendet ist FupLoader zuruecksetzen
                if (oFupLoader != null && oFupLoader.status == "loadedToolTipPopup") {
                    oFupLoader.oHtmlContainer = null;
                    oFupLoader.oActionControl = null;
                    oFupLoader = null;
                }
            } else {
                ErrorHandler.Http.handleStatus("ResponseHandlerLoadToolTipPopup", oRequest);
            }

            try {
                oActionControl.oOrgProperties = null;
                oActionControl = null;
            } catch (e) {
                debug(e);
            }

            return;
        }
    } catch (oExc) {
        ErrorHandler.Ajax.handleJsException("ResponseHandlerLoadToolTipPopup", oRequest, oExc);
        if (oFupLoader) {
            oFupLoader.onAfterLoad();
            oFupLoader = null;
        }
        throw oExc;
        // return;
    }
}
/**
 * Responsehandler for loading javascript files by ajax.
 * This is the responseHandler for Loader.loadJsFileOnDemand().
 * @see Loader
 * @private
 */
function ResponseHandlerProcessJsFile() {
    if (oRequest.readyState == State.done) {
        // Timer loeschen
        if (typeof oXmlHttpRequestTimer == "number") window.clearTimeout(oXmlHttpRequestTimer);
        if (oRequest.status == Status.ok) {
            var l_bResetDocWrite = false;
            // eslint-disable-next-line no-unused-vars
            var name;
            // eslint-disable-next-line no-unused-vars
            var result;
            var content = oRequest.responseText;
            if (typeof this.fileName != "string") this.fileName = "unknown";
            // document . write unterbinden
            if (
                typeof Loader.aFilesWithoutDocWriteHandling[this.fileName] == "undefined" &&
                content.indexOf("document.write") > -1 &&
                typeof self.oUp.oMessage == "object"
            ) {
                var txt = self.oUp.oMessage.ERR_DOCWRITE_FILE_TEXT.replace(
                    /<filename>/,
                    "<i>&lt;" + this.fileName + "&gt;</i>"
                );
                l_bResetDocWrite = true;
                var bakFuncWrite = document.write;
                var bakFuncWriteln = document.writeln;
                if (self.name != oHtmlRoot.name) {
                    Notifier.status.error(txt, self.oUp.oMessage.ERR_DOCWRITE_FILE_TITLE);
                }
                // eslint-disable-next-line no-unused-vars
                document.write = document.writeln = function (t) {};
            }
            // Js-Code ausfuehren
            try {
                Browser.evalInGlobalContext(content, true);
            } catch (e) {
                var strIn;
                if (this.fileName.indexOf("/application.js") > -1) strIn = "in <strong>user defined file</strong>: ";
                else strIn = "in file: ";
                strIn += this.fileName;
                ErrorHandler.Javascript.handleEvalError("ResponseHandler.processJsFile()", e, strIn, content);
            }
            // document . write ggf. zuruecksetzen
            if (l_bResetDocWrite) {
                document.write = bakFuncWrite;
                document.writeln = bakFuncWriteln;
            }
            // application.js immer neu laden, siehe Issue#30534: Anwendungen gleichen Vorlagentyps überschreiben sich gegenseitig JS-Funktionen
            if (this.fileName != null && this.fileName.indexOf("/application.js") < 0) {
                var jsName = this.fileName;
                Loader.jsFileRegistry.push(jsName);
            }
            return true;
        } else if (oRequest.status == "0") {
            // z.B. wird das Portal neu geladen, wenn gerade ein File preloaded wird
            return;
        } else {
            ErrorHandler.Http.handleStatus("ResponseHandlerProcessJsFile", oRequest);
        }
    }
}
/**
 * Trims a string.
 * @param s String to trim.
 * @private
 */
function Trim(s) {
    if (arguments.length == 0 || typeof s == "undefined") return false;
    s = new String(s);
    return s.replace(/^\s+|\s+$/g, "");
}
/**
 * Handler for a xml response to a xmlhttp request. This Handler provides our standard functionality like errorhandling.<br>
 * It is most easy to use it with upSimpleAjax.loadXmlVm().
 * @param p_func Reference to a function, which includes the specific functionality.
 * @see upSimpleAjax
 */
function ResponseHandlerProcessXml(p_func) {
    try {
        // Timer loeschen
        if (typeof oXmlHttpRequestTimer == "number") window.clearTimeout(oXmlHttpRequestTimer);
        if (oRequest.readyState == State.done) {
            if (oRequest.status == Status.ok) {
                // error response
                if (!ErrorHandler.Ajax.checkXMLResponse("ResponseHandlerProcessXml", oRequest)) {
                    return;
                }
                var oXMLDocumentElement = oRequest.responseXML.documentElement;

                if (arguments.length == 1) {
                    p_func(oXMLDocumentElement);
                } else if (typeof this.userResponseHandler != "undefined") {
                    this.userResponseHandler(oXMLDocumentElement);
                }
            } else {
                ErrorHandler.Http.handleStatus("ResponseHandlerProcessJson", oRequest);
                return;
            }
        }
    } catch (oExc) {
        ErrorHandler.Ajax.handleJsException("ResponseHandlerProcessXml", oRequest, oExc);

        if (oFupLoader) {
            oFupLoader.onAfterLoad();
            oFupLoader = null;
        }

        return;
    }
}
/**
 * Handler for a json response to a xmlhttp request.This Handler provides our standard functionality like errorhandling.<br>
 * It is most easy to use it with upSimpleAjax.loadJsonVm().
 * @param p_func Reference to a function, which includes the specific functionality.
 */
function ResponseHandlerProcessJson(p_func) {
    try {
        if (typeof oXmlHttpRequestTimer == "number") window.clearTimeout(oXmlHttpRequestTimer);
        if (oRequest.readyState == State.done) {
            if (oRequest.status == Status.ok) {
                var oJSON;
                if (Browser.safari) {
                    oJSON = eval("(" + oRequest.responseText + ")");
                } else {
                    oJSON = JSON.parse(oRequest.responseText);
                }
                // error response
                if (!ErrorHandler.Ajax.checkJSONResponse("ResponseHandlerProcessJson", oRequest, oJSON)) {
                    return;
                }
                if (arguments.length == 1) {
                    return p_func(oJSON);
                } else if (typeof this.userResponseHandler != "undefined") {
                    return this.userResponseHandler(oJSON);
                }
            } else {
                ErrorHandler.Http.handleStatus("ResponseHandlerProcessJson", oRequest);

                if (oFupLoader) {
                    oFupLoader.onAfterLoad();
                    oFupLoader = null;
                }

                if (typeof this.userErrorHandler != "undefined") {
                    return this.userErrorHandler();
                }

                return;
            }
        }
    } catch (oExc) {
        ErrorHandler.Ajax.handleJsException("ResponseHandlerProcessJson", oRequest, oExc);

        if (oFupLoader) {
            oFupLoader.onAfterLoad();
            oFupLoader = null;
        }

        if (typeof this.userErrorHandler != "undefined") {
            return this.userErrorHandler();
        }

        return;
    }
}
/**
 * Handler for a javascript response to a xmlhttp request.This Handler provides our standard functionality like errorhandling.<br>
 * It is most easy to use it with upSimpleAjax.loadJsVm().<br>
 * @param p_func Reference to a function, which includes the specific functionality.
 */
function ResponseHandlerProcessJs(p_bEvalJs, p_func) {
    try {
        if (typeof oXmlHttpRequestTimer == "number") window.clearTimeout(oXmlHttpRequestTimer);
        if (oRequest.readyState == State.done) {
            if (
                oRequest.status == Status.ok &&
                ErrorHandler.Ajax.checkJsResponse("ResponseHandlerProcessJs", oRequest)
            ) {
                var content = oRequest.responseText;

                if (p_bEvalJs) {
                    var l_bRet = false;
                    // Js-Code ausfuehren
                    try {
                        Browser.evalInGlobalContext(content, true);
                        l_bRet = true;
                    } catch (e) {
                        ErrorHandler.Javascript.handleEvalError(
                            "ResponseHandler.processJs()",
                            e,
                            "in js code: ",
                            content
                        );
                    }

                    if (arguments.length == 1 && typeof p_func == "function") {
                        return p_func(l_bRet);
                    } else if (typeof this.userResponseHandler != "undefined") {
                        return this.userResponseHandler(l_bRet);
                    }

                    return l_bRet;
                } else {
                    p_func(content);
                }
            } else {
                ErrorHandler.Http.handleStatus("ResponseHandlerProcessJs", oRequest);
                return;
            }
        }
    } catch (oExc) {
        ErrorHandler.Ajax.handleJsException("ResponseHandlerProcessJs", oRequest, oExc);
        return;
    }
}
//////////////////////////////////////////////////////////////////////////////////// UpLoader /////////////////////////////////////////////////////////////////////
/**
 * @file Loader
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* global Loader, g_oUsn, oHtmlRoot */
/* exported upLoader, LoaderLoadCssFileOnDemand, LoaderLoadJsFilesOnDemand, LoaderLoadCssFilesOnDemand,
            LoaderGetFromJsFileReg, LoaderLoadCssFileOnDemandByScreen, LoaderIsExternalUri, LoaderLoadJsFileOnDemand,
            Loader_LoadJsFileOnDemand, parseUri
*/
/**
    @class Loader
    @private
    @description
    Loader Class, responsible for dynamically loading JS and CSS Files
*/
function upLoader() {
    this.queuedScripts = [];
    this.jsFileRegistry = [];
    // this.onDemandPrototypeList  = [];
    this.postOnloadList = [];
    this.bHeadCssScanned = false;
    this.cssFileRegistry = {};
    this.bScriptsScanned = false;
}
/**
 * @param {String} fileName Path and filename of the css file.
 * @param {String} media The media property.
 * @return {Object|Boolean} style object
 * @description
 * Loads a css file, but not by ajax.
 */
function LoaderLoadCssFileOnDemand(fileName, media) {
    if (typeof fileName != "string" || fileName.length == 0) {
        return false;
    }

    if (typeof this.cssFileRegistry[fileName] == "undefined") {
        if (fileName.indexOf("[layout]") > -1) {
            fileName = fileName.replace(/\[layout\]/g, oHtmlRoot.oUp.oFormatInfo.layoutDir);
        }

        if (g_oUsn && fileName.indexOf("usn=") == -1) {
            if (fileName.indexOf("/" + oHtmlRoot.oUp.oFormatInfo.layoutDir + "/") > -1) {
                fileName += "?usn=" + g_oUsn.keyCssLayout;
            } else {
                fileName += "?usn=" + g_oUsn.keyCss;
            }
        }
        this.cssFileRegistry[fileName] = fileName;
        var nCssLink = document.createElement("link");
        nCssLink.rel = "stylesheet";
        nCssLink.type = "text/css";
        nCssLink.href = fileName;
        if (typeof media === "string" && media.length > 0) {
            nCssLink.media = media;
        }
        document.getElementsByTagName("head")[0].appendChild(nCssLink);
    }
    return true;
}
/**
 * @param {String} htmlText Html text, which includes link tags.
 * @param {String} container html element or string
 * @return {Boolean} boolean <code>true</code>
 * @description
 * Loads all css files by ajax to which a html text is linking.
 */
// eslint-disable-next-line no-unused-vars
function LoaderLoadCssFilesOnDemand(htmlText, container) {
    var cssName;
    // bereits im HEAD geladene CSS-Files
    if (!this.bHeadCssScanned) {
        var head = document.getElementsByTagName("head")[0];
        var aCssNodesHead = head.getElementsByTagName("link");
        // bereits geladene Css-Files
        for (var i = 0; i < aCssNodesHead.length; i++) {
            cssName = aCssNodesHead[i].getAttribute("href");
            if (cssName !== null) {
                this.cssFileRegistry[cssName] = cssName;
            }
        }
        this.bHeadCssScanned = true;
    }
    if (
        typeof htmlText !== "undefined" &&
        (typeof htmlText === "string" || (typeof htmlText === "object" && htmlText instanceof String))
    ) {
        if (htmlText.toString().length > 0) {
            var html = $.parseHTML(htmlText.toString());
            var that = this;
            if ($.isArray(html)) {
                $.each(html, function (i, el) {
                    // use only link-nodes, which represent stylesheets
                    if (el.nodeType === 1 && el.nodeName === "LINK") {
                        that.loadCssFileOnDemand(el.getAttribute("href"), el.media);
                    }
                });
            }
        }
    }

    return true;
}
/**
    @private
    @param {String} fileName
    @description
    Loads a css file, but not by ajax.
    @param fileName Path and filename of the css file.
    @return {Object|Boolean} style object
*/
function LoaderLoadCssFileOnDemandByScreen(fileName) {
    if (typeof this.cssFileRegistry[fileName] == "undefined") {
        // CSS fuer IEs nachladen
        if (document.createStyleSheet) {
            document.createStyleSheet(fileName);
            return document.styleSheets[document.styleSheets.length - 1];
        } else {
            // CSS fuer Mozilla, Netscape, Opera nachladen
            var objStyle = document.createElement("style");
            var objText = document.createTextNode("@import url(" + fileName + ") screen;");
            objStyle.appendChild(objText);
            document.getElementsByTagName("body")[0].appendChild(objStyle);
            return objStyle;
        }
    } else {
        return false;
    }
}
/**
 * @param {String} htmlText Html text, which includes &lt;script src> tags.
 * @return {Boolean} boolean <code>true</code>
 * @description
 * Loads all javascript files by ajax to which a html text is linking.
 */
function LoaderLoadJsFilesOnDemand(htmlText) {
    var jsName;
    var match;
    // Nachladen von inkludierten JS-Files (auch per document.write - HtmlEditor)
    var regEx = /(<script[^>]*src=[^>]*>)([^<]*)(<\/script>)/gi;
    var aScripts = htmlText.match(regEx);
    if (aScripts !== null) {
        for (var i = 0; i < aScripts.length; i++) {
            match = aScripts[i];
            jsName = match.slice(match.indexOf("src") + 5, match.indexOf('"', match.indexOf("src") + 5));
            Loader.loadJsFileOnDemand(jsName);
        }
    }
    return true;
}
/**
 * Returns an Element from jsFileRegistry.
 */
function LoaderGetFromJsFileReg(fileName) {
    for (var i = 0; i < this.jsFileRegistry.length; i++) {
        if (fileName == this.jsFileRegistry[i]) {
            return fileName;
        }
    }
    return null;
}

/**
 * @description
 * Loads a javascript file.
 * @param {String} fileName Path and filename of the javascript file.
 * @param {Boolean} isAsync Boolean, whether the xmlhttp request shall be asynchron.
 * @return {Object|Boolean} boolean <code>true</code>, if file could be loaded, otherwise <code>false</code>.
 */
function LoaderLoadJsFileOnDemand(fileName, isAsync) {
    if (typeof isAsync != "boolean") {
        isAsync = false;
    }
    return this._loadJsFileOnDemand(fileName, isAsync);
}
/**
 * @private
 */
function Loader_LoadJsFileOnDemand(fileName, isAsync) {
    var defer = $.Deferred();
    defer.promise();

    if (typeof isAsync != "boolean") {
        isAsync = false;
    }

    var url = fileName;

    // root path workaround
    if (
        url.indexOf("thirdparty/", 0) === 0 ||
        url.indexOf("userfiles/", 0) === 0 ||
        url.indexOf("script/", 0) === 0 ||
        url.indexOf("include/", 0) === 0
    ) {
        url = "/" + url;
    }

    if (typeof g_oUsn != "undefined" && url.indexOf("usn=") == -1) {
        url += (url.indexOf("?") ? "?usn=" : "&usn=") + g_oUsn.keyInclude;
    }

    var bCache = fileName.indexOf("/application.js") == -1;

    if (bCache) {
        if (Loader.getFromJsFileReg(fileName) != null) {
            defer.resolve();
            return defer;
        }
    }

    $.ajax({
        async: isAsync,
        cache: true,
        url: url,
        dataType: "script",
        type: "GET",
        context: this,
        ixSetXHR: false,
        success: function (data, textStatus, jqXHR) {
            Loader.jsFileRegistry.push(fileName);
            defer.resolve(jqXHR);
        },
        // eslint-disable-next-line no-unused-vars
        error: function (jqXHR, textStatus, errorThrown) {
            defer.reject(jqXHR);
        },
        complete: defer.resolve,
    });

    return defer;
}
/**
 * @param {String} url
 * @return {Boolean} isExternal
 * @description Examine file uri.
 */
function LoaderIsExternalUri(url) {
    return ix.loader.isExternalUri(url);
}
/**
 * @param {string} name The name of the bundle.
 */
upLoader.getLegacyBundle = function (name) {
    // eslint-disable-next-line no-undef
    return "/script/dist-legacy/" + __legacyBundles[name];
};

/*
    @description
    extend the upLoader Instance
*/
upLoader.prototype = $.extend(true, upLoader.prototype, {
    /**
     * @memberof upLoader
     */
    onDemandPrototypeList: new Array(
        new upOnDemandPrototype("_upFlipFlopControl", [upLoader.getLegacyBundle("flipflop.js")], ""),
        new upOnDemandPrototype("_upMultiSelectControl", [upLoader.getLegacyBundle("multiselect.js")], ""),
        new upOnDemandPrototype("_upDistributeControl", [upLoader.getLegacyBundle("multiselect.js")], ""),
        new upOnDemandPrototype("_upDistributeParticipantsControl", [upLoader.getLegacyBundle("multiselect.js")], ""),
        new upOnDemandPrototype("_upDistributorControl", [upLoader.getLegacyBundle("multiselect.js")], ""),
        new upOnDemandPrototype("_upDistributeOrgUnitsControl", [upLoader.getLegacyBundle("multiselect.js")], ""),
        new upOnDemandPrototype("_upSimpleAjax", [upLoader.getLegacyBundle("simpleajax.js")], "PRELOAD"),
        new upOnDemandPrototype("_upSimpleAjaxContainer", [upLoader.getLegacyBundle("simpleajax.js")], ""),
        new upOnDemandPrototype("_upEventListenerContainer", [upLoader.getLegacyBundle("eventmanager.js")], ""),
        new upOnDemandPrototype("upEffectHelper", [upLoader.getLegacyBundle("effecthelper.js")], ""),
        new upOnDemandPrototype(
            "upWebServiceProcessor",
            [upLoader.getLegacyBundle("webservice.js"), upLoader.getLegacyBundle("namespace.js")],
            ""
        ),
        new upOnDemandPrototype("upCloneContainer", [upLoader.getLegacyBundle("clonecontainer.js")], ""),
        new upOnDemandPrototype("upScaleInterval", [upLoader.getLegacyBundle("scaleinterval.js")], "PRELOAD"),
        new upOnDemandPrototype("upContextMenu", [upLoader.getLegacyBundle("contextmenu.js")], ""),
        new upOnDemandPrototype("upGroovyCalNavigator", [upLoader.getLegacyBundle("groovycalnav.js")], "PRELOAD"),
        new upOnDemandPrototype("upGroovySelect", [upLoader.getLegacyBundle("groovyselect.js")], ""),
        new upOnDemandPrototype("upTreeControl", [upLoader.getLegacyBundle("treecontrol.js")], ""),
        new upOnDemandPrototype("upTreeBreadCrumbControl", [upLoader.getLegacyBundle("treecontrol.js")], ""),
        new upOnDemandPrototype("upPinboard", [upLoader.getLegacyBundle("pinboard.js")], "")
    ),
});

upLoader.prototype.getFromJsFileReg = LoaderGetFromJsFileReg;
upLoader.prototype.loadJsPostOnload = LoaderLoadJsPostOnload;
upLoader.prototype.loadJsFilesOnDemand = LoaderLoadJsFilesOnDemand;
upLoader.prototype.loadScriptXhrInjection = LoaderLoadScriptXhrInjection;
upLoader.prototype.injectScripts = LoaderInjectScripts;
upLoader.prototype.loadJsFileOnDemand = LoaderLoadJsFileOnDemand;
upLoader.prototype.preloadJsFileOnDemand = LoaderPreloadJsFileOnDemand;
upLoader.prototype._loadJsFileOnDemand = Loader_LoadJsFileOnDemand;
upLoader.prototype._loadExternalJsFileOnDemand = Loader_LoadExternalJsFileOnDemand;
upLoader.prototype.registerLoadedJsFiles = LoaderRegisterLoadedJsFiles;
upLoader.prototype.loadCssFilesOnDemand = LoaderLoadCssFilesOnDemand;
upLoader.prototype.loadCssFileOnDemand = LoaderLoadCssFileOnDemand;
upLoader.prototype.loadCssFileOnDemandByScreen = LoaderLoadCssFileOnDemandByScreen;
upLoader.prototype.loadCssFileOnDemandInline = LoaderLoadCssFileOnDemandInline;
upLoader.prototype.loadUrl = LoaderLoadUrl;
upLoader.prototype._loadUrl = Loader_LoadUrl;
upLoader.prototype.loadStageUrl = LoaderLoadStageUrl;
upLoader.prototype.loadStageUrlAndSetMenu = LoaderLoadStageUrlAndSetMenu;
upLoader.prototype.loadStageUrlAndInitializeMenu = LoaderLoadStageUrlAndInitializeMenu;
upLoader.prototype.loadStageUrlAndClearMenu = LoaderLoadStageUrlAndClearMenu;
upLoader.prototype.loadExternalUrl = LoaderLoadExternalUrl;
upLoader.prototype._loadStageUrl = Loader_LoadStageUrl;
upLoader.prototype.loadLastPage = LoaderLoadLastPage;
upLoader.prototype.createIndicator = LoaderCreateIndicator;
upLoader.prototype.isExternalUri = LoaderIsExternalUri;
/**
 * @deprecated
 */
upLoader.prototype.hijaxAllLinks = function () {
    console.log(
        "Loader.hijaxAllLinks is a deprected function in Intrexx 5.1. Please use html attribute data-hijax instead!"
    );
};
upLoader.prototype.hijaxLink = function (p_strId) {
    console.log(
        "Loader.hijaxLink is a deprected function in Intrexx 5.1. Please use html attribute data-hijax instead!"
    );
    if ($("#" + p_strId).attr("rel") === undefined || $("#" + p_strId).attr("rel") != "External") {
        $("#" + p_strId).attr("data-hijax", "stage");
    }
};
// eslint-disable-next-line no-global-assign
Loader = new upLoader();

// initialize loaded css files
if (typeof jQuery != "undefined" && typeof $ != "undefined") {
    $(document).ready(function () {
        try {
            // eslint-disable-next-line no-unused-vars
            $('link[type="text/css"]').each(function (index) {
                Loader.cssFileRegistry[$(this).attr("href")] = $(this).attr("href");
            });
        } catch (e) {
            console.log("Intrexx Error: could not inititialize css file registry.");
        }
    });
}

/**
 * Creates a loadindicator.
 * @return boolean  <code>true</code>.
 */
function LoaderCreateIndicator() {
    var loadIndicator = document.createElement("div");
    loadIndicator.id = "loadindicator";
    document.body.appendChild(loadIndicator);
    return true;
}

/**
 * Preloads javascript files after onload event.
 */
function LoaderLoadJsPostOnload() {
    for (var i = 0; i < this.postOnloadList.length; i++) {
        this.loadJsFileOnDemand(this.postOnloadList[i], false);
    }
    var pInfo = null;
    // eslint-disable-next-line no-redeclare
    for (var i = 0; i < this.onDemandPrototypeList.length; i++) {
        pInfo = this.onDemandPrototypeList[i];
        if (pInfo.strUse == "PRELOAD") {
            for (var j = 0; j < pInfo.aFileNames.length; j++) {
                if (pInfo.aFileNames[j].substr(pInfo.aFileNames[j].length - 4) == ".css")
                    Loader.loadCssFileOnDemand(pInfo.aFileNames[j]);
                else Loader.preloadJsFileOnDemand(pInfo.aFileNames[j]);
            }
        }
    }
}
/**
 * Loads a script by Steve Souders ScriptXhrInjection technic.
 * @param url An url.
 * @param onload A script to execute after script is loaded or null.
 * @return {String}something
 */
function LoaderLoadScriptXhrInjection(url, onload) {
    if (this.getFromJsFileReg(url) != null) {
        if (onload) onload();
        return;
    }
    var iQ = this.queuedScripts.length;
    var qScript = {
        file: url,
        response: null,
        onload: onload,
        done: false,
    };
    this.queuedScripts[iQ] = qScript;
    var xhrObj = new XmlHttp_RequestObject();
    xhrObj.onreadystatechange = function () {
        if (xhrObj.readyState == 4) {
            Loader.queuedScripts[iQ].response = xhrObj.responseText;
            Loader.injectScripts();
        }
    };
    xhrObj.open("GET", url, true);
    xhrObj.send("");
}
// Process queued scripts to see if any are ready to inject.
function LoaderInjectScripts() {
    var len = this.queuedScripts.length;
    for (var i = 0; i < len; i++) {
        var qScript = Loader.queuedScripts[i];
        if (!qScript.done) {
            if (!qScript.response) {
                // STOP! need to wait for this response
                break;
            } else {
                try {
                    Browser.evalInGlobalContext(qScript.response, true);
                    Loader.jsFileRegistry.push(qScript.url);
                } catch (e) {
                    ErrorHandler.Javascript.handleEvalError(
                        "ResponseHandler.processJsFile()",
                        e,
                        qScript.file,
                        qScript.response
                    );
                }
                if (qScript.onload) qScript.onload();
                qScript.done = true;
            }
        }
    }
}

/**
 * Loads an url of another site. This works not by ajax, but by location.href.
 * @param p_strUrl An url.
 * @return boolean <code>false</code>, if no stage frame could be found, <code>true</code> if it could be found and document could be loaded.
 */
function LoaderLoadExternalUrl(p_strUrl) {
    var oHtmlStageWindow = window;
    if (oHtmlStageWindow) {
        oHtmlStageWindow.location.href = p_strUrl;
        return true;
    } else {
        return false;
    }
}

/**
 * Loads an Intrexx url into appmain container.
 * @param p_strUrl An url
 * @return boolean  <code>false</code>, if no appmain or stage could be found, <code>true</code> if it could be found and url could be loaded.
 */
function LoaderLoadUrl(url) {
    var _settings = {};
    var oHtmlStageWindow = window;

    if (isString(url)) {
        _settings.url = url;
    } else if ($.isPlainObject(url)) {
        _settings = $.extend(true, {}, _settings, url);
    }
    //Stopwatch
    if (isStopwatch) {
        iStart = parseInt(new Date().getTime());
    }
    if (document.getElementById("Container_AppMain")) {
        _settings.htmlTarget = "#Container_AppMain";
        this._loadUrl(_settings);
        return true;
    }
    if (oHtmlStageWindow && oHtmlStageWindow.Loader) {
        _settings.htmlTarget = window;
        oHtmlStageWindow.Loader._loadUrl(_settings.url);
        return true;
    } else {
        return false;
    }
}
/**
 * @private
 */
function Loader_LoadUrl(url) {
    ix.loader.stage({ url: url });
}
/**
 * Loads an Intrexx url into a stage container and sets the menu.
 * @param url An url.
 * @return boolean  <code>true</code>
 */
function LoaderLoadStageUrlAndSetMenu(url) {
    return ix.loader.loadStageUrlAndSetMenu(url);
}
/**
 * Loads an Intrexx url into a stage container.
 * @param p_strUrl An url.
 * @return boolean  <code>true</code>
 */
function LoaderLoadStageUrl(url) {
    this._loadStageUrl(url);
}
/**
 * Loads an Intrexx url into a stage container and initializes the menu.
 * @param p_strUrl An url.
 * @return boolean  <code>true</code>
 */
function LoaderLoadStageUrlAndInitializeMenu(p_strUrl) {
    return ix.loader.loadStageUrlAndInitializeMenu(p_strUrl);
}
/**
 * Loads an Intrexx url into a stage container and clears the menu.
 * @param p_strUrl An url.
 * @return boolean  <code>true</code>
 */
function LoaderLoadStageUrlAndClearMenu(url) {
    oHtmlRoot.oMenuTree.clearAll();
    return this.loadStageUrl(url);
}
/**
 * @private
 */
function Loader_LoadStageUrl(url) {
    ix.loader.stage({ url: url });
    return true;
}

/**
 * Loads last loaded functional part.
 * @return boolean <code>true</code>
 * @private
 */
function LoaderLoadLastPage() {
    return ix.history.loadLastPage();
}

function LoaderLoadCssFileOnDemandInline(p_fileName) {
    if (typeof this.cssFileRegistry[p_fileName] == "undefined") {
        return;
    } else {
        return false;
    }
}

/**
 * Registers all javascript files, which are already loaded by script-tag.
 * @return  boolean <code>true</code>
 */
function LoaderRegisterLoadedJsFiles() {
    var jsName;
    if (!this.bScriptsScanned) {
        var head = document.getElementsByTagName("head")[0];
        var aScriptNodesHead = head.getElementsByTagName("script");
        for (var i = 0; i < aScriptNodesHead.length; i++) {
            jsName = aScriptNodesHead[i].getAttribute("src");
            if (jsName != null && jsName.indexOf("/application.js") < 0) {
                this.jsFileRegistry.push(jsName);
            }
        }
        this.bScriptsScanned = true;
    }
    return true;
}

/**
 * Preloads a javascript file.
 * @param p_fileName Path and filename of the javascript file.
 * @param p_bAsync Boolean, whether the xmlhttp request shall be asynchron.
 * @return boolean <code>true</code>, if file could be loaded, otherwise <code>false</code>.
 */
function LoaderPreloadJsFileOnDemand(p_fileName) {
    return this._loadJsFileOnDemand(p_fileName, true);
}

/**
 * @private
 */
function Loader_LoadExternalJsFileOnDemand(p_fileName) {
    /*
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = p_fileName;
    head.appendChild(script);
    head = script = null;
    */
    var defer = $.Deferred();
    defer.promise();

    if (Loader.getFromJsFileReg(p_fileName) != null) {
        defer.resolve();
        return defer;
    }

    $.ajax({
        cache: true,
        url: p_fileName,
        dataType: "script",
        context: this,
        success: function (data, textStatus, jqXHR) {
            Loader.jsFileRegistry.push(p_fileName);
            defer.resolve(jqXHR);
        },
        // eslint-disable-next-line no-unused-vars
        error: function (jqXHR, textStatus, errorThrown) {
            defer.reject(jqXHR);
        },
        complete: defer.resolve,
    });

    return defer;
}
