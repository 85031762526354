/**
 * @file Basic prototypes for the functional part, container and controls.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals upObject, ix, Helper, oHtmlRoot, Notifier, ErrorHandler */

/**
 * @class
 * @param {Object} settings
 */
function upContainer(settings) {
    this.upType = "upContainer";
    this.oStyle = null;
    this.oHtml = null;
    this.bValidateRequired = true;
    this.bEnabled = true;

    if (typeof settings === "object") {
        $.extend(this, settings);
        if (this.oHtml !== null) {
            this.biDirectUpHtml2(this, this.oHtml, self, typeof this.oFup == "object" ? this.oFup : null);
        }
    }
}
upContainer.prototype = new upObject();

/**
 * @param {String} message User defined error message.
 * @return {Boolean}
 * @description Validates input controls. Have all required fields been filled in?
 * If not, return a list of unfilled fields an set focus on first not filled field.
 */
upContainer.prototype.validateRequired = function (message) {
    var strRel;
    var eleUp;
    var oHtmlElement;
    var bSetRequiredClass;
    var strErrorId;
    var fmValidated = true;
    var eleValidated = false;
    var eleName = "";
    var eleNoValList = "";
    var firstNoVal = "";
    var aDoneControls = [];
    var msg = "";
    var $elements =
        this.oHtml.tagName.toLowerCase() === "form"
            ? ix.util.getFormFields({
                  formNode: this.oHtml,
              })
            : ix.util.getFormFields({
                  containerNode: this.oHtml,
              });

    if (!message || message === "") {
        msg = self.oUp.oMessage.FORM_CONSTR_REQUIRED;
    } else {
        msg = message;
    }

    if (typeof $elements === "undefined") {
        return true;
    }

    $elements.each(function () {
        oHtmlElement = this;

        // Referenzen(eleUp) auf alle zu pruefenden Elemente aufbauen
        eleUp = null;
        bSetRequiredClass = true;
        strRel = oHtmlElement.getAttribute("rel");

        // rel Attribut auswerten: Mehrfachauswahl
        if (
            typeof strRel != "undefined" &&
            strRel !== null &&
            strRel === "json_selected" &&
            oHtmlElement.oDistributionCtrl
        ) {
            eleUp = oHtmlElement.oDistributionCtrl;
            bSetRequiredClass = false;
        } else if (
            (oHtmlElement.type === "checkbox" && oHtmlElement.oUp && oHtmlElement.oUp.group) ||
            (oHtmlElement.type === "radio" && oHtmlElement.oUp && oHtmlElement.oUp.group)
        ) {
            // fuer Gruppen Referenz auf die zugehoerige upGruppe aufbauen
            if (oHtmlElement.oUp.group.id && !aDoneControls.indexOf(oHtmlElement.oUp.group.id) > -1) {
                eleUp = oHtmlElement.oUp.group;
                aDoneControls[aDoneControls.length] = oHtmlElement.oUp.group.id;
                oHtmlElement = eleUp.oHtml;
            } else {
                return true; // skip immediately to the next iteration
            }
        } else if (
            oHtmlElement.type === "select-multiple" &&
            oHtmlElement.oUp &&
            typeof oHtmlElement.oUp.oHtmlSel != "undefined" &&
            oHtmlElement.oUp.oHtmlSel !== null
        ) {
            // MultiSelectControls
            eleUp = oHtmlElement.oUp;
            aDoneControls[aDoneControls.length] = oHtmlElement.id;
        } else if (oHtmlElement.oUp) {
            // fuer "einfache" Elemente Referenz auf das zugehoerige upControl
            eleUp = oHtmlElement.oUp;
            aDoneControls[aDoneControls.length] = oHtmlElement.name;
        } else if (
            typeof oHtmlElement === "object" &&
            Helper.getInstanceOfElement(oHtmlElement) &&
            oHtmlElement.tagName.toLowerCase() === "input" &&
            !!oHtmlElement.getAttribute("type") &&
            oHtmlElement.getAttribute("type").toLowerCase() === "file"
        ) {
            // fuer fileuploadkontrollen, upObjekt aus uebergeordnetem container beziehen
            eleUp = $("#ID_" + oHtmlElement.name).get(0).oUp;
        } else {
            return true; // skip immediately to the next iteration
        }

        // validieren
        if (eleUp !== null && eleUp.required && eleUp.required === "1") {
            var requiredClass = "";
            eleValidated = eleUp.validateRequiredDo();
            if (!eleValidated) {
                if (bSetRequiredClass) {
                    //oHtmlElement.className = "" + eleUp.styleType + requiredClass + " notvalid";
                    $(oHtmlElement).addClass("notvalid");
                }
                if (eleUp.userName && eleUp.userName !== "") {
                    eleName = eleUp.userName;
                } else {
                    eleName = eleUp.oHtml.id;
                }
                eleNoValList += "<br/>" + eleName;
                if (firstNoVal === "" && oHtmlElement.type !== "hidden") {
                    firstNoVal = oHtmlElement;
                }
                fmValidated = false;
                // ARIA
                eleUp.oHtml.setAttribute("aria-invalid", true);
                if (oHtmlRoot.oUp.oWAISettings.bWriteValidationErrorInline) {
                    strErrorId = eleUp.oHtml.id + "_ReqValidation_Error";
                    if (!document.getElementById(strErrorId)) {
                        var oSpan = document.createElement("span");
                        oSpan.appendChild(document.createTextNode(self.oUp.oMessage.ERROR_VALIDATOR_REQUIRED));
                        oSpan.className = "validation-error";
                        oSpan.setAttribute("id", strErrorId);
                        if (eleUp.getLabel(eleUp.oHtml.id)) {
                            eleUp.getLabel(eleUp.oHtml.id).appendChild(oSpan);
                        } else if (eleUp.oHtml.nextSibling) {
                            eleUp.oHtml.parentNode.insertBefore(oSpan, eleUp.oHtml.nextSibling);
                        } else {
                            eleUp.oHtml.parentNode.appendChild(oSpan);
                        }
                    }
                }
            } else if (bSetRequiredClass) {
                if (oHtmlRoot.oUp.oWAISettings.bWriteValidationErrorInline) {
                    strErrorId = eleUp.oHtml.id + "_ReqValidation_Error";
                    if (document.getElementById(strErrorId)) {
                        document.getElementById(strErrorId).parentNode.removeChild(document.getElementById(strErrorId));
                    }
                    if (
                        !document.getElementById(eleUp.oHtml.id + "_ValueValidation_Error") &&
                        !document.getElementById(eleUp.oHtml.id + "_PatternValidation_Error")
                    ) {
                        eleUp.oHtml.className = "" + eleUp.styleType + requiredClass;
                        eleUp.oHtml.setAttribute("aria-invalid", false);
                    }
                } else {
                    // eleUp.oHtml.className = "" + eleUp.styleType + requiredClass;
                    $(oHtmlElement).removeClass("notvalid");
                    eleUp.oHtml.setAttribute("aria-invalid", false);
                }
            }
        }
    });

    if (!fmValidated) {
        if (!oHtmlRoot.oUp.oWAISettings.bWriteValidationErrorInline) {
            var errorDescription = msg + eleNoValList;
            Notifier.status.error(errorDescription, self.oUp.oMessage.ERROR_VALIDATOR_REQUIRED, "FormValidateRequired");
        }
        if (firstNoVal !== "") {
            this.setFocusOn(firstNoVal);
        }
        return false;
    } else {
        return true;
    }
};

/**
 * @private
 * @param {HTMLElement} ctrl
 * @return {Boolean}
 * @description Sets focus on html control.
 */
upContainer.prototype.setFocusOn = function (ctrl) {
    try {
        ctrl.focus();
    } catch (e) {
        ErrorHandler.warn(e, "FormSetFocusOn");
        return true;
    }
    return true;
};

/**
 * @return {Array}
 * @description A set of form elements as an array of names and values.
 */
upContainer.prototype.serializeArray = function () {
    var oFup;
    var aRet = [];

    if (typeof this.oHtml == "object" && this.oHtml !== null) {
        if (this.upType === "upFuncPart") {
            oFup = this;
        } else if (this.oFuncPart) {
            oFup = this.oFuncPart;
        } else if (this.oFup) {
            oFup = this.oFup;
        } else if (this.upType !== "upForm") {
            oFup = $(this.oHtml).getFuncPart();
        }

        /* FUP mit Form (z.B. Standard IX-Seite)
            - Elemente einsammeln mit direkter Referenz auf die Form
            - Elemente einsammeln die zur FUP gehören UND KEINE Form Referenz haben
        */
        if (typeof oFup === "object" && oFup !== null && typeof oFup.oForm === "object" && oFup.oForm !== null) {
            aRet = ix.Ajax.serializeArray({
                formNode: oFup.oForm.oHtml,
                containerNode: oFup.oHtml,
                filter: function () {
                    var oHtmlClosestFup = $(this).closest('[data-container="upFuncPart"]')[0];
                    if (oHtmlClosestFup === undefined) {
                        oHtmlClosestFup = $(this).getFuncPart();
                    }
                    return typeof oHtmlClosestFup === "object" && oHtmlClosestFup.id === oFup.oHtml.id;
                },
            });
        } else if (typeof oFup === "object" && oFup !== null) {
            // FUP ohne Form
            aRet = ix.Ajax.serializeArray({
                containerNode: oFup.oHtml,
                filter: function () {
                    var oHtmlClosestFup = $(this).closest('[data-container="upFuncPart"]')[0];
                    if (oHtmlClosestFup === undefined) {
                        oHtmlClosestFup = $(this).getFuncPart();
                    }
                    return typeof oHtmlClosestFup === "object" && oHtmlClosestFup.id === oFup.oHtml.id;
                },
            });
        } else if (this.upType === "upForm") {
            // IX-Form Objekt ohne FUP Referenz (vielleicht custom vm)
            aRet = ix.Ajax.serializeArray({
                formNode: this.oHtml,
            });
        } else if (this.oHtml.tagName.toLowerCase() === "form") {
            return ix.Ajax.serializeArray({
                formNode: this.oHtml,
            });
        } else {
            var oFm = ix.util.getForm(this.oHtml);

            // IX-Form gesucht und gefunden
            if (typeof oFm === "object" && oFm !== null && oFm !== "undefined" && oFm !== undefined) {
                return oFm.serializeArray();
            } else {
                // weder FUP noch FORM vorhanden
                aRet = ix.Ajax.serializeArray({
                    containerNode: this.oHtml,
                });

                console.log(
                    "Methode 'serializeArray' could not detect IX container, neither FORM-Element nor IX-Fup. ",
                    "warn"
                );
                console.debug(this);
            }
        }
    }
    return aRet;
};
