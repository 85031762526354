/**
 * @file Prototypes upRootWindow.
 * @copyright 2004-2018 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/*
    globals upWindow, Notifier, setTimeOut, Helper, oHtmlRoot, ix
 */

/**
 * @private
 * @class
 * intrexx root window object.
 */
function upRootWindow() {
    this.upType = "upRootWindow";
    this.aInfoPipe = {};
    this.oRegistry = new upRegistry();
    this.closeAll = false;
    this.oFormatInfo = new upFormatInfo();
    this.oUserInfo = new upUserInfo();
    this.loggedoutUrl = "";
}
upRootWindow.prototype = new upWindow();
// upRootWindow.prototype.createPopUp = RootCreatePopUp;
// upRootWindow.prototype.clonePopUp = RootClonePopUp;
// upRootWindow.prototype.closePopUp = RootClosePopUp;
// upRootWindow.prototype.closeAllPopUp = RootCloseAllPopUp;
upRootWindow.prototype.cleanUp = RootCleanUp;
// upRootWindow.prototype.unregisterPopup = RootUnregisterPopup;
// upRootWindow.prototype.rebuildPopUpReg = RootRebuildPopUpReg;
// upRootWindow.prototype.registerPopup = RootRegisterPopup;
upRootWindow.prototype.registerToolTipPopup = RootRegisterToolTipPopup;
upRootWindow.prototype.getToolTipPopup = RootGetToolTipPopup;
upRootWindow.prototype.unregisterToolTipPopup = RootUnregisterToolTipPopup;
upRootWindow.prototype.rebuildToolTipPopupReg = RootRebuildToolTipPopupReg;
upRootWindow.prototype.sessionGetInfo = SessionGetInfo;
upRootWindow.prototype.timeOutSession = RootTimeOutSession;
upRootWindow.prototype.timeOutSessionWarn = RootTimeOutSessionWarn;
upRootWindow.prototype.resetTimeOut = RootResetTimeOut;
upRootWindow.prototype.adviceShow = RootAdviceShow;
upRootWindow.prototype.adviceClose = RootAdviceClose;

/*
 * @deprecated
 * @private
 */
// eslint-disable-next-line no-unused-vars
function RootAdviceShow(p_strHead, p_strBody, p_strNotifyId, p_oAdviceSettings, p_oWindow) {
    Notifier.status.error(p_strBody, p_strHead, p_strNotifyId);
}

/*
 * @deprecated
 * @private
 */
// eslint-disable-next-line no-unused-vars
function RootAdviceClose(p_strNotifyId, p_oWindow) {
    Notifier.status.close(p_strNotifyId);
}

/**
 * @ignore
 * @private
 * @param {boolean} [debug] Shows debug informations.
 * @returns {object} XMLHttpRequest
 * @description Performs an asynchronous Ajax request to get session informations.
 */
function SessionGetInfo(debug, info) {
    // prepare request
    var sessionInfoUrl = ix.util.getBaseUrl() + "?" + "qs_service=services/sessioninfo/&qs_operation=getSessionInfo";
    var xhr = new XMLHttpRequest();

    // status handling when request is done ('readyState' will be 4)
    xhr.addEventListener("load", function () {
        if (xhr.status == 200) {
            if (debug) {
                console.group("SessionGetInfo");
                console.info("» sessionInfoUrl:", sessionInfoUrl);

                // get detailed session infos
                var sessionInfoResponse = xhr.responseXML.getElementsByTagName("getSessionInfoResponse");
                if (sessionInfoResponse.length) {
                    console.group("» getSessionInfoResponse");
                    sessionInfoResponse[0].childNodes.forEach(function (node) {
                        if (info && info == node.nodeName) {
                            console.info(node.nodeName + ": " + node.innerHTML);
                        }
                        if (!info) {
                            console.info(node.nodeName + ": " + node.innerHTML);
                        }
                    });
                    console.groupEnd();
                }
                console.groupEnd();
            }
        } else {
            console.warn("Unexpected status while processing 'SessionGetInfo' response:", xhr.status);
        }
    });

    // request error handling
    xhr.addEventListener("error", function () {
        console.error("Unexpected exception while processing 'SessionGetInfo' response.");
    });

    // init + send request
    xhr.open("GET", sessionInfoUrl);
    xhr.send();

    // return sessionInfos
    return xhr;
}

/**
 * @ignore
 * @param {string} urlRelogin
 * @param {boolean} [debug] Shows debug informations.
 * @description Sets the client session timeout.
 */
function RootTimeOutSession(forceLogout, debug) {
    // get session infos
    var sessionInfoXHR = oHtmlRoot.oUp.sessionGetInfo();
    sessionInfoXHR.addEventListener("load", function () {
        // determine 'timeToLive' from session infos
        var timeToLive;
        try {
            if (sessionInfoXHR.responseXML.getElementsByTagName("timeToLive").length) {
                // session active: (re-)set time to live
                timeToLive = sessionInfoXHR.responseXML.getElementsByTagName("timeToLive")[0].textContent;
            } else if (sessionInfoXHR.responseXML.getElementsByTagName("noSession").length) {
                // session expired: nothing todo here
                console.info("intrexx:ajax:session: no session found!");
            }

            // debug: 'sessionInfos' + 'timeToLive'
            if (debug) {
                console.info("» RootTimeOutSession -> sessionInfoXHR.responseXML", sessionInfoXHR.responseXML);
                console.info("» RootTimeOutSession -> timeToLive:", timeToLive + "ms");
            }
        } catch (err) {
            console.warn("Unexpected exception while processing 'RootTimeOutSession' response.", err);
        }

        // wether or not the session check was successfull, the logout logic should be proceeded
        if (timeToLive && !forceLogout) {
            // reset session timeout
            setTimeOut(timeToLive);
        } else {
            // TODO: how to logout session?
            if (forceLogout) {
                console.info("intrexx:ajax:session: logout forced!");
            }

            ix.util.forceLogout();
        }
    });
}

/**
 * @ignore
 * @description Shows a tooltip with session timeout warning.
 */
function RootTimeOutSessionWarn(forceShow) {
    // get session infos
    var sessionInfoXHR = oHtmlRoot.oUp.sessionGetInfo();
    sessionInfoXHR.addEventListener("load", function () {
        // determine 'timeToLive' from session infos
        var timeToLive;
        try {
            if (sessionInfoXHR.responseXML.getElementsByTagName("timeToLive").length) {
                // session active: (re-)set time to live
                timeToLive = sessionInfoXHR.responseXML.getElementsByTagName("timeToLive")[0].textContent;
            } else if (sessionInfoXHR.responseXML.getElementsByTagName("noSession").length) {
                // session expired: nothing todo here
                console.info("intrexx:ajax:session: no session found!");
            }
        } catch (err) {
            console.warn("Unexpected exception while processing 'SessionGetInfo()' response.", err);
        }

        // show the session timeout warning only, if the session expires soon
        if (timeToLive > 20500 && !forceShow) {
            setTimeOut(timeToLive);
        } else {
            var content = [];

            content.push('<div class="Session_LogoutWarning">');
            content.push('<span class="Icon"></span>');
            content.push('<h2 class="Text_H2">' + self.oUp.oMessage.LOGIN_WARN_HEADLINE + "</h2>");
            content.push('<div class="Text_Standard" id="ErrorMsg">' + self.oUp.oMessage.LOGIN_WARN_TEXT + "</div>");
            content.push(
                '<input role="button" aria-label="' +
                    self.oUp.oMessage.LOGIN_WARN_ACTION +
                    '" type="button" id="ID_buttonReset" class="Button_Standard" name="buttonReset" onclick="if(oHtmlRoot && oHtmlRoot.oUp){oHtmlRoot.oUp.resetTimeOut(true); Notifier.status.close(\'LOGOUT_WARNING\');}" value="' +
                    self.oUp.oMessage.LOGIN_WARN_ACTION +
                    '">'
            );
            content.push("</div>");

            self.sessionLogoutWarning = "LOGOUT_WARNING";
            Notifier.status.warn(content.join(""), null, self.sessionLogoutWarning, {
                autoCloseInterval: 20000,
                className: "TT_ADVICE",
                closeByButton: false,
            });
        }
    });
}

/**
 * @ignore
 * @param {boolean} [serverReset] If true, even server session timeout will be resetted.
 * @description Resets the session timeout.
 */
function RootResetTimeOut(serverReset) {
    if (oHtmlRoot && oHtmlRoot.oUp && !isNaN(oHtmlRoot.oUp.timeOut)) {
        // reset client timeout
        setTimeOut(oHtmlRoot.oUp.iSessionTimeOut);

        // reset server timeout
        if (serverReset === true) {
            // build refresh url
            var UrlHelper = ix.util.UrlHelper;
            var url = UrlHelper.getBaseUrl("internal/system/vm/html/login/getRefreshServerTimeout.vm");
            // add language param
            if (Helper.getQsValueByParam("rq_Lang") && !Helper.isCookieEnabled()) {
                url = UrlHelper.setUrlValue("rq_Lang", UrlHelper.getQsValueByParam("rq_Lang"), url);
            }

            // reset server timeout
            var xhr = new XMLHttpRequest();

            // status handling when request is done ('readyState' will be 4)
            xhr.addEventListener("load", function () {
                if (xhr.status == 200) {
                    var response = JSON.parse(xhr.responseText);
                    try {
                        // request successfull, but session was not reseted
                        if (!response.status || response.status != 1) {
                            Notifier.status.warn(
                                "Could not reset the session timeout.",
                                "Error: RootResetTimeOut",
                                "intrexx:ajax:session"
                            );
                        }
                    } catch (err) {
                        // request successfull, response unexpected
                        Notifier.status.error(
                            "Unexpected exception while processing the response.",
                            "Error: RootResetTimeOut",
                            "intrexx:ajax:session"
                        );
                    }
                } else {
                    console.warn("Unexpected status while processing 'SessionGetInfo' response:", xhr.status);
                }
            });

            // request error handling
            xhr.addEventListener("error", function () {
                console.error("Unexpected exception while processing 'RootResetTimeOut' response.");
            });

            // init + send request
            xhr.open("GET", url);
            xhr.send();
        }
    }
}

// function RootUnregisterPopup(popup) {
//     if (this.oRegistry.oPopUpReg[popup]) {
//         this.oRegistry.oPopUpReg[popup] = null;
//     }
//     this.rebuildPopUpReg();
//     return this.oRegistry.oPopUpReg;
// }
// function RootRebuildPopUpReg() {
//     var newPopUpReg = {};
//     for (var popup in this.oRegistry.oPopUpReg) {
//         if (this.oRegistry.oPopUpReg[popup] != null) {
//             newPopUpReg[popup] = this.oRegistry.oPopUpReg[popup];
//         }
//     }
//     this.oRegistry.oPopUpReg = newPopUpReg;
// }
// function RootRegisterPopup(popup, oPopup) {
//     this.rebuildPopUpReg();
//     this.oRegistry.oPopUpReg[popup] = oPopup;
//     return this.oRegistry.oPopUpReg[popup];
// }

// function RootCreatePopUp(p_wndName) {
//     if (!p_wndName || p_wndName == "") {
//         var wndName = "newWnd";
//     } else {
//         var wndName = p_wndName;
//     }
//     var wnd = this.registerPopup(wndName, new upPopUpWindow());
//     wnd.id = wndName;
//     wnd.aObjects = {};
//     wnd.setRefToRootWin();
//     return wnd;
// }
// function RootClonePopUp(p_wndName) {
//     var popup = this.oRegistry.oPopUpReg[p_wndName];
//     if (typeof popup === "object") {
//         this.unregisterPopup(p_wndName);
//         var popupClone = this.createPopUp(p_wndName);
//         for (var name in popup) {
//             if (typeof popup[name] !== "function") {
//                 try {
//                     popupClone[name] = popup[name];
//                 } catch (e) {
//                     debug("copy popup settings. name: " + name, "warning");
//                     debug(e);
//                 }
//             }
//         }
//     }
//     return popupClone;
// }
function RootRegisterToolTipPopup(popup, oPopup) {
    this.rebuildToolTipPopupReg();
    this.oRegistry.oToolTipPopupReg[popup] = oPopup;
    return this.oRegistry.oToolTipPopupReg[popup];
}

function RootGetToolTipPopup(popup) {
    return typeof this.oRegistry.oToolTipPopupReg[popup] == "object" ? this.oRegistry.oToolTipPopupReg[popup] : false;
}

function RootUnregisterToolTipPopup(popup) {
    if (this.oRegistry.oToolTipPopupReg[popup]) {
        this.oRegistry.oToolTipPopupReg[popup] = null;
    }
    this.rebuildToolTipPopupReg();
    return this.oRegistry.oToolTipPopupReg;
}

function RootRebuildToolTipPopupReg() {
    var newToolTipPopupReg = {};
    for (var popup in this.oRegistry.oToolTipPopupReg) {
        if (this.oRegistry.oToolTipPopupReg[popup] != null) {
            newToolTipPopupReg[popup] = this.oRegistry.oToolTipPopupReg[popup];
        }
    }
    this.oRegistry.oToolTipPopupReg = newToolTipPopupReg;
}

// function RootClosePopUp(wndName) {
//     var wnd = null;
//     if (!wndName || wndName === "") {
//         return false;
//     }
//     wnd = this.oRegistry.oPopUpReg[wndName];

//     try {
//         if (wnd && wnd.oHtml && wnd.oHtml !== null && wnd.oHtml.open && !wnd.oHtml.closed) {
//             wnd.byUpClose = true;

//             wnd.oHtml.focus();
//             // falls unloadHandler existiert, diesen ausfuehren; um die Synchronisierung zu garantieren,
//             if (wnd.oHtml.unloadHandler && !this.closeAll) {
//                 wnd.oHtml.unloadHandler();
//             }
//             wnd.hide();
//         }
//         this.unregisterPopup(wndName);
//     } catch (e) {
//         debug(e);
//     }
//     return true;
// }

// function RootCloseAllPopUp() {
//     for (var i in this.oRegistry.oPopUpReg) {
//         if (i != "upType" && i != null) {
//             this.closePopUp(i);
//         }
//     }
//     return true;
// }
function RootCleanUp() {
    this.closeAll = true;
    this.aInfoPipe = null;
    this.oMenuReg = null;
    this.oFormatInfo = null;
    this.oMessage = null;
    this.oFormatInfo = null;
    this.oUserInfo = null;
}

function upFormatInfo() {
    this.upType = "upFormatInfo";
    this.dateFormatString = "";
    this.timeFormatString = "";
    this.leadingNulls = "";
    this.decSep = "";
    this.groupSep = "";
    this.firstDayOfWeek = "1";
    this.lang = "de";
}

function upUserInfo() {
    this.upType = "upUserInfo";
    this.password = "";
    this.userName = "";
}

function upRegistry() {
    this.upType = "upRegistry";
    this.oFuncPartReg = {};
    this.oFuncPartRegStack = {};
    // this.oPopUpReg = {};
    this.oToolTipPopupReg = {};
    this.oMenuReg = {};
    this.oTopLevelContainerReg = {};
}
