/**
 * @file Polling functions.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ErrorHandler */

/* exported registerAjaxPolling */

/**
 * @typedef {Object} AjaxPollingRegistrationSettings
 * @property {String} key=null The polling key (e.g. "share").
 * @property {Function} [success=null] A function to be called if the polling usn changed.
 * @property {Function} [successNoChange=null] A function to be called if request completes successfully the polling usn not changed.
 * @property {Function} [error=null] A function to be called if the polling fails.
 * @property {String} [id="an unique value"] Unique listener ID.
 * @property {Number} [millisec=15000] The interval (in milliseconds) how often the polling has to be executed.
 */
/**
 * @param {String} key
 * @param {String} id
 * @description Stop the polling.
 */
function unregisterAjaxPolling(key, id) {
    var poller = $("body").data(key);
    if (typeof poller === "object") {
        // einen spezifischen polling listener entfernen
        if (typeof id !== "undefined" && id !== null) {
            poller.unbindListener(id);
        }
        // prüfen ob polling beendet und entfernt werden kann
        if (poller.callbacks.length === 0 || typeof id === "undefined" || id === null) {
            poller.stop();
            // Polling aus der window registry entfernen
            $("body").removeData(key);
        }
    }
}

function upPoll(settings) {
    // Interval setzen
    this.millisec = typeof settings.millisec === "number" ? settings.millisec : 15000;
    this.settings = settings;
    this.intervalKey = undefined;
    this.usn = null;
    this.callbacks = [];
    this.key = settings.key;
    this.promiseInit = undefined;

    this.isRunning = function () {
        return typeof this.intervalKey !== "undefined";
    };

    this.start = function () {
        // polling läuft bereits
        if (this.isRunning()) {
            return true;
        } else if (typeof this.usn === "undefined") {
            return false;
        }

        this.intervalKey = window.setInterval(
            function () {
                var poller = this;

                // Polling
                $.ajax({
                    url: "/?qs_pollingKey=" + this.key + "&rq_NoSessionTouch=true",
                    dataType: "json",
                    cache: false,
                    context: poller,
                    data: {},
                    global: false,
                    success: function (json) {
                        var usn = json.usn;
                        var poller = this;

                        if (poller.usn !== usn) {
                            poller.usn = usn;
                            for (var i = 0, l = poller.callbacks.length; i < l; i++) {
                                if (poller.callbacks[i].success instanceof Function) {
                                    poller.callbacks[i].success(json, poller);
                                }
                            }
                        } else {
                            for (var j = 0, len = poller.callbacks.length; j < len; j++) {
                                if (poller.callbacks[j].successNoChange instanceof Function) {
                                    poller.callbacks[j].successNoChange(json, poller);
                                }
                            }
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        var poller = this;
                        unregisterAjaxPolling(poller.key);
                        for (var i = 0, l = poller.callbacks.length; i < l; i++) {
                            if (poller.callbacks[i].error instanceof Function) {
                                poller.callbacks[i].error(
                                    {
                                        upType: "exception",
                                        exceptionType: "standard-exception",
                                        originalException: undefined,
                                        jqXHR: jqXHR,
                                        textStatus: textStatus,
                                        errorThrown: errorThrown,
                                    },
                                    poller
                                );
                            }
                        }
                    },
                });
            }.bind(this),
            this.millisec
        );
    };

    this.stop = function () {
        window.clearInterval(this.intervalKey);
        this.intervalKey = undefined;
    };

    this.setInterval = function (millisec) {
        this.millisec = millisec;
        if (this.isRunning()) {
            this.stop();
            this.start();
        }
    };

    this.bindListener = function (id, settings) {
        // eventuell bereits vorhandene listener mit dem selben key entfernen
        this.unbindListener(id);

        this.callbacks.push({
            id: id,
            success: typeof settings.success === "function" ? settings.success : null,
            successNoChange: typeof settings.successNoChange === "function" ? settings.successNoChange : null,
            error: typeof settings.error === "function" ? settings.error : null,
        });
    };

    this.unbindListener = function (id) {
        for (var i = this.callbacks.length - 1; i >= 0; i--) {
            if (this.callbacks[i].id === id) {
                this.callbacks.splice(i, 1);
            }
        }
    };

    this.initialize = function () {
        if (typeof this.promiseInit === "undefined") {
            this.promiseInit = $.ajax({
                url: "/?qs_pollingKey=" + this.key + "&rq_NoSessionTouch=true",
                context: this,
                dataType: "json",
                data: {},
                cache: false,
                global: false,
                success: function (json) {
                    if (json.usn) {
                        var poller = this;
                        poller.usn = json.usn;
                    } else {
                        console.log("Server returns no polling interval usn! Polling key: " + this.key, "warn");
                    }
                },
                error: function (e) {
                    ErrorHandler.notify(e.responseText || e.statusText, "Initialize Polling", "PollingInit");
                },
            });
            return this.promiseInit;
        } else {
            return this.promiseInit;
        }
    };
}

/**
 * @param {AjaxPollingRegistrationSettings} settings
 * @returns {String} id
 * @description Start the polling.
 */
function registerAjaxPolling(settings) {
    settings = $.extend(
        true,
        {
            key: null,
            success: null,
            successNoChange: null,
            error: null,
            id: new Date().getTime() + Math.round(Math.random() * 999999),
            millisec: 15000,
        },
        settings
    );

    // Polling Object
    var isNewPoll = typeof $("body").data(settings.key) === "undefined" || $("body").data(settings.key) === null;
    var poller;
    if (isNewPoll) {
        poller = new upPoll(settings);
        $("body").data(settings.key, poller);
    } else {
        poller = $("body").data(settings.key);
    }

    // Polling Callbacks registrieren
    poller.bindListener(settings.id, settings);

    // falls Polling bereits vorhanden war prüfen, ob sich das Intervall geändert hat
    if (typeof settings.millisec !== "undefined" && settings.millisec !== poller.millisec) {
        poller.setInterval(poller.millisec);
        poller.stop();
    }

    // Polling ggf. initialisieren und starten
    if (poller.callbacks.length > 0) {
        $.when(poller.initialize()).done(
            function () {
                if (!this.isRunning()) {
                    this.start();
                }
            }.bind(poller)
        );
    }

    // Polling ID
    return settings.id;
}
