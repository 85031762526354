/* exported checkRecurringAppointment */
/**
 * @file The Helper methods for calendar.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
function checkRecurringAppointment(e, actionType, aButtons) {
    var retDeferred = $.Deferred();
    if (!Array.isArray(aButtons)) {
        aButtons = ["exception", "close", "delete"];
    }
    var buttons = {};

    if (actionType == "actDelete") {
        if (aButtons.indexOf("exception") > -1) {
            buttons.exception = {
                name: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_DELETE_OPT_EXCEPTION,
                id: "ID_btnAppointmentException",
                class: "Button_Standard button",
                title: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_DELETE_OPT_EXCEPTION_DESC,
                action: function () {
                    retDeferred.resolve("exception");
                },
            };
        }

        if (aButtons.indexOf("close") > -1) {
            buttons.close = {
                name: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_DELETE_OPT_CLOSE,
                id: "ID_btnAppointmentClose",
                class: "Button_Standard button",
                title: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_DELETE_OPT_CLOSE_DESC,
                action: function () {
                    retDeferred.resolve("close");
                },
            };
        }

        if (aButtons.indexOf("delete") > -1) {
            buttons.delete = {
                name: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_DELETE_OPT_DELETE,
                id: "ID_btnAppointmentDelete",
                class: "Button_Standard button",
                title: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_DELETE_OPT_DELETE_DESC,
                action: function () {
                    retDeferred.resolve("delete");
                },
            };
        }
    } else {
        if (aButtons.indexOf("exception") > -1) {
            buttons.exception = {
                name: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_CHANGE_OPT_EXCEPTION,
                id: "ID_btnAppointmentException",
                class: "Button_Standard button",
                title: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_CHANGE_OPT_EXCEPTION_DESC,
                action: function () {
                    retDeferred.resolve("exception");
                },
            };
        }

        if (aButtons.indexOf("close") > -1) {
            buttons.close = {
                name: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_OPT_CLOSE,
                id: "ID_btnAppointmentClose",
                class: "Button_Standard button",
                title: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_OPT_CLOSE_DESC,
                action: function () {
                    retDeferred.resolve("close");
                },
            };
        }

        if (aButtons.indexOf("delete") > -1) {
            buttons.delete = {
                name: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_OPT_DELETE,
                id: "ID_btnAppointmentDelete",
                class: "Button_Standard button",
                title: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_OPT_DELETE_DESC,
                action: function () {
                    retDeferred.resolve("delete");
                },
            };
        }
    }
    $.confirm({
        title: self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_CHANGE_TITLE,
        message:
            self.oUp.oMessage.CAL_CONFIRM_RECURRENCE_SUBTITLE +
            '<div style="padding-top: 10px; text-align:center;"><img src="/images/assets/common/warning.png" width="32" height="32"></div>',
        buttons: buttons,
        escAction: function () {
            retDeferred.reject("esc");
        },
        bDisableEscAction: false,
    });

    /*
                        switch (dlgAppType.getReturn()) {
                            case "exception":    // Ausnahme
                                oHtmlCalControl.value = "exception";
                                rv = true;
                                break;
                            case "delete":    // alte Serie loeschen und eine neue Serie anlegen
                                oHtmlCalControl.value = "delete";
                                rv = true;
                                break;
                            case "close":    // alte Serie beenden und eine neue Serie anlegen
                                oHtmlCalControl.value = "close";
                                rv = true;
                                break;
                                */

    return retDeferred;
}
