/**
 * @file Basic prototype for any control.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals upObject */

/**
 * @ignore
 * @class
 */
function upControl() {
    this.upType = "";
    this.userName = "";
}
upControl.prototype = new upObject();

/**
 * @param {Boolean} [state=false]
 * @description Set a control to be required or not required.
 */
upControl.prototype.setRequired = function (state) {
    if (typeof state != "boolean") {
        state = true;
    }
    this.required = state;
    if (typeof this.oHtml == "object" && this.oHtml !== null) {
        $(this.oHtml).attr("aria-required", state);
        $(this.oHtml).attr("aria-invalid", false);
    }
};

/**
 * @ignore
 * @return {Boolean}
 */
upControl.prototype.isEnabled = function () {
    return this.bEnabled;
};

/**
 * @ignore
 */
upControl.prototype.reset = function () {};

/**
 * @ignore
 */
upControl.prototype.enable = function () {};

/**
 * @ignore
 */
upControl.prototype.disable = function () {};
