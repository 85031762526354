/**
 * @file Prototypes for viewcontrols, table reg and other container
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals upContainer */
/**
 * @class
 * @private
 */
function upCaption() {
    this.upType = "upCaption";
}
upCaption.prototype = new upContainer();

/**
 * @class
 * @private
 */
function upImage() {
    this.upType = "upImage";
}
upImage.prototype = new upContainer();

/**
 * @class
 * @private
 */
function upUri() {
    this.upType = "upUri";
}
upUri.prototype = new upContainer();

/**
 * @class
 * @private
 */
function upDivContainer() {
    this.upType = "upDivContainer";
}
upDivContainer.prototype = new upContainer();
