/**
 * @file Prototype upObjectRegistry.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/**
 * upObjectRegistry
 * @class
 * @private
 */
function upObjectRegistry() {
    this.oContainer = [];
}
upObjectRegistry.prototype.addObj = upObjectRegistryAdd;
upObjectRegistry.prototype.getObj = upObjectRegistryGet;
upObjectRegistry.prototype.removeObj = upObjectRegistryRemove;
upObjectRegistry.prototype.size = upObjectRegistrySize;

/**
 * Returns objects count

 * @private
 */
function upObjectRegistrySize() {
    var i = 0;
    for (var key in this.oContainer) {
        if (this.oContainer[key] != null) i++;
    }
    return i;
}
/**
 * Returns object
 * @private
 */
function upObjectRegistryGet(p_strKey) {
    var l_Ret = false;
    for (var key in this.oContainer) {
        if (key == p_strKey) {
            l_Ret = this.oContainer[key];
            break;
        }
    }
    return l_Ret;
}

/**
 * Add object
 * @private
 */
function upObjectRegistryAdd(p_strKey, p_o) {
    //if(!this.getObject(p_strKey))
    //{
    this.oContainer[p_strKey] = p_o;
    //}
    return true;
}

/**
 * Remove object

 * @private
 */
function upObjectRegistryRemove(p_strKey) {
    var newObj = {};
    for (var key in this.oContainer) {
        if (key != p_strKey) {
            newObj[key] = this.oContainer[key];
        }
    }
    this.oContainer = newObj;
}
