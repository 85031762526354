/* eslint-disable no-useless-escape */
/* globals contentKey: true, oHtmlRoot, ObjectFactory, Helper,
    UpFloatControl, UpIntegerControl, Browser, trim, log, ActiveXObject */
/* exported contentKey, isValidUrl, getRow, getCol */
/**
 * @file Additional Helper methods.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ix, isUndefinedOrNull, HelperMain */

/**
 * @private
 */
HelperMain.prototype.event = new upEventHelper(this);

/**
 * remove illegal chars
 * ranges: #x9 | #xA | #xD | [#x2000-#xD7FF] | [#xE000-#xFFFD] | [#x10000-#x10FFFF]
 * @public
 * @param {Object} p_oInputField
 * @return {String} strInputVal the new string
 */
HelperMain.prototype.removeIllegalChars = function (p_oInputField) {
    var strInputVal = p_oInputField.value;
    for (var i = strInputVal.length - 1; i >= 0; i--) {
        if (
            // eslint-disable-next-line no-control-regex
            strInputVal.charAt(i).match(/^\u0009/g) ||
            // eslint-disable-next-line no-control-regex
            strInputVal.charAt(i).match(/^\u000A/g) ||
            // eslint-disable-next-line no-control-regex
            strInputVal.charAt(i).match(/^\u000D/g) ||
            strInputVal.charAt(i).match(/^[\u2000-\uD7FF]/g) ||
            strInputVal.charAt(i).match(/^[\uE000-\uFFFD]/g)
        ) {
            strInputVal = strInputVal.replace(strInputVal.charAt(i), "");
        }
    }
    p_oInputField.value = strInputVal;
    return strInputVal;
};

/**
 * Trim left whitespaces from a string.
 * @param s String with potentially whitespaces.
 * @return String without unneccessary whitespaces.
 */
HelperMain.prototype.lTrim = function (s) {
    if (arguments.length == 0 || typeof s == "undefined") return false;
    return s.replace(/^\s+/g, "");
};
/**
 * Trim right whitespaces from a string.
 * @param s String with potentially whitespaces.
 * @return String without unneccessary whitespaces.
 */
HelperMain.prototype.rTrim = function (s) {
    if (arguments.length == 0 || typeof s == "undefined") return false;
    return s.replace(/\s+$/g, "");
};

/**
 * @return {String} url
 * @description Get the absolute base url.
 */
HelperMain.prototype.getAbsBaseUrl = function () {
    var l_strUrl;
    if (typeof oHtmlRoot.oUp.strAbsBaseUrl == "string" && oHtmlRoot.oUp.strAbsBaseUrl.length > 0) {
        l_strUrl = oHtmlRoot.oUp.strAbsBaseUrl;
    } else {
        l_strUrl = this.oDoc.location.protocol + "//" + this.oWin.location.host + this.getBaseUrl();
    }
    return l_strUrl;
};

//////////////////////////////////////////////////////////////////////////////////////
//                                MEDIA-CONTROL                                     //
//////////////////////////////////////////////////////////////////////////////////////
/**
 * @methodOf HelperMain
 * @private
 * @param {HTMLElement} p_oHtml the given text element
 * @param {HTMLElement} p_oHiddenField
 * @description call apis of youtube, vimeo and dailymotion. handle video, image and normal urls. put video informations to json
 */
HelperMain.prototype.videoJSON = function (p_oHtml, p_oHiddenField) {
    var l_oHtml = p_oHtml,
        l_oHiddenField = p_oHiddenField,
        agent = navigator.userAgent,
        isWindowsMobile = agent.indexOf("Windows Phone") > 0,
        isOperaMobile = agent.indexOf("Opera Mobi") > 0;

    $.valHooks.textarea = {
        get: function (p_oHtml) {
            return p_oHtml.value.replace(/\r?\n/g, "\r\n");
        },
    };

    var l_strValue = $(l_oHtml).val();
    var l_patternUrl =
            /(\s>|https?:\/\/|ftp:\/\/)(?:www\.)?([-A-Z0-9ß-ü+$&@#\/%?=~_|!:,.;\(\)\*\'\[\]])*[-A-Z0-9ß-ü+&@#\/%=~_|\]]/gim,
        l_patternYT =
            /^http[s]?:\/\/(?:www\.)?youtu(?:(?:\.be\/)|(?:be(?:-nocookie|\.googleapis)?\.com)\/(?:(?:(?:embed|v)\/)|(?:watch\?v=)))([a-zA-Z0-9\-_]*)/,
        l_patternYT2 = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i,
        l_patternMyV = /^http[s]?:\/\/(?:www\.)?myvideo\.de\/(?:(?:watch)|(?:movie))\/([0-9]*)/,
        l_patternVimeo =
            /^http[s]?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:moogaloop\.swf\?clip_id=)?([0-9]*)/,
        l_patternDM = /(^http[s]?:\/\/(?:www\.)?dailymotion\.com\/(?:(?:video))|dai\.ly)\/([a-zA-Z0-9\-_]*)/,
        l_aJSON = [],
        l_strFormat = "",
        l_strNoAPI = "noApi",
        l_bYtFeatureLink = false;

    var l_aUrls, match, l_strPositionStart, l_strPositionEnd, id, l_strUrlAPI, l_strUrl, l_strType, laenge;

    if (!l_strValue.match(l_patternUrl)) {
        $(l_oHiddenField).val("");
        return true;
    } else {
        l_aUrls = l_strValue.match(l_patternUrl);
    }

    if (isWindowsMobile || isOperaMobile) {
        l_patternUrl = /(\s>|https?:\/\/|ftp:\/\/)(?:www\.)?[-A-Z0-9+&@#\/%?=~_|!:,.;\(\)\*\']*[-A-Z0-9+&@#\/%=~_|]/gim;
    }

    $.each(l_aUrls, function (i) {
        var l_strVideoYT = l_aUrls[i].match(l_patternYT) ? l_aUrls[i].match(l_patternYT)[1] : null,
            l_strVideoMyV = l_aUrls[i].match(l_patternMyV) ? l_aUrls[i].match(l_patternMyV)[1] : null,
            l_strVideoVimeo = l_aUrls[i].match(l_patternVimeo) ? l_aUrls[i].match(l_patternVimeo)[1] : null,
            l_strVideoDM = l_aUrls[i].match(l_patternDM) ? l_aUrls[i].match(l_patternDM)[2] : null;

        if (!l_strVideoYT) {
            l_strVideoYT = l_aUrls[i].match(l_patternYT2) ? l_aUrls[i].match(l_patternYT2)[1] : null;
            l_bYtFeatureLink = true;
        }
        var l_strTextUrl;
        if (l_strVideoYT) {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            if (!l_bYtFeatureLink) {
                id = l_aUrls[i].match(l_patternYT)[1];
            } else {
                id = l_strVideoYT;
            }

            if (location.protocol === "https:") {
                l_strUrlAPI = 'https://gdata.youtube.com/feeds/api/videos?q="' + id + '"&max-results=1&v=2&alt=jsonc';
            } else {
                l_strUrlAPI = 'http://gdata.youtube.com/feeds/api/videos?q="' + id + '"&max-results=1&v=2&alt=jsonc';
            }

            l_strFormat = "youtube";

            _createJSON(l_strTextUrl, id, l_strPositionStart, l_strPositionEnd, l_strFormat);
        } else if (l_strVideoVimeo) {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            id = l_aUrls[i].match(l_patternVimeo)[1];
            if (location.protocol === "https:") {
                l_strUrlAPI = "https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/" + id + "";
            } else {
                l_strUrlAPI = "https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/" + id + "";
            }

            l_strFormat = "vimeo";

            _getJSON(l_strTextUrl, l_strUrlAPI, l_strPositionStart, l_strPositionEnd, l_strFormat);
        } else if (l_strVideoDM) {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            id = l_aUrls[i].match(l_patternDM)[2];
            l_strUrlAPI =
                "https://api.dailymotion.com/video/" + id + "?fields=title,duration,user,thumbnail_url,url,id,tags";
            l_strFormat = "dailymotion";

            _getJSON(l_strTextUrl, l_strUrlAPI, l_strPositionStart, l_strPositionEnd, l_strFormat);
        } else if (l_strVideoMyV) {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            id = l_aUrls[i].match(l_patternMyV)[1];
            l_strUrl = l_aUrls[i].match(l_patternMyV)[0];
            l_strFormat = "myvideo";
            l_strType = "video";

            _createJSON(
                l_strTextUrl,
                l_strNoAPI,
                l_strPositionStart,
                l_strPositionEnd,
                l_strFormat,
                l_strType,
                l_strUrl,
                id
            );
        } else {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            var splitUrl = l_aUrls[i].split("."),
                mime = splitUrl.pop();

            switch (mime) {
                case "swf": // fall through
                case "mp4": // fall through
                case "ogg": // fall through
                case "ogv": // fall through
                case "oga": // fall through
                case "ogx": // fall through
                case "webm": // fall through
                case "mov":
                    l_strType = "video";
                    l_strFormat = mime;
                    break;
                case "jpg": // fall through
                case "jpeg": // fall through
                case "png": // fall through
                case "gif": // fall through
                case "bmp":
                    var l_strPatternCheckUrl =
                        /(\.php|\.php4|\.php5|\.phtml|\.asp|\.aspx|\.jsp|\.csp|\.cfm|\.ssjs|\.cgi|\.ipl|\.pl|\.py|\.rb|\.rbw|\.dna|\.tpl)/i;
                    if (l_strTextUrl.match(l_strPatternCheckUrl)) {
                        l_strType = "url";
                        l_strFormat = "url";
                    } else {
                        l_strType = "image";
                        l_strFormat = mime;
                    }
                    break;
                case "js":
                    l_strType = "javascript";
                    l_strFormat = mime;
                    break;
                default:
                    l_strFormat = "url";
                    l_strType = "url";
                    break;
            }
            _createJSON(
                l_strTextUrl,
                l_strNoAPI,
                l_strPositionStart,
                l_strPositionEnd,
                l_strFormat,
                l_strType,
                l_aUrls[i]
            );
        }
    });

    function _getJSON(p_strTextUrl, p_url, p_strPositionStart, p_strPositionEnd, p_strFormat) {
        var strJsonData = "json";
        $.ajax({
            url: p_url,
            async: false,
            dataType: strJsonData,
            type: "GET",
        })
            .done(function (p_oResponse) {
                if (!p_oResponse.data) {
                    _createJSON(p_strTextUrl, p_oResponse, p_strPositionStart, p_strPositionEnd, p_strFormat);
                } else {
                    if (p_oResponse.data.totalItems !== 0) {
                        if (id !== p_oResponse.data.items[0].id) {
                            _createJSON(
                                p_strTextUrl,
                                "noApi",
                                p_strPositionStart,
                                p_strPositionEnd,
                                "url",
                                "url",
                                p_strTextUrl
                            );
                        } else {
                            _createJSON(p_strTextUrl, p_oResponse, p_strPositionStart, p_strPositionEnd, p_strFormat);
                        }
                    } else {
                        _createJSON(
                            p_strTextUrl,
                            "noApi",
                            p_strPositionStart,
                            p_strPositionEnd,
                            "url",
                            "url",
                            p_strTextUrl
                        );
                    }
                }
            })
            .fail(function () {
                return false;
            });
    }

    function _createJSON(
        p_strTextUrl,
        p_oResponse,
        p_strPositionStart,
        p_strPositionEnd,
        p_strFormat,
        p_strType,
        p_strUrl,
        p_strId
    ) {
        var l_strVideoThumbNailLow;
        var l_strVideoThumbNailHigh;
        var l_strUrl;
        var l_strVideoId;
        var l_strVideoTitle;
        var strJSONFinal;

        if (p_oResponse !== "noApi") {
            strJSONFinal = '{"url":[';

            if (l_aJSON.length !== 0) {
                strJSONFinal.slice(0, 8);
            }

            if (!p_oResponse.data) {
                l_strVideoThumbNailLow = p_oResponse.thumbnail_url;
                l_strVideoThumbNailHigh = p_oResponse.thumbnail_url;
                if (p_strFormat === "vimeo") {
                    l_strUrl = p_oResponse.provider_url + p_oResponse.video_id;
                    l_strVideoId = p_oResponse.video_id;

                    if (p_oResponse.title !== undefined) {
                        l_strVideoTitle = Helper.jsEncode(p_oResponse.title);
                    }
                } else if (p_strFormat === "dailymotion") {
                    l_strUrl = p_oResponse.url;
                    l_strVideoId = p_oResponse.id;

                    if (p_oResponse.title !== undefined) {
                        l_strVideoTitle = Helper.jsEncode(p_oResponse.title);
                    }
                } else if (p_strFormat === "youtube") {
                    l_strVideoId = p_oResponse;
                    l_strVideoTitle = "YouTube Video";
                    l_strVideoThumbNailLow = "http://img.youtube.com/vi/" + l_strVideoId + "/default.jpg";
                    l_strVideoThumbNailHigh = "http://img.youtube.com/vi/" + l_strVideoId + "/hqdefault.jpg";
                    l_strUrl = "http://www.youtube.com/watch?v=" + l_strVideoId;
                }
            }

            if (p_strFormat !== "url" && p_strType !== "url") {
                var resTPMatch = p_strTextUrl.match(/t=/);
                if (resTPMatch) {
                    var timeString = "start=" + p_strTextUrl.slice(resTPMatch.index + 2, p_strTextUrl.length);
                    p_strFormat = "youtubeWithTimestamp";
                }
            }

            l_aJSON.push("{");
            l_aJSON.push('"type":"video",');
            l_aJSON.push('"format":"' + p_strFormat + '",');
            l_aJSON.push('"title":"' + l_strVideoTitle + '",');
            l_aJSON.push('"id":"' + l_strVideoId + '",');
            l_aJSON.push('"url":"' + l_strUrl + '",');
            l_aJSON.push('"thumbnailHighQuality":"' + l_strVideoThumbNailHigh + '",');
            l_aJSON.push('"thumbnailLowQuality":"' + l_strVideoThumbNailLow + '",');
            l_aJSON.push('"startPoint":"' + p_strPositionStart + '",');
            l_aJSON.push('"endPoint":"' + p_strPositionEnd + '",');
            l_aJSON.push('"textUrl":"' + p_strTextUrl + '",');
            if (timeString) {
                l_aJSON.push('"timeStamp":"' + timeString + '"');
            }
            l_aJSON.push("},");

            for (var i = 0; i <= l_aJSON.length - 1; i++) {
                strJSONFinal = strJSONFinal + l_aJSON[i];
            }

            laenge = strJSONFinal.length;

            strJSONFinal = strJSONFinal.slice(0, laenge - 1);
            strJSONFinal = strJSONFinal + "]}";

            $(l_oHiddenField).val(strJSONFinal);
            p_strUrl = "";
            return;
        } else {
            if (p_strFormat !== "url" && p_strType !== "url") {
                var resTPMatch = p_strTextUrl.match(/t=/);
                if (resTPMatch) {
                    var timeString = "start=" + p_strTextUrl.slice(resTPMatch.index + 2, p_strTextUrl.length);
                    p_strFormat = "youtubeWithTimestamp";
                }
            }
            strJSONFinal = '{"url":[';

            if (l_aJSON.length !== 0) {
                strJSONFinal.slice(0, 8);
            }

            l_aJSON.push("{");
            l_aJSON.push('"type":"' + p_strType + '",');
            l_aJSON.push('"format":"' + p_strFormat + '",');
            l_aJSON.push('"title":"",');
            if (p_strId !== "") {
                l_aJSON.push('"id":"' + p_strId + '",');
            } else {
                l_aJSON.push('"id":"",');
            }
            l_aJSON.push('"url":"' + p_strUrl + '",');
            l_aJSON.push('"thumbnailHighQuality":"",');
            l_aJSON.push('"thumbnailLowQuality":"",');
            l_aJSON.push('"startPoint":"' + p_strPositionStart + '",');
            l_aJSON.push('"endPoint":"' + p_strPositionEnd + '",');
            l_aJSON.push('"textUrl":"' + p_strTextUrl + '",');
            if (timeString) {
                l_aJSON.push('"timeStamp":"' + timeString + '"');
            }
            l_aJSON.push("},");

            for (var j = 0; j <= l_aJSON.length - 1; j++) {
                strJSONFinal = strJSONFinal + l_aJSON[j];
            }

            laenge = strJSONFinal.length;

            strJSONFinal = strJSONFinal.slice(0, laenge - 1);
            strJSONFinal = strJSONFinal + "]}";

            $(l_oHiddenField).val(strJSONFinal);
            return true;
        }
    }
    return true;
};

/**
 * @private
 * embedded video, image and website urls
 * @param {object} p_oHtml Reference to a div element with data-video attribute
 */
HelperMain.prototype.videoEmbed = function (p_oHtml) {
    var inputUrlVal = p_oHtml.text(),
        htmlMarkUp = "",
        elToAppend = p_oHtml;

    if (!inputUrlVal) return false;

    var vPortYT = inputUrlVal.search(
            /^http[s]?:\/\/(?:www\.)?youtu(?:(?:\.be\/)|(?:be(?:-nocookie|\.googleapis)?\.com)\/(?:(?:(?:embed|v)\/)|(?:watch\?v=)))([a-zA-Z0-9\-_]*)/
        ),
        vPortMyV = inputUrlVal.search(/^http[s]?:\/\/(?:www\.)?myvideo\.de\/(?:(?:watch)|(?:movie))\/([0-9]*)/),
        vPortVimeo = inputUrlVal.search(
            /^http[s]?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:moogaloop\.swf\?clip_id=)?([0-9]*)/
        ),
        vPortDailyMotion = inputUrlVal.search(
            /(^http[s]?:\/\/(?:www\.)?dailymotion\.com\/(?:(?:video)))\/([a-zA-Z0-9\-_]*)/
        );

    if (vPortYT != -1) {
        if (
            inputUrlVal.match(
                /^http[s]?:\/\/(?:www\.)?youtu(?:(?:\.be\/)|(?:be(?:-nocookie|\.googleapis)?\.com)\/(?:(?:(?:embed|v)\/)|(?:watch\?v=)))([a-zA-Z0-9\-_]*)/
            )[1]
        ) {
            var strVideoId = inputUrlVal.match(
                /^http[s]?:\/\/(?:www\.)?youtu(?:(?:\.be\/)|(?:be(?:-nocookie|\.googleapis)?\.com)\/(?:(?:(?:embed|v)\/)|(?:watch\?v=)))([a-zA-Z0-9\-_]*)/
            )[1];
            if (location.protocol === "https:") {
                htmlMarkUp =
                    "<iFrame src='https://www.youtube.com/embed/" +
                    strVideoId +
                    "' frameborder='0' style='width:inherit;height:inherhit;' allowfullscreen></iFrame>";
            } else {
                htmlMarkUp =
                    "<iFrame src='http://www.youtube.com/embed/" +
                    strVideoId +
                    "' frameborder='0' style='width:inherit;height:inherhit;' allowfullscreen></iFrame>";
            }
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        }
    } else if (vPortMyV != -1) {
        if (inputUrlVal.match(/^http[s]?:\/\/(?:www\.)?myvideo\.de\/(?:(?:watch)|(?:movie))\/([0-9]*)/)[1]) {
            strVideoId = inputUrlVal.match(/^http[s]?:\/\/(?:www\.)?myvideo\.de\/(?:(?:watch)|(?:movie))\/([0-9]*)/)[1];
            htmlMarkUp =
                "<iFrame src='http://www.myvideo.de/embed/" +
                strVideoId +
                "' style='width:inherit;height:inherhit;border:0px none;padding:0;margin:0;' frameborder='0' scrolling='no'></iFrame>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        }
    } else if (vPortVimeo != -1) {
        if (
            inputUrlVal.match(
                /^http[s]?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:moogaloop\.swf\?clip_id=)?([0-9]*)/
            )[1]
        ) {
            strVideoId = inputUrlVal.match(
                /^http[s]?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:moogaloop\.swf\?clip_id=)?([0-9]*)/
            )[1];
            if (location.protocol === "https:") {
                htmlMarkUp =
                    "<iFrame src='https://player.vimeo.com/video/" +
                    strVideoId +
                    "' frameborder='0' style='width:inherit;height:inherhit;' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iFrame>";
            } else {
                htmlMarkUp =
                    "<iFrame src='http://player.vimeo.com/video/" +
                    strVideoId +
                    "' frameborder='0' style='width:inherit;height:inherhit;' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iFrame>";
            }
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        }
    } else if (vPortDailyMotion != -1) {
        if (inputUrlVal.match(/(^http[s]?:\/\/(?:www\.)?dailymotion\.com\/(?:(?:video)))\/([a-zA-Z0-9\-_]*)/)[2]) {
            strVideoId = inputUrlVal.match(
                /(^http[s]?:\/\/(?:www\.)?dailymotion\.com\/(?:(?:video)))\/([a-zA-Z0-9\-_]*)/
            )[2];
            htmlMarkUp =
                "<iframe frameborder='0' style='width:inherit;height:inherhit;' src='http://www.dailymotion.com/embed/video/" +
                strVideoId +
                "'></iframe>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        }
    } else {
        var splitUrl = inputUrlVal.split(".");
        var lastArrEl = splitUrl.pop();

        lastArrEl = lastArrEl.toLowerCase();

        if (lastArrEl === "swf") {
            htmlMarkUp =
                "<object type='application/x-shockwave-flash' style='width:inherit;height:inherhit;' data='" +
                inputUrlVal +
                "'>";
            htmlMarkUp = htmlMarkUp + "<param name='movie' value='" + inputUrlVal + "'>";
            htmlMarkUp = htmlMarkUp + "<param name='FlashVars' value='playerMode=embedded'>";
            htmlMarkUp = htmlMarkUp + "<param name='quality' value='best'>";
            htmlMarkUp = htmlMarkUp + "<param name='wmode' value='transparent'>";
            htmlMarkUp = htmlMarkUp + "</object>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        } else if (lastArrEl === "mp4" || lastArrEl.match(/ogg|oga|ogv|ogx/) || lastArrEl === "webm") {
            htmlMarkUp = "<video controls='controls' preload='auto' style='width:inherit;height:inherhit;'>";
            htmlMarkUp = htmlMarkUp + '<source src="' + inputUrlVal + '" type="video/' + lastArrEl + '">';
            htmlMarkUp =
                htmlMarkUp +
                "<p class='noSupport'>" +
                self.oUp.oMessage.VIDEO_NO_SUPPORT +
                "<a href=" +
                inputUrlVal +
                ">" +
                self.oUp.oMessage.VIDEO_DOWNLOAD +
                "</a></p>";
            htmlMarkUp = htmlMarkUp + "</video>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        } else if (lastArrEl === "mov") {
            htmlMarkUp =
                "<object classid='clsid:02BF25D5-8C17-4B23-BC80-D3488ABDDC6B' style='width:inherit;height:inherhit;' codebase='http://www.apple.com/qtactivex/qtplugin.cab'>";
            htmlMarkUp = htmlMarkUp + "<param name='src' value='" + inputUrlVal + "'>";
            htmlMarkUp = htmlMarkUp + "<param name='autoplay' value='false'>";
            htmlMarkUp = htmlMarkUp + "<param name='type' value='video/quicktime'>";
            htmlMarkUp =
                htmlMarkUp +
                "<embed src='" +
                inputUrlVal +
                " autoplay='false' style='width:inherit;height:inherhit;' type='video/quicktime' pluginspage='http://www.apple.com/quicktime/download/'>";
            htmlMarkUp = htmlMarkUp + "</object>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        } else if (
            lastArrEl === "jpg" ||
            lastArrEl === "jpeg" ||
            lastArrEl === "gif" ||
            lastArrEl === "png" ||
            lastArrEl === "bmp"
        ) {
            htmlMarkUp =
                '<img src="' +
                inputUrlVal +
                '" class="Share_Container_Image_Border_3" style="width:inherit;height:inherhit;" />';
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        } else if (lastArrEl === "js") {
            htmlMarkUp = inputUrlVal;
            $(elToAppend).removeAttr("class").text("").append(htmlMarkUp);
        }
    }
    return true;
};

/**
 * Creates a local formatted string from an date object or number object
 * and writes it as value into a html control.
 * @param oHtml Reference to a html input control of types "date", "time", "date & time", "integer", "float", "currency" (format settings can be appended here, too).
 * @param Value Value to localize.
 * @return <code>True</code> or <code>false</code>.
 */
HelperMain.prototype.writeLocalString = function (p_oHtml, p_value, p_bFireEvent) {
    if (arguments.length < 2 || !p_oHtml || !p_oHtml.oUp) {
        return false;
    }

    this.oWin.Browser.setValue(
        p_oHtml.oUp,
        p_oHtml.oUp.toLocalFormat(p_value),
        null,
        typeof p_bFireEvent == "boolean" ? p_bFireEvent : true
    );

    return true;
};
/**
 * @private
 * @deprecated Use ix.loader instead of Helper.showTTP!
 * @description Shows a Tooltip-Popup
 * @param {object} p_oHtml html control for alignment
 * @param {object} p_oFup Fup of the calling page
 * @param {string} p_strTTP Name of the tooltip
 * @param {object} p_oAddParams Additional parameters
 * @param {object} p_oTTPProps Tooltip properties
 * @param {object} p_oTTPContentProps Tooltip content properties
 * @return {string} Name of the tooltip
 */
HelperMain.prototype.showTTP = function (p_oHtml, p_oFup, p_strTTP, p_oAddParams, p_oTTPProps, p_oTTPContentProps) {
    var strAppGuid,
        strPageGuid,
        strRecId,
        position,
        alignment,
        overflowWindow,
        avoidMouse,
        className,
        fixWidth,
        fixHeight,
        closeByButton;

    var loaderSettings = {},
        loaderSettingsTooltipEntry = {},
        bModal = false;

    if (arguments.length < 4) return false;

    if (typeof p_strTTP != "string" || p_strTTP == null) {
        var dt = new Date();
        p_strTTP = "oTTP_" + dt.getTime();
    } else {
        // fester TTP Key -> TTP bereits geoeffnet: schliessen
        if (this.isTTPOpen(p_strTTP)) this.closeTTP(p_strTTP);
    }
    if (typeof p_oFup != "object" || p_oFup == null) {
        p_oFup = $(p_oHtml).getFuncPart();
    }

    if (!jQuery.isEmptyObject(p_oTTPContentProps)) {
        for (var name in p_oTTPContentProps) {
            switch (name) {
                case "bModal":
                    bModal = p_oTTPContentProps.bModal;
                    break;
                case "bMouse":
                    position = "mouse";
                    break;
                case "bWindow":
                    position = "window";
                    break;
                case "positionHandling":
                    alignment = p_oTTPContentProps[name];
                    break;
                case "bOverflowWindow":
                    overflowWindow = p_oTTPContentProps[name];
                    break;
                case "strClassName":
                    className = p_oTTPContentProps[name];
                    break;
                case "bAvoidMouse":
                    avoidMouse = p_oTTPContentProps[name];
                    break;
                case "height":
                    if (typeof p_oTTPContentProps[name] !== "undefined" && !isNaN(p_oTTPContentProps[name]))
                        fixHeight = p_oTTPContentProps[name];
                    break;
                case "width":
                    if (typeof p_oTTPContentProps[name] !== "undefined" && !isNaN(p_oTTPContentProps[name]))
                        fixWidth = p_oTTPContentProps[name];
                    break;
                case "bHandleMouseOver":
                    var oHtmlOpener = p_oTTPContentProps.oHtmlOpener;
                    if ((typeof oHtmlOpener != "object" || oHtmlOpener == null) && p_oHtml != null) {
                        // Vorsicht: direkter Zugriff auf TooltipEntry Properties
                        loaderSettingsTooltipEntry.bHandleMouseOver = p_oTTPContentProps[name];
                        loaderSettingsTooltipEntry.oHtmlOpener = p_oHtml;
                    }
                    break;
                case "bCloseButton":
                    if (typeof p_oTTPContentProps[name] === "boolean") {
                        closeByButton = p_oTTPContentProps[name];
                    }
                    break;
                default:
                    // Vorsicht: direkter Zugriff auf TooltipEntry Properties
                    loaderSettingsTooltipEntry[name] = p_oTTPContentProps[name];
            }
        }
    }

    if (!jQuery.isEmptyObject(loaderSettingsTooltipEntry)) loaderSettings.tooltipEntry = loaderSettingsTooltipEntry;

    loaderSettings.data = {};

    for (var param in p_oAddParams) {
        if (typeof p_oAddParams[param] == "string") {
            if (param == "rq_RecId") strRecId = p_oAddParams[param];
            else if (param == "rq_AppGuid") strAppGuid = p_oAddParams[param];
            else if (param == "rq_TargetPageGuid") strPageGuid = p_oAddParams[param];
            else loaderSettings.data[param] = p_oAddParams[param];
        }
    }

    loaderSettings.ixApp = {
        guid: strAppGuid,
        pageGuid: strPageGuid,
    };

    if (typeof strRecId !== "undefined") loaderSettings.ixApp.recId = strRecId;

    if (typeof p_oFup !== "undefined" && p_oFup !== null) {
        loaderSettings.fup = p_oFup;
    }

    if (typeof p_oHtml !== "undefined" && p_oHtml !== null) {
        loaderSettings.html = p_oHtml;
    }

    loaderSettings.windowSettings = {
        key: p_strTTP,
        modal: bModal,
    };

    if (typeof position !== "undefined") loaderSettings.windowSettings.position = position;

    if (typeof alignment !== "undefined") loaderSettings.windowSettings.alignment = alignment;

    if (typeof overflowWindow !== "undefined") loaderSettings.windowSettings.overflowWindow = overflowWindow;

    if (typeof className !== "undefined") loaderSettings.windowSettings.className = className;

    if (typeof avoidMouse !== "undefined") loaderSettings.windowSettings.avoidMouse = avoidMouse;

    if (typeof fixWidth !== "undefined") loaderSettings.windowSettings.width = fixWidth;

    if (typeof fixHeight !== "undefined") loaderSettings.windowSettings.height = fixHeight;

    if (typeof closeByButton !== "undefined") loaderSettings.windowSettings.closeByButton = closeByButton;

    if (typeof p_oTTPProps.bHasHeader === "boolean") loaderSettings.windowSettings.title = p_oTTPProps.bHasHeader;

    ix.loader.tooltip(loaderSettings);

    return p_strTTP;
};
/**
 * Close Tooltip-Popup
 * @param {string} p_strTTP Name of the tooltip
 */
HelperMain.prototype.closeTTP = function (p_strTTP) {
    if (arguments.length < 1 || p_strTTP == null) return false;
    if (typeof this.oWin[p_strTTP] == "object" && this.oWin[p_strTTP] != null && this.oWin[p_strTTP].oActionControl) {
        // var a = this.oWin[p_strTTP].oActionControl;
        this.oWin[p_strTTP].closePopup();
    }
};

/**
 * RegExp Function to check if URL is valid
 * @private
 */
function isValidUrl(p_strUrl) {
    var urlregex = new RegExp(
        /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i
    );
    //var urlregex = new RegExp(/^(((f|ht)tp[s]?:\/\/w{0,3})|(www\.)){1}([0-9A-Za-z]+.)/);
    if (urlregex.test(p_strUrl)) {
        return true;
    }
    return false;
}

/**
 * @private
 */
HelperMain.prototype.effect = function () {
    return ObjectFactory.construct("upEffectHelper", Helper);
};
/**
 * Gets the absolute url of an action control including all parameters
 * @private
 * @param {Object} p_oAct
 * @return {String} the absolute url
 */
HelperMain.prototype.getUrlFromUpAction = function (p_oAct) {
    var l_strText = new String("");
    if (
        typeof p_oAct == "object" &&
        (p_oAct.upType == "upTextActionControl" ||
            p_oAct.upType == "upButtonControl" ||
            p_oAct.upType == "upImageActionControl" ||
            p_oAct.upType == "upActionControl")
    ) {
        l_strText = this.getAbsBaseUrl();
        l_strText += p_oAct.getUrl();
    } else if (p_oAct.upType == "UpGenericActionControl") {
        l_strText = this.getAbsBaseUrl();
        l_strText += p_oAct.settings.loader.url;
    }
    return l_strText;
};

/**
 * Creates an array with n elements.
 * @private
 * @param n Number of elements.
 * @return An array with n elements.
 */
HelperMain.prototype.makeArray = function (n) {
    var arr = new Array(n + 1);
    for (var i = 1; i <= n; i++) {
        arr[i] = 0;
    }
    return arr;
};
/**
 * Return, if given number is an integer.
 * @private
 * @param s Input to test.
 * @return <code>True</code>, if given input is an integer, else <code>false</code>.
 */
HelperMain.prototype.isInteger = function (s) {
    if (isNaN(parseInt(s, 10))) {
        return false;
    } else {
        return true;
    }
};
/**
 * Replaces substring of string.
 * @private
 * @param p_strString Input string.
 * @param p_strSubstringFrom String to replace
 * @param p_strSubstringTo String to replace p_strSubstringFrom with.
 * @return <code>True</code> or <code>false</code>.
 */
HelperMain.prototype.replaceSubstring = function (p_strString, p_strSubstringFrom, p_strSubstringTo) {
    var l_strStingOut = "";
    if (!p_strString || p_strString == "") {
        return "";
    } else {
        var l_strString = p_strString.toString();
    }
    if (!p_strSubstringFrom || p_strSubstringFrom == "") {
        return l_strString;
    } else {
        var l_strSubstringFrom = p_strSubstringFrom.toString();
    }
    if (!p_strSubstringTo) {
        l_strSubstringTo = "";
    } else {
        var l_strSubstringTo = p_strSubstringTo.toString();
    }

    var l_lStartPos = 0;
    var l_lEndPos = l_strString.indexOf(l_strSubstringFrom, 0);
    while (l_lEndPos != -1) {
        for (var i = l_lStartPos; i < l_lEndPos; i++) {
            l_strStingOut += l_strString.charAt(i);
        }
        l_strStingOut += l_strSubstringTo;
        l_lStartPos = l_lEndPos + l_strSubstringFrom.length;
        l_lEndPos = l_strString.indexOf(l_strSubstringFrom, l_lStartPos);
    }
    for (i = l_lStartPos; i < l_strString.length; i++) {
        l_strStingOut += l_strString.charAt(i);
    }
    return l_strStingOut;
};
/**
 * @private
 * @param {Object} Float representation
 * @return {Number}
 * @description Wrapper-Method of parseFloat() to support Intrexx' localized float representations.
 */
HelperMain.prototype.parseFloat = function (oInput) {
    var oFloat = new UpFloatControl($('<input type="text"/>')[0]);
    // compatibility to Intrexx 6.0
    if (typeof oInput === "string" && oInput === "") {
        return 0;
    } else {
        return oFloat.toJSNumber(oInput);
    }
};
/**
 * @private
 * @param {Object} Integer representation
 * @return {Number}
 * @description Wrapper-Method of parseInt() to support Intrexx' localized integer representations.
 */
HelperMain.prototype.parseInt = function (oInput) {
    var oInt = new UpIntegerControl($('<input type="text"/>')[0]);
    // compatibility to Intrexx 6.0
    if (typeof oInput === "string" && trim(oInput) === "") {
        return 0;
    } else {
        return oInt.toJSNumber(oInput);
    }
};
/**
 * Compares the values of 2 html fields
 * @private
 * @param {Object} oHtmlField1 1st value
 * @param  {Object} oHtmlField2 2nd value
 * @return 2 if 1st value > 2nd value, 1 if 1st value = 2nd value , 0 if 1st value < 2nd value
 */
HelperMain.prototype.compareValues = function (oHtmlField1, oHtmlField2) {
    if (
        oHtmlField1 != null &&
        oHtmlField2 != null &&
        oHtmlField1.oUp &&
        oHtmlField2.oUp &&
        oHtmlField1.oUp.upType == oHtmlField2.oUp.upType
    ) {
        if (
            oHtmlField1.oUp.upType == "upDateControl" ||
            oHtmlField1.oUp.upType == "upTimeControl" ||
            oHtmlField1.oUp.upType == "upDateTimeControl"
        ) {
            var oDate1 = new Date();
            var oDate2 = new Date();
            var l_strDate1 = trim(Browser.getValue(oHtmlField1.oUp));
            var l_strDate2 = trim(Browser.getValue(oHtmlField2.oUp));
            if (l_strDate1 != "" && l_strDate2 != "") {
                oDate1 = oHtmlField1.oUp.setDateObjectFromLocal(l_strDate1);
                oDate2 = oHtmlField2.oUp.setDateObjectFromLocal(l_strDate2);
                if (Date.parse(oDate1) == Date.parse(oDate2)) return 1;
                else if (Date.parse(oDate1) > Date.parse(oDate2)) return 2;
                else return 0;
            }
        } else if (
            oHtmlField1.oUp.upType == "upFloatControl" ||
            oHtmlField1.oUp.upType == "upIntegerControl" ||
            oHtmlField1.oUp.upType == "upCurrencyControl"
        ) {
            var number1 = oHtmlField1.oUp.toJSNumber(trim(Browser.getValue(oHtmlField1.oUp)));
            var number2 = oHtmlField2.oUp.toJSNumber(trim(Browser.getValue(oHtmlField2.oUp)));
            if (!isNaN(number1) && !isNaN(number2)) {
                if (number1 == number2) return 1;
                else if (number1 > number2) return 2;
                else return 0;
            }
        }
    }
    return -1;
};
/**
 * Resets the fields of a datapicker
 * @private
 * @param {Object} l_oDatapicker
 * @param {Array} l_aFields Array of fields to reset
 */
HelperMain.prototype.resetDatapickerFields = function (l_oDatapicker, l_aFields) {
    if (l_oDatapicker.selectedIndex <= 0) {
        for (var fieldId in l_aFields) {
            if (l_aFields[fieldId] != null && typeof l_aFields[fieldId] == "object") l_aFields[fieldId].value = "";
        }
    }
};
/**
 * @private
 * @param {Object} p_oHtml
 * @param {Object} p_oText
 */
HelperMain.prototype.setValueToNA = function (p_oHtml, p_oText) {
    if (p_oHtml != null && p_oHtml.oUp && trim(Browser.getValue(p_oHtml.oUp)) == "") {
        var out = new String();
        if (arguments.length >= 2) out = p_oText;
        else {
            if (
                p_oHtml.oUp.upType == "upFloatControl" ||
                p_oHtml.oUp.upType == "upIntegerControl" ||
                p_oHtml.oUp.upType == "upCurrencyControl"
            ) {
                out = p_oHtml.oUp.toLocalFormat("0");
            }
        }
        Browser.setValue(p_oHtml.oUp, out);
    }
};
/**
 * Sets the visibility of an element
 * @private
 * @param {Boolean} p_bVisible make element visible or invisible
 * @param {String} p_strElement Id of the element
 * @param {Boolean} p_bKeepPlaceFree keep the space the element occupied
 * @return {Boolean} <code>false</code> in case of wrong parameters
 */
HelperMain.prototype.setElementVisibility = function (p_bVisible, p_strElement, p_bKeepPlaceFree) {
    if (arguments.length >= 2 && p_strElement != null) {
        var oElement;
        if (this.oDoc.getElementById(p_strElement) != null) oElement = this.oDoc.getElementById(p_strElement);
        else {
            if (typeof p_strElement == "object") oElement = p_strElement;
            else return false;
        }
        if (p_bVisible) {
            oElement.style.visibility = "visible";
            oElement.style.display = "";
        } else {
            oElement.style.visibility = "hidden";
            if (!p_bKeepPlaceFree || p_bKeepPlaceFree == null) oElement.style.display = "none";
        }
    } else return false;
};
/**
 * Toggles the visibility of an element
 * @private
 * @param {String} p_strElement Id of the element
 */
HelperMain.prototype.toggleElementVisibility = function (p_strElement) {
    if (typeof p_strElement == "string" && this.oDoc.getElementById(p_strElement)) {
        this.oWin.Helper.setElementVisibility(
            this.oDoc.getElementById(p_strElement).style.visibility == "hidden" ||
                this.oDoc.getElementById(p_strElement).style.display == "none",
            p_strElement
        );
    } else {
        if (typeof p_strElement == "object")
            this.oWin.Helper.setElementVisibility(
                p_strElement.style.visibility == "hidden" || p_strElement.style.display == "none",
                p_strElement
            );
    }
};
/**
 * @private
 */
HelperMain.prototype.createElement = function (p_strEl, p_strId, p_strStyleClass, p_aStyles) {
    if (arguments.length >= 2) {
        // eslint-disable-next-line no-inner-declarations
        function _upCreateElement(doc) {
            var newElement = doc.createElement(p_strEl);
            var newElementStyle = newElement.style;
            newElement.id = p_strId;
            if (typeof p_strStyleClass == "string") newElement.className = p_strStyleClass;
            if (typeof p_aStyles == "object") {
                for (var l_Style in p_aStyles) {
                    //(S_10300) prevent from parsing zIndex value "auto" as number
                    if (l_Style == "zIndex" && isNaN(p_aStyles[l_Style])) newElementStyle.l_Style = p_aStyles[l_Style];
                    else newElementStyle[l_Style] = p_aStyles[l_Style];
                }
            }
            return newElement;
        }
        return _upCreateElement(this.oDoc);
    }
    return false;
};
/**
 * @private
 * Checks if a tooltip popup with the given name is already open
 * @param {String} p_strTTP Name of the tooltip
 * @return {Boolean} <code>true</code>, if open
 */
HelperMain.prototype.isTTPOpen = function (p_strTTP) {
    var l_bRet = false;
    if (arguments.length > 0 || p_strTTP == null) {
        if (oHtmlRoot.oUp.oRegistry.oToolTipPopupReg[p_strTTP]) {
            l_bRet = true;
        }
    }
    return l_bRet;
};
/**
 * Resizes a window to fit its contents
 * @param {Object} p_oWindow
 * @private
 */
HelperMain.prototype.resizeByContent = function (p_oWindow) {
    if (arguments.length < 1 || !p_oWindow || p_oWindow == null) return false;
    if (!p_oWindow.style && p_oWindow == this.oWin.top) {
        if (p_oWindow.oUp && p_oWindow.oUp.upType == "upPopUpWindow") {
            p_oWindow.oUp.resizeByContent();
        }
    } else if (p_oWindow.style) {
        // IFrame
        var ffEH;
        if (Browser.firefox) {
            var iFFVersion = navigator.userAgent.substring(navigator.userAgent.indexOf("Firefox")).split("/")[1];
            ffEH = parseFloat(iFFVersion) >= 0.1 ? 3 : 0;
        } else {
            ffEH = 0;
        }
        //var l_iWidth =(p_oWindow.contentDocument && p_oWindow.contentDocument.body.offsetWidth)? (p_oWindow.contentDocument.body.offsetWidth)+'px' : (p_oWindow.Document && p_oWindow.Document.body.scrollWidth)? (p_oWindow.Document.body.scrollWidth)+'px':'';
        //var l_iHeight =(p_oWindow.contentDocument && p_oWindow.contentDocument.body.offsetHeight)? (p_oWindow.contentDocument.body.offsetHeight+ffEH)+'px' : (p_oWindow.Document && p_oWindow.Document.body.scrollHeight)? (p_oWindow.Document.body.scrollHeight)+'px':'';
        // eslint-disable-next-line no-undef
        var l_iWidth = (l_iHeight = 0);
        if (p_oWindow.contentDocument) {
            if (p_oWindow.contentDocument.body.scrollWidth) l_iWidth = p_oWindow.contentDocument.body.scrollWidth;
            else if (p_oWindow.contentDocument.body.offsetWidth) l_iWidth = p_oWindow.contentDocument.body.offsetWidth;
        } else {
            if (p_oWindow.Document && p_oWindow.Document.body.scrollWidth)
                l_iWidth = p_oWindow.Document.body.scrollWidth;
        }
        if (p_oWindow.contentDocument) {
            if (p_oWindow.contentDocument.body.scrollHeight) {
                // eslint-disable-next-line no-undef
                l_iHeight = p_oWindow.contentDocument.body.scrollHeight;
            } else if (p_oWindow.contentDocument.body.offsetHeight) {
                // eslint-disable-next-line no-undef
                l_iHeight = p_oWindow.contentDocument.body.offsetHeight + ffEH;
            }
        } else {
            if (p_oWindow.Document && p_oWindow.Document.body.scrollHeight) {
                // eslint-disable-next-line no-undef
                l_iHeight = p_oWindow.Document.body.scrollHeight;
            }
        }
        // eslint-disable-next-line no-undef
        if (l_iWidth > 0 && l_iHeight > 0) {
            p_oWindow.style.width = l_iWidth + "px";
            // eslint-disable-next-line no-undef
            p_oWindow.style.height = l_iHeight + "px";
        }
    }
};
/**
 * Generates a unique (random) key
 * @private
 * @param {String} p_strPre prefix for the generated string
 * @return {String} generated key
 */
HelperMain.prototype.getUniqueKey = function (p_strPre) {
    if (arguments.length < 1) {
        p_strPre = "ID_";
    }
    var a = 1 + 99999999 * Math.random();
    a = Math.round(a);
    return p_strPre + a;
};
/**
 * @private
 */
HelperMain.prototype.startTimer = function (p_strKey) {
    if (typeof this.aStopWatch == "undefined" || this.aStopWatch == null) this.aStopWatch = [];
    this.aStopWatch.push([new Date(), p_strKey || null]);
};
/**
 * @private
 */
HelperMain.prototype.stopTimer = function (p_bOut) {
    var l_strRet = "";
    if (typeof this.aStopWatch != "undefined" && this.aStopWatch != null && this.aStopWatch.length > 0) {
        if (this.aStopWatch.length > 1) {
            for (var i = 1; i < this.aStopWatch.length; i++) {
                if (i > 1) l_strRet += " - ";
                if (this.aStopWatch[i][1] != null) l_strRet += " (" + this.aStopWatch[i][1] + ") ";
                l_strRet += this.aStopWatch[i][0].getTime() - this.aStopWatch[0][0].getTime() + " ms";
            }
            l_strRet += " ==> ";
        }
        var dtEnd = new Date();
        if (this.aStopWatch[0][1] != null) l_strRet += " (" + this.aStopWatch[0][1] + ") ";
        l_strRet += dtEnd.getTime() - this.aStopWatch[0][0].getTime() + " ms";
        this.aStopWatch = [];
    }
    if (p_bOut) {
        log(l_strRet);
    } else {
        return l_strRet;
    }
};
/**
 * Cross-browser function to load and parse a XML string.
 * @private
 * @param {String} p_strXml XML string to parse.
 * @return documentElement Represents the xml root node.
 */
HelperMain.prototype.parseXmlString = function (p_strXml) {
    var doc;
    if (window.ActiveXObject) {
        doc = new ActiveXObject("Microsoft.XMLDOM");
        doc.async = "false";
        doc.loadXML(p_strXml);
    } else {
        // code for Mozilla, Firefox, Opera, etc.
        var parser = new DOMParser();
        doc = parser.parseFromString(p_strXml, "text/xml");
    }
    // documentElement == root node
    return doc.documentElement;
};
/**
 * returns textcontent of heading 1 from Stage.
 * @private
 * @return {String} textcontent of heading 1
 */
HelperMain.prototype.getH1 = function (p_oHtml) {
    try {
        if (typeof p_oHtml != "object" && isUndefinedOrNull(ix.container.getStageContainer())) return "";
        var oHtml =
            typeof p_oHtml != "object" || p_oHtml == null ? ix.container.getStageContainer().getHtml() : p_oHtml;
        var aH1 = oHtml.getElementsByTagName("h1");
        if (aH1.length == 0) return "";
        var nHtml = aH1[0];
        if (
            nHtml.nodeType == 3 //Textknoten
        )
            return nHtml.nodeValue;
        else if (nHtml.hasChildNodes()) return this._getH1(nHtml);
        else return "";
    } catch (e) {
        return "";
    }
};
/**
 * @private
 */
HelperMain.prototype._getH1 = function (nHtml) {
    var text = "";
    for (var i = 0; i < nHtml.childNodes.length; i++) {
        var cNode = nHtml.childNodes[i];

        if (
            cNode.nodeType == 3 //Textknoten
        )
            text += cNode.nodeValue;
        else if (cNode.hasChildNodes() && cNode.nodeName && cNode.nodeName != "SCRIPT") text += this._getH1(cNode);
    }
    return text;
};

/**
 * @class
 * @private
 */
function upEventHelper(helper) {
    /**
     * @private
     */
    this.oHlp = helper;
    /**
     * @private
     */
    this.oWin = this.oHlp.oWin;
    /**
     * @private
     */
    this.oDoc = this.oHlp.oDoc;
}
/**
 * @private
 */
upEventHelper.prototype.cancelBubble = function (e) {
    try {
        if (e.cancelable) e.preventDefault();
        if (e.stopPropagation) e.stopPropagation();

        e.cancelBubble = true;

        if (e.preventDefault) {
            e.preventDefault();
        } else {
            e.returnValue = false;
        }
    } catch (e) {
        // IE HACK (Stichwort Event Clone)
        if (Browser.ie && window.event) {
            try {
                if (window.event.cancelable) window.event.preventDefault();
                if (window.event.stopPropagation) window.event.stopPropagation();
                window.event.cancelBubble = true;
                if (window.event.preventDefault) {
                    window.event.preventDefault();
                } else {
                    window.event.returnValue = false;
                }
            } catch (e) {
                console.trace(e);
            }
        }
        //window.event
    }
};

/**
 * @private
 * Enlarge the stage or a given container to document.body size
 * @param {Boolean} p_bKeepWidth keep the containers width
 * @param {Boolean} p_bCenter keep the container centered if possible
 * @param {String} p_strContainerId id of the container to enlarge
 */
HelperMain.prototype.enlargeStage = function (p_bKeepWidth, p_bCenter, p_strContainerId) {
    if (arguments.length < 3) p_strContainerId = "Container_Stage";
    var oCont = $("#" + p_strContainerId);
    if (oCont.parent().attr("id") === undefined) oCont.parent().attr("id", "elp");
    var p_strParentContainerId = oCont.parent().attr("id");
    var origWidth = oCont.width();
    var ContStage = oCont.detach();
    var shrinkIcon =
        "<div id='ID_ShrinkIconContainer'><a href='#' onclick='$(\"#" +
        p_strContainerId +
        '").off("dblclick.shrink");Helper.shrinkStage("' +
        p_strContainerId +
        '", "' +
        p_strParentContainerId +
        "\");return false;' title='" +
        self.oUp.oMessage.FULLSCREEN_OFF +
        "'></a></div>";
    ContStage.find("script[type='text/javascript']").detach();
    ContStage.css({
        height: "100%",
        padding: "0",
        display: "none",
    });
    if (typeof p_bKeepWidth !== "undefined" && p_bKeepWidth)
        ContStage.css({
            width: origWidth,
        });
    else
        ContStage.css({
            width: "100%",
        });
    if (typeof p_bCenter !== "undefined" && p_bCenter)
        ContStage.css({
            margin: "0 auto",
        });
    else
        ContStage.css({
            margin: "0",
        });
    $("body > *").not("*[id^=fancybox],[id=blueimp-gallery]").fadeOut();
    $("body").prepend(ContStage);
    $("body").append(shrinkIcon);
    oCont.fadeIn("slow");
    oCont.one("dblclick.shrink", function () {
        Helper.shrinkStage(p_strContainerId, p_strParentContainerId);
    });
};

/**
 * @private
 * reverse the effects of "enlargeStage"
 * @param {String} p_strContainerId id of the enlarged container
 * @param {String} p_oParentContainer jquery parent of the container
 */
HelperMain.prototype.shrinkStage = function (p_strContainerId, p_strParentContainerId) {
    var p_oParentContainer, p_oContainer;
    if (arguments.length < 2) p_oParentContainer = $("#Container_Content");
    else p_oParentContainer = $("#" + p_strParentContainerId);
    if (arguments.length < 1) p_oContainer = $("#Container_Stage");
    else p_oContainer = $("#" + p_strContainerId);
    var ContStage = p_oContainer.detach();
    ContStage.find("script[type='text/javascript']").detach();
    ContStage.css({
        width: "",
        height: "",
        padding: "",
        margin: "",
    });
    p_oParentContainer.append(ContStage);
    $("#ID_ShrinkIconContainer").remove();
    $("body > *").not("*[id^=fancybox],[id=blueimp-gallery]").fadeIn("slow");
};

/**
 * Returns the parent node, which is the row.
 * @private
 * @deprecated
 * @param {object} no Reference to a element node.
 * @return {object} element node
 */
function getRow(no) {
    if (no.nodeName.toLowerCase() == "tr") {
        return no;
    } else {
        if (no.parentNode) {
            no = this.getRow(no.parentNode);
        } else {
            return null;
        }
    }
    return no;
}

/**
 * Returns the parent node, which is the col.
 * @private
 * @deprecated
 * @param {object} no Reference to a node.
 * @return {object} element node
 */
function getCol(no) {
    if (no.nodeName.toLowerCase() == "td") {
        return no;
    } else {
        if (no.parentNode) {
            no = this.getCol(no.parentNode);
        } else {
            return null;
        }
    }
    return no;
}
