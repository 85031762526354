/**
 * @file deprecated global mappings for ix module functions
 */
/* global ix */
/* exported addHistoryEntry, createBrowserHistory */
/**
 * @ignore
 * @param {String} url A url as a string.
 * @param {Object} postData An assoziative array, which contains all posted data.
 * @return {Object} HistoryEntry
 * @description
 * Adds an HistoryEntry to Browserhistory.
 */
function addHistoryEntry(url, postData) {
    return ix.history.addHistoryEntry(url, postData);
}

/**
 * @ignore
 * @return {Boolean} status
 * @see BrowserHistory
 * @see HistoryManager
 * @description
 * creates and initializes the Intrexx browser history, which brings the back button to work in the context af an ajax layout.
 */
function createBrowserHistory() {
    return ix.history.createBrowserHistory();
}
