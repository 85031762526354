/**
 * @file API functions
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ix, Helper, UpDateTimeEditControl, UpDateEditControl, Browser */

(function (root, factory) {
    /**
     * @namespace API
     * @description General API methods.
     */
    root.API = factory();

    // publish everything from API to global for backward compatibility
    for (var prop in root.API) {
        if (root.API.hasOwnProperty(prop)) {
            root[prop] = root.API[prop];
        }
    }
})(this, function () {
    "use strict";

    var ns = {};

    /**
     * @type {Object}
     * @private
     * @description Stores all possible operators for comparison.
     * Used to prevent from using eval().
     */
    var operators = {
        ">": function (a, b) {
            return a > b;
        },
        "<": function (a, b) {
            return a < b;
        },
        ">=": function (a, b) {
            return a >= b;
        },
        "<=": function (a, b) {
            return a <= b;
        },
        "==": function (a, b) {
            return a == b; // eslint-disable-line eqeqeq
        },
        "!=": function (a, b) {
            return a != b; // eslint-disable-line eqeqeq
        },
        "===": function (a, b) {
            return a === b;
        },
        "!==": function (a, b) {
            return a !== b;
        },
    };

    /**
     * @function getDateObject
     * @memberof API
     * @param {Object} ctrl Reference to a html input control of types "date", "time" or "date & time".
     * @return {Date} date
     * @description Creates an JavaScript Date from a html control.
     * @example
     * getDateObject(getElement("A84E5E7F9EA9ED1A829DB98DBF5459F59961FA3B"));
     */
    ns.getDateObject = function (ctrl) {
        return Helper.getDateObject(ctrl);
    };

    /**
     * @function getNumberObject
     * @memberof API
     * @param {Object} ctrl Reference to a html input control of types "integer", "double" or "currency".
     * @return {Number} number
     * @description Creates an JavaScript Number from a html control.
     * @example
     * getNumberObject(getElement("5F7S5E7F9EA9ED1A829DB98DBF5459F59961FA3B"));
     */
    ns.getNumberObject = function (ctrl) {
        return Helper.getNumberObject(ctrl);
    };

    /**
     * @ignore
     * @deprecated
     * @function getIntegerStringByLocal
     * @memberof API
     * @param {String} value Local formatted integer string.
     * @return {String}
     * @description Parses a local formatted string representing an integer value.
     * @example
     * getIntegerStringByLocal("1.234");
     */
    ns.getIntegerStringByLocal = function (value) {
        return Helper.getIntegerStringByLocal(value);
    };

    /**
     * @ignore
     * @deprecated
     * @function getDoubleStringByLocal
     * @memberof API
     * @param {String} value Local formatted double string.
     * @return {String}
     * @description Parses a local formatted string representing a double value.
     * @example
     * getDoubleStringByLocal("1.234");
     */
    ns.getDoubleStringByLocal = function (value) {
        return Helper.getFloatStringByLocal(value);
    };

    /**
     * @ignore
     * @deprecated
     * @function getFloatStringByLocal
     * @memberof API
     * @param {String} value
     * @return {String}
     */
    ns.getFloatStringByLocal = function (value) {
        return Helper.getFloatStringByLocal(value);
    };

    /**
     * @ignore
     * @deprecated
     * @function getCurrencyStringByLocal
     * @memberof API
     * @param {String} value Local formatted currency string.
     * @return {String}
     * @description Parses a local formatted string representing a currency value.
     * @example
     * getCurrencyStringByLocal("1.234");
     */
    ns.getCurrencyStringByLocal = function (value) {
        return Helper.getCurrencyStringByLocal(value);
    };

    /**
     * @ignore
     * @deprecated
     * @function getIntegerByLocalString
     * @memberof API
     * @param {String} value Local formatted integer string.
     * @return {Number}
     * @description Parses a local formatted string representing an integer value and returns a JavaScript Number.
     * @example
     * getIntegerByLocalString("1.234");
     */
    ns.getIntegerByLocalString = function (value) {
        return Helper.getIntegerByLocalString(value);
    };

    /**
     * @ignore
     * @deprecated
     * @function getDoubleByLocalString
     * @memberof API
     * @param {String} value Local formatted string.
     * @return {Number}
     * @description Parses a local formatted string representing a double value and returns a JavaScript Number.
     * @example
     * getDoubleByLocalString("1.234");
     */
    ns.getDoubleByLocalString = function (value) {
        return Helper.getFloatByLocalString(value);
    };

    /**
     * @ignore
     * @deprecated
     * @function getFloatByLocalString
     * @memberof API
     * @param {String} value
     * @return {Number}
     */
    ns.getFloatByLocalString = function (value) {
        return Helper.getFloatByLocalString(value);
    };

    /**
     * @ignore
     * @deprecated
     * @function getCurrencyByLocalString
     * @memberof API
     * @param {String} value Local formatted currency string.
     * @return {Number}
     * @description Parses a local formatted string representing a currency value and returns a JavaScript Number.
     * @example
     * getCurrencyByLocalString("1.234");
     */
    ns.getCurrencyByLocalString = function (value) {
        return Helper.getCurrencyByLocalString(value);
    };

    /**
     * @ignore
     * @deprecated
     * @function toLocalString
     * @memberof API
     * @param {Object} ctrl Reference to a html input control of types "integer", "double", "currency" (format settings can be appended here, too).
     * @param {String} value Number string or date string.
     * @return {String} formatted Local formatted number as string or local formatted datetime as string.
     * @description Creates a local formatted string from an number string or a datetime string.
     * @example
     * toLocalString(getElement("DDB279D4974A17DB27A89F58DB36F7BF4E10DA13"),"123.45");
     */
    ns.toLocalString = function (ctrl, value) {
        return Helper.toLocalString(ctrl, value);
    };

    /**
     * @ignore
     * @deprecated
     * @function writeLocalString
     * @memberof API
     * @param {Object} ctrl Reference to a html input control of types "date", "time", "date & time", "integer", "double", "currency" (format settings can be appended here, too).
     * @param {String} value Value to localize.
     * @return {Boolean}
     * @description Creates a local formatted string from an number string or a datetime string
     * and writes it as value into a html control.
     * @example
     * writeLocalString(getElement("DDB279D4974A17DB27A89F58DB36F7BF4E10DA13"),"123.45");
     */
    ns.writeLocalString = function (ctrl, value) {
        return Helper.writeLocalString(ctrl, value);
    };

    /**
     * @ignore
     * @deprecated
     * @function checkRequired
     * @memberof API
     * @param {Object} ctrl Reference to a html input control or to a form control ("FormGroup")
     * @param {String} [message] Text to show, if checkRequired returns <code>false</code>. If not set, value of FORM_CONSTR_REQUIRED from "preset texts" will be displayed.
     * @return {Boolean}
     * @description Checks a html control, as if "entry required" has been set OR checks all controls of a form, where "entry required" was set.
     * @example
     * checkRequired(getElement("E94A9FBDADD46039BB62F76870E1EC57C226C6DC"), "An alternative text!");
     */
    ns.checkRequired = function (ctrl, message) {
        if (!ctrl || !ctrl.oUp) {
            return false;
        }
        if (arguments.length < 2) {
            message = "";
        }

        var validationResult = validateRequired(ctrl);
        if (!validationResult) {
            // Eingabe ist erforderlich aber ein Wert konnte nicht gefunden werden
            var mediator = self.oUp.mediator;
            mediator.publish("ixpage/validation/control/report/show", {
                element: ctrl,
                isValid: false,
                message: message,
            });
        }
        return validationResult;
    };

    function validateRequired(ctrl) {
        // ist das Eingabefeld erforderlich?
        var isRequired =
            (ctrl instanceof HTMLInputElement && ctrl.required) ||
            (!!ctrl.oUp &&
                !!ctrl.oUp.required &&
                (ctrl.oUp.required === true || ctrl.oUp.required === 1 || ctrl.oUp.required === "1"));

        // nein -> fertig
        if (!isRequired) return true;

        if (ctrl.oUp.upType === "upDistribution") {
            if (ctrl.oUp.checkValidity().isValid) return true;
        } else if (ctrl.oUp.upType === "upFileEditControl") {
            return ctrl.oUp.getValue().length > 0;
        } else if (ctrl.oUp.hasValue != null) {
            return ctrl.oUp.hasValue();
        } else if (!!ctrl.oUp && !!ctrl.oUp.getValue) {
            return ctrl.oUp.getValue() !== "" && ctrl.oUp.getValue() !== undefined && ctrl.oUp.getValue() !== null;
        } else if (ctrl instanceof HTMLInputElement && ctrl.value !== "") {
            return true;
        } else if (ctrl instanceof HTMLSelectElement && ctrl.selectedIndex > -1) {
            return true;
        }
        return false;
    }

    /**
     * @ignore
     * @deprecated
     * @function calculate
     * @memberof API
     * @param {Object} ctrl1 Reference to a html control of type "integer", "double", "currency"
     * @param {Object} ctrl2 Reference to a html control of type "integer", "double", "currency"
     * @param {String} operator
     * @param {Object} ctrlResult Reference to a html control of type "integer", "double", "currency"
     * @return {Boolean}
     * @description Calculates a result of values from two html controls with an operator and writes it into a html control.
     * @example
     * calculate(getElement("F28BA7351E3C8F5123880548CFA72B64566A1421"), getElement("80467069EA61BD786CFFEB508786723D6451F2F5"), "+", getElement("B52A9E9FC555A820984E63DECF13BCAA33E308CD"));
     */
    ns.calculate = function (ctrl1, ctrl2, operator, ctrlResult) {
        try {
            if (
                !ctrl1 ||
                !ctrl1.oUp ||
                !ctrl2 ||
                !ctrl2.oUp ||
                !operator ||
                !ctrlResult ||
                !ctrlResult.oUp ||
                (ctrl1.oUp.getValue().length === 0 && ctrl2.oUp.getValue().length === 0)
            ) {
                return false;
            }
            var val1 = ctrl1.oUp.getNumberObject();
            var val2 = ctrl2.oUp.getNumberObject();
            var result;
            if (!isNaN(val1) && !isNaN(val2)) {
                if (operator === "+") {
                    result = val1 + val2;
                } else if (operator === "-") {
                    result = val1 - val2;
                } else if (operator === "*") {
                    result = val1 * val2;
                } else if (operator === "/") {
                    result = val1 / val2;
                } else if (operator === "%") {
                    result = val1 % val2;
                }
                ctrlResult.oUp.setValue(result, { triggerOnchange: "strict" });
            }
        } catch (e) {
            if (e instanceof Error) {
                var toAlert = e.message;
                if (e.oControl && e.oControl.userName && e.oControl.userName !== "") {
                    toAlert = toAlert + "\n(" + e.oControl.userName + ")";
                }
                if (e.info && e.info !== "") {
                    toAlert = toAlert + "\n(" + e.info + ")";
                }
                alert(toAlert);
            } else {
                alert(Browser.getExceptionMessage(e));
            }
            return false;
        }
    };

    /**
     * @ignore
     * @deprecated
     * @function setTextValueById
     * @memberof API
     * @param {String} id DOM id of the element; equivalent to the id-attribute value of the html element.
     * @param {String} value Value to write.
     * @return {Boolean}
     * @description Writes a value in a viewcontrol or static textcontrol.
     * @example
     * setTextValueById("ID_labelcontrol900CB1AB", "Some text");
     */
    ns.setTextValueById = function (id, value) {
        return Browser.setValueByElementId(id, value);
    };

    /**
     * @ignore
     * @deprecated
     * @function setTextValue
     * @memberof API
     * @param {Object} ctrl Reference to a html input control.
     * @param {String} value Value to write.
     * @return {Boolean}
     * @description Writes a value in a viewcontrol or static textcontrol.
     * @example
     * setTextValue(getElement("F28BA7351E3C8F5123880548CFA72B64566A1433"), "Some text");
     */
    ns.setTextValue = function (ctrl, value) {
        return Browser.setValue(ctrl, value);
    };

    /**
     * @ignore
     * @deprecated
     * @function getTextValueById
     * @memberof API
     * @param {String} id DOM id of the element; equivalent to the id-attribute value of the html element.
     * @return {String} value Value from viewcontrol or static textcontrol.
     * @description Reads a value from a viewcontrol or static textcontrol.
     * @example
     * getTextValueById("ID_labelcontrol900CB1AB");
     */
    ns.getTextValueById = function (id) {
        return Browser.getValueByElementId(id);
    };

    /**
     * @ignore
     * @deprecated
     * @function getTextValue
     * @memberof API
     * @param {Object} ctrl Reference to a html input control.
     * @return {String} value Value from viewcontrol or static textcontrol.
     * @description Reads a value from a viewcontrol or static textcontrol.
     * @example
     * getTextValue(getElement("F28BA7351E3C8F5123880548CFA72B64566A1433"));
     */
    ns.getTextValue = function (ctrl) {
        return Browser.getValue(ctrl);
    };

    /**
     * @ignore
     * @deprecated
     * @function setFullDays
     * @memberof API
     * @param {Object} ctrlCheck Reference to a checkbox input control
     * @param {Object} ctrlFrom Reference to a html input control (date from)
     * @param {Object} ctrlTo Reference to a html input control (date to)
     * @description Sets two datafields on a duration of a whole day: dateFrom 00:00 a.m., dateTo 00:00 a.m. next day.
     * @example
     * // Example usage (executable on an checkbox onclick() event):
     * setFullDays(getElement("123401F1E55C9A193BE6EC4091B45C6A5A6C8154"), getElement("DD2E01F1E55C9A193BE6EC4091B45C6A5A6C8154"), getElement("4D0708BDECC7D088613F818CE1B09B8D812F5750"));
     */
    ns.setFullDays = function (ctrlCheck, ctrlFrom, ctrlTo) {
        if (!ctrlCheck.checked) {
            ctrlFrom.disabled = false;
            ctrlTo.disabled = false;
            return;
        }
        var dateFrom;
        var dateTo;
        if (ctrlFrom.value === "") {
            dateFrom = new Date();
        } else {
            dateFrom = ctrlFrom.oUp.setDateObjectFromLocal(ctrlFrom.value);
        }
        dateFrom.setHours(0, 0, 0, 0);
        ctrlFrom.value = ctrlFrom.oUp.toLocalFormat(dateFrom);
        if (ctrlTo.value === "") {
            dateTo = new Date(dateFrom);
            dateTo = Helper.dateAdd("d", +1, dateTo); // einen Tag hochzaehlen
        } else {
            dateTo = Helper.getDateObject(ctrlTo);
            if (!(dateTo.getHours() === 0 && dateTo.getMinutes() === 0 && dateTo.getSeconds() === 0)) {
                dateTo = Helper.dateAdd("d", 1, dateTo); // einen Tag hochzaehlen
            } else if (dateFrom.getTime() >= dateTo.getTime()) {
                dateTo = Helper.dateAdd("d", 1, dateTo); // einen Tag hochzaehlen
            }
        }
        dateTo.setHours(0, 0, 0, 0);
        ctrlTo.value = ctrlTo.oUp.toLocalFormat(dateTo);
        return;
    };

    /**
     * @function inking
     * @memberof API
     * @param {String} control Name of control, whose value should be checked (name from expert page of the column).
     * @param {String} op Operator
     * @param {String} comp Value to compare with.
     * @param {String} color Value of color (hexadecimal or name)
     * @param {String} colored Possible values: "row" = whole row (tr), "cell" = whole cell (td), "span" = text of cell (span).
     * @description Colors rows and cells in a viewtablecontrol depending on a number value.
     * @example
     * inking("viewcontrolD54BA2DD", ">", 10, "#008000", "row");
     */
    ns.inking = function (control, op, comp, color, colored) {
        var id;
        var value;
        var idStart = "ID_" + control;
        var spans = $("span[id^=" + idStart + "],a[id^=" + idStart + "]");

        for (var i = 0; i < spans.length; i++) {
            var span = spans[i];
            if (span.getAttribute("id") && span.getAttribute("id").length > idStart.length) {
                id = span.getAttribute("id");
                if (idStart === id.substring(0, idStart.length) && span.firstChild !== null) {
                    value = span.firstChild.nodeValue;
                    var parsedValue = Helper.getFloatStringByLocal(value);
                    switch (colored) {
                        case "span":
                            if (span.style) {
                                if (operators[op](parsedValue, comp)) {
                                    span.style.backgroundColor = color;
                                }
                            }
                            break;
                        case "cell":
                            if (span.parentNode.nodeName.toLowerCase() === "td" && span.parentNode.style) {
                                if (operators[op](parsedValue, comp)) {
                                    span.parentNode.style.backgroundColor = color;
                                }
                            }
                            break;
                        default:
                            if (
                                span.parentNode.parentNode.nodeName.toLowerCase() === "tr" &&
                                span.parentNode.parentNode.style
                            ) {
                                if (operators[op](parsedValue, comp)) {
                                    span.parentNode.parentNode.style.backgroundColor = color;
                                }
                            }
                            break;
                    }
                }
            }
        }
    };

    /**
     * @see inking
     * @function inkingDate
     * @memberof API
     * @param {String} control Id of control, whose value should be checked, without prefix "ID_".
     * @param {String} op Operator
     * @param {String} [type=""] Compare either against today as "date" or today as "datetime".
     * @param {String} color Value of color (hexadecimal or name)
     * @param {String} colored Possible values: "row" = whole row (tr), "cell" = whole cell (td), "span" = text of cell (span).
     * @description Colors date or datetime values in a viewtablecontrol depending on today.
     */
    ns.inkingDate = function (control, op, type, color, colored) {
        var ctrl = null;
        if (type === "date") {
            /* jshint -W055 */
            ctrl = new UpDateEditControl($('<input type="text"/>')[0]);
            /* jshint +W055 */
        } else {
            /* jshint -W055 */
            ctrl = new UpDateTimeEditControl($('<input type="text"/>')[0]);
            /* jshint +W055 */
        }
        var now = new Date();
        if (type === "date") {
            now.setHours(0, 0, 0, 0);
        }
        var idStart = "ID_" + control;
        var spans = $("span[id^=" + idStart + "],a[id^=" + idStart + "]");

        for (var i = 0; i < spans.length; i++) {
            var span = spans[i];
            if (span.getAttribute("id") && span.getAttribute("id").length > idStart.length) {
                var id = span.getAttribute("id");
                if (idStart === id.substring(0, idStart.length) && span.firstChild !== null) {
                    var value = span.firstChild.nodeValue;
                    var parsedValue = ctrl.setDateObjectFromLocal(value);
                    switch (colored) {
                        case "span":
                            if (span.style) {
                                if (operators[op](Date.parse(parsedValue), Date.parse(now))) {
                                    span.style.backgroundColor = color;
                                }
                            }
                            break;

                        case "cell":
                            if (span.parentNode.nodeName.toLowerCase() === "td" && span.parentNode.style) {
                                if (operators[op](Date.parse(parsedValue), Date.parse(now))) {
                                    span.parentNode.style.backgroundColor = color;
                                }
                            }
                            break;

                        default:
                            if (
                                span.parentNode.parentNode.nodeName.toLowerCase() === "tr" &&
                                span.parentNode.parentNode.style
                            ) {
                                if (operators[op](Date.parse(parsedValue), Date.parse(now))) {
                                    span.parentNode.parentNode.style.backgroundColor = color;
                                }
                            }
                            break;
                    }
                }
            }
        }
    };

    /**
     * @see inking
     * @function inkingString
     * @memberof API
     * @param {String} control Name of control, whose value should be checked (name from expert page of the column).
     * @param {String} op Operator
     * @param {String} comp Value to compare with.
     * @param {String} color Value of color (hexadecimal or name)
     * @param {String} colored Possible values: "row" = whole row (tr), "cell" = whole cell (td), "span" = text of cell (span).
     * @description Colors strings in a viewtablecontrol depending on a number value.
     */
    ns.inkingString = function (control, op, comp, color, colored) {
        var id;
        var value;
        var idStart = "ID_" + control;
        var spans = $("span[id^=" + idStart + "],a[id^=" + idStart + "]");

        for (var i = 0; i < spans.length; i++) {
            var span = spans[i];
            if (span.getAttribute("id") && span.getAttribute("id").length > idStart.length) {
                id = span.getAttribute("id");
                if (idStart === id.substring(0, idStart.length) && span.firstChild !== null) {
                    value = Helper.trim(span.firstChild.nodeValue);
                    if (operators[op](value, comp)) {
                        switch (colored) {
                            case "span":
                                span.style.backgroundColor = color;
                                break;

                            case "cell":
                                if (span.parentNode.nodeName.toLowerCase() === "td" && span.parentNode.style) {
                                    span.parentNode.style.backgroundColor = color;
                                }
                                break;

                            default:
                                if (
                                    span.parentNode.parentNode.nodeName.toLowerCase() === "tr" &&
                                    span.parentNode.parentNode.style
                                ) {
                                    span.parentNode.parentNode.style.backgroundColor = color;
                                }
                                break;
                        }
                    }
                }
            }
        }
    };

    /**
     * @ignore
     * @deprecated
     * @function dateComparedToNow
     * @memberof API
     * @param {Object} ctrl Reference to an html input control of type "date", "time" or "datetime"
     * @return {Number}
     * @description Compares a value of a date control with the current date.
     * Imperative for input and view controls (for view controls the expert attribute <code>jsobject = true (Boolean)</code> has to be set.)
     * @example
     * dateComparedToNow(getElement("5F7S5E7F9EA9ED1A829DB98DBF5459F59961FA3B"));
     */
    ns.dateComparedToNow = function (ctrl) {
        return Helper.dateComparedToNow(ctrl);
    };

    /**
     * @ignore
     * @deprecated
     * @function dateAdd
     * @memberof API
     * @param {String} interval Possible values: "ms" - for milliseconds; "s" - for seconds; "mi" - for minutes; "h" - for hours; "d" - for days; "mo" - for months; "y" - for years;
     * @param {Number} number Value for given interval, that should be added to date. Negative values are also possible.
     * @param {Date} date
     * @return {Date}
     * @description Adds or subtracts a interval to/from a given JavaScript date object.
     * @example
     * var dtNow = new Date();
     * var dtAdd1 = dateAdd("d", 1,  dtNow); // adds 1 day to current date.
     * var dtAdd2 = dateAdd("h", -2, dtNow); // subtracts 2 hours from current date.
     */
    ns.dateAdd = function (interval, number, date) {
        return Helper.dateAdd(interval, number, date);
    };

    /**
     * @ignore
     * @deprecated
     * @function makeAppRequest
     * @memberof API
     * @param {String} appGuid
     * @param {String} targetPageGuid
     * @param {String} [recId="-1"]
     * @param {Boolean} [closePopup]
     * @param {Boolean} [changeParent]
     * @param {Boolean} [newWindow]
     * @param {Object} [params]
     * @return {Boolean|undefined}
     * @deprecated Use ix.loader instead of makeAppRequest.
     */
    ns.makeAppRequest = function (appGuid, targetPageGuid, recId, closePopup, changeParent, newWindow, params) {
        if (arguments.length < 2) {
            return false;
        }
        if (typeof recId === "boolean") {
            if (arguments.length < 4) {
                changeParent = false;
            }
            if (arguments.length < 5) {
                newWindow = false;
            }
            newWindow = changeParent;
            changeParent = closePopup;
            closePopup = recId;
            recId = "-1";
        } else {
            if (arguments.length < 4) {
                closePopup = false;
            }
            if (arguments.length < 5) {
                changeParent = false;
            }
            if (arguments.length < 6) {
                newWindow = false;
            }
        }

        var loaderSettings = {
            ixApp: {
                guid: appGuid,
                pageGuid: targetPageGuid,
                recId: typeof recId !== "undefined" && recId !== null ? recId : "-1",
            },
            close: closePopup,
        };

        if (typeof params === "object") {
            loaderSettings.data = params;
        }

        if (newWindow && !changeParent) {
            ix.loader.tooltip(loaderSettings);
        } else {
            ix.loader.stage(loaderSettings);
        }
    };

    /**
     * @function triggerUserWorkflowEvent
     * @memberof API
     * @param {String} eventGuid The parameter "eventGuid" of the generic event handler.
     * @param {Object} [urlParameters] An object containing name value pairs, which will be part of the Query-String.
     * @param {Object} [ajaxParameters] An object containing synchronous mode.
     * @return {Promise}
     * @description Triggers an User Workflow Event, which is always a POST-Request.
     * @example
     * // Example 1
     * // Trigger event with guid <EVENTGUID>. Define a generic event handler with the class "de.uplanet.lucy.server.workflow.eventhandler.UserWorkflowEventHandler" before.
     * triggerUserWorkflowEvent("<EVENTGUID>", {"param1":"value1","param2":"value2"}, {"async": true});
     *
     * // Example 2
     * $.when(triggerUserWorkflowEvent("<EVENTGUID>", {"param1":"value1", "param2":"value2"}, {"async": true}))
     *     .done(function() {
     *        alert("done");
     *     })
     *     .fail(function() {
     *        alert("failed :(");
     *     });
     */
    ns.triggerUserWorkflowEvent = function (eventGuid, urlParameters, ajaxParameters) {
        var r = $.Deferred();
        if (typeof eventGuid === "string") {
            // prepare url parameters
            urlParameters = urlParameters || {};
            $.extend(urlParameters, {
                qs_wfEventGuid: eventGuid,
                rq_command: "workflow",
            });
            // build the url
            var url = ix.util.getBaseUrl();
            for (var param in urlParameters) {
                if (urlParameters.hasOwnProperty(param)) {
                    url = Helper.setUrlValueByParam(param, urlParameters[param], url);
                }
            }
            // some parameters are fixed
            $.ajax(
                $.extend(true, {}, ajaxParameters, {
                    url: url,
                    method: "POST", // we only use POST due to CSRFToken
                    type: undefined, // no deprecated type, method is used
                    dataType: "xml", // connector gives always xml
                    success: function (data, status, jqXHR) {
                        r.resolve(data, status, jqXHR);
                    },
                    error: function (data, status) {
                        if (status === "error") {
                            var $error = $(data.responseText).filter("error");
                            if ($error.length > 0) {
                                // connector gave us an error
                                r.reject($error.find("message").text());
                            }
                        }
                    },
                })
            );
        } else {
            r.reject("Parameter 'eventGuid' seems to be not of type String.");
        }
        return r.promise();
    };

    return ns;
});
