/**
 * @file Prototype upPopUpWindow
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ErrorHandler, oHtmlRoot, ix, Browser, upWindow */
/* exported delPopupRef */

/**
 * @class
 * @private
 */
function upPopUpWindow() {
    this.upType = "upPopUpWindow";
    this.mimeType = "text/html";
    this.HtmlName = "";
    this.content = "";
    this.width = 250;
    this.height = 250;
    this.innerWidth = 250;
    this.innerHeight = 250;
    this.chromeWidth = 0;
    this.chromeHeight = 0;
    this.widthOrg = 0;
    this.heightOrg = 0;
    this.offsetX = 0;
    this.offsetY = 0;
    this.elePosX = 0;
    this.elePosY = 0;
    this.plusWidth = 0;
    this.plusHeight = 0;
    this.posX = 0;
    this.posY = 0;
    this.URL = "";
    this.eventScreenX = "";
    this.eventScreenY = "";
    this.props = "dependent=yes,menubar=no,toolbar=no,scrollbars=yes,resizable=yes";
    this.fupOfOpener = "";
    this.winOfOpener = "";
    this.oHtml = null;
    this.byUpClose = false;
    this.byReload = false;
    this.nextPopup = false;
    this.samePopup = false;
    this.oControlOfRef = null;
    this.bAsTab = false;
    this.oRootWin = oHtmlRoot.oUp;
    /**
     * @ignore
     * @private
     * @description Creates references to registry objects of the root window inside a popup.
     */
    this.setRefToRootWin = function () {
        try {
            // HINT: this is for very old InterNet Explorer Versions
            // eslint-disable-next-line valid-typeof
            if (opener && typeof opener.oUp != "unknown" && opener.oUp) {
                if (oHtmlRoot && oHtmlRoot.oUp) {
                    this._setRefToRootWin(oHtmlRoot.oUp);
                } else {
                    alert(opener.oUp);
                    this._setRefToRootWin(opener.oUp);
                }
                return;
            }
        } catch (e) {
            ErrorHandler.warn(e, "setRefToRootWin()");
        }
        if (oHtmlRoot && oHtmlRoot.oUp) {
            this._setRefToRootWin(oHtmlRoot.oUp);
        } else if (self.oUp) {
            this._setRefToRootWin(self.oUp);
        }
    };

    /**
     * @ignore
     * @private
     * @param {Object} root
     */
    this._setRefToRootWin = function (root) {
        if (root) {
            this.oRootWin = root;
            this.aInfoPipe = root.aInfoPipe;
            if (root.oRegistry) {
                this.oRegistry = root.oRegistry;
            }
            if (root.oFormatInfo) {
                this.oFormatInfo = root.oFormatInfo;
            }
        }
    };
}
upPopUpWindow.prototype = new upWindow();

/**
 * @ignore
 * @private
 * @param {Object} oActControlCont The container, which includes the actioncontrol. This container is not part of the printout.
 * @description Prints the content of a popup window.
 */
upPopUpWindow.prototype.print = function (oActControlCont) {
    var $ctrl = $(oActControlCont);
    if (this.oHtml && $ctrl.get(0)) {
        $ctrl.hide();
        this.oHtml.print();
    }
};

/**
 * @description Closes the popup window.
 */
upPopUpWindow.prototype.close = function () {
    this.byUpClose = true;
    this.nextPopup = false;
    if (this.oRootWin && this.id) {
        this.oRootWin.closePopUp(this.id);
    } else {
        this.oHtml.close();
    }
};

/**
 * @ignore
 * @private
 * @param {String} htmlName The name of the popup window.
 * @description Sets the name of the popup window.
 */
upPopUpWindow.prototype.setHtmlName = function (htmlName) {
    this.HtmlName = htmlName;
};

/**
 * @deprecated since 7.0 - use upPopUpWindow.resizeAndMove() instead
 * @private
 * @ignore
 * @description Resizes and moves the popup appropriate to the content.
 */
upPopUpWindow.prototype.resizeByContent = function () {
    this.resizeAndMove();
    return;
};

/**
 * @typedef {Object} upPopUpWindow~Settings
 * @property {upPopUpWindow~Settings~PlaceHolder} [placeHolder] Space for immeasurable parts of the browser-window in percent.
 * @property {upPopUpWindow~Settings~Minimum} [minimum] Minimum size of the popup.
 * @property {upPopUpWindow~Settings~Maximum} [maximum] Maximum size of the popup.
 * @property {upPopUpWindow~Settings~Fixed} [fixed] Fixed position and size of the popup, will override all other properties.
 */
/**
 * @typedef {Object} upPopUpWindow~Settings~PlaceHolder
 * @property {Number} [x=0.05] Space for immeasurable parts of the browser-window in percent.
 * @property {Number} [y=0.1] Space for immeasurable parts of the browser-window in percent.
 */
/**
 * @typedef {Object} upPopUpWindow~Settings~Minimum
 * @property {Number} [width=200] Minimum width of the popup in pixels.
 * @property {Number} [height=200] Minimum height of the popup in pixels.
 */
/**
 * @typedef {Object} upPopUpWindow~Settings~Maximum
 * @property {Number} [width=screen.availWidth*(1-placeHolder.x)] Maximum width of the popup in pixels.
 * @property {Number} [height=screen.availHeight*(1-placeHolder.y)] Maximum height of the popup in pixels.
 */
/**
 * @typedef {Object} upPopUpWindow~Settings~Fixed
 * @property {Number} [width] Target width of the popup in pixels.
 * @property {Number} [height] Target height of the popup in pixels.
 * @property {upPopUpWindow~Settings~Fixed~Offset} [offset] Target position of the popup on the screen.
 */
/**
 * @typedef {Object} upPopUpWindow~Settings~Fixed~Offset
 * @property {Number} [x] Target position of the popup from left of the screen.
 * @property {Number} [y] Target position of the popup from top of the screen.
 */
/**
 * @private
 * @ignore
 * @param {upPopUpWindow~Settings} settings
 * @return {upPopUpWindow} popup
 * @description Resizes and moves the popup appropriate to the content.
 */
upPopUpWindow.prototype.resizeAndMove = function (settings) {
    // often used vars
    var popup = this.oHtml;
    var $popup = $(popup);
    var $document = $(popup.document);

    // stores all dimensions, which will be used
    var dim = {
        screen: {
            width: screen.availWidth,
            height: screen.availHeight,
        },
        popup: {
            width: $popup.outerWidth(true),
            height: $popup.outerHeight(true),
        },
        document: {
            width: $document.outerWidth(true),
            height: $document.outerHeight(true),
        },
    };

    // since it is not possible to measure all sizes exactly the popup-window will have,
    // we use 5% of the width and 10% of the height
    // possible "space-waster":
    // - scrollbars (different sizes per OS)
    // - resize-edges of the frame (different sizes per OS)
    // - toolbars (completly different)
    // - searchbar (different sizes per Browser)
    // - statusbar (different sizes per Browser)
    // - titlebar (different sizes per OS)
    // - etc.
    var _placeHolder = {
        x: 0.05,
        y: 0.1,
    };

    // the default settings
    var _settings = {
        placeHolder: _placeHolder,
        minimum: {
            width: 200,
            height: 200,
        },
        maximum: {
            width: dim.screen.width * (1 - _placeHolder.x),
            height: dim.screen.height * (1 - _placeHolder.y),
        },
    };
    $.extend(true, _settings, settings);

    if (
        typeof _settings.fixed !== "undefined" &&
        _settings.fixed.width instanceof Number &&
        _settings.fixed.height instanceof Number
    ) {
        // handle fixed position
        popup.resizeTo(_settings.fixed.width, _settings.fixed.height);
        if (
            typeof _settings.fixed.offset !== "undefined" &&
            _settings.fixed.offset.x instanceof Number &&
            _settings.fixed.offset.y instanceof Number
        ) {
            popup.moveTo(_settings.fixed.offset.x, _settings.fixed.offset.y);
        }
    } else {
        // set default values for target
        var target = {
            x: {
                method: undefined,
                value: undefined,
                offset: popup.screenX || 0,
            },
            y: {
                method: undefined,
                value: undefined,
                offset: popup.screenY || 0,
            },
        };

        if (_settings.maximum.width <= dim.document.width) {
            // maximum width reached
            $.extend(true, target.x, {
                method: "absolute",
                value: dim.screen.width,
                offset: 0,
            });
        } else if (_settings.minimum.width >= dim.document.width) {
            // minimum width reached
            $.extend(true, target.x, {
                method: "absolute",
                value: _settings.minimum.width,
                offset:
                    dim.screen.width < _settings.minimum.width + target.x.offset
                        ? dim.screen.width - _settings.minimum.width
                        : target.x.offset,
            });
        }

        if (_settings.maximum.height <= dim.document.height) {
            // maximum height reached
            $.extend(true, target.y, {
                method: "absolute",
                value: dim.screen.height,
                offset: 0,
            });
        } else if (_settings.minimum.height >= dim.document.height) {
            // minimum height reached
            $.extend(true, target.y, {
                method: "absolute",
                value: _settings.minimum.height,
                offset:
                    dim.screen.height < _settings.minimum.height + target.y.offset
                        ? dim.screen.height - _settings.minimum.height
                        : target.y.offset,
            });
        }

        // decide whether to use resizeTo() or resizeBy()
        if (target.x.method === "absolute" && target.y.method === "absolute") {
            // absolute, if both are absolute
            popup.moveTo(target.x.offset, target.y.offset);
            popup.resizeTo(target.x.value, target.y.value);
        } else {
            // make both relative, if one is relative
            var _temp = {
                x: {
                    method: "relative",
                    value: undefined,
                    offset: popup.screenX || 0,
                },
                y: {
                    method: "relative",
                    value: undefined,
                    offset: popup.screenY || 0,
                },
            };

            if (_settings.maximum.width <= dim.document.width) {
                // popup width won't fit into screen
                _temp.x.value = _settings.maximum.width - dim.popup.width;
                _temp.x.offset = _temp.x.offset * -1;
            } else if (_settings.maximum.width < _temp.x.offset + dim.document.width) {
                // popup width will fit into screen, but only by moving the popup to the left
                _temp.x.value = dim.document.width - dim.popup.width;
                _temp.x.offset =
                    _temp.x.offset * -1 + (dim.screen.width * 1 + _settings.placeHolder.x - dim.document.width);
            } else {
                // popup width will fit into screen, without moving
                _temp.x.value = dim.document.width - dim.popup.width;
                _temp.x.offset = 0;
            }

            if (_settings.maximum.height <= dim.document.height) {
                // popup height won't fit into screen
                _temp.y.value = _settings.maximum.height - dim.popup.height;
                _temp.y.offset = _temp.y.offset * -1;
            } else if (_settings.maximum.height < _temp.y.offset + dim.document.height) {
                // popup height will fit into screen, but only by moving the popup to up
                _temp.y.value = dim.document.height - dim.popup.height;
                _temp.y.offset =
                    _temp.y.offset * -1 + (dim.screen.height * 1 + _settings.placeHolder.y - dim.document.height);
            } else {
                // popup height will fit into screen, without moving
                _temp.y.value = dim.document.height - dim.popup.height;
                _temp.y.offset = 0;
            }

            $.extend(true, target, _temp);
            popup.resizeBy(target.x.value, target.y.value);
            popup.moveBy(target.x.offset, target.y.offset);
        }
    }

    popup.scrollTo(0, 0);
    return this;
};

/**
 * @ignore
 * @private
 * @param {String} text The content, which is written by a textstream.
 * @description Sets the content, which is written by a textstream.
 */
upPopUpWindow.prototype.setContent = function (text) {
    this.content = text;
};

/**
 * @ignore
 * @private
 * @param {String} mimeType The mimetype, which is used by the textstream.
 * @description Sets the mimetype property, which is used by the textstream.
 */
upPopUpWindow.prototype.setMimeType = function (mimeType) {
    this.mimeType = mimeType;
};

/**
 * @ignore
 * @private
 * @return {Boolean}
 * @description Returns, whether a html window object exists or not.
 */
upPopUpWindow.prototype.isHtmlObject = function () {
    if (!this.oHtml || this.oHtml.closed) {
        return false;
    }
    return true;
};

/**
 * @ignore
 * @private
 * @return {Boolean}
 * @description Writes the content of the popup. Either this.content is written by a textstream or this.url is used.
 */
upPopUpWindow.prototype.writeHtml = function () {
    var charset = "UTF-8";
    if (this.isHtmlObject()) {
        if (this.URL === "" && this.content !== "") {
            if (
                self.oUp &&
                self.oUp.oFormatInfo &&
                self.oUp.oFormatInfo.charset &&
                self.oUp.oFormatInfo.charset !== ""
            ) {
                charset = self.oUp.oFormatInfo.charset;
            }
            //charset explicit setzen, weil IE sonst immer unicode verwendet
            if (this.oHtml.document.charset) {
                this.oHtml.document.charset = charset;
            }
            this.oHtml.document.open(this.mimeType);
            if (this.oHtml.document.charset) {
                this.oHtml.document.charset = charset;
            }
            this.oHtml.document.write(this.content);
            this.oHtml.document.close();
            if (this.oHtml.document.charset) {
                this.oHtml.document.charset = charset;
            }
        } else if (this.URL !== "") {
            this.oHtml.document.location.href = this.URL;
        }
        this.oHtml.oUp = this;
    } else {
        return false;
    }
    return true;
};

/**
 * @ignore
 * @private
 * @description Closes the html window and sets oHtml=null.
 */
upPopUpWindow.prototype.hide = function () {
    if (this.oHtml.oUp) {
        this.oHtml.oUp = null;
    }
    this.oHtml.setTimeout("self.close();", 0);
    this.oRootWin = null;
    this.aInfoPipe = null;
    this.oRegistry = null;
    this.oFormatInfo = null;
    this.oMessage = null;
    this.oHtml = null;
};

/**
 * @ignore
 * @private
 * @return {Boolean}
 * @description Creates a html window, registers the window and positions it.
 */
upPopUpWindow.prototype.show = function () {
    // holt Referenz auf bestehendes Fenster
    if (this.samePopup) {
        this.oHtml = window.open("", this.HtmlName);
        if (typeof this.oHtml === "undefined" || this.oHtml === null) {
            alert(self.oUp.oMessage.BROWSER_POPUPBLOCKER);
            return false;
        }
    } else {
        // setzt Properties fuer Position
        this.setPosition();
        // oeffnet Html - Window; falls this.URL gesetzt ist, laden dieser Url
        // Die Groessen sind die Groessen der contentArea, nicht des auesseren Fensters
        var url = this.URL;
        var name = this.HtmlName;
        if (this.bAsTab) {
            this.oHtml = window.open(url, name);
        } else {
            var features = this.props + ",";
            features += "width=" + this.innerWidth;
            features += ",height=" + this.innerHeight;
            features += ",left=" + this.posX;
            features += ",top=" + this.posY;
            this.oHtml = window.open(url, name, features);
        }
        if (typeof this.oHtml === "undefined" || this.oHtml === null) {
            alert(ix.text.i18n.get("BROWSER_POPUPBLOCKER"));
            return false;
        }
    }
    this.oHtml.focus();
    // bildet Referenz des HtmlWindow Objektes auf upPopup Objekt
    this.oHtml.oUp = this;
    // falls this.content gesetzt wurde
    if (this.content !== "") {
        this.writeHtml();
    }
    return true;
};

/**
 * @ignore
 * @private
 * @description Refreshs the html window: rewrites content and repositions it.
 */
upPopUpWindow.prototype.refresh = function () {
    this.writeHtml();
    this.oHtml.oUp = this;
    this.oHtml.focus();
};

/**
 * @ignore
 * @private
 * @param {String} url An optional url of the popup. Setting this property is a alternative to setting this.content.
 * @description Set the URL - property.
 */
upPopUpWindow.prototype.setURL = function (url) {
    this.URL = url;
};

/**
 * @ignore
 * @private
 * @param {String} props The chrome properties. Default is "dependent=yes,menubar=no,toolbar=no,scrollbars=yes,resizable=yes".
 * @description Sets the chrome properties.
 */
upPopUpWindow.prototype.setProps = function (props) {
    this.props = props;
};

/**
 * @ignore
 * @private
 * @param {Number} innerWidth The inner width of the popup.
 * @param {Number} innerHeight The inner height of the popup.
 * @description Sets the size properties.
 */
upPopUpWindow.prototype.setSize = function (innerWidth, innerHeight) {
    this.innerWidth = typeof innerWidth === "number" ? innerWidth : parseInt(innerWidth, 10);
    this.innerHeight = typeof innerHeight === "number" ? innerHeight : parseInt(innerHeight, 10);
    this.width = this.innerWidth + this.chromeWidth;
    this.height = this.innerHeight + this.chromeHeight;
};

/**
 * @ignore
 * @private
 * @param {Number} offsetX The horizontal offset of the popup.
 * @param {Number} offsetY The vertical offset of the popup.
 * @description Sets the offset properties.
 */
upPopUpWindow.prototype.setOffset = function (offsetX, offsetY) {
    this.offsetX = parseInt(offsetX, 10);
    this.offsetY = parseInt(offsetY, 10);
};

/**
 * @ignore
 * @private
 * @param {Object} control Control, which is used for positioning of the popup.
 * @description Sets the control, which is used for positioning of the popup.
 */
upPopUpWindow.prototype.setControlOfRef = function (control) {
    this.oControlOfRef = control;
    var addX = 0;
    if (Browser.mozilla) {
        addX = parseInt(Browser.getWidth(this.oControlOfRef), 10) / 2;
    } else {
        addX = parseInt(Browser.getWidth(this.oControlOfRef), 10);
    }
    this.setElementPosition(Browser.getPosition2Screen(this.oControlOfRef), addX);
};

/**
 * @ignore
 * @private
 * @param {Object} oPosXY Object containing posX and posY.
 * @param {Number} addX Added to posX, e.g. the width of a button.
 * @description Calculates the position of the popup from the position of the control, which is used for positioning of the popup and an additional x value, and sets this as elePosX and elePosY.
 */
upPopUpWindow.prototype.setElementPosition = function (oPosXY, addX) {
    if (oPosXY) {
        this.elePosX = parseInt(oPosXY.posX, 10);
        if (addX && !isNaN(parseInt(addX, 10))) {
            this.elePosX = parseInt(oPosXY.posX, 10) + parseInt(addX, 10);
        }
        this.elePosY = parseInt(oPosXY.posY, 10);
    }
};

/**
 * @ignore
 * @private
 * @description Calculates the posX and posY properties of the popup from its elePosX/elePosY and the offset.
 */
upPopUpWindow.prototype.setPosition = function () {
    this.posX = parseInt(this.elePosX + this.offsetX, 10);
    this.posY = parseInt(this.elePosY + this.offsetY, 10);
};

/**
 * @ignore
 * @private
 * @description Calculates the posX and posY properties of the popup from its actual position as part of the document.
 */
upPopUpWindow.prototype.setActPosition = function () {
    if (
        this.oHtml &&
        this.oHtml.screenTop &&
        !isNaN(this.oHtml.screenTop) &&
        Browser.getOffsetHeight(this.oHtml) &&
        Browser.getOffsetHeight(this.oHtml) !== ""
    ) {
        // IE
        this.posX = this.oHtml.screenLeft;
        this.posY = this.oHtml.screenTop;
    } else if (this.oHtml && this.oHtml.screenY && !isNaN(this.oHtml.screenY)) {
        // Mozilla
        this.posX = this.oHtml.screenX;
        this.posY = this.oHtml.screenY;
    }
};

/**
 * @ignore
 * @private
 * @param {Object} popup
 * @description Removes references between Intrexx objects and dom objects during the unload of a popup.
 * This method also unregisters the popup and functional part.
 */
function delPopupRef(popup) {
    if (popup.oHtml && popup.oHtml.oUp) {
        popup.oHtml.oUp = null;
    }
    popup.oHtml = null;
    // nur, wenn nicht ein neues Dokument ins Popup Window geladen wird.
    if (!popup.nextPopup && !popup.samePopup) {
        popup.oRootWin.unregisterPopup(self.name);
        popup.oRootWin = null;
        popup.aInfoPipe = null;
        popup.oRegistry = null;
        popup.oFormatInfo = null;
        popup.oMessage = null;
    }
}
