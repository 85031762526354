/**
 * @file Browser
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ix, Browser, oHtmlRoot, Helper, getElement */

/**
 * @param {Object} control Reference to an html element or an upControl object, whose value is to be set.
 * @param {String|Number|Date|Boolean|Object} value Control value to set.
 * @param {Browser.BrowserGetValueAttr} [attr] Additional information for target control, depends on target control.
 * @param {Boolean} [fireEvent=true] Trigger the onchange event.
 * @return {Boolean}
 * @description Set the value for the given control.
 * @example
 * // general
 * Browser.setValue(getElement("[ELEMENT-GUID]"), "Hallo Welt!");
 * @example
 * // Distribution-/Multiselect-Control
 * // strModus: append | replace
 * Browser.setValue(getElement("[ELEMENT-GUID]"), ["GUID1", "GUID2"], {bAsync: false, strModus: "append"});
 * @example
 * // upIntegerControl | upCurrencyControl | upFloatControl
 * Browser.setValue(getElement("[ELEMENT-GUID]"), 1.5);
 * @example
 * // upFilterCtrlRangeof | upFilterCtrlCalendar
 * // set from current date - 5 days until now
 * Browser.setValue(getElement("[ELEMENT-GUID]"), {from: Helper.dateAdd("d", 5, new Date()), to: new Date()});
 * Browser.setValue(getElement("[ELEMENT-GUID]"), {from: ",,-5,,,", to: ",,,,,"});
 * @example
 * // upFilterCtrlOperator
 * Browser.setValue(getElement("[ELEMENT-GUID]"), {datafield: "datafieldGUID", operator: "contains", search: "test"});
 */
Browser.setValue = function (control, value, attr, fireEvent) {
    var result = false;
    var element = false;
    var yellowFade = window.oHtmlRoot && window.oHtmlRoot.oUp && window.oHtmlRoot.oUp.oWAISettings.bYellowFade;

    if (arguments.length < 2) {
        value = "";
    }

    // zu beschreibende Kontrolle und Typ der Kontrolle
    if (control && typeof control === "string" && control.length == 40) {
        control = getElement(control);
    }

    if (control && typeof control.oUp === "object") {
        // Html Kontrolle mit Up-Referenz
        control = control.oUp;
    }

    if (!control || !control.oHtml) {
        element = control;
        result = this.setValue_HtmlCtrl(control, value, attr, fireEvent);
    } else if ($.isFunction(control.setValue)) {
        result = this._setValueUpObject(control, value, attr, fireEvent);
        yellowFade = false;
    } else if (control.bFilter) {
        element = control.oHtml;
        result = this.setValue_UpFilterCtrl(control, value, attr, fireEvent);
    } else if (typeof control.bEdit === "boolean" && !control.bEdit) {
        element = control.oHtml;
        result = this.setValue_UpViewCtrl(control, value, attr, fireEvent);
    } else {
        element = control.oHtml;
        result = this.setValue_UpEditCtrl(control, value, attr, fireEvent);
    }
    if (element && result && yellowFade) {
        $(element).yellowFade();
    }

    if (!$.isDeferred(result)) {
        if (result || result === undefined) {
            result = $.Deferred().resolve();
        } else {
            result = $.Deferred().reject();
        }
    }

    return result;
};

/**
 * @ignore
 * @param {Object} control
 * @param {String|Number|Date|Boolean|Object} value
 * @param {Browser.BrowserGetValueAttr} [attr]
 * @param {Boolean} [fireEvent=true]
 * @description Writes value of a ViewControl.<br>
 * Sub-method, called by Browser#setValue
 */
Browser.setValue_UpViewCtrl = function (control, value, attr, fireEvent) {
    var result = this.setValue_HtmlCtrl(control.oHtml, value, attr, fireEvent);
    if (control.displayValue && result) {
        control.displayValue = this.getValue(control.oHtml);
    }
};

/**
 * @ignore
 * @param {Object} control
 * @param {String|Number|Date|Boolean|Object} value
 * @param {Browser.BrowserGetValueAttr} [attr]
 * @param {Boolean} [fireEvent=true]
 * @return {Boolean}
 * @description Writes value of a EditControl.<br>
 * Sub-method, called by Browser#setValue
 */
Browser.setValue_UpEditCtrl = function (control, value, attr, fireEvent) {
    var result = false;
    var element = control.oHtml;

    if (control.upType == "upTreeControl") {
        // --> upGroovySelect
        result = Browser.setValue(control.oGroovyTree, value, attr, fireEvent);
        if (!fireEvent) {
            // prepareSubmit setzt ID des selektierten Datensatzes im versteckten integer-Eingabefeld und erzeugt die Treesettings
            control.prepareSubmit();
            control.manageTreeActionCtrls();
            control.glc.triggerEventListener("select");
        }
        fireEvent = false;
    } else if (control.upType == "upGroovySelect") {
        var option;
        if (attr == "recid") {
            option = control.getOptionByComperator(value, {
                match: function (option, val) {
                    return val == option.primaryKey;
                },
            });
        } else {
            option = control.getOptionByKey(value);
        }

        if (option) {
            control.changeSelectedOption(option, fireEvent, null, true);
            result = true;
        }
        fireEvent = false;
    } else if (control.upType == "upDistributionCtrl") {
        // alte Verteilerkontrolle
        //control, value, attr, fireEvent
        if (typeof control.oCurrentView !== "undefined" && control.oCurrentView !== null) {
            if (control.oCurrentView.upType === "upDCViewMain") {
                if (attr == "search") {
                    if (control.oCurrentView.oHtmlInp) {
                        control.oCurrentView.oHtmlInp.value = value;
                    }
                    control.oCurrentView.filterNotSelected(value);
                }
            }
        }
    } else if (control.upType == "upDistribution") {
        // neue Mehrfachauswahl
        var items = [];

        if (typeof value == "object" && value.length) {
            items = value;
        } else if (typeof value === "object" && value !== null && value.bStuffed) {
            items = Helper.undoCharStuffing(value);
        } else if (typeof value == "string") {
            items = Helper.undoCharStuffing(value);
        }

        var async = typeof attr === "object" && attr !== null && typeof attr.bAsync == "boolean" ? attr.bAsync : true;
        var modus =
            typeof attr === "object" && attr !== null && typeof attr.strModus == "string" ? attr.strModus : null;

        if (items && items.length > 0) {
            if (control.initState() == "pending") {
                control.onInitizialized(
                    function (settings) {
                        Browser.setValue(settings.control, settings.value, settings.oAttr, settings.bFireEvent);
                    },
                    {
                        control: control,
                        value: value,
                        oAttr: attr,
                        bFireEvent: fireEvent,
                    }
                );

                // write value for form submit directly
                var saveSettings = control.createSettings("selected", items);
                control.writeSettings("selected", saveSettings);

                return false;
            } else if (control.initState() == "resolved") {
                control.selectItemsByGuid(items, modus, async);
            }
        }
    } else if (typeof element.value !== "undefined") {
        element.value = value;
        result = true;
    } else {
        result = this.setValue_UpViewCtrl(control, value, attr, fireEvent);
    }

    // trigger onchange event
    if (result && (fireEvent || typeof fireEvent == "undefined")) {
        $(element).trigger("change");
    }
    if (control.validateInput === "function") {
        control.validateInput();
    }
    return result;
};

/**
 * @ignore
 * @description Writes value of a HtmlControl.
 * Sub-method, called by Browser#setValue
 */
Browser.setValue_HtmlCtrl = function (element, value, attr, fireEvent) {
    if (arguments.length < 4 || typeof fireEvent != "boolean") {
        fireEvent = true;
    }

    var result = false;
    var tagName = element && element.tagName ? element.tagName.toUpperCase() : null;

    // handle ix-web-components
    if (ix.util.isIxCustomElement(element)) {
        // check if control has a `setValue` method
        if (typeof element.setValue === "function") {
            return element.setValue(value);
        }

        // try to set value via attribute
        if (element.getAttribute("value")) {
            element.value = value || "";
        }
    }

    // Auswahlliste
    if (tagName == "SELECT" && element.options) {
        var values = Helper.undoCharStuffing(value);

        for (var j = 0; j < element.options.length; j++) {
            element.options[j].selected = false;
        }

        if (!element.multiple && values.length > 1) {
            values = values.splice(values.length - 1);
        }

        values.forEach(function (val) {
            for (var j = 0; j < element.options.length; j++) {
                if (element.options[j].value === val || element.options[j].text === val) {
                    element.options[j].selected = true;
                }
            }
        });
    } else if (
        tagName == "TEXTAREA" ||
        (tagName == "INPUT" &&
            (element.type == "text" ||
                element.type == "hidden" ||
                element.type == "number" ||
                element.type == "email" ||
                element.type == "url" ||
                element.type == "tel" ||
                element.type == "search"))
    ) {
        // Input-Kontrolle vom Typ Text | Textarea | Hidden
        element.value = value;
        result = true;
    } else if (tagName == "INPUT" && element.type == "checkbox") {
        // Checkbox
        fireEvent = fireEvent || typeof fireEvent == "undefined";
        if ((typeof value == "boolean" && value) || value === "1" || value === "true" || value === 1) {
            element.checked = fireEvent ? false : true;
        } else {
            element.checked = fireEvent ? true : false;
        }
        if (fireEvent) {
            $(element).trigger("click");
        }
        result = true;
        fireEvent = false;
    } else {
        // Ansichtsfeld (Span, Div, TD, ...)
        if (tagName == "TD" && value === "") {
            value = "&nbsp;";
        }

        // value browserspezifisch schreiben
        if (typeof element.innerHTML == "string") {
            element.innerHTML = value;
            result = true;
        } else if (element.firstChild && element.firstChild.nodeValue) {
            element.firstChild.nodeValue = value;
            result = true;
        } else {
            result = false;
        }
    }

    if (result && fireEvent) {
        $(element).trigger("change");
    }
    return result;
};

/**
 * @ignore
 * @description Writes value of a upFilterCtrl.
 * Sub-method, called by Browser#setValue
 */
Browser.setValue_UpFilterCtrl = function (control, value, attr, fireEvent) {
    if (typeof fireEvent != "boolean") {
        fireEvent = true;
    }

    var result = false;
    switch (control.upType) {
        case "upFilterCtrlCalendar":
            if (typeof value == "string" && value.split(",").length == 6) {
                var dt = new Date();
                value = Browser.addOffset(value, dt);
            }
            if (attr == "from") {
                control.changeDateFrom(value);
                result = true;
            } else if (attr == "to") {
                control.changeDateTo(value);
                result = true;
            } else if (typeof value == "object" && value.from && value.to) {
                this.setValue(control, value.from, "from", false);
                this.setValue(control, value.to, "to", false);
                result = true;
            }
            if (result && fireEvent) {
                control.onChange();
            }
            break;
        case "upFilterCtrlOperator":
            if (typeof value == "object" && (value.datafield || value.operator || value.search)) {
                if (value.datafield && Array.isArray(value.datafield)) {
                    control.selectDatafields(value.datafield);
                } else if (value.datafield && typeof value.datafield === "string") {
                    control.selectDatafields([value.datafield]);
                }
                if (value.operator) {
                    control.selectOperator(value.operator);
                }
                if (value.search) {
                    control.setSearchValue(value.search);
                }
            } else {
                if (attr == "datafield") {
                    if (value && Array.isArray(value)) {
                        control.selectDatafields(value);
                    } else if (value && typeof value === "string") {
                        control.selectDatafields([value]);
                    }
                } else if (attr == "operator") {
                    if (value) {
                        control.selectOperator(value);
                    }
                } else if (attr == "search") {
                    if (value) {
                        control.setSearchValue(value);
                    }
                }
            }
            result = true;
            if (result && fireEvent) {
                control.triggerOnchange();
            }
            break;
    }

    return result;
};

/**
 * @ignore
 * @param {String} id
 * @param {String} value
 * @param {Document} [doc=document]
 * @return {Boolean}
 * @description Writes value of a control in html, by html id in a defined html document.
 */
Browser.setValueByElementId = function (id, value, doc) {
    if (!id) {
        return false;
    }
    if (!value) {
        value = "";
    }
    if (!doc) {
        doc = document;
    }
    var element = Browser.getRef(id, doc);
    if (!element) {
        return false;
    } else if (element.oUp) {
        return Browser.setValue(element.oUp, value);
    } else if (element) {
        if (element.value) {
            element.value = value;
        } else {
            if (element.tagName == "TD" && value === "") {
                value = "&nbsp;";
            }
            if (typeof element.innerHTML == "string") {
                element.innerHTML = value;
            } else if (element.firstChild && element.firstChild.nodeValue) {
                element.firstChild.nodeValue = value;
            }
        }
    }
    if (oHtmlRoot.oUp.oWAISettings.bYellowFade) {
        $(element).yellowFade();
    }
    return true;
};

/**
 * @ignore
 * @param {Event} [e]
 * @return {Number|String}
 * @description Retrieve key code for key event.
 */
Browser.getEventKeyCode = function (e) {
    if (!e) {
        e = self.oUp.oEvent;
    }
    var codeInput = "";
    if (e && e.keyCode && e.keyCode !== "" && e.keyCode !== 0) {
        codeInput = e.keyCode;
    } else {
        if (e) {
            codeInput = e.which;
        }
    }
    return codeInput;
};

/**
 * @enum {String}
 * @description Enum for Browser.getValue() Parameter "attr".
 */
Browser.BrowserGetValueAttr = {
    /**
     * @description Use to get the Primary Key.
     */
    KEY: "key",
    /**
     * @description Use to get the shown text.
     */
    TEXT: "text",
    /**
     * @description Use to get the value.
     */
    VALUE: "value",
    STORE: "store",
    SELECTION: "selection",
    OFFSET: "offset",
    FROM: "from",
    TO: "to",
    DATAFIELD: "datafield",
    OPERATOR: "operator",
    SEARCH: "search",
};

/**
 * @param {Object} control Reference to an html element or an upControl object.
 * @param {Browser.BrowserGetValueAttr} [attr] Additional information for target control, depends on target control.
 * @return {String|Boolean}
 * @description Get the value from the given control.
 * @example
 * Browser.getValue(getElement("[ELEMENT-GUID]"));
 */
Browser.getValue = function (control, attr) {
    var result = false;

    if (control === null) {
        return false;
    }

    if (control && typeof control === "string" && control.length == 40) {
        control = getElement(control);
    }
    if (control && typeof control.oUp === "object") {
        // Html Kontrolle mit Up-Referenz
        control = control.oUp;
    }

    if (!control || !control.oHtml) {
        // Html-Kontrolle
        result = Browser._getValueHtmlCtrl(control, attr);
    } else if (control.upType === "upRangeFilterControl") {
        if (attr === "from") {
            result = control.valueFrom;
        } else if (attr === "to") {
            result = control.valueTo;
        } else if (attr === "text") {
            var strFrom = control.valueFrom;
            var strTo = control.valueTo;

            if (typeof strFrom == "string" && strFrom.length > 0 && typeof strTo == "string" && strTo.length > 0) {
                result = strFrom + " - " + strTo;
            } else if (typeof strFrom == "string" && strFrom.length > 0) {
                result = strFrom + " ...";
            } else if (typeof strTo == "string" && strTo.length > 0) {
                result = "... " + strTo;
            }
        }
    } else if (attr !== "html" && typeof control.displayValue !== "undefined") {
        // Up-Ansichtskontrolle MIT displayValue Attribut am UP-Objekt
        result = Browser._getValueUpViewCtrl(control, attr);
    } else if ($.isFunction(control.getValue)) {
        result = this._getValueUpObject(control, attr);
    } else if (control.bFilter) {
        // upFilterCtrl
        result = Browser._getValueUpFilterCtrl(control, attr);
    } else if (typeof control.bEdit === "boolean" && !control.bEdit) {
        // Up-Ansichtskontrolle OHNE displayValue Attribut am UP-Objekt
        result = Browser._getValueUpViewCtrl(control, attr);
    } else {
        // Up-Eingabekontrolle
        result = Browser._getValueUpEditCtrl(control, attr);
    }

    return result;
};

/**
 * @ignore
 * @ignore
 * @description Reads value of a upViewControl.
 * Sub-method, called by Browser#getValue
 */
Browser._getValueUpViewCtrl = function (control, attr) {
    var result = false;

    // Up-Ansichtskontrolle mit displayValue
    if (typeof control.displayValue == "string") {
        result = control.displayValue;
    } else {
        result = this._getValueHtmlCtrl(control.oHtml, attr);
    }
    return result;
};

/**
 * @ignore
 * @param {Object} control reference to UP-Control
 * @param {String} [attr] Possible values are: <code>"key" || "text"</code>.
 * @returns {Object|Boolean} [recordId|text]
 * @description Reads value of a upEditControl.
 * Sub-method, called by Browser#getValue.
 */
Browser._getValueUpEditCtrl = function (control, attr) {
    var result = false;
    // Label vorhanden
    if (typeof control.hasLabel != "undefined" && control.hasLabel()) {
        return false;
    }

    if (control.upType == "upTreeControl") {
        if (attr === "text") {
            // --> upGroovySelect
            result = Browser.getValue(control.oGroovyTree, attr);
        } else {
            var item = control.getSelectedItem();
            if (attr === "id") {
                return item.strKey;
            } else if (attr === "treeKey") {
                return item.strTreeKey;
            } else {
                return item.primaryKey ? item.primaryKey : item.strKey;
            }
        }
    } else if (control.upType == "upDistribution") {
        if (attr == "text") {
            var aSelected = control.getSelectedItems();
            var aSelectedText = [];
            for (var i = 0, l = aSelected.length; i < l; i++) {
                aSelectedText.push(aSelected[i].strText);
            }
            if (aSelectedText.length > 1) {
                result = Helper.doCharStuffing(aSelectedText);
                result.bStuffed = true;
            } else if (aSelectedText.length == 1) {
                result = aSelectedText[0];
            }
        } else if (control.initState() != "resolved") {
            if ($.isArray(control.aInitSelected)) {
                if (control.aInitSelected.length == 1) {
                    result = control.aInitSelected[0].key;
                } else if (control.aInitSelected.length > 1) {
                    var keys = [];
                    for (var j = 0, len = control.aInitSelected.length; j < len; j++) {
                        keys.push(control.aInitSelected[j].key);
                    }
                    result = Helper.doCharStuffing(keys);
                    result.bStuffed = true;
                }
            }
        } else {
            var info = control.createSettings("selected");
            if (info && info.selected && info.selected.length > 0) {
                if (info.selected.length > 1) {
                    result = Helper.doCharStuffing(info.selected);
                    result.bStuffed = true;
                } else {
                    result = info.selected[0];
                }
            }
        }
    } else if (control.upType == "upGroovySelect") {
        var option = control.getSelectedOption();
        if (option) {
            if (attr == "text") {
                result = option.getText();
            } else {
                result = option.getValue();
            }
        }
    } else if (typeof control.oHtml.value !== "undefined") {
        result = control.oHtml.value;
    } else {
        result = this._getValueUpViewCtrl(control, attr);
    }
    return result;
};

/**
 * @ignore
 */
Browser._getValueUpFilterCtrl = function (control, attr) {
    var result = false;

    switch (control.upType) {
        case "upFilterCtrlCalendar":
            if (attr == "from") {
                result = control.getDateFrom();
            } else if (attr == "to") {
                result = control.getDateTo();
            } else if (attr == "text") {
                var dtFrom = control.getDateFrom();
                var dtTo = control.getDateTo();
                if (typeof dtFrom == "object" && typeof dtTo == "object") {
                    // von == bis
                    if (
                        (dtFrom.getMonth() == dtTo.getMonth() ||
                            dtFrom.getMonth() == Helper.dateAdd("d", -1, dtTo).getMonth()) &&
                        dtFrom.getDate() === Helper.dateAdd("d", -1, dtTo).getDate()
                    ) {
                        result = new ix.text.SimpleDateFormat(self.oUp.oFormatInfo.dateFormat).format(dtFrom);
                    } else {
                        result =
                            new ix.text.SimpleDateFormat(self.oUp.oFormatInfo.shortDateFormat).format(dtFrom) +
                            " - " +
                            new ix.text.SimpleDateFormat(self.oUp.oFormatInfo.shortDateFormat).format(dtTo);
                    }
                }
            } else {
                result = {
                    from: control.getDateFrom(),
                    to: control.getDateTo(),
                };
            }
            break;
        case "upFilterCtrlOperator":
            if (attr == "datafield") {
                result = control.getSelectedDatafields();
            } else if (attr == "operator") {
                result = control.getSelectedOperator();
            } else if (attr == "search") {
                result = control.getSearchValue();
            }
            // } else if (attr == "text" && control.isDulyCompleted()) {
            //     operator = control.getSelectedOperator("strTitle");
            //     value = this.getValue(control.getElementSearch());
            //     result = value;
            // }
            break;
    }
    return result;
};

/**
 * @ignore
 * @description Reads value of a HtmlControl
 * Sub-method, called by Browser#getValue
 */
Browser._getValueHtmlCtrl = function (control, attr) {
    var element = control;

    if (control === undefined || control === null || control.tagName === undefined) {
        return false;
    }

    var tagName = element.tagName.toUpperCase();
    var result = false;

    // handle ix-web-components
    if (ix.util.isIxCustomElement(element)) {
        // check if control has a `getValue` method
        if (typeof control.getValue === "function") {
            result = control.getValue(attr);
            return result;
        }

        // try to get value via attribute
        if (control.getAttribute("value")) {
            result = control.getAttribute("value");
            return result;
        }
    }

    // Auswahlliste
    if (tagName == "SELECT" && element.options) {
        var list = [];
        for (var j = 0; j < element.options.length; j++) {
            if (element.options[j].selected) {
                list.push(
                    typeof element.options[j].value == "string" && element.options[j].value.length > 0
                        ? element.options[j].value
                        : element.options[j].text
                );
                if (!element.multiple) {
                    break;
                }
            }
        }

        if (list.length == 1) {
            result = list[0];
        } else if (list.length > 1) {
            result = Helper.doCharStuffing(list);
            result.bStuffed = true;
        }
    } else if (
        tagName == "TEXTAREA" ||
        (tagName == "INPUT" &&
            (element.type == "text" ||
                element.type == "hidden" ||
                element.type == "number" ||
                element.type == "email" ||
                element.type == "url" ||
                element.type == "tel" ||
                element.type == "search"))
    ) {
        // Input-Kontrolle vom Typ Text | Textarea | Hidden
        result = element.value;
    } else if (
        tagName == "DIV" &&
        !!$(element).data("control") &&
        $(element).data("control").indexOf("filevcontrol") > -1
    ) {
        // Fileansichten
        $(element)
            .find("[data-filename]")
            .each(function () {
                if (!result) {
                    result = "";
                }
                if (result !== "") {
                    result += "||";
                }
                result += $(this).data("filename");
            });
    } else if (tagName == "INPUT" && element.type == "checkbox") {
        // Checkbox
        result = element.checked;
    } else if (control.getAttribute("data-control-type") === "distributionvcontrol") {
        var listContent = [];
        var list = control.querySelectorAll("div.ListCtrl ul");
        list[0].children.forEach(function (listEntry) {
            var val = listEntry.getAttribute("data-item-key");
            listContent.push(val);
        });
        result = ix.util.doCharStuffing(listContent);
    } else {
        //textContent fuer Firefox, innerText fuer den Rest der Welt...
        if (attr != "html" && (element.innerText || element.textContent)) {
            result = typeof element.innerText !== "undefined" ? element.innerText : element.textContent;
        } else if (attr != "html" && element.firstChild && element.firstChild.nodeValue) {
            result = element.firstChild.nodeValue;
        } else if (typeof element.innerHTML == "string") {
            result = Helper.htmlDecode(element.innerHTML);
        }
    }
    return result;
};
