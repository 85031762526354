/**
 * @file Prototype upWindow.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ix, Loader, wndRoot, Notifier: true,
        Browser,
        oHtmlRoot, ErrorHandler, Helper,
        UpWindow, UpNotifier
*/
/*
    exported WindowInitNotifier, Notifier, removeEvents, delFrameRef
*/

/**
 * @class
 * upWindow object
 */
function upWindow() {
    this.load = function () {
        this.registerValidationReporting();
        this.getMouseCoordinates();
        this.registerShortcuts(oHtmlRoot.oUp ? oHtmlRoot.oUp.aShortcuts : []);
        ix.hijax.delegate();
        // We need to "click" Elements with role=button if spacebar is pressed
        // this is necessary for keyboard accessibility
        $("body").on("keydown", "button, [role=button]", function (ev) {
            if (ev.key === " " && this.closest(".menu") === null && this.closest(".Action_Settings") === null) {
                this.click();
            }
            return true;
        });
        this.triggerEvent("onload");
        /* @deprecated */
        eval(this.strOnLoad);
        // @todo ix9
        Loader.loadJsPostOnload();
    };

    this.unload = function () {
        try {
            this.triggerEvent("onunload");
            /* @deprecated */
            eval(wndRoot.strOnUnload);
            this.cleanUp();
            self.oUp.delObjects();
        } catch (e) {
            console.trace(e);
        }
    };

    /**
     * @private
     */
    this.upType = "upWindow";
    /**
     * @private
     */
    this.id = "";
    /**
     * @private
     */
    this.strOnLoad = "";
    /**
     * @private
     */
    this.strOnUnLoad = "";
    /**
     * @private
     */
    this.QueryString = "";
    /**
     * @private
     */
    this.oEvent = null;
    /**
     * @private
     */
    this.oRegistry = null;
    /**
     * @private
     */
    this.oFormatInfo = null;
    /**
     * @private
     */
    this.aObjects = new Array();
    /**
     * @private
     */
    this.focusOnLoadId = "";
}
upWindow.prototype = new UpWindow();
upWindow.prototype.delObjects = WindowDelObjects;
upWindow.prototype.setFocus = WindowSetFocus;
upWindow.prototype.setAccessibilityMessages = WindowShowAccessibilityMessages;
upWindow.prototype.setMenu = WindowSetMenu;
upWindow.prototype.reload = WindowReload;
upWindow.prototype._reload = Window_Reload;
upWindow.prototype.setRefToRootWin = WindowSetRefToRootWin;
upWindow.prototype._setRefToRootWin = Window_SetRefToRootWin;

ix.util.applyMixins(upWindow, [ix.EventHandling]);

/**
 * @private
 * Notifier init
 */
function WindowInitNotifier() {
    Notifier = UpNotifier;
}
/**
 * This method reloads the actual popup or window.
 * @private
 */
function WindowReload() {
    window.location.reload();
    return true;
}

/**
 * Creates references to registry objects of the root window used by hidden frames.
 * @private
 */
function WindowSetRefToRootWin() {
    try {
        if (oHtmlRoot && oHtmlRoot.oUp) {
            this._setRefToRootWin(oHtmlRoot.oUp);
        } else {
            // eslint-disable-next-line valid-typeof
            if (parent && typeof parent.oUp != "unknown" && parent.oUp) {
                this._setRefToRootWin(parent.oUp);
            }
        }

        return;
    } catch (e) {
        ErrorHandler.warn(e, "WindowSetRefToRootWin");
    }
}

function Window_SetRefToRootWin(oRootWin) {
    if (oRootWin) {
        this.oRootWin = oRootWin;
        this.aInfoPipe = oRootWin.aInfoPipe;
        if (oRootWin.oRegistry) {
            this.oRegistry = oRootWin.oRegistry;
        }
        if (oRootWin.oFormatInfo) {
            this.oFormatInfo = oRootWin.oFormatInfo;
        }
    }
}

/**
 * This method is called by this.reload to reload the actual popup or window after a timeout.
 * If used in the portal there are no fr_ActionId and recId.
 * @private
 */
function Window_Reload() {
    self.reload();
}
/**
 * This method resets all menu areas by the guid of the application or the guid of the menuitem.
 * @param p_appGuid Contains the app guid as string.
 * @param p_targetGuid is no more used.
 * @param p_menuId contains the menu guid.
 */
function WindowSetMenu(p_appGuid, p_targetGuid, p_menuId) {
    var l_appGuid = "";
    var l_menuId = "";
    if (p_appGuid) l_appGuid = p_appGuid;
    if (p_menuId) l_menuId = p_menuId;
    if (typeof oHtmlRoot.oMenuTree == "undefined") return false;
    var oMenuTree = oHtmlRoot.oMenuTree;
    if (l_appGuid.length > 2 || l_menuId.length > 2)
        // Alle Menues neu setzen
        oMenuTree.resetAll("", l_appGuid, l_menuId);
    // Menues initialisieren
    else oMenuTree.initAll();
    return true;
}
/**
 * This method sets the focus to an anchor or the first appropriate input field.
 * @return boolean <code>true</code>, if focus could be set, otherwise <code>false</code>.
 * @private
 */
function WindowSetFocus() {
    var oHtml = null;
    var bSet = false;
    try {
        var doc = this.oHtml.document;
        var lastFup = ix.util.getFuncParts().pop();
        var alternativeForm;
        if (lastFup && lastFup.element && lastFup.element.closest("[data-ix-control='tooltip']")) {
            //Wir haben ein Tooltip geöffnet
            alternativeForm = lastFup.element.querySelector("form");
        }
        // first scroll up, if needed
        if (
            !alternativeForm &&
            doc.documentElement &&
            doc.documentElement.scrollTop &&
            doc.documentElement.scrollTop > 0
        ) {
            doc.documentElement.scrollTop = 0;
        }
        var anchor = Helper.getQsValueByParam("rq_Anchor");
        // setFocus() unterdruecken
        if (anchor && anchor == "no") {
            return true;
        }
        if (anchor && anchor != "" && Browser.getRef("ID_" + anchor)) {
            Browser.gotoAnchor(anchor, this.oHtml.document);
        } else if (this.focusOnLoadId != "") {
            // falls vom Benutzer ein tabindex in der Anwendung gesetzt wurde
            if (typeof this.upType !== "undefined" && this.upType == "upPopUpWindow" && this.oHtml.document) {
                oHtml = Browser.getRef(this.focusOnLoadId, this.oHtml.document);
            } else {
                oHtml = Browser.getRef(this.focusOnLoadId);
            }
            if (oHtml) {
                this.oHtml.focus();
                if (
                    oHtml &&
                    (oHtml.getAttribute("data-auto-focus") === "true" || oHtml.getAttribute("data-auto-focus") === null)
                )
                    oHtml.focus();
            }
            this.focusOnLoadId = "";
        } else if (document.forms && document.forms.length > 0) {
            // cover.vm
            if (document.getElementById("Container_Cover")) {
                if (document.getElementById("Container_Cover").getElementsByTagName("a").length > 0)
                    document.getElementById("Container_Cover").getElementsByTagName("a")[0].focus();
                return true;
            }
            // Sitemap
            if (document.getElementById("Container_Sitemap")) {
                if (document.getElementById("Container_Sitemap").getElementsByTagName("a").length > 0)
                    document.getElementById("Container_Sitemap").getElementsByTagName("a")[0].focus();
                return true;
            }
            var fm;
            // Popup
            if (this.upType == "upPopUpWindow" && this.oHtml.document) {
                if (this.oHtml.document.getElementsByTagName("form").length > 0) {
                    fm = this.oHtml.document.getElementsByTagName("form")[0];
                } else {
                    this.oHtml.focus();
                    return;
                }
            } else if (
                document.getElementById("Container_AppMain") &&
                document.getElementById("Container_AppMain").getElementsByTagName("form").length > 0
            )
                // Anwendungsseite
                fm = document.getElementById("Container_AppMain").getElementsByTagName("form")[0];
            else if (Helper.getQsValueByParam("rq_MenuGuid"))
                // nach Auswahl im Menue und falls keine Anwendungsseite geladen wird: hier keinen Focus setzen.Er wird evtl. in Menu.init() gesetzt.
                return false;
            else fm = document.forms[0];
            if (alternativeForm) {
                fm = alternativeForm;
            }
            var $formElements;
            // collect elements in current form and elements with html5-form-attribute
            if (typeof fm !== "undefined") {
                $formElements = this.oHtml.$(
                    'input[name][form="' +
                        fm.id +
                        '"]:not(:disabled):not(:hidden), select[name][form="' +
                        fm.id +
                        '"]:not(:hidden), textarea[name][form="' +
                        fm.id +
                        '"]:not(:hidden)'
                );
                $formElements.add(
                    this.oHtml.ix.util.getFormFields({
                        formNode: $(fm).get(0),
                        selector:
                            "input[name]:not(:disabled):not(:hidden), select[name]:not(:hidden), textarea[name]:not(:hidden)",
                    })
                );

                $formElements.each(function () {
                    oHtml = this;
                    var strHtmlType = oHtml.type;

                    if (
                        strHtmlType != "hidden" &&
                        strHtmlType != "button" &&
                        strHtmlType != "submit" &&
                        strHtmlType != "select-one" &&
                        (typeof oHtml.oUp == "undefined" || oHtml.oUp == null || oHtml.readOnly == false)
                    ) {
                        if (oHtml.oUp === undefined || oHtml.oUp.upType !== "upTimeControl") {
                            if ($("input[name^='fr_strTextControl']").val() != oHtml.name) {
                                if (
                                    oHtml &&
                                    (oHtml.getAttribute("data-auto-focus") === "true" ||
                                        oHtml.getAttribute("data-auto-focus") === null)
                                )
                                    oHtml.focus();
                            } else {
                                var val = $(oHtml).val();
                                if (
                                    oHtml &&
                                    (oHtml.getAttribute("data-auto-focus") === "true" ||
                                        oHtml.getAttribute("data-auto-focus") === null)
                                )
                                    $(oHtml).focus().val("").val(val);
                            }
                        }
                        bSet = true;
                        return false;
                    }
                });
            }

            if (bSet) {
                return true;
            }

            this.oHtml.focus();
            if (document.getElementById("maincontent")) {
                document.getElementById("maincontent").focus();
            }
            return true;
        }
        return false;
    } catch (e) {
        ErrorHandler.warn(e, "Window.setFocus()");
        return false;
    }
}

/**
 * Select and mark an element wich can receive focus with `data-ix-autofocus`.
 * @internal
 *
 * @TODO Set typings!
 */
function WindowShowAccessibilityMessages() {
    this.mediator.subscribe("ixpage/action/done", function (param) {
        if (param.action === "actMerge") {
            UpNotifier.status.notify(
                ix.text.i18n.get("ACCESSIBILITY_SAVE_SUCCESS_TEXT"),
                ix.text.i18n.get("ACCESSIBILITY_SAVE_SUCCESS_TITLE"),
                "mykey"
            );
        } else if (param.action === "actDelete") {
            UpNotifier.status.notify(
                ix.text.i18n.get("ACCESSIBILITY_DELETE_SUCCESS_TEXT"),
                ix.text.i18n.get("ACCESSIBILITY_DELETE_SUCCESS_TITLE"),
                "mykey"
            );
        }
    });
}

/**
 * Removes all eventhandlers from all elements of the current document.
 * @private
 */
function removeEvents() {
    //var iStart = parseInt(new Date().getTime());
    var all, l;
    var ob = null;
    // 'option','abbr','address','area','b','blockquote','big','center','cite','del','dfn','dir','em','hr','i','ins','kbd','noframes','noscript','pre','q','s','samp','small','strike','strong','sub','sup','var'
    var aElements = [
        "a",
        "acronym",
        "body",
        "button",
        "caption",
        "code",
        "col",
        "colgroup",
        "dd",
        "div",
        "dl",
        "dt",
        "fieldset",
        "form",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "img",
        "input",
        "label",
        "legend",
        "li",
        "link",
        "map",
        "menu",
        "object",
        "ol",
        "optgroup",
        "p",
        "select",
        "span",
        "table",
        "tbody",
        "td",
        "textarea",
        "tfoot",
        "th",
        "thead",
        "tr",
        "tt",
        "u",
        "ul",
    ];
    var iLengthElements = aElements.length;
    var aEvents = [
        "onkeydown",
        "onkeyup",
        "onmousemove",
        "onmouseover",
        "onmouseout",
        "onmousedown",
        "onmouseup",
        "ondblclick",
        "onclick",
        "onchange",
        "onfocus",
        "onblur",
    ];
    var iLengthEvents = aEvents.length;
    for (var k = iLengthElements; k--; ) {
        all = document.getElementsByTagName(aElements[k]);
        l = all.length;
        if (l == 0) continue;
        for (var j = l; j--; ) {
            ob = all[j];
            for (var i = iLengthEvents; i--; ) {
                ob[aEvents[i]] = null;
            }
        }
    }
    //var iEnd = parseInt(new Date().getTime());
    //alert((iEnd-iStart)/1000 + " Sekunden");
}
/**
 * Removes all references between Intrexx objects and html dom objects, which were constructed by bidirectUpHtml().
 * @private
 */
function WindowDelObjects() {
    // eslint-disable-next-line valid-typeof
    if (Browser.ie && typeof this.oHtml.document == "unknown") return;

    // falls IE: erst alle Eventhandler loeschen
    if (this.oHtml.document.all && typeof this.oHtml.removeEvents != "undefined") {
        this.oHtml.removeEvents();
    }

    this.aObjects = new Array();
}
/**
 * Removes window and page references between Intrexx objects and dom objects.
 * This method is called during the unload of the page.
 * @private
 */
function delFrameRef() {
    if (self.oUp) {
        self.oUp.oHtml = null;
        self.oUp = null;
    }
}
