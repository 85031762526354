/* globals ix, debug */
/**
 * @file Abstract core prototypes: In particular, in addition and complementary to the common prototypes
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/**
 * @file The upObject.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals isUndefinedOrNull */
/**
 * upObject
 *
 * @class
 * @private
 */
function upObject(options) {
    this.upType = "upObject";
    this.defaults = {};
    this.options = $.extend({}, this.defaults, options);
    return this;
}

/**
 * Creates an bidirectional associativity between upObject and HtmlObject
 *
 * @private
 * @param upObject upObject
 * @param elementId Html element id
 * @param refWindow window reference (optional)
 * @return Enhanced upObject
 */
upObject.prototype.biDirectUpHtml = function (up, elementId, refWindow, fup) {
    if (isUndefinedOrNull(refWindow)) {
        refWindow = window;
    }
    return this.biDirectUpHtml2(up, refWindow.document.getElementById(elementId), fup);
};

/**
 * Creates an bidirectional associativity between upObject and HtmlObject
 *
 * @private
 * @param up upObject
 * @param element Html element
 * @return Enhanced upObject
 */
upObject.prototype.biDirectUpHtml2 = function (up, element, fup) {
    try {
        up.oHtml = element;
        up.id = element.id;
        up.oHtml.oUp = up;
        up.oFup = fup;

        // html objekt als func part markieren
        if (up.upType == "upFuncPart" && $(element).attr("data-container") === undefined) {
            $(element).attr("data-container", "upFuncPart");
        }

        if (isUndefinedOrNull(fup)) {
            fup = $(element).getFuncPart();
        }

        if (typeof up.cleanUp === "function" && !isUndefinedOrNull(fup)) {
            var ns = ix.control.getGuid(element) || element.id;
            fup.bindEvent(
                "onunload." + ns,
                function (up) {
                    if (!isUndefinedOrNull(up)) {
                        up.cleanUp();
                    }
                }.bind(fup, up)
            );
        }

        return up;
    } catch (e) {
        debug("upObject.prototype.biDirectUpHtml2 ------------------------------------------------");
        debug("up:");
        debug(up);
        debug("element:");
        debug(element);
        //throw (e);
        return false;
    }
};

/**
    @methodOf upObject
    @private
*/
upObject.prototype.getUpType = function () {
    return this.upType;
};
