/**
 * @file upDataControl
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals
   upDataControl, ix, ObjectFactory, ErrorHandler,
   Notifier,oHtmlRoot
 */

/**
 * @ignore
 * @deprecated
 * @description Avoids input in readonly field.<br>
 * Sets focus to next field, that is not readonly or hidden.
 * @return {Boolean}
 */
upDataControl.prototype.skipReadonlyControl = function () {
    var fm = ix.util.getForm(this.oHtml);
    var fmLength = fm.length;
    var start;
    for (var i = 0; i < fmLength; i++) {
        if (this.oHtml.name == fm.elements[i].name) {
            //letztes Element
            if (i + 1 >= fmLength) {
                start = 0;
            } else {
                start = i + 1;
            }
            for (var j = start; j < fmLength; j++) {
                var oHtml = fm.elements[j];
                if (!oHtml.oUp) {
                    continue;
                }
                if (oHtml.type != "hidden" && oHtml.style.display != "none" && !oHtml.oUp.getReadOnly()) {
                    if (fm.oUp.setFocusOn(oHtml)) {
                        return true;
                    } else {
                        continue;
                    }
                }
            }
            //alle Elemente nach schreibgeschuetztem Feld sind Hidden
            self.oUp.setFocus();
            return true;
        }
    }
    return false;
};

/**
 * @ignore
 * @param {String} message Message in case of an error.
 * @return {Boolean}
 * @description Validates a form element. Is length greater than 0? Encapsulates validateRequiredDo(), if triggered <br>
 * directly at a control event and not at a form event.
 */
upDataControl.prototype.validateRequired = function (message) {
    var msg = "";
    if (!message || message === "") {
        msg = ix.text.i18n.get("CTRL_CONSTR_PATTERN");
    } else {
        msg = message;
    }
    var errorId = "";
    var additionalClasses = "";
    if (!this.validateRequiredDo()) {
        this.oHtml.setAttribute("aria-invalid", true);
        if (oHtmlRoot.oUp.oWAISettings.bWriteValidationErrorInline) {
            errorId = this.oHtml.id + "_ReqValidation_Error";
            if (!document.getElementById(errorId)) {
                var oSpan = document.createElement("span");
                oSpan.appendChild(document.createTextNode(msg));
                oSpan.className = "validation-error";
                oSpan.setAttribute("id", errorId);
                if (this.getLabel(this.oHtml.id)) {
                    this.getLabel(this.oHtml.id).appendChild(oSpan);
                } else if (this.oHtml.nextSibling) {
                    this.oHtml.parentNode.insertBefore(oSpan, this.oHtml.nextSibling);
                } else {
                    this.oHtml.parentNode.appendChild(oSpan);
                }
            }
        } else {
            Notifier.status.error(msg, ix.text.i18n.get("ERROR_VALIDATOR_HEADING"), "NumericsValidateValue");
        }
        this.oHtml.className = this.styleType + " selected " + additionalClasses + "";
        this.oHtml.focus();
        var oHtml = this.oHtml;
        setTimeout(function () {
            oHtml.focus();
        }, 100);
        return false;
    } else {
        this.oHtml.setAttribute("aria-invalid", false);
        this.oHtml.className = "" + this.styleType + additionalClasses;
        if (oHtmlRoot.oUp.oWAISettings.bWriteValidationErrorInline) {
            errorId = this.oHtml.id + "_ReqValidation_Error";
            if (document.getElementById(errorId)) {
                document.getElementById(errorId).parentNode.removeChild(document.getElementById(errorId));
            }
        }
    }
    return true;
};

/**
 * @ignore
 * @description Validates a form element. Is its value not empty?
 * @return {Boolean}
 */
upDataControl.prototype.validateRequiredDo = function () {
    var oCtrlValueInfo = ObjectFactory.construct("upCtrlValueInfo", this);
    return oCtrlValueInfo.hasValue() && !oCtrlValueInfo.isEmptyEntry();
};

/**
 * @ignore
 * @param {String} pattern
 */
upDataControl.prototype.getValidatedValue = function (pattern) {
    var value = this.oHtml.value;

    if (pattern.toString().substr(0, 1) == "/") {
        pattern = pattern.toString().substr(1, pattern.toString().length - 1);
    }
    if (pattern.toString().substr(pattern.length - 1, 1) == "/") {
        pattern = pattern.toString().substring(0, pattern.toString().length - 1);
    }

    var reg = new RegExp(pattern, "i");
    var validatedValue = [];

    for (var strChar, i = 0, l = value.length; i < l; i++) {
        strChar = value.charAt(i);
        if (!reg.test(strChar)) {
            validatedValue.push(
                '<span style="font-weight: 700; font-size: 1.2em; color: red; margin-left: 2px; margin-right: 2px;">' +
                    strChar +
                    "</span>"
            );
        } else {
            validatedValue.push(strChar);
        }
    }

    return validatedValue.join("");
};

/**
 * @public
 * @param {Boolean} state the attribute value
 * @description Set state of the readonly attribute for the given input element.
 */
upDataControl.prototype.setReadOnly = function (state) {
    var bState = state !== undefined ? (state === 1 || state === true ? true : false) : true;

    if (!this || this === null) {
        ErrorHandler.notify(
            ix.text.i18n.get("DEPENDENCY_ERROR_TARGET_INVALID"),
            ix.text.i18n.get("ERROR_XHR_TITLE"),
            "Intrexx"
        );
        return;
    } else {
        this.readOnly = bState;
        if (bState === true) {
            this.oHtml.setAttribute("readonly", bState);
        } else {
            this.oHtml.removeAttribute("readOnly");
        }
        return;
    }
};

/**
 * @methodOf upDataControl
 * @public
 * @description
    get state of the readonly attribute for the given input element
 * @return {Boolean} readOnly state
 */
upDataControl.prototype.getReadOnly = function () {
    if (!this || this === null) {
        ErrorHandler.notify(
            ix.text.i18n.get("DEPENDENCY_ERROR_TARGET_INVALID"),
            ix.text.i18n.get("ERROR_XHR_TITLE"),
            "Intrexx"
        );
        return;
    } else {
        var readOnly = $(this).attr("readonly");
        return readOnly ? true : false;
    }
};

/**
 * @ignore
 * @param {String} id The Id of the input control.
 * @return {HTMLElement|null}
 * @description Returns the label of an input control.
 */
upDataControl.prototype.getLabel = function (id) {
    var nsLabels = document.getElementsByTagName("label");
    for (var i = 0; i < nsLabels.length; i++) {
        if (nsLabels[i].getAttribute("for") == id) {
            return nsLabels[i];
        }
    }
    return null;
};

/**
 * @ignore
 * @param {String} val
 * @returns {String}
 * @description Creates an ISO string for a webservice.
 */
upDataControl.prototype.toWsInputString = function (val) {
    return val;
};
