/**
 * @file upTextControl
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals ix, upTextMainControl, MissingHTMLElementException */

(function (root, factory) {
    root.upTextControl = factory();
})(this, function () {
    "use strict";

    /**
     * @private
     * @param {upObject} obj
     * @return {Boolean}
     */
    var hasElement = function (obj) {
        return HTMLElement.prototype.isPrototypeOf(obj.oHtml);
    };

    /**
     * @class upTextControl
     * @mixes ix.control.Validatable
     * @param {Object} [options]
     * @description Generic upTextControl class.
     */
    function upTextControl(options) {
        this.upType = "upTextControl";
        this.oHtml = null;
        jQuery.extend(this, {}, options);
    }
    // safely assign inherited prototype and constructor
    upTextControl.prototype = Object.create(upTextMainControl.prototype);
    upTextControl.prototype.constructor = upTextControl;
    // mixin but real inheritance would be appreciated in future...
    $.extend(upTextControl.prototype, ix.control.Validatable);

    /**
     * @private
     * @return {ix.control.Validatable.Validity}
     */
    var getValidity = function () {
        var element = this.oHtml;
        var validity = {
            state: element.validity,
            message: element.validationMessage,
        };
        return validity;
    };

    /**
     * @private
     * @param {String} customConstraint
     * @return {upTextControl}
     */
    var setValidity = function (customConstraint) {
        var element = this.oHtml;
        element.setCustomValidity(customConstraint);
        return this;
    };

    /**
     * @inheritdoc
     */
    upTextControl.prototype.validity = function (customConstraint) {
        var d = $.Deferred();
        if (!hasElement(this)) {
            d.reject(new MissingHTMLElementException());
        } else {
            if (typeof customConstraint === "undefined") {
                d.resolve(getValidity.call(this));
            } else {
                d.resolve(setValidity.call(this, customConstraint));
            }
        }
        return d.promise();
    };

    return upTextControl;
});
