/* globals HelperMain, Helper, colIndex: true , colIndexNew: true, */
/**
 * @file Prototype upListboxHelper
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/**
 * @class
 * @private
 */
function upListboxHelperReg(helper) {
    this.oHlp = helper;
    this.oWin = this.oHlp.oWin;
    this.oDoc = this.oHlp.oDoc;
}
upListboxHelperReg.prototype.register = upListboxHelperRegRegister;
HelperMain.prototype.listbox = new upListboxHelperReg(Helper);
/**
 * @private
 */
function upListboxHelperRegRegister(p_strName, p_aListboxes) {
    if (p_strName == null || typeof p_strName != "string" || p_strName.length <= 0) {
        p_strName = this.oHlp.getUniqueKey();
    }
    this[p_strName] = new upListboxHelper(this);
    this[p_strName].addListbox(p_aListboxes);
    this[p_strName].strKey = p_strName;
    return this[p_strName];
}
/**
 * @class
 * @private
 */
function upListboxHelper(reg) {
    /**
     * @private
     */
    this.oRegistry = reg;
    /**
     * @private
     */
    this.oWin = reg.oWin;
    /**
     * @private
     */
    this.oDoc = reg.oDoc;
    /**
     * @private
     */
    this.colArray = new Array();
    /**
     * @private
     */
    this.colbox = null;
    /**
     * @private
     */
    this.strKey = null;
    /**
     * @private
     */
    this.onchange = null;
}
// Funktionen fuer "Container" mit mehreren Auswahllisten
upListboxHelper.prototype.addListbox = upListboxHelperAddListbox;
upListboxHelper.prototype.selectListbox = upListboxHelperSelectListbox;
upListboxHelper.prototype.insert = upListboxHelperInsert;
upListboxHelper.prototype.remove = upListboxHelperRemove;
upListboxHelper.prototype.moveLeft = upListboxHelperMoveLeft;
upListboxHelper.prototype.moveRight = upListboxHelperMoveRight;
upListboxHelper.prototype.moveUp = upListboxHelperMoveUp;
upListboxHelper.prototype.moveDown = upListboxHelperMoveDown;
upListboxHelper.prototype.moveRightMultiple = upListboxHelperMoveRightMultiple;
upListboxHelper.prototype.moveLeftMultiple = upListboxHelperMoveLeftMultiple;
upListboxHelper.prototype.moveUpMultiple = upListboxHelperMoveUpMultiple;
upListboxHelper.prototype.moveDownMultiple = upListboxHelperMoveDownMultiple;
upListboxHelper.prototype.insertMultiple = upListboxHelperInsertMultiple;
upListboxHelper.prototype.removeMultiple = upListboxHelperRemoveMultiple;
upListboxHelper.prototype.getCountOfSelectedItems = upListboxHelperGetCountOfSelectedItems;
upListboxHelper.prototype.doCharStuffing = upListboxHelperDoCharStuffing;
upListboxHelper.prototype.getSelected = upListboxHelperGetSelected;
upListboxHelper.prototype._onchange = upListboxHelper_Onchange;

function upListboxHelperAddListbox(p_aLists) {
    if (p_aLists) {
        var k = 0;
        for (var id in p_aLists) {
            if (this.oDoc.getElementById(p_aLists[id])) {
                var oHtml = this.oDoc.getElementById(p_aLists[id]);
                if (k == 0) this.colbox = oHtml;
                this.colArray[k] = oHtml;
                k++;
            }
        }
    }
}
/**
 * Selects a listbox
 * @private
 * @param {Object} oColBox Reference to selected listbox
 */
function upListboxHelperSelectListbox(oColBox, bResetSelected) {
    if (arguments.length < 2) bResetSelected = true;

    if (this.colbox != oColBox) {
        if (bResetSelected && this.colbox != null && this.colbox.selectedIndex != -1) {
            this.colbox.selectedIndex = -1;
        }
        this.colbox = oColBox;
    }
}

/**
 * Inserts text and value into a listbox at a given position
 * @private
 * @param {String} strTxt Text to insert
 * @param {String} strValue Value to insert
 * @param {Integer} intPos Position
 */
function upListboxHelperInsert(strTxt, strValue, intPos) {
    var pos = this.colbox.selectedIndex;
    var len = this.colbox.length;
    var posNew = len;
    if (strTxt == null) return false;
    if (strValue == null) return false;
    if (pos == -1) {
        if (intPos == null || intPos >= len || intPos < 0) {
            posNew = len;
            pos = len;
        } else {
            pos = intPos - 1;
            posNew = pos + 1;
        }
    } else posNew = pos + 1;

    strTxt = Helper.hexEncodeString(strTxt);
    strValue = Helper.hexEncodeString(strValue);
    this.oWin.eval(
        "document.getElementById('" +
            this.colbox.id +
            "').options[" +
            len +
            "] = new Option(Helper.hexDecodeString('" +
            strTxt +
            "'),Helper.hexDecodeString('" +
            strValue +
            "'), true, true);"
    );
    for (var i = len; i > pos; i--) {
        if (i == 0) break;
        this.colbox.options[i].text = this.colbox.options[i - 1].text;
        this.colbox.options[i].value = this.colbox.options[i - 1].value;
    }
    // neue Option setzen und markieren
    this.colbox.options[posNew].text = Helper.hexDecodeString(strTxt);
    this.colbox.options[posNew].value = Helper.hexDecodeString(strValue);
    this.colbox.options[posNew].selected = true;
}

/**
 * Removes an element from a listbox
 * @private
 * @return <code>true</code> is element was successfully removed
 */
function upListboxHelperRemove() {
    var pos = this.colbox.selectedIndex;
    var len = this.colbox.length;
    if (len == 0) return;
    if (pos == -1) return;
    var posNew = pos;
    if (pos == len - 1) posNew = pos - 1;
    else posNew = pos;
    for (var i = pos; i < len - 1; i++) {
        this.colbox.options[i].text = this.colbox.options[i + 1].text;
        this.colbox.options[i].value = this.colbox.options[i + 1].value;
    }
    this.colbox.options[len - 1] = null;
    if (this.colbox.length > 0) this.colbox.options[posNew].selected = true;
    return true;
}

/**
 * Moves a listbox element to the listbox on the left
 * @private
 */
function upListboxHelperMoveLeft() {
    var pos = this.colbox.selectedIndex;
    var colIndex = 0;
    var colIndexNew = 0;
    if (pos == -1) return;
    for (var i = 0; i < this.colArray.length; i++) {
        if (this.colArray[i] == this.colbox) {
            colIndex = i;
            break;
        }
    }
    if (colIndex == 0) colIndexNew = this.colArray.length - 1;
    else colIndexNew = colIndex - 1;
    var strTxt = this.colbox.options[pos].text;
    var strValue = this.colbox.options[pos].value;
    this.remove();
    this.selectListbox(this.colArray[colIndexNew], false);
    this.insert(strTxt, strValue, pos);
    this._onchange();
}

/**
 * Moves a listbox element to the listbox on the right
 * @private
 */
function upListboxHelperMoveRight() {
    var pos = this.colbox.selectedIndex;
    var colIndex = 0;
    var colIndexNew = 0;
    if (pos == -1) return;
    for (var i = 0; i < this.colArray.length; i++) {
        if (this.colArray[i] == this.colbox) {
            colIndex = i;
            break;
        }
    }
    if (colIndex == this.colArray.length - 1) {
        colIndexNew = 0;
    } else {
        colIndexNew = colIndex + 1;
    }
    var strTxt = this.colbox.options[pos].text;
    var strValue = this.colbox.options[pos].value;
    this.remove();
    this.selectListbox(this.colArray[colIndexNew], false);
    this.insert(strTxt, strValue, pos);
    this._onchange();
}

/**
 * Moves a listbox element up one position
 * @private
 */
function upListboxHelperMoveUp() {
    var pos = this.colbox.selectedIndex;
    var len = this.colbox.length;
    var posNew = 0;
    if (pos == -1) {
        return;
    }
    var textTmp = this.colbox.options[pos].text;
    var valueTmp = this.colbox.options[pos].value;
    if (pos == 0) {
        posNew = len - 1;
        for (var k = 1; k < this.colbox.length; k++) {
            this.colbox.options[k - 1].text = this.colbox.options[k].text;
            this.colbox.options[k - 1].value = this.colbox.options[k].value;
        }
    } else {
        posNew = pos - 1;
        this.colbox.options[pos].text = this.colbox.options[posNew].text;
        this.colbox.options[pos].value = this.colbox.options[posNew].value;
    }
    this.colbox.options[posNew].text = textTmp;
    this.colbox.options[posNew].value = valueTmp;
    this.colbox.options[posNew].selected = true;
    this._onchange();
}

/**
 * Moves a listbox element down one position
 * @private
 */
function upListboxHelperMoveDown() {
    var pos = this.colbox.selectedIndex;
    var len = this.colbox.length;
    var posNew = 0;
    if (pos == -1) {
        return;
    }
    var textTmp = this.colbox.options[pos].text;
    var valueTmp = this.colbox.options[pos].value;
    if (pos == len - 1) {
        posNew = 0;
        for (var k = this.colbox.length - 2; k >= 0; k--) {
            this.colbox.options[k + 1].text = this.colbox.options[k].text;
            this.colbox.options[k + 1].value = this.colbox.options[k].value;
        }
    } else {
        posNew = pos + 1;
        this.colbox.options[pos].text = this.colbox.options[posNew].text;
        this.colbox.options[pos].value = this.colbox.options[posNew].value;
    }
    this.colbox.options[posNew].text = textTmp;
    this.colbox.options[posNew].value = valueTmp;
    this.colbox.options[posNew].selected = true;
    this._onchange();
}

/**
 * Moves multiple listbox elements to the listbox on the right
 * @private
 */
function upListboxHelperMoveRightMultiple() {
    var pos = this.colbox.selectedIndex;
    if (pos == -1) return;
    for (var i = 0; i < this.colArray.length; i++) {
        if (this.colArray[i] == this.colbox) {
            colIndex = i;
            break;
        }
    }

    if (colIndex == this.colArray.length - 1) {
        colIndexNew = 0;
    } else {
        colIndexNew = colIndex + 1;
    }

    var l_aSelected = new Array();
    // eslint-disable-next-line no-redeclare
    for (var i = 0; i < this.colbox.options.length; i++) {
        if (this.colbox.options[i].selected) {
            var strTxt = this.colbox.options[i].text;
            var strValue = this.colbox.options[i].value;
            l_aSelected[l_aSelected.length] = new Array(strValue, strTxt);
        }
    }
    this.removeMultiple();
    this.selectListbox(this.colArray[colIndexNew], false);
    this.insertMultiple(l_aSelected);
    this._onchange();
}

/**
 * Moves multiple listbox elements to the listbox on the left
 * @private
 */
function upListboxHelperMoveLeftMultiple() {
    var pos = this.colbox.selectedIndex;
    if (pos == -1) return;
    for (var i = 0; i < this.colArray.length; i++) {
        if (this.colArray[i] == this.colbox) {
            colIndex = i;
            break;
        }
    }
    if (colIndex == 0) colIndexNew = this.colArray.length - 1;
    else colIndexNew = colIndex - 1;

    var strTxt, strValue;
    var l_aSelected = new Array();
    // eslint-disable-next-line no-redeclare
    for (var i = 0; i < this.colbox.options.length; i++) {
        if (this.colbox.options[i].selected) {
            strTxt = this.colbox.options[i].text;
            strValue = this.colbox.options[i].value;
            l_aSelected[l_aSelected.length] = new Array(strValue, strTxt);
        }
    }
    this.removeMultiple();
    this.selectListbox(this.colArray[colIndexNew], false);
    this.insertMultiple(l_aSelected);
    this._onchange();
}

/**
 * Moves multiple listbox elements up one position
 * @private
 */
function upListboxHelperMoveUpMultiple() {
    var pos = this.colbox.selectedIndex;
    // eslint-disable-next-line no-unused-vars
    var len = this.colbox.length;
    posNew = 0;
    if (pos == -1) return;
    var l_aRemove = new Array();
    var l_aOption = new Array();
    var l_aOptions = new Array();
    if (!this.colbox.options[0].selected) {
        // eslint-disable-next-line no-unused-vars
        var l_bLastOptIsSelected = false;
        for (var i = this.colbox.options.length - 1; i >= 0; i--) {
            if (this.colbox.options[i].selected) {
                if (i == this.colbox.options.length - 1) {
                    // eslint-disable-next-line no-unused-vars
                    l_bLastOptIsSelected = true;
                }
                if (i == 0) {
                    var l_bLast = true;
                    var newPos = this.colbox.options.length - 1;
                } else {
                    l_bLast = false;
                    newPos = i - 1;
                }
                l_aRemove[l_aRemove.length] = i;
                l_aOption = new Array();
                l_aOption[0] = newPos;
                l_aOption[1] = this.colbox.options[i].value;
                l_aOption[2] = this.colbox.options[i].text;
                l_aOption[3] = l_bLast;
                l_aOptions[l_aOptions.length] = l_aOption;
            }
        }
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < l_aRemove.length; i++) {
            this.colbox.options[l_aRemove[i]] = null;
        }
        // eslint-disable-next-line no-unused-vars
        var newEntry;
        var posNew;
        var value;
        var text;
        var selected;
        var l_oOpt;
        // eslint-disable-next-line no-redeclare
        for (var i = l_aOptions.length - 1; i >= 0; i--) {
            l_oOpt = l_aOptions[i];
            posNew = l_aOptions[i][0];
            if (posNew > this.colbox.options.length) posNew = this.colbox.options.length;
            else if (!l_aOptions[i][3] && posNew == this.colbox.options.length) posNew--;
            if (posNew < this.colbox.options.length) {
                for (var k = this.colbox.options.length - 1; k >= posNew; k--) {
                    value = this.colbox.options[k].value;
                    text = this.colbox.options[k].text;
                    selected = this.colbox.options[k].selected;
                    this.oWin.eval(
                        "document.getElementById('" +
                            this.colbox.id +
                            "').options[" +
                            (k + 1) +
                            "] = new Option('" +
                            text +
                            "','" +
                            value +
                            "', " +
                            selected +
                            ", " +
                            selected +
                            ");"
                    );
                    // eslint-disable-next-line no-unused-vars
                    newEntry = null;
                }
            }
            this.oWin.eval(
                "document.getElementById('" +
                    this.colbox.id +
                    "').options[" +
                    posNew +
                    "] = new Option('" +
                    l_oOpt[2] +
                    "','" +
                    l_oOpt[1] +
                    "', true, true);"
            );
        }
    }
    this._onchange();
}

/**
 * Get selected listbox elements
 * @private
 * @return {Array} selected elements
 */
function upListboxHelperGetSelected() {
    var l_aOptions = new Array();
    // var pos = this.colbox.selectedIndex;
    // var len = this.colbox.length;
    for (var i = 0; i < this.colbox.options.length; i++) {
        if (this.colbox.options[i].selected) {
            l_aOptions.push(this.colbox.options[i]);
        }
    }
    return l_aOptions;
}
/**
 * Moves multiple listbox elements down one position
 * @private
 */
function upListboxHelperMoveDownMultiple() {
    var pos = this.colbox.selectedIndex;
    // var len = this.colbox.length;
    posNew = 0;
    if (pos == -1) return;
    var l_aRemove = new Array();
    var l_aOption = new Array();
    var l_aOptions = new Array();
    if (!this.colbox.options[this.colbox.options.length - 1].selected) {
        for (var i = 0; i < this.colbox.options.length; i++) {
            if (this.colbox.options[i].selected) {
                var newPos;
                if (i == this.colbox.options.length - 1) {
                    newPos = 0;
                } else {
                    newPos = i + 1;
                }
                l_aRemove[l_aRemove.length] = i;
                l_aOption = new Array();
                l_aOption[0] = newPos;
                l_aOption[1] = this.colbox.options[i].value;
                l_aOption[2] = this.colbox.options[i].text;
                l_aOptions[l_aOptions.length] = l_aOption;
            }
        }
        // eslint-disable-next-line no-redeclare
        for (var i = l_aOptions.length - 1; i >= 0; i--) {
            this.colbox.options[l_aRemove[i]] = null;
        }
        var posNew;
        var value;
        var text;
        // eslint-disable-next-line no-unused-vars
        var newEntry;
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < l_aOptions.length; i++) {
            posNew = l_aOptions[i][0];
            if (posNew > this.colbox.options.length) posNew = this.colbox.options.length;
            if (posNew <= this.colbox.options.length) {
                for (var k = this.colbox.options.length - 1; k >= posNew; k--) {
                    value = this.colbox.options[k].value;
                    text = this.colbox.options[k].text;
                    var selected = this.colbox.options[k].selected;
                    this.oWin.eval(
                        "document.getElementById('" +
                            this.colbox.id +
                            "').options[" +
                            (k + 1) +
                            "] = new Option('" +
                            text +
                            "','" +
                            value +
                            "', " +
                            selected +
                            ", " +
                            selected +
                            ");"
                    );
                }
            }
            this.oWin.eval(
                "document.getElementById('" +
                    this.colbox.id +
                    "').options[" +
                    posNew +
                    "] = new Option('" +
                    l_aOptions[i][2] +
                    "','" +
                    l_aOptions[i][1] +
                    "', true, true);"
            );
        }
    }
    this._onchange();
}

/**
 * Removes multiple listbox elements
 * @private
 * @return <code>true</code>
 */
function upListboxHelperRemoveMultiple() {
    for (var i = this.colbox.length - 1; i >= 0; i--) {
        if (this.colbox.options[i].selected) {
            this.colbox.options[i] = null;
        }
    }
    return true;
}

/**
 * Inserts multiple (selected) listbox elements into another listbox
 * @private
 * @param {Array} p_aSelected
 */
function upListboxHelperInsertMultiple(p_aSelected) {
    for (var k = 0; k < p_aSelected.length; k++) {
        if (p_aSelected[k].length == 2) {
            this.insert(p_aSelected[k][1], p_aSelected[k][0]);
            //this.oWin.eval("document.getElementById('" + this.colbox.id + "').options[" + this.colbox.options.length + "] = new Option('" + p_aSelected[k][1] + "','" + p_aSelected[k][0] + "', true, true);")
        }
    }
}
/**
 * Counts selected elements in a listbox
 * @private
 * @param {Object} p_oSelected Reference to the selected listbox
 * @return the number of selected elements
 */
function upListboxHelperGetCountOfSelectedItems(p_oSelect) {
    if (arguments.length < 1 || p_oSelect == null) p_oSelect = this.colbox;

    if (p_oSelect.selectedIndex == -1) {
        return -1;
    } else {
        var l_lCount = 0;
        for (var k = 0; k < p_oSelect.options.length; k++) {
            if (p_oSelect.options[k].selected) l_lCount++;
        }
    }
    return l_lCount;
}

/**
 * @private
 */
function upListboxHelperDoCharStuffing(p_oSelect, p_bText, p_bSelectedOnly) {
    if (arguments.length < 1 || p_oSelect == null) {
        var l_aTemp = new Array();
        for (var i = 0; i < this.colArray.length; i++) {
            l_aTemp.push(this.doCharStuffing(this.colArray[i], p_bText));
        }
        return Helper.doCharStuffing(l_aTemp);
    }
    if (arguments.length < 2 || p_bSelectedOnly == null) p_bSelectedOnly = false;

    var aOptions = new Array();
    for (var k = 0; k < p_oSelect.options.length; k++) {
        if (p_oSelect.options[k].selected || !p_bSelectedOnly) {
            if (typeof p_bText == "undefined" || p_bText == false) aOptions.push(p_oSelect.options[k].value);
            else aOptions.push(p_oSelect.options[k].text);
        }
    }
    return Helper.doCharStuffing(aOptions);
}

/**
 * @private
 */
function upListboxHelper_Onchange() {
    if (this.onchange != null) {
        switch (typeof this.onchange) {
            case "string": // eval func
                this.oWin.eval(this.onchange + "(Helper.listbox['" + this.strKey + "']);");
                break;
            case "object":
            case "function":
                this.onchange(this);
                break;
        }
    }
}
