/**
 * @file upTextMainControl
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals upDataControl, SetValueResultInfo */

/**
 * @class upTextMainControl
 * @ignore
 * @param {Object} options
 * @description Generic upTextControl-class, used as template for all EditControls
 */
function upTextMainControl(options) {
    this.upType = "upTextMainControl";
    this.oHtml = null;
    this.defaults = {}; //
    this.options = $.extend({}, this.defaults, options); // extend empty object with defaults and options (if present)
    jQuery.extend(this, {}, options);
}
upTextMainControl.prototype = new upDataControl();

/**
 * @return {Object}
 * @description Get the value of the control.
 * Without settings specified, the html value will be delivered, like jQuery does.
 * That means, that for some controls, the settings parameter will be mandatory to retrieve an useful value.
 */
upTextMainControl.prototype.getValue = function () {
    var ret = "";
    if (typeof this.oHtml !== "undefined" && !!this.oHtml) {
        ret = $(this.oHtml).val();
    }
    return ret;
};

/**
 * @param {Object} value The value to be set.
 * @param {upDataControl~SetValue~Settings} [settings={}] The settings to be used.
 * @return {Promise}
 * @description Set value of the control, can be asynchron, depending on the control type.
 */
upTextMainControl.prototype.setValue = function (value, settings) {
    var that = this; // preserve this context
    var _settings = {
        triggerOnchange: "strict",
    };
    _settings = $.extend({}, _settings, settings);
    return $.Deferred(
        function (value, settings, dfd) {
            try {
                if (typeof this.oHtml !== "undefined" && typeof value !== "undefined") {
                    var initialState = this.getValue();
                    var hasChanged = false;
                    $(that.oHtml).val(value);
                    hasChanged = initialState !== this.getValue();
                    // triggerOnchange=disabled] disabled | strict | always
                    if (
                        settings.triggerOnchange === "always" ||
                        (settings.triggerOnchange === "strict" && hasChanged)
                    ) {
                        $(this.oHtml).trigger("change");
                    }
                    dfd.resolve(
                        new SetValueResultInfo({
                            status: "successful",
                            hasChanged: hasChanged,
                        })
                    );
                } else {
                    dfd.reject(
                        new SetValueResultInfo({
                            status: "failed",
                            error: new Error("Element not found."),
                        })
                    );
                }
            } catch (ex) {
                dfd.reject(
                    new SetValueResultInfo({
                        status: "failed",
                        error: ex,
                    })
                );
            }
        }.bind(this, value, _settings)
    ).promise();
};
