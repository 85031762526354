/**
 * @file Helper
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals
    HelperMain, Helper: true , isUndefinedOrNull
*/
/* exported Helper */

/**
 * @memberof Helper
 * @function getFupId
 * @param {HTMLElement} element
 * @return {String}
 * @description Get the GUID or ID of functional part, where given element is part of, otherwise empty string.
 */
HelperMain.prototype.getFupId = function (element) {
    var fup = $(element).getFuncPart();
    if (!isUndefinedOrNull(fup)) {
        return fup.guid || fup.id;
    } else {
        return "";
    }
};

/**
 * @ignore
 */
/* jshint -W020 */
// TODO: If Helper is refeactored, don't use constructor, use namespace.
Helper = new HelperMain();
/* jshint +W020 */
