/* globals Helper, Browser, UpDateTimeEditControl, UpTimeEditControl, UpDateEditControl, UpFloatControl, UpCurrencyControl, UpIntegerControl, ix */

/**
 * @class
 * Control value info
 *
 * @param {Object} control
 * @param {String} attr (compare BrowserGetValue)
 * @return object with control value infos
 * @type Object
 */
function upCtrlValueInfo(control, attr) {
    this.oUpControl = control;

    this.strAttr = attr;

    if (
        this.oUpControl.upType == "upDropdownControl" ||
        this.oUpControl.upType == "upListboxControl" ||
        this.oUpControl.upType == "upExtendedListboxControl"
    ) {
        if (attr == "value") {
            attr = "text";
        }
    }

    this.value = Browser.getValue(this.oUpControl, attr);
    if (
        this.oUpControl.upType == "upDateControl" &&
        attr == "offset" &&
        typeof attr.value == "string" &&
        typeof this.value !== "undefined" &&
        this.value.length > 0
    ) {
        var dt = this.oUpControl.getDateObject();
        dt = Browser.addOffset(attr.value, dt);
        this.value = this.oUpControl.toLocalFormat(dt);
    } else if (Array.isArray(this.value)) {
        /*jshint -W053 */
        this.value = new String(Helper.doCharStuffing(this.value));
        this.value.bStuffed = true;
        /*jshint +W053 */
    }

    /**
     * @private
     */
    this.m_oUpByRenderingType = null;
    /**
     * @private
     */
    this.m_strDbValue = null;
    /**
     * @private
     */
    this.m_bLocal = null;
    /**
     * @private
     */
    this.m_bEmptyEntry = null;
    /**
     * @private
     */
    this.m_bEmptyEntryAll = null;
    /**
     * @private
     */
    this.m_bHasValue = null;
    /**
     * @private
     */
    this.m_bStuffed = null;
    /**
     * @private
     */
    this.m_aStuffedValue = null;
}
upCtrlValueInfo.prototype.getValue = upCtrlValueInfoGetValue;
upCtrlValueInfo.prototype.getDbValue = upCtrlValueInfoGetDbValue;
upCtrlValueInfo.prototype.hasValue = upCtrlValueInfoHasValue;
upCtrlValueInfo.prototype.hasValidValue = upCtrlValueInfoHasValidValue;
upCtrlValueInfo.prototype.isEmptyEntry = upCtrlValueInfoIsEmptyEntry;
upCtrlValueInfo.prototype.isEmptyEntryAll = upCtrlValueInfoIsEmptyEntryAll;
upCtrlValueInfo.prototype.isLocal = upCtrlValueInfoIsLocal;
upCtrlValueInfo.prototype.isStuffed = upCtrlValueInfoIsStuffed;
upCtrlValueInfo.prototype.getUpObjectByRenderingType = upCtrlValueInfoGetUpObjectByRenderingType;
upCtrlValueInfo.prototype.getStuffedValues = upCtrlValueInfoGetStuffedValues;
upCtrlValueInfo.prototype.getStuffedDbValues = upCtrlValueInfoGetStuffedDbValues;

function upCtrlValueInfoHasValidValue() {
    var ret;
    if (typeof this.oUpControl === "object" && this.oUpControl != null) {
        if (typeof this.oUpControl.validateValueDo == "function") {
            ret = this.oUpControl.validateValueDo();
        } else if (typeof this.oUpControl.isDulyCompleted == "function") {
            ret = this.oUpControl.isDulyCompleted();
        }
        if (typeof ret !== "boolean") {
            switch (this.oUpControl.upType) {
                case "upIntegerControl":
                case "upFloatControl":
                case "upCurrencyControl":
                case "upDateControl":
                case "upTimeControl":
                case "upDateTimeControl":
                    /**
                     * Die Validierung der Kontrolle sollte korrekterweise über UP.checkValidity laufen.
                     * Diese Methode läuft allerdings async was im Zusammenspiel mit der Dependency leider zum aktuellen Zeitpunkt nicht funktioniert.
                     */
                    ret = this.oUpControl.oHtml ? checkValidity(this.oUpControl.oHtml) : true;
                    break;
                default:
                    ret = true;
            }
        }
    } else {
        ret = true;
    }
    return ret;
}

function checkValidity(element) {
    return element.willValidate ? element.validity.valid : element.getAttribute("aria-invalid") !== "true";
}

/**
 * Returns control value
 */
function upCtrlValueInfoGetValue() {
    return this.value;
}

/**
 * Returns if control value is stuffed and contains multiple entries
 *
 * @return {boolean}
 */
function upCtrlValueInfoIsStuffed() {
    if (this.m_bStuffed == null) {
        this.m_bStuffed =
            this.hasValue() &&
            typeof this.value == "object" &&
            this.value.bStuffed &&
            Helper.undoCharStuffing(this.value).length > 1;
    }
    return this.m_bStuffed;
}
/**
 * Returns multiple control values as array
 * @return {Array}
 */
function upCtrlValueInfoGetStuffedValues() {
    if (this.m_aStuffedValue == null) {
        if (this.isStuffed()) {
            this.m_aStuffedValue = Helper.undoCharStuffing(this.value);
        } else if (this.hasValue()) {
            this.m_aStuffedValue = [this.value];
        }
    }
    return this.m_aStuffedValue;
}
/**
 * Returns multiple control values as array (db formated)
 * @return {Array}
 */
function upCtrlValueInfoGetStuffedDbValues() {
    var a = this.getStuffedValues();
    if (a && a.length > 0) {
        var r = new Array(a.length);
        for (var i = 0; i < a.length; i++) {
            r[i] = Helper.htmlEncode(a[i], false, false);
        }
        return r;
    } else {
        return false;
    }
}
/**
 * Returns upObject fitting to control rendering type
 * @return {object}
 */
function upCtrlValueInfoGetUpObjectByRenderingType() {
    if (this.m_oUpByRenderingType == null) {
        this.m_oUpByRenderingType = this.oUpControl;
        // besitzt Kontrolle einen speziellen RenderingType, dann diesen bevorzugen
        if (typeof this.oUpControl.renderingType == "string" && this.oUpControl.oHtml) {
            switch (this.oUpControl.renderingType.toLowerCase()) {
                case "integer":
                    this.m_oUpByRenderingType = new UpIntegerControl(this.oUpControl.oHtml);
                    break;
                case "float":
                    this.m_oUpByRenderingType = new UpFloatControl(this.oUpControl.oHtml);
                    break;
                case "currency":
                    this.m_oUpByRenderingType = new UpCurrencyControl(this.oUpControl.oHtml);
                    break;
                case "date":
                    this.m_oUpByRenderingType = new UpDateEditControl(this.oUpControl.oHtml);
                    break;
                case "time":
                    this.m_oUpByRenderingType = new UpTimeEditControl(this.oUpControl.oHtml);
                    break;
                case "datetime":
                    this.m_oUpByRenderingType = new UpDateTimeEditControl(this.oUpControl.oHtml);
                    break;
            }
        }
    }
    return this.m_oUpByRenderingType;
}
/**
 * Returns control value (db filter formated)
 *
 * @return {string}
 */
function upCtrlValueInfoGetDbValue() {
    if (this.m_strDbValue === null) {
        if (this.oUpControl.upType === "upRangeFilterControl") {
            var valAsObject =
                this.strAttr === "to" ? this.oUpControl.valueToAsObject : this.oUpControl.valueFromAsObject;

            if (valAsObject instanceof Date) {
                // to-date should includes the complete day until 24 o'clock
                if (this.strAttr === "to" && this.oUpControl.rangeType === "upDateControl") {
                    valAsObject = new Date(valAsObject.setDate(valAsObject.getDate() + 1));
                }
                this.m_strDbValue = Helper.xmlEncode(
                    new ix.text.SimpleDateFormat("yyyy-MM-dd HH:mm:ss").format(valAsObject)
                );
            } else if (valAsObject instanceof Number || typeof valAsObject === "number") {
                this.m_strDbValue = valAsObject;
            }
        } else if (this.value) {
            var upCtrl = this.getUpObjectByRenderingType();
            //this.m_strDbValue = Helper.htmlEncode(this.value, false, false);
            if (this.value instanceof Date) {
                this.m_strDbValue = Helper.xmlEncode(
                    new ix.text.SimpleDateFormat("yyyy-MM-dd HH:mm:ss").format(this.value)
                );
            } else if (typeof this.value !== "boolean") {
                this.m_strDbValue = Helper.xmlEncode(this.value);
            } else {
                this.m_strDbValue = false;
            }
            if (this.strAttr !== "key") {
                switch (upCtrl.upType) {
                    case "upIntegerControl":
                    case "upFloatControl":
                    case "upCurrencyControl":
                        this.m_strDbValue = upCtrl.toJSNumber(this.value);
                        break;
                    case "upDateControl":
                    case "upTimeControl":
                    case "upDateTimeControl":
                        var l_dt = upCtrl.setDateObjectFromLocal(this.value);
                        if (l_dt) {
                            this.m_strDbValue = upCtrl.toISODateTimeString(l_dt);
                        }
                        break;
                    case "upFilterCtrlCalendar":
                        // Js-Date-Object
                        if (this.value && typeof this.value == "object") {
                            this.m_strDbValue = new ix.text.SimpleDateFormat("yyyy-MM-dd HH:mm:ss").format(this.value);
                        }
                        break;
                    case "upDropdownControl":
                    case "upListboxControl":
                        // @todo Ist es richtig, dass hier getRawDisplayValue und nicht getRawValue?
                        // unformatierten Anzeigetext ermitteln (ab ix7 gibt es keinen renderingType mehr)
                        var rawDisplayValue = upCtrl.getRawDisplayValue();
                        if (this.strAttr == "value" && rawDisplayValue !== null) {
                            if ($.isArray(rawDisplayValue)) {
                                this.m_strDbValue = Helper.xmlEncode(rawDisplayValue[0]);
                            } else {
                                this.m_strDbValue = Helper.xmlEncode(rawDisplayValue);
                            }
                        }
                    /*
                    if (this.strAttr == "value" && jqSelectedOptions.size() > 0) {
                        this.m_strDbValue = $(jqSelectedOptions.get(0)).data("rawdisplayvalue");
                    }
                    data-key-map
                    */
                }
            }
        } else {
            this.m_strDbValue = false;
        }
    }

    return this.m_strDbValue;
}
/**
 * Returns if value is local formated (in user timezone)
 *
 * @return {boolean}
 */
function upCtrlValueInfoIsLocal() {
    if (this.m_bLocal == null) {
        this.m_bLocal = false;
        if (this.value) {
            var upCtrl = this.getUpObjectByRenderingType();
            if (this.strAttr != "key") {
                var upType = upCtrl.upType === "upRangeFilterControl" ? upCtrl.rangeType : upCtrl.upType;
                switch (upType) {
                    case "upDateControl":
                    case "upTimeControl":
                    case "upDateTimeControl":
                    case "upFilterCtrlCalendar":
                        this.m_bLocal = true;
                        break;
                }
            }
        }
    }
    return this.m_bLocal;
}
/**
 * Returns if value is empty or emty entry (dropdown)
 *
 * @return {boolean}
 */
function upCtrlValueInfoIsEmptyEntry() {
    if (this.m_bEmptyEntry == null) {
        this.m_bEmptyEntry = false;
        if (this.value) {
            switch (this.oUpControl.upType) {
                case "upFilterCtrlAlphaindex":
                    this.m_bEmptyEntry = this.value == "*" || this.value === undefined;
                    break;
                case "upFilterCtrlCalendar":
                    this.m_bEmptyEntry = !this.value;
                    break;
                case "upDropdownControl":
                case "upListboxControl":
                    var oHtml = this.oUpControl.oHtml;
                    var $option = $(oHtml).find(":selected");
                    this.m_bEmptyEntry = $option.attr("data-emptyentry") == "1";
                    break;
                default:
                    this.m_bEmptyEntry = this.value == "";
            }
        }
    }
    return this.m_bEmptyEntry;
}
/**
 * Returns if value is placeholder entry for "all entries", e.g. alphaindex *
 *
 * @return {boolean}
 */
function upCtrlValueInfoIsEmptyEntryAll() {
    if (this.m_bEmptyEntryAll == null) {
        this.m_bEmptyEntryAll = false;
        if (this.value && this.isEmptyEntry()) {
            switch (this.oUpControl.upType) {
                case "upFilterCtrlAlphaindex":
                    this.m_bEmptyEntryAll = this.value == "*";
            }
        }
    }
    return this.m_bEmptyEntryAll;
}
/**
 * Returns if control has value or not (empty or empty entry)
 *
 * @return {boolean}
 */
function upCtrlValueInfoHasValue() {
    if (this.m_bHasValue == null) {
        switch (this.oUpControl.upType) {
            case "upFilterCtrlCalendar":
                this.m_bHasValue = typeof this.value == "object" && this.value != null;
                break;
            default:
                if (
                    this.value &&
                    (typeof this.value == "string" || typeof this.value == "object") &&
                    this.value.length > 0
                ) {
                    this.m_bHasValue =
                        this.oUpControl == null ||
                        typeof this.oUpControl.validateValueDo == "undefined" ||
                        this.oUpControl.validateValueDo();
                } else {
                    this.m_bHasValue = false;
                }
        }
    }
    return this.m_bHasValue;
}
