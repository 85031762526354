/**
 * @file Prototypes _upEventListenerContainer and EventManager.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/*
    globals EventManager: true
*/
/*
    exported EventManager
*/
/**
 * @class
 * @private
 */
function _upEventListenerContainer(p_oWin) {
    this.oWin = p_oWin || self;
    this.oListeners = {};
}
_upEventListenerContainer.prototype.add = upListenerContainerAdd;
_upEventListenerContainer.prototype.remove = upListenerContainerRemove;
_upEventListenerContainer.prototype.removeAll = upListenerContainerRemoveAll;
/**
 * @private
 */
function upListenerContainerAdd(p_strName, p_oListener) {
    this.oListeners[p_strName] = p_oListener;
}
/**
 * @private
 */
function upListenerContainerRemoveAll() {
    for (var listenerId in this.oListeners) {
        if (this.oListeners[listenerId] != null) {
            this.oWin.EventManager.removeListener(this.oListeners[listenerId]);
        }
    }
    this.oListeners = {};
}
/**
 * @private
 */
function upListenerContainerRemove(p_strListenerId) {
    for (var listenerId in this.oListeners) {
        if (listenerId == p_strListenerId) {
            this.oWin.EventManager.removeListener(this.oListeners[listenerId]);
            this.oListeners[listenerId] = null;
            break;
        }
    }
}
/**
 * @class
 * This prototype manages events. A static instance EventManager is always available.
 * @private
 */
function upEventManager() {
    this._eventListeners = new Array();

    this.evtHash = [];
    this.ieGetUniqueID = function (_elem) {
        if (_elem === window) {
            return "theWindow";
        } else if (_elem === document) {
            return "theDocument";
        } else {
            return _elem.uniqueID;
        }
    };
}
upEventManager.prototype.addListener = EventManagerAddListener;
upEventManager.prototype.removeListener = EventManagerRemoveListener;
upEventManager.prototype.unregisterAll = EventManager_UnregisterAll;
upEventManager.prototype._removeListener = EventManager_RemoveListener;
upEventManager.prototype._removeListenerByKey = EventManager_RemoveListenerByKey;
upEventManager.prototype._removeListenerByObj = EventManager_RemoveListenerByObj;
upEventManager.prototype._checkEvent = EventManagerCheckEvent;
upEventManager.prototype.hasListener = EventManagerHasListener;
upEventManager.prototype.fireEvent = EventManagerFireEvent;

EventManager = new upEventManager();

function EventManagerFireEvent(instance, eventName) {
    var evt;
    if (document.createEvent) {
        evt = document.createEvent("Events");
        evt.initEvent(eventName, true, true);
        instance.dispatchEvent(evt);
    } else if (document.createEventObject) {
        // IE window Hack
        evt = document.createEventObject();
        try {
            instance.fireEvent("on" + eventName, evt);
        } catch (e) {
            console.trace(e);
        }
    }
}

function EventManagerHasListener(instance, eventName) {
    var l_bEl = typeof instance.tagName == "string";
    var l_bRet = false;
    if (l_bEl) {
        l_bRet = typeof eval("document.getElementById('" + instance.id + "').on" + eventName) == "function";
    } else {
        l_bRet = typeof (instance + ".on" + eventName) == "function";
    }
    if (!l_bRet) {
        for (var i = 0; i < this._eventListeners.length; i++) {
            if (
                this._eventListeners[i].eventListener.name == eventName &&
                (!l_bEl || instance.id == this._eventListeners[i].eventListener.instance.id)
            ) {
                l_bRet = true;
                break;
            }
        }
    }
    return l_bRet;
}

//------------------------------------------------------------
// Eventlistener registrieren
// Parameter: instance - (object) Referenz auf ein Html-Objekt
//            eventName - (string) Eventname ohne das fuehrende on
//            listener - (function oder string) - Anweisung die bei Eintreffen des Events ausgefuehrt werden soll
//              (optional) exclusive - True: bestehende Events werden ueberschrieben
//              (optional) key - ListenerKey mit dem sich der Listener wieder entfernen laesst
// Beispiel   var listenerXYZ = EventManager.addListener(window, "load", "alert('test')");
//            var listenerXYZ = EventManager.addListener(window, "load", functionxy);
//              EventManager.removeListener(listenerXYZ);
//                oder
//              EventManager.addListener(window, "load", "alert('test')", "xyz");
//              EventManager.removeListener("xyz");
//------------------------------------------------------------
function EventManagerAddListener(instance, eventName, listener, exclusive, key) {
    if (arguments.length < 3 || !instance || typeof instance != "object" || typeof eventName != "string") {
        return false;
    }

    this.removeListener(instance, eventName, listener);

    var regEvent = true; //(arguments.length>=5);
    var listenerFn = this._checkEvent(instance, eventName, listener);
    if (listenerFn == null) {
        return false;
    }

    var ret = {
        instance: instance,
        name: eventName,
        listener: listenerFn,
    };

    $(instance).on(eventName, listenerFn);

    if (regEvent) {
        this._eventListeners.push({
            key: key,
            eventListener: ret,
        });
    }

    return ret;
}
// moegliche Parameter:
//  key (eventListener Key)
//  eventListenerObjekt (returnValue von AddListener)
//  instance, eventName, listener
function EventManagerRemoveListener() {
    if (arguments.length == 1 && typeof arguments[0] == "string") {
        this._removeListenerByKey(arguments[0]);
    } else if (arguments.length == 1 && typeof arguments[0] == "object" && arguments[0] != null) {
        this._removeListenerByObj(arguments[0]);
    } else if (arguments.length >= 3) {
        this._removeListener(arguments[0], arguments[1], arguments[2]);
    }
}

function EventManager_RemoveListener(instance, eventName, listener) {
    if (arguments.length < 3 || !instance || typeof instance != "object" || typeof eventName != "string") {
        return false;
    }
    var listenerFn = this._checkEvent(instance, eventName, listener);
    if (listenerFn == null) {
        return false;
    }
    $(instance).off(eventName, listenerFn);
}

function EventManager_RemoveListenerByObj(eventListener) {
    this._removeListener(eventListener.instance, eventListener.name, eventListener.listener);
    eventListener = null;
}

function EventManager_RemoveListenerByKey(key) {
    for (var i = 0; i < this._eventListeners.length; i++) {
        if (this._eventListeners[i].key == key) {
            this._removeListener(
                this._eventListeners[i].eventListener.instance,
                this._eventListeners[i].eventListener.name,
                this._eventListeners[i].eventListener.listener
            );
            this._eventListeners.filter(function (el, j) {
                return j !== i;
            });
            break;
        }
    }
}

function EventManagerCheckEvent(instance, eventName, f) {
    return typeof f == "string" ? new Function("e", f) : typeof f == "function" || typeof f == "object" ? f : null;
}

function EventManager_UnregisterAll() {
    while (this._eventListeners.length > 0) {
        removeEventListener(this._eventListeners[0].key);
    }
}
/*
 * Drag&Drop
 * oDomElement.onmousedown = testEl.ontouchstart = GestureListener.addDrag;
 *
 * based on ppk
 */

upEventManager.prototype.addDrag = function (e, DragMove, DragEnd) {
    if (arguments.length < 2) {
        DragMove = moveDrag;
    }

    if (!e) {
        e = window.event;
    }

    var oDomElement = e.target || e.srcElement;
    if (e.type === "touchstart") {
        oDomElement.onmousedown = null;
        oDomElement.ontouchmove = DragMove;
        oDomElement.ontouchend = function () {
            oDomElement.ontouchmove = null;
            oDomElement.ontouchend = null;
            if (typeof DragEnd != "undefined") {
                DragEnd();
            }
        };
    } else {
        document.onmousemove = DragMove;
        document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;
            if (typeof DragEnd != "undefined") {
                DragEnd();
            }
        };
    }

    var pos = [oDomElement.offsetLeft, oDomElement.offsetTop];
    var origin = getCoors(e);
    return false; // cancels scrolling

    function moveDrag(e) {
        var currentPos = getCoors(e);
        var deltaX = currentPos[0] - origin[0];
        var deltaY = currentPos[1] - origin[1];
        oDomElement.style.left = pos[0] + deltaX + "px";
        oDomElement.style.top = pos[1] + deltaY + "px";
    }

    function getCoors(e) {
        var coors = [];
        if (e.touches && e.touches.length) {
            // iPhone
            coors[0] = e.touches[0].clientX;
            coors[1] = e.touches[0].clientY;
        } else {
            // all others
            coors[0] = e.clientX;
            coors[1] = e.clientY;
        }
        return coors;
    }
};

/**
 * @class
 * GestureListener
 *
 */
var GestureListener = new Object();

/**
 * You can identify a swipe gesture as follows:
 * 1. Begin gesture if you receive a touchstart event containing one target touch.
 * 2. Abort gesture if, at any time, you receive an event with >1 touches.
 * 3. Continue gesture if you receive a touchmove event mostly in the x-direction.
 * 4. Abort gesture if you receive a touchmove event mostly the y-direction.
 * 5. End gesture if you receive a touchend event.
 *
 * based on Dave Dunkins script
 *
 * use only in case of loading a new FuncPart
 */
GestureListener.addSwipe = function (oDomElement, fnCallback) {
    if (document.documentElement === undefined || !("ontouchstart" in document.documentElement)) {
        return;
    }
    if (typeof oDomElement == "undefined" || oDomElement == null) {
        return;
    }
    var startX;
    var startY;
    var deltaX;
    var direction;

    function onTouchStart(e) {
        if (e.touches.length == 1) {
            startX = e.touches[0].pageX;
            startY = e.touches[0].pageY;
            oDomElement.addEventListener("touchmove", onTouchMove, false);
            oDomElement.addEventListener("touchend", onTouchEnd, false);
        }
    }

    function cancelTouch() {
        oDomElement.removeEventListener("touchmove", onTouchMove);
        oDomElement.removeEventListener("touchend", onTouchEnd);
        startX = null;
        startY = null;
        direction = null;
    }

    function onTouchMove(e) {
        if (e.touches.length > 1) {
            cancelTouch();
        } else {
            deltaX = e.touches[0].pageX - startX;
            var deltaY = e.touches[0].pageY - startY;
            if (direction == null) {
                direction = deltaX;
            } else if (Math.abs(deltaY) < 60 && ((direction < 0 && deltaX < 0) || (direction > 0 && deltaX > 0))) {
                e.preventDefault();
            } else {
                cancelTouch();
            }
        }
    }
    // eslint-disable-next-line no-unused-vars
    function onTouchEnd(e) {
        cancelTouch();
        if (Math.abs(deltaX) > 50) {
            fnCallback({
                target: oDomElement,
                direction: deltaX > 0 ? "right" : "left",
            });
        }
    }
    oDomElement.ontouchstart = onTouchStart;
};
