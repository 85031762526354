/**
 * @file upDurationControl
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals upTextMainControl, Browser */

/**
 * @class
 * @ignore
 */
function upDurationControl() {
    this.upType = "upDurationControl";
    this.UNIT_MINUTE = ["MIN", "MINUTE", "MINUTES", "MINUTEN"];
    this.UNIT_HOUR = ["H", "S", "HOUR", "STUNDE", "HOURS", "STUNDEN"];
    this.UNIT_DAY = ["D", "T", "DAY", "TAG", "DAYS", "TAGE"];
    this.UNIT_WEEK = ["W", "WEEK", "WOCHE", "WEEKS", "WOCHEN"];
    this.UNIT_MONTH = ["M", "MONTH", "MONAT", "MONTHS", "MONATE"];
    this.UNIT_MINUTE[this.UNIT_MINUTE.length] = self.oUp.oMessage.CAL_MINUTE;
    this.UNIT_MINUTE[this.UNIT_MINUTE.length] = self.oUp.oMessage.CAL_MINUTES;
    this.UNIT_HOUR[this.UNIT_HOUR.length] = self.oUp.oMessage.CAL_HOUR;
    this.UNIT_HOUR[this.UNIT_HOUR.length] = self.oUp.oMessage.CAL_HOURS;
    this.UNIT_DAY[this.UNIT_DAY.length] = self.oUp.oMessage.CAL_DAY;
    this.UNIT_DAY[this.UNIT_DAY.length] = self.oUp.oMessage.CAL_DAY;
    this.UNIT_WEEK[this.UNIT_WEEK.length] = self.oUp.oMessage.CAL_WEEK;
    this.UNIT_WEEK[this.UNIT_WEEK.length] = self.oUp.oMessage.CAL_WEEKS;
    this.UNIT_MONTH[this.UNIT_MONTH.length] = self.oUp.oMessage.CAL_MONTH;
    this.UNIT_MONTH[this.UNIT_MONTH.length] = self.oUp.oMessage.CAL_MONTHS;
}
upDurationControl.prototype = new upTextMainControl();

/**
 * Get value of a duration in milliseconds.
 * @ignore
 * @return {Number} Duration in milliseconds.
 */
upDurationControl.prototype.getDurationInMilliseconds = function () {
    var milliseconds = -1;
    var pattern = "^([0-9]{1,6})([a-zA-Z]{1,7})$";
    var reg = new RegExp(pattern, "i");
    var input = Browser.getValue(this);
    var number;
    var unit;

    input = input.replace(/\s/, "").replace(/\(/, "").replace(/\)/, "");
    if (reg.test(input)) {
        var aUserInput = reg.exec(input);
        if (aUserInput.length === 3) {
            number = aUserInput[1];
            unit = aUserInput[2];
            if (number !== "" && unit !== "") {
                if (this._arrayContainsVal(this.UNIT_MINUTE, unit)) {
                    milliseconds = 60000;
                } else if (this._arrayContainsVal(this.UNIT_HOUR, unit)) {
                    milliseconds = 3600000;
                } else if (this._arrayContainsVal(this.UNIT_DAY, unit)) {
                    milliseconds = 86400000;
                } else if (this._arrayContainsVal(this.UNIT_WEEK, unit)) {
                    milliseconds = 604800000;
                } else if (this._arrayContainsVal(this.UNIT_MONTH, unit)) {
                    milliseconds = 2592000000;
                }
                if (milliseconds !== -1) {
                    return milliseconds * number;
                }
            }
        }
    }
    return false;
};

upDurationControl.prototype.format = function () {
    var milliseconds = -1;
    var pattern = "^([0-9]{1,6})([a-zA-Z]{1,7})$";
    var reg = new RegExp(pattern, "i");
    var input = Browser.getValue(this);
    var number;
    var unit;
    var unitFormated;

    input = input.replace(/\s/, "").replace(/\(/, "").replace(/\)/, "");
    if (reg.test(input)) {
        var aUserInput = reg.exec(input);
        if (aUserInput.length === 3) {
            number = aUserInput[1];
            unit = aUserInput[2];
            if (number !== "" && unit !== "") {
                if (this._arrayContainsVal(this.UNIT_MINUTE, unit)) {
                    milliseconds = 60000;
                    if (number > 1) {
                        unitFormated = self.oUp.oMessage.CAL_MINUTES;
                    } else {
                        unitFormated = self.oUp.oMessage.CAL_MINUTE;
                    }
                    Browser.setValue(this, number + " " + unitFormated);
                } else if (this._arrayContainsVal(this.UNIT_HOUR, unit)) {
                    milliseconds = 3600000;
                    if (number > 1) {
                        unitFormated = self.oUp.oMessage.CAL_HOURS;
                    } else {
                        unitFormated = self.oUp.oMessage.CAL_HOUR;
                    }
                    Browser.setValue(this, number + " " + unitFormated);
                } else if (this._arrayContainsVal(this.UNIT_DAY, unit)) {
                    milliseconds = 86400000;
                    if (number > 1) {
                        unitFormated = self.oUp.oMessage.CAL_DAYS;
                    } else {
                        unitFormated = self.oUp.oMessage.CAL_DAY;
                    }
                    Browser.setValue(this, number + " " + unitFormated);
                } else if (this._arrayContainsVal(this.UNIT_WEEK, unit)) {
                    milliseconds = 604800000;
                    if (number > 1) {
                        unitFormated = self.oUp.oMessage.CAL_WEEKS;
                    } else {
                        unitFormated = self.oUp.oMessage.CAL_WEEK;
                    }
                    Browser.setValue(this, number + " " + unitFormated);
                } else if (this._arrayContainsVal(this.UNIT_MONTH, unit)) {
                    milliseconds = 2592000000;
                    if (number > 1) {
                        unitFormated = self.oUp.oMessage.CAL_MONTHS;
                    } else {
                        unitFormated = self.oUp.oMessage.CAL_MONTH;
                    }
                    Browser.setValue(this, number + " " + unitFormated);
                }
                if (milliseconds !== -1) {
                    return milliseconds * number;
                }
            }
        }
    }
    return false;
};

upDurationControl.prototype.validate = function () {
    var input = new String(Browser.getValue(this));
    var pattern = "^([0-9]{1,6})([a-zA-Z]{1,7})$";
    var reg = new RegExp(pattern, "i");
    var number;
    var unit;

    input = input.replace(/\s/, "").replace(/\(/, "").replace(/\)/, "");
    if (reg.test(input)) {
        var aUserInput = reg.exec(input);
        if (aUserInput.length === 3) {
            number = aUserInput[1];
            unit = aUserInput[2];
            if (number !== "" && unit !== "") {
                if (this._arrayContainsVal(this.UNIT_MINUTE, unit)) {
                    return true;
                } else if (this._arrayContainsVal(this.UNIT_HOUR, unit)) {
                    return true;
                } else if (this._arrayContainsVal(this.UNIT_DAY, unit)) {
                    return true;
                } else if (this._arrayContainsVal(this.UNIT_WEEK, unit)) {
                    return true;
                } else if (this._arrayContainsVal(this.UNIT_MONTH, unit)) {
                    return true;
                }
            }
        }
    }
    return false;
};

upDurationControl.prototype._arrayContainsVal = function (array, val) {
    if (typeof array === "object" && val) {
        if (typeof val === "string") {
            val = val.toUpperCase();
        }
        // eslint-disable-next-line no-unused-vars
        var list;
        for (var i = 0; i < array.length; i++) {
            if (typeof array[i] === "string") {
                list = array[i].toUpperCase();
            } else {
                // eslint-disable-next-line no-unused-vars
                list = array[i];
            }
            if (array[i] === val) {
                return true;
            }
        }
    }
    return false;
};
