/* globals _i18n, oMessage:true */

/**
 * @deprecated
 */
oMessage = _i18n;

/**
 * @deprecated
 */
if (typeof self.oUp !== "undefined") {
    self.oUp.oMessage = oMessage;
}
