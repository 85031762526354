/**
 * @file Global core functions and constants.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals debug, ix, isHTMLElement, isSVGElement */
/* exported handleApp2MenuGuid, getElement, setFocus */

/**
 * @class InputRequiredException
 * @augments {Error}
 * @param {String} [message="Please fill out this field."] The message, to be displayed.
 * @description Exception, which will be thrown, if input is missing.
 */
function InputRequiredException(message) {
    this.name = "InputRequiredException";
    this.message = message || "Please fill out this field.";
    this.stack = new Error().stack;
}
InputRequiredException.prototype = Object.create(Error.prototype);
InputRequiredException.prototype.constructor = InputRequiredException;

/**
 * @class NoSuchElementException
 * @augments {Error}
 * @param {String} [message="Could not find a matching element."] The message, to be displayed.
 * @description Exception, which will be thrown, if no element matches.
 */
function NoSuchElementException(message) {
    this.name = "NoSuchElementException";
    this.message = message || "Could not find a matching element.";
    this.stack = new Error().stack;
}
NoSuchElementException.prototype = Object.create(Error.prototype);
NoSuchElementException.prototype.constructor = NoSuchElementException;

/**
 * @class MissingHTMLElementException
 * @augments {Error}
 * @param {String} [message="There is no HTMLElement connected."] The message, to be displayed.
 * @description Exception, which will be thrown, if no HTMLElement is attached.
 */
function MissingHTMLElementException(message) {
    this.name = "MissingHTMLElementException";
    this.message = message || "There is no HTMLElement connected.";
    this.stack = new Error().stack;
}
MissingHTMLElementException.prototype = Object.create(Error.prototype);
MissingHTMLElementException.prototype.constructor = MissingHTMLElementException;

/**
 * @deprecated Will be removed within next few days.
 * @param {Object} [value] Object to be inspected.
 * @param {String} [type="log"] Possible values: "log","debug","info","warn","error"
 * @description Write text into console if debug mode ist enabled (DEBUG = true)
 */
window.debug = function (value) {
    if (typeof window.DEBUG === "boolean" && window.DEBUG === true) {
        console.log(value);
    }
};

/**
 * @deprecated Use it and I'll kick ya!
 * @param {Object} [value] Object to be inspected.
 * @param {String} [type="log"] Possible values: "log","debug","info","warn","error"
 * @description Write text into browsers console.
 */
window.log = function (value, type) {
    // basicly Johne Resigs idea
    var _type =
        {
            log: "log",
            debug: "debug",
            info: "info",
            warn: "warn",
            error: "error",
        }[type] || "log";
    console[_type].apply(console, [value]);
};

window.synchronousDeferred = function (aFunctions, oContext, param) {
    var syncDeferred = $.Deferred();
    var execSynchronousDeferred = function (functions, context, syncDeferred, param) {
        if (functions.length > 0) {
            try {
                var retVal;
                if (context === undefined && param === undefined) {
                    retVal = functions[0]();
                } else {
                    retVal = functions[0].call(context, param);
                }

                $.when(retVal)
                    .done(function () {
                        //******************************************************************
                        // Hinweis:
                        // Ab IX7 muss zum Abbrechen der Eventverarbeitung false zurückgegeben werden (oder ein Deferred-Objekt das rejected wird)
                        // Aus Kompatibilitätsgründen zu IX6 müsste eigentlich auch retVal === undefined zum Abbruch führen.
                        // Falls dies nicht unbedingt nötig ist, sollte aber darauf verzichtet werden und der
                        // Benutzer in seiner Funktion return false verwenden
                        //******************************************************************
                        if (typeof retVal === "boolean" && !retVal) {
                            syncDeferred.reject();
                        } else {
                            functions.splice(0, 1);
                            execSynchronousDeferred(functions, context, syncDeferred, param);
                        }
                    })
                    .fail(function () {
                        syncDeferred.reject();
                    });
            } catch (e) {
                debug("execSynchronousDeferred: An internal error occurred during processing Javascript Function:");
                if (functions[0]) {
                    debug(functions[0]);
                }
                debug(e);
                syncDeferred.reject();
            }
        } else {
            syncDeferred.resolve();
        }
    };

    if (oContext === undefined) {
        oContext = this;
    }
    if ($.isArray(aFunctions) && aFunctions.length > 0) {
        execSynchronousDeferred($.extend([], aFunctions), oContext, syncDeferred, param);
    } else {
        syncDeferred.resolve();
    }

    return syncDeferred.promise();
};

/**
 * @ignore
 * @deprecated
 * @param {HTMLElement} element
 * @param {Boolean} [isInwards]
 * @return {HTMLFormElement}
 */
window.getFormObjectByElement = function (element, isInwards) {
    var elementForm;

    if (isInwards) {
        elementForm = ix.util.getAncestorForm(element);
    } else {
        elementForm = ix.util.getForm(element);
    }

    if (
        (typeof element !== "undefined" && element !== null && typeof elementForm === "undefined") ||
        elementForm === null
    ) {
        if (!element) {
            debug("Methode 'getFormObjectByElement' could not detect form reference. Element is null", "info");
        } else if (element === "undefined" || element === undefined) {
            debug("Methode 'getFormObjectByElement' could not detect form reference. Element is undefined", "info");
        } else {
            debug("Methode 'getFormObjectByElement' could not detect form reference. Element: " + element.id, "info");
        }
    }
    return elementForm;
};

window.createMailInputFields = function (container) {
    var form = ix.util.getForm(container);
    var selectorTest;
    var addFormRef;

    if (typeof form === "object" && form !== null) {
        selectorTest = 'input[name="fr_MailInfo"][form="' + form.id + '"]';
        addFormRef = ' form="' + form.id + '"';
    } else {
        selectorTest = 'input[name="fr_MailInfo"]';
        addFormRef = "";
        debug("Methode 'createMailInputFields' could not detect form reference. ", "warn");
        debug(container);
    }

    if (typeof container === "object" && $(container).find(selectorTest).length === 0) {
        $(container).append('<input type="hidden" name="fr_MailInfo" value=""' + addFormRef + "/>");
        $(container).append('<input type="hidden" name="fr_Rcpt_To" value=""' + addFormRef + "/>");
        $(container).append('<input type="hidden" name="fr_Rcpt_Cc" value=""' + addFormRef + "/>");
        $(container).append('<input type="hidden" name="fr_Rcpt_Bcc" value=""' + addFormRef + "/>");
    }
};

/**
 * @ignore
 * @typedef {SetValueResultInfo~Settings} [settings={}] The settings to be used.
 * @property {String} status=failed Values: failed | successful
 * @property {Boolean} [hasChanged=false] Value was changed or not.
 * @property {Boolean} [error=undefined] Error object if status is failed.
 */
/**
 * @ignore
 * @class SetValueResultInfo
 * @param {SetValueResultInfo~Settings} settings
 * @description Exception, which will be thrown, if essential parameters are missing.
 * set to ignore due to documentation seems to requires a review
 */
window.SetValueResultInfo = function (settings) {
    this.status = "failed"; // failed
    this.hasChanged = false; // false,
    this.error = undefined; // status==failed -> Error

    $.extend(this, settings);
};

/**
 * @ignore
 * @param {String} appGuid
 * @param {String} menuGuid
 * @return {String}
 * @description MenuGuid <> AppGuid Mapping, App can be available multiple times in a menu.
 */
function handleApp2MenuGuid(appGuid, menuGuid) {
    if ($(window).data("mapApp2Menu") === undefined) {
        $(window).data("mapApp2Menu", {});
    }
    if (typeof appGuid === "string" && appGuid !== "-" && typeof menuGuid === "string" && menuGuid !== "-") {
        var map = $(window).data("mapApp2Menu");
        map[appGuid] = menuGuid;
        $(window).data("mapApp2Menu", map);
    } else if (typeof appGuid === "string" && appGuid !== "-" && (typeof menuGuid !== "string" || menuGuid === "-")) {
        if (typeof $(window).data("mapApp2Menu")[appGuid] !== "undefined") {
            menuGuid = $(window).data("mapApp2Menu")[appGuid];
        }
    }
    return menuGuid;
}

/**
 * @ignore
 * @deprecated Since version 8000, use ix.util.getElement("GUID") or ix.util.getUp("GUID")
 * @param {String} guid
 * @param {HTMLElement|SVGElement} [context]
 * @return {HTMLElement|Boolean}
 */
function getElement(guid, context) {
    var settings = {};
    var result;

    if (context !== undefined) {
        if (isHTMLElement(context)) {
            settings.context = context;
        } else if (isHTMLElement(context.element)) {
            settings.context = context.element;
        } else if (isHTMLElement(context.oHtml)) {
            settings.context = context.oHtml;
        } else if (isSVGElement(context)) {
            settings.context = context.oHtml;
        }
    }
    result = ix.util.getElement(guid, settings);
    return result.length === 0 ? false : result[0];
}
