/* globals HelperMain */

/**
 * @file Helper-methods for events.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/**
    @methodOf HelperMain
    @private
    @param {jQuery-Selector} target
    @param {function} callbackActive
    @param {function} callbackInactive
    @returns {Boolean} status
    @description
    disable onclick action for target element
*/
HelperMain.prototype.disableClick = function (target, callbackActive, callbackInactive) {
    $(target).pushEventStack("click", {
        callbackActive: callbackActive,
        callbackInactive: callbackInactive,
        key: "disable-click",
        target: target,
        activate: function () {
            this.target.onclick = function () {
                return false;
            };
            this.callbackActive();
        },
        deactivate: function () {
            this.target.onclick = null;
            this.callbackInactive();
        },
    });
    return true;
};
/**
    @methodOf HelperMain
    @private
    @param {jQuery-Selector} target
    @description
    enable onclick action for target element
*/
HelperMain.prototype.enableClick = function (target) {
    $(target).popEventStack("click", "disable-click", false);
};
/**
    @memberof HelperMain
    @Namespace HelperMain.eventhandlerstack
    @private
    @description
    EventHandlerStack for special events
*/
HelperMain.prototype.eventHandlerStack = {
    /**
        @methodOf HelperMain.eventhandlerstack
        @private
        @param {HTMLElement|jQuery-Object} target
        @param {String} eventType
        @param {Object} settings
        @description
        push a new event to the stack
    */
    push: function (target, eventType, settings) {
        var key;
        if (settings !== undefined) {
            key = settings.key;
        }

        var namespace;
        if (eventType.indexOf(".") >= 0) {
            var aS = eventType.split(".");
            namespace = aS[1];
            eventType = aS[0];
        }
        var eventTypeNS = namespace !== undefined ? eventType + "." + namespace : eventType;

        var handlerEntry = {
            target: target,
            namespace: namespace,
            eventType: eventType,
            key: key,
            processed: false,
            settings: settings,
            htmlEventListener: undefined,
            jqueryHandlers: undefined,
            removeFromStack: true, // action
            collisionResolution:
                typeof settings !== "undefined" && settings.collisionResolution !== undefined
                    ? settings.collisionResolution
                    : "moveup", // cancel | moveup
        };

        if (handlerEntry.key !== undefined) {
            // gibt es bereits einen Eintrag mit dem key: collision handling einleiten
            if (this.containsKey(target, eventType, handlerEntry.key)) {
                if (handlerEntry.collisionResolution === "cancel") {
                    return true;
                } else {
                    this.remove(target, eventType, settings);
                }
            }
        }

        if ($(target).data(eventType + "ehs")) {
            this.resetStack(target, eventType);
        }

        var events = $._data(target, "events");

        if ($(target).data("_handlers" + eventType) === undefined) {
            $(target).data("_handlers" + eventType, new Array());
        }

        $(target)
            .data("_handlers" + eventType)
            .push(handlerEntry);

        // passende jQuery Events herausnehmen
        if (events) {
            // eventhandlerstack eigene funktion herausnehmen
            $(target).off(eventType + ".ehs");
            $(target).data(eventType + "ehs", false);
            if (events[eventTypeNS] !== undefined) {
                handlerEntry.jqueryHandlers = new Array();

                for (var i = 0, l = events[eventTypeNS].length; i < l; i++) {
                    handlerEntry.jqueryHandlers.push(events[eventTypeNS][i]);
                }

                $(target).off(eventTypeNS);
            }
        }

        // standard html action (z.B. onclick) herausnehmen
        // Hinweis: nur wenn kein namespace definiert wurde
        if (namespace === undefined && typeof target["on" + eventType] == "function") {
            handlerEntry.htmlEventListener = target["on" + eventType];
            target["on" + eventType] = null;
        }

        if (handlerEntry.settings !== undefined && handlerEntry.settings.activate !== undefined) {
            //$(target).off(eventTypeNS);
            //target["on" + eventType] = null;
            handlerEntry.settings.activate(handlerEntry);
        }
    },
    /**
        @methodOf HelperMain.eventhandlerstack
        @private
        @param {HTMLElement|jQuery-Object} target
        @param {String} eventType
        @param {Object} settings
        @description
        pop a new event from the stack
    */
    pop: function (target, eventType, settings) {
        var namespace;

        if (eventType.indexOf(".") >= 0) {
            var aS = eventType.split(".");
            namespace = aS[1];
            eventType = aS[0];
        }
        // eslint-disable-next-line no-unused-vars
        var eventTypeNS = namespace !== undefined ? eventType + "." + namespace : eventType;

        if (
            $(target).data("_handlers" + eventType) !== undefined &&
            $(target).data("_handlers" + eventType).length > 0
        ) {
            for (var i = $(target).data("_handlers" + eventType).length - 1; i >= 0; i--) {
                if (!$(target).data("_handlers" + eventType)[i].processed) {
                    this.removeEntryByPos(target, eventType, settings, i);
                    return true;
                }
            }
        }
        return false;
    },
    /**
        @methodOf HelperMain.eventhandlerstack
        @private
        @param {HTMLElement|jQuery-Object} target
        @param {String} eventType
        @param {Object} settings
        @description
        process next event on the stack
    */
    processNext: function (target, eventType, settings) {
        var namespace;
        if (eventType.indexOf(".") >= 0) {
            var aS = eventType.split(".");
            namespace = aS[1];
            eventType = aS[0];
        }
        // eslint-disable-next-line no-unused-vars
        var eventTypeNS = namespace !== undefined ? eventType + "." + namespace : eventType;

        this.pop(target, eventType, settings);

        $(target).trigger(eventType);
    },
    /**
        @methodOf HelperMain.eventhandlerstack
        @private
        @param {HTMLElement|jQuery-Object} target
        @param {String} eventType
        @param {Object} settings
        @description
        remove event from the event stack
    */
    remove: function (target, eventType, settings) {
        var key;
        if (settings !== undefined) {
            key = settings.key;
        }

        var namespace;
        if (eventType.indexOf(".") >= 0) {
            var aS = eventType.split(".");
            namespace = aS[1];
            eventType = aS[0];
        }
        // eslint-disable-next-line no-unused-vars
        var eventTypeNS = namespace !== undefined ? eventType + "." + namespace : eventType;
        if ($(target).data("_handlers" + eventType) !== undefined) {
            var handlerEntry;

            for (var i = $(target).data("_handlers" + eventType).length - 1; i >= 0; i--) {
                handlerEntry = $(target).data("_handlers" + eventType)[i];

                if (
                    (key === undefined || handlerEntry.key == key) &&
                    (namespace === undefined || handlerEntry.namespace == namespace)
                ) {
                    this.removeEntryByPos(target, eventType, settings, i);
                }
            }
        }
    },
    /**
        @methodOf HelperMain.eventhandlerstack
        @private
        @param {HTMLElement|jQuery-Object} target
        @param {String} eventType
        @param {Object} settings
        @param {Number} position
        @description
        remove an event by its position on the stack
    */
    removeEntryByPos: function (target, eventType, settings, position) {
        var removeFromStack;

        if (settings !== undefined) {
            if (typeof settings.removeFromStack == "boolean") {
                removeFromStack = settings.removeFromStack;
            }
        }

        var namespace;
        if (eventType.indexOf(".") >= 0) {
            var aS = eventType.split(".");

            namespace = aS[1];
            eventType = aS[0];
        }
        // eslint-disable-next-line no-unused-vars
        var eventTypeNS = namespace !== undefined ? eventType + "." + namespace : eventType;

        if (
            $(target).data("_handlers" + eventType) !== undefined &&
            position < $(target).data("_handlers" + eventType).length
        ) {
            var handlerEntry = $(target).data("_handlers" + eventType)[position];

            if (removeFromStack === undefined) {
                if (handlerEntry.settings !== undefined && typeof handlerEntry.settings.removeFromStack == "boolean") {
                    removeFromStack = handlerEntry.settings.removeFromStack;
                } else {
                    removeFromStack = handlerEntry.removeFromStack;
                }
            }

            if (!removeFromStack && handlerEntry.settings.processed) {
                return;
            }

            var activeHandlerEntry = this.peek(target, eventType);
            var bActiveHandlerEntry = activeHandlerEntry === handlerEntry;

            // Eintrag aus der Liste entfernen
            if (removeFromStack) {
                // trigger callback "remove"
                if (handlerEntry.settings !== undefined && handlerEntry.settings.deactivate !== undefined) {
                    handlerEntry.settings.deactivate(handlerEntry);
                }

                // ist Eintrag nicht der letzte in der Liste, so m�ssen die Wiederherstellungsinformationen an den dar�berliegenden Eintrag �bertragen werden
                if (position + 1 < $(target).data("_handlers" + eventType).length) {
                    $(target).data("_handlers" + eventType)[position + 1].namespace = handlerEntry.namespace;
                    $(target).data("_handlers" + eventType)[position + 1].htmlEventListener =
                        handlerEntry.htmlEventListener;
                    $(target).data("_handlers" + eventType)[position + 1].jqueryHandlers = handlerEntry.jqueryHandlers;
                }

                // ist der aktuelle nun zu entfernende Eintrag der oberste in der Queue, dann...
                if (bActiveHandlerEntry) {
                    this.rebind(target, handlerEntry);
                }

                $(target)
                    .data("_handlers" + eventType)
                    .splice(position, 1);
            } else {
                handlerEntry.processed = true;

                // trigger callback "deactivate"
                if (handlerEntry.settings !== undefined && handlerEntry.settings.deactivate !== undefined) {
                    handlerEntry.settings.deactivate(handlerEntry);
                }

                // ist der aktuelle nun zu entfernende Eintrag der oberste in der Queue, dann...
                if (bActiveHandlerEntry) {
                    this.rebind(target, handlerEntry);
                }
            }
            // der entfernte Stack Eintrag war der aktive, den n�chsten Eintrag im Stack suchen
            if (bActiveHandlerEntry) {
                for (var i = $(target).data("_handlers" + eventType).length - 1; i >= 0; i--) {
                    handlerEntry = $(target).data("_handlers" + eventType)[i];

                    if (
                        !handlerEntry.processed &&
                        handlerEntry.settings !== undefined &&
                        handlerEntry.settings.activate !== undefined
                    ) {
                        handlerEntry.settings.activate(handlerEntry);
                    } else if (i == 0 && handlerEntry !== activeHandlerEntry) {
                        this.rebind(target, handlerEntry);
                    }
                }
            }
        }
    },
    /**
        @methodOf HelperMain.eventhandlerstack
        @private
        @param {HTMLElement|jQuery-Object} target
        @param {String} eventType
        @returns {Event} handlerEntry
        @description
        look for an event on the stack and return the matching entry
    */
    peek: function (target, eventType) {
        var namespace;
        if (eventType.indexOf(".") >= 0) {
            var aS = eventType.split(".");

            namespace = aS[1];
            eventType = aS[0];
        }
        // eslint-disable-next-line no-unused-vars
        var eventTypeNS = namespace !== undefined ? eventType + "." + namespace : eventType;
        if ($(target).data("_handlers" + eventType) !== undefined) {
            var handlerEntry;

            for (var i = $(target).data("_handlers" + eventType).length - 1; i >= 0; i--) {
                handlerEntry = $(target).data("_handlers" + eventType)[i];

                if (!handlerEntry.processed) {
                    return handlerEntry;
                }
            }
        }
    },
    /**
        @methodOf HelperMain.eventhandlerstack
        @private
        @param {HTMLElement|jQuery-Object} target
        @param {String} eventType
        @description
        reset the event stack
    */
    resetStack: function (target, eventType) {
        var namespace;
        if (eventType.indexOf(".") >= 0) {
            var aS = eventType.split(".");
            namespace = aS[1];
            eventType = aS[0];
        }
        var eventTypeNS = namespace !== undefined ? eventType + "." + namespace : eventType;

        var handlerEntry;

        if (
            $(target).data("_handlers" + eventType) !== undefined &&
            $(target).data("_handlers" + eventType).length > 0
        ) {
            for (var i = 0, l = $(target).data("_handlers" + eventType).length; i < l; i++) {
                handlerEntry = $(target).data("_handlers" + eventType)[i];
                handlerEntry.processed = false;
                // die aktuellen Events entfernen
                $(target).off(eventType);
                target["on" + eventType] = null;

                // den letzten Eintrag aktivieren
                if (i + 1 == l) {
                    if (handlerEntry.settings !== undefined && handlerEntry.settings.activate !== undefined) {
                        $(target).off(eventTypeNS);
                        target["on" + eventType] = null;

                        handlerEntry.settings.activate(handlerEntry);
                    }
                }
            }
        }
    },
    /**
        @methodOf HelperMain.eventhandlerstack
        @private
        @param {HTMLElement|jQuery-Object} target
        @param {Event} handlerEntry
        @description
        re-bind events to the target element
    */
    rebind: function (target, handlerEntry) {
        // eslint-disable-next-line no-unused-vars
        var eventType = handlerEntry.eventType;
        var eventTypeNS =
            handlerEntry.namespace !== undefined
                ? handlerEntry.eventType + "." + handlerEntry.namespace
                : handlerEntry.eventType;

        // falls das ursprüngliche Event
        if (
            $(target).data("_handlers" + handlerEntry.eventType) !== undefined &&
            $(target).data("_handlers" + handlerEntry.eventType).length > 0
        ) {
            // originalzustand wiederherstellen
            if (handlerEntry === $(target).data("_handlers" + handlerEntry.eventType)[0]) {
                // Events der Zielkontrolle wiederherstellen
                if (handlerEntry.htmlEventListener !== undefined) {
                    target["on" + handlerEntry.eventType] = handlerEntry.htmlEventListener;
                } else {
                    target["on" + handlerEntry.eventType] = null;
                }

                // Events der Zielkontrolle wiederherstellen (jQuery)
                if (handlerEntry.jqueryHandlers !== undefined && handlerEntry.jqueryHandlers.length > 0) {
                    jQuery.each(handlerEntry.jqueryHandlers, function () {
                        $(target).on(eventTypeNS, this);
                    });
                }
            }
        }
    },
    /**
        @methodOf HelperMain.eventhandlerstack
        @private
        @param {HTMLElement|jQuery-Object} target
        @param {String} eventType
        @param {Object} key
        @description
        checks, if the event stack contains a specific key
    */
    containsKey: function (target, eventType, key) {
        var namespace;
        if (eventType.indexOf(".") >= 0) {
            var aS = eventType.split(".");
            namespace = aS[1];
            eventType = aS[0];
        }
        // eslint-disable-next-line no-unused-vars
        var eventTypeNS = namespace !== undefined ? eventType + "." + namespace : eventType;

        if ($(target).data("_handlers" + eventType) !== undefined) {
            var handlerEntry;

            for (var i = $(target).data("_handlers" + eventType).length - 1; i >= 0; i--) {
                handlerEntry = $(target).data("_handlers" + eventType)[i];
                if (key == handlerEntry.key) {
                    return true;
                }
            }
        }
        return false;
    },
};
