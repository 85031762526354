/**
 * @file Global functions and constants.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals oHtmlRoot, ErrorHandler, oHtmlRoot: true , iStartEvalFunc1: true, iStartEvalRegexp2: true,
    Browser, Notifier, ObjectFactory, ix,
    g_oAsyncRequestRegistry: true, g_oSettingsRegistry: true, g_WaitAnimRegistry: true,
    shortcut:true, code: true, k:true,
*/

/* exported shortcuts, bRootInsidePortal, IDLEVELSEP, IDCOUNTERSEP
    doFileUploadViaAjax, QSVAR: true, COOKIEVAR: true, getMaxTabIndex, increaseTabIndex, evalResponseJs, iStartEvalFunc1,
    iStartEvalRegexp2, _evalJs, trim, upEventListenerContainer, upFlipFlopControl, upMultiSelectControl, upDistributeControl
    upDistributeParticipantsControl, upDistributorControl, upDistributeOrgUnitsControl, upSimpleAjax, upSimpleAjaxContainer
    getElementRelInfo, g_oAsyncRequestRegistry, registerAsynchronousDatarangeRequest, g_oSettingsRegistry, registerSetting,
    registerSettingCustom, unregisterSetting, registerSettingsControl, g_WaitAnimRegistry, startWaitAnimation, upObjectSetProperties,
    shortcut, WiKiLink, WiKiToggleHistory, getTableRecordByRecordHtml, getTableByRecordHtml
*/

/**
 * Name of the Intrexx root frame.
 * @final
 * @private
 */
var WINDOW_INTREXX = "wndRoot";

/**
 * <code>true</code>, if Intrexx portal is framed inside another portal.
 * @private
 */
var bRootInsidePortal = false;
/**
 * separator - Separates each level of a clonecontainer from the origin id.
 * @final
 * @private
 */
var IDLEVELSEP = "_";
/**
 * separator - Separates all levels of a clonecontainer from the origin id.
 * @final
 * @private
 */
var IDCOUNTERSEP = "__";
/**
 * @private
 */
var isStopwatch = false;

function doFileUploadViaAjax() {
    return true;
}
/**
 * @class
 * Holds querystring variables, which are not hexencoded.
 * @see COOKIEVAR
 * @private
 */
function QSVAR() {
    /**
     * An array of the names of querystring variables, which are not hexencoded.
     * @private
     */
    this.RQ_NOT_ENCODED = new Array();
    this.RQ_NOT_ENCODED.push("rq_Lang");
    this.RQ_NOT_ENCODED.push("rq_command");
    this.RQ_NOT_ENCODED.push("rq_MenuGuid");
    this.RQ_NOT_ENCODED.push("rq_Layout");
    this.RQ_NOT_ENCODED.push("rq_ReloadParent");
    this.RQ_NOT_ENCODED.push("rq_TargetPageGuid");
    this.RQ_NOT_ENCODED.push("rq_AppGuid");
    this.RQ_NOT_ENCODED.push("rq_SourceAppGuid");
    this.RQ_NOT_ENCODED.push("rq_SourcePageGuid");
    this.RQ_NOT_ENCODED.push("rq_FupOfOpener");
    this.RQ_NOT_ENCODED.push("rq_SortBy");
    this.RQ_NOT_ENCODED.push("rq_DatarangeGuid");
    this.RQ_NOT_ENCODED.push("rq_PortalGuid");
    this.RQ_NOT_ENCODED.push("urn:schemas-unitedplanet-de:ixservlet:name");
    this.RQ_NOT_ENCODED.push("rq_NoSessionTouch");
    this.isEncoded = QSVARIsEncoded;
}
// eslint-disable-next-line no-func-assign
QSVAR = new QSVAR();
/**
 * Is the querystring variable p_strParam hexencoded?
 * @param p_strParam Name of querystring variable as string.
 * @return <code>true</code> if the querystring variable p_strParam is encoded
 *       or <code>false</code> otherwise.
 */
function QSVARIsEncoded(p_strParam) {
    for (var i = 0; i < this.RQ_NOT_ENCODED.length; i++) {
        if (this.RQ_NOT_ENCODED[i] == p_strParam) {
            return false;
        }
    }
    return true;
}
/**
 * @class
 * Holds cookie variables, which are not hexencoded.
 * @see QSVAR
 * @private
 */
function COOKIEVAR() {
    /**
     * An array of the names of all cookie variables, which are not hexencoded.
     * @private
     */
    this.CO_NOT_ENCODED = [];
    this.CO_NOT_ENCODED.push(/^co_Lang$/);
    this.CO_NOT_ENCODED.push(/^co_Locale$/);
    this.CO_NOT_ENCODED.push(/^co_Layout(_[0-9A-F]{40})?$/);
    this.isEncoded = COOKIEVARIsEncoded;
}
// eslint-disable-next-line no-func-assign
COOKIEVAR = new COOKIEVAR();
/**
 * Is the cookie variable p_strParam hexencoded?
 * @param p_strParam Name of a cookie variable as string
 * @return <code>true</code> if the cookie variable p_strParam is encoded
 *       or <code>false</code> otherwise.
 */
function COOKIEVARIsEncoded(p_strParam) {
    return !this.CO_NOT_ENCODED.some(function (i) {
        return i.test(p_strParam);
    });
}
/**
 * Returns the html window object, which is the root window of Intrexx portal.
 * @param win Name of actual window as String.
 * @return html root window of Intrexx portal.
 * @private
 */
function getHtmlRoot(win) {
    // kann der opener ausgelesen werden, oder wird der Zugriff verweigert (z.B. andere Domain)
    var l_bCanReadOpener = false;
    try {
        if (win.opener && win.opener.oUp) {
            l_bCanReadOpener = true;
        }
    } catch (e) {
        console.trace(e);
    }

    try {
        if (win.oHtmlRoot) {
            return win.oHtmlRoot;
        } else if (win.name == WINDOW_INTREXX || win.bRootInsidePortal) {
            // z.B. falls in IFrame liegt
            return win;
        }
        if (l_bCanReadOpener && win.opener && win.opener.oUp) {
            var openerRoot = getHtmlRoot(win.opener);
            // geoeffnet von demselben IntrexxPortal
            if (openerRoot && openerRoot.location.host == win.location.host) {
                return openerRoot;
            } else {
                // geoeffnet von einem anderen IntrexxPortal
                if (win == top) {
                    return win;
                } else if (win.parent && win.parent != win) {
                    return getHtmlRoot(win.parent);
                } else {
                    return null;
                }
            }
        } else if (win == top) {
            return win;
        } else if (win.parent && win.parent != win) {
            if (win.parent && win.parent.location.host != win.location.host) {
                win.bRootInsidePortal = true;
                return win;
            }
            return getHtmlRoot(win.parent);
        } else {
            return null;
        }
    } catch (e) {
        // das Fenster selber wird HtmlRoot, z.B. falls Link einen Hostnamen mit Domainerweiterung enthaelt
        // und die Url der Seite nicht.
        ErrorHandler.warn(e, "getHtmlRoot");
        win.bRootInsidePortal = true;
        return win;
    }
}
/**
 * Root window of Intrexx portal.
 * @final
 */
var oHtmlRoot = null;
// eslint-disable-next-line no-unused-vars
oHtmlRoot = getHtmlRoot(self);

/**
 * Returns the current maximum TabIndex value.
 * @param p_oDoc Document reference.
 * @private
 */
function getMaxTabIndex(p_oDoc) {
    if (arguments.length < 1) {
        p_oDoc = document;
    }
    var l_iMax = -1;
    var l_aTypes = ["input", "textarea", "select", "button"];

    function _getMaxTabIndex(p_oElements, p_iMax) {
        if (typeof p_oElements == "object" && p_oElements.length > 0) {
            var l_iTmp;
            for (var i = 0; i < p_oElements.length; i++) {
                l_iTmp = p_oElements[i].getAttribute("tabIndex");
                if (!isNaN(l_iTmp) && l_iTmp > p_iMax) {
                    p_iMax = l_iTmp;
                }
            }
        }
        return p_iMax;
    }
    for (var i = 0; i < l_aTypes.length; i++) {
        l_iMax = _getMaxTabIndex(p_oDoc.getElementsByTagName(l_aTypes[i]), l_iMax);
    }
    if (typeof l_iMax == "string") {
        l_iMax = parseInt(l_iMax);
    }

    return l_iMax;
}
/**
 * increaseTabIndex values the current maximum TabIndex value.
 * @param p_oHtml container with elements
 * @param p_iMin
 * @private
 */
function increaseTabIndex(p_oHtml, p_iMin) {
    if (typeof p_oHtml != "object" || isNaN(p_iMin) || p_iMin < 1) {
        return false;
    }

    var l_aTypes = ["input", "textarea", "select", "button"];
    var l_oElements;
    var l_iTab;
    for (var i = 0; i < l_aTypes.length; i++) {
        l_oElements = p_oHtml.getElementsByTagName(l_aTypes[i]);
        if (typeof l_oElements == "object" && l_oElements.length > 0) {
            for (var k = 0; k < l_oElements.length; k++) {
                l_iTab = l_oElements[k].getAttribute("tabIndex");
                if (!isNaN(l_iTab)) {
                    l_oElements[k].setAttribute("tabIndex", l_iTab + p_iMin);
                }
            }
        }
    }
}
/**
 * Evaluates javascript code. Script code of load and unload events is handled properly.
 * @param text Text string, of which javascript code is part of.
 */
function evalResponseJs(text) {
    try {
        if (isStopwatch) {
            iStartEvalFunc1 = parseInt(new Date().getTime());
        }

        var strOldOnload = self.oUp.strOnLoad;
        var strNewOnload = "";
        var strOldOnUnLoad = self.oUp.strOnUnLoad;
        var strNewOnUnLoad;
        var aReturn = new Array();
        var l_bHasDocumentWrite = false;
        var bakFuncWrite;
        var bakFuncWriteln;

        self.oUp.strOnUnLoad = "";
        self.oUp.strOnLoad = "";

        if (isStopwatch) {
            iStartEvalRegexp2 = parseInt(new Date().getTime());
        }

        // Scriptbloecke
        text = text.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, function () {
            if (text !== null) {
                // document.write vorhanden?
                if (!l_bHasDocumentWrite && arguments[1].indexOf("document.write") > -1) {
                    bakFuncWrite = document.write;
                    bakFuncWriteln = document.writeln;
                    // eslint-disable-next-line no-unused-vars
                    document.write = document.writeln = function (t) {};
                    l_bHasDocumentWrite = true;
                }

                // code evaluieren
                if (arguments[1] != "") {
                    try {
                        Browser.evalInGlobalContext(arguments[1], false);
                    } catch (e) {
                        ErrorHandler.Javascript.handleEvalError("evalResponseJs", e, "in evalResponseJs", arguments[1]);
                    }
                }
            }
            return "";
        });

        // onload und/oder onunload
        var bOnLoad = typeof self.oUp.strOnLoad == "string" && self.oUp.strOnLoad.length > 0;
        var bOnUnLoad = typeof self.oUp.strOnUnLoad == "string" && self.oUp.strOnUnLoad.length > 0;

        // Behandlung des OnLoadHandlers
        if (bOnLoad) {
            var iStartOldOnload = self.oUp.strOnLoad.indexOf(strOldOnload);
            var iEndOldOnload = iStartOldOnload + strOldOnload.length - 1;
            if (iStartOldOnload == -1) {
                // neues onLoad ist vollstaendig neu (= kein altes OnLoad oder altes OnLoad ueberschrieben)
                strNewOnload = self.oUp.strOnLoad;
            } else if (iStartOldOnload == 0) {
                // neues onLoad am Ende
                strNewOnload = self.oUp.strOnLoad.substring(strOldOnload.length, self.oUp.strOnLoad.length);
            } else {
                // neues onLoad am Anfang
                strNewOnload = self.oUp.strOnLoad.substring(0, iStartOldOnload);
                if (self.oUp.strOnLoad.length - strOldOnload.length > strNewOnload.length) {
                    // neues onLoad ausserdem noch am Ende
                    strNewOnload += self.oUp.strOnLoad.substring(iEndOldOnload + 1, self.oUp.strOnLoad.length);
                }
            }
        }
        // Behandlung des OnUnLoadHandlers
        if (bOnUnLoad) {
            var iStartOldOnUnLoad = self.oUp.strOnUnLoad.indexOf(strOldOnUnLoad);
            var iEndOldOnUnLoad = iStartOldOnUnLoad + strOldOnUnLoad.length - 1;
            if (iStartOldOnUnLoad == -1) {
                // neues onUnLoad ist vollstaendig neu (= kein altes OnUnLoad oder altes OnUnLoad ueberschrieben)
                strNewOnUnLoad = self.oUp.strOnUnLoad;
            } else if (iStartOldOnUnLoad == 0) {
                // neues onUnLoad am Ende
                strNewOnUnLoad = self.oUp.strOnUnLoad.substring(strOldOnUnLoad.length, self.oUp.strOnUnLoad.length);
            } else {
                // neues onUnLoad am Anfang
                strNewOnUnLoad = self.oUp.strOnUnLoad.substring(0, iStartOldOnUnLoad);
                if (self.oUp.strOnUnLoad.length - strOldOnUnLoad.length > strNewOnUnLoad.length) {
                    // neues onUnLoad ausserdem noch am Ende
                    strNewOnUnLoad += self.oUp.strOnUnLoad.substring(iEndOldOnUnLoad + 1, self.oUp.strOnUnLoad.length);
                }
            }
            // onUnload zurueckgeben
            aReturn[0] = strNewOnUnLoad;
        } else {
            aReturn[0] = "";
        }

        // document . write ggf. zuruecksetzen
        if (l_bHasDocumentWrite) {
            Notifier.status.warn(
                self.oUp.oMessage.ERR_DOCWRITE_TEXT,
                self.oUp.oMessage.ERR_DOCWRITE_TITLE,
                "ERR_DOCWRITE_TEXT"
            );
            document.write = bakFuncWrite;
            document.writeln = bakFuncWriteln;
        }

        // onLoad behandeln
        aReturn[1] = strNewOnload;
        // Handler fuer onLoad/onUnLoad zuruecksetzen
        self.oUp.strOnLoad = strOldOnload;
        self.oUp.strOnUnLoad = strOldOnUnLoad;
        return aReturn;
    } catch (exc) {
        alert("Exception in Function evalResponseJs\n" + exc.message);
        throw exc;
        // return new Array();
    }
}
/**
 * Evaluates javascript code. Variables are only valid in the eval context.
 * @param {string} strCode The javascript code to evaluate.
 * @private
 */
function _evalJs(strCode) {
    eval(strCode);
}

/**
 * removes leading and trailing white spaces from a string
 * @private
 */
function trim(str) {
    var a = [];
    for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) == 160) {
            a.push(" ");
        } else {
            a.push(str.charAt(i));
        }
    }
    var strOut = a.join("");
    return strOut.replace(/^\s*|\s*$/g, "");
}

/**
 * @class
 * Wrapper for _upEventListenerContainer
 * @private
 */
function upEventListenerContainer(p_oWin) {
    return ObjectFactory.construct("_upEventListenerContainer", p_oWin);
}
/**
 * @class
 * Wrapper for _upFlipFlopControl
 * @private
 */
function upFlipFlopControl() {
    return ObjectFactory.construct("_upFlipFlopControl", "");
}
/**
 * @class
 * Wrapper for _upMultiSelectControl
 * @private
 */
function upMultiSelectControl() {
    return ObjectFactory.construct("_upMultiSelectControl", "");
}
/**
 * @class
 * Wrapper for _upDistributeControl
 * @private
 */
function upDistributeControl() {
    return ObjectFactory.construct("_upDistributeControl", "");
}
/**
 * @class
 * Wrapper for _upDistributeParticipantsControl
 * @private
 */
function upDistributeParticipantsControl() {
    return ObjectFactory.construct("_upDistributeParticipantsControl", "");
}
/**
 * @class
 * Wrapper for _upDistributorControl
 * @private
 */
function upDistributorControl() {
    return ObjectFactory.construct("_upDistributorControl", "");
}
/**
 * @class
 * Wrapper for _upDistributeOrgUnitsControl
 * @private
 */
function upDistributeOrgUnitsControl() {
    return ObjectFactory.construct("_upDistributeOrgUnitsControl", "");
}
/**
 * @class
 * Wrapper for _upSimpleAjax
 * @private
 */
function upSimpleAjax() {
    return ObjectFactory.construct("_upSimpleAjax", "");
}
/**
 * @class
 * Wrapper for _upSimpleAjaxContainer
 * @private
 */
function upSimpleAjaxContainer(p_oHtmlContainer, p_oProps) {
    return ObjectFactory.construct("_upSimpleAjaxContainer", p_oHtmlContainer, p_oProps);
}

/**
 * @return an array of all elements with a specific attribute
 */
document.getElementsByAttribute = function (attrib, value, compare, context_node, tag) {
    var nodes = [];
    if (context_node == null) {
        context_node = this;
    }
    if (tag == null) {
        tag = "*";
    }
    var elems = context_node.getElementsByTagName(tag);
    var bComp;
    for (var i = 0; i < elems.length; i += 1) {
        if (value) {
            if (typeof elems[i].hasAttribute == "undefined" || elems[i].hasAttribute(attrib)) {
                bComp = false;
                if (compare == "equals") {
                    bComp = elems[i].getAttribute(attrib) == value;
                } else if (compare == "contains") {
                    bComp = elems[i].getAttribute(attrib).indexOf(value) > -1;
                } else if (compare == "startsWith" && elems[i].getAttribute(attrib).length >= value.length) {
                    bComp = elems[i].getAttribute(attrib).substr(0, value.length) == value;
                } else if (compare == "exist") {
                    bComp = true;
                }

                if (bComp) {
                    nodes.push(elems[i]);
                }
            }
        } else {
            if (elems[i].hasAttribute(attrib)) {
                nodes.push(elems[i]);
            }
        }
    }
    return nodes;
};

/**
 * @return an array of all elements with a specific tag name
 * document.getElementsByTagNames("h1,h2,h3")
 */
document.getElementsByTagNames = function (list, obj) {
    if (!obj) {
        // eslint-disable-next-line no-redeclare
        var obj = document;
    }
    var tagNames = list.split(",");
    var resultArray = new Array();
    for (var i = 0; i < tagNames.length; i++) {
        var tags = obj.getElementsByTagName(tagNames[i]);
        for (var j = 0; j < tags.length; j++) {
            resultArray.push(tags[j]);
        }
    }
    var testNode = resultArray[0];
    if (testNode.sourceIndex) {
        resultArray.sort(function (a, b) {
            return a.sourceIndex - b.sourceIndex;
        });
    } else if (testNode.compareDocumentPosition) {
        resultArray.sort(function (a, b) {
            return 3 - (a.compareDocumentPosition(b) & 6);
        });
    }
    return resultArray;
};

/*
 * <a rel="xyz{&quot;name&quot;: &quot;value&quot;}" ...
 */
function getElementRelInfo(p_oHtml) {
    if (typeof p_oHtml == "undefined" || !p_oHtml || p_oHtml == null) {
        return false;
    }

    var strRel = p_oHtml.getAttribute("rel");

    // rel Attribut auswerten
    if (typeof strRel != "undefined" && strRel != null) {
        var oRet = {
            name: null,
            oJSON: null,
        };

        /*
        var matchResult = strRel.match(/(.+)(\{.+\})/);
        if (matchResult == null || matchResult.length < 2) return false;
        oRet.name = matchResult[1];
        if(matchResult.length==3)
        oRet.oJSON = JSON.parse(matchResult[2]);
        */

        var iJsonStart = strRel.indexOf("{");

        if (iJsonStart == -1) {
            oRet.name = strRel;
        } else {
            oRet.name = strRel.substr(0, iJsonStart);
            oRet.oJSON = JSON.parse(strRel.substr(iJsonStart, strRel.length));
        }

        return oRet;
    }

    return false;
}

g_oSettingsRegistry = new (function () {
    this.aControls = [];
    this.aSettings = [];

    this.aControls.contains = this.aSettings.contains = function (p_strKey) {
        for (var i = 0, l = this.length; i < l; i++) {
            if (this[i].strKey == p_strKey) {
                return true;
            }
        }
    };
    return false;
})();

function registerSetting(p_strKey, p_strText, p_Action) {
    if (g_oSettingsRegistry.aSettings.indexOf(p_strKey) > -1) {
        return false;
    }

    var oSetting = new upSetting(p_strKey);
    oSetting.strText = p_strText;
    oSetting.actionClick = p_Action;
    g_oSettingsRegistry.aSettings.push(oSetting);

    if (g_oSettingsRegistry.aControls.length > 0) {
        for (var i = 0, l = g_oSettingsRegistry.aControls.length; i < l; i++) {
            g_oSettingsRegistry.aControls[i].addSetting(oSetting);
        }
    }

    return oSetting;
}

function registerSettingCustom(p_strKey, p_oHtml) {
    if (g_oSettingsRegistry.aSettings.indexOf(p_strKey) > -1) {
        return false;
    }

    var oSetting = new upSettingCustom(p_strKey);
    oSetting.oCustomHtml = p_oHtml;
    g_oSettingsRegistry.aSettings.push(oSetting);

    if (g_oSettingsRegistry.aControls.length > 0) {
        for (var i = 0, l = g_oSettingsRegistry.aControls.length; i < l; i++) {
            g_oSettingsRegistry.aControls[i].addSetting(oSetting);
        }
    }

    return oSetting;
}

function unregisterSetting(p_strKey) {
    if (!g_oSettingsRegistry.aSettings.indexOf(p_strKey) > -1) {
        return false;
    }

    for (var i = 0, l = g_oSettingsRegistry.aSettings.length; i < l; i++) {
        if (g_oSettingsRegistry.aSettings[i].strKey == p_strKey) {
            if (g_oSettingsRegistry.aControls.length > 0) {
                for (var ii = 0, ll = g_oSettingsRegistry.aControls.length; ii < ll; ii++) {
                    g_oSettingsRegistry.aControls[ii].removeSetting(g_oSettingsRegistry.aSettings[i]);
                }
            }

            g_oSettingsRegistry.aSettings.splice(i, 1);
            return true;
        }
    }
}

function registerSettingsControl(p_strKey) {
    if (g_oSettingsRegistry.aControls.indexOf(p_strKey) > -1) {
        return false;
    }

    var oControl = new upSettingsControl(p_strKey);
    g_oSettingsRegistry.aControls.push(oControl);

    if (g_oSettingsRegistry.aSettings.length > 0) {
        for (var i = 0, l = g_oSettingsRegistry.aSettings.length; i < l; i++) {
            oControl.addSetting(g_oSettingsRegistry.aSettings[i]);
        }
    }

    var oHtmlUl = oControl.getContainer(false);
    if (oHtmlUl) {
        if (oHtmlUl.childNodes.length == 0) {
            oControl.disable();
        }
    }
}
/*
function unregisterSettingsControl(p_strKey) {
    if (typeof g_oSettingsRegistry.oControls[p_strKey] != "object") return false;

    var controls = g_oSettingsRegistry.oControls;
    g_oSettingsRegistry.oControls = {};
    for (var control in controls) {
        if (control.strKey != p_strKey) {
            g_oSettingsRegistry.oControls[control.strKey] = control;
        }
    }
}
*/
function upSetting(p_strKey) {
    this.strKey = p_strKey;
    this.strText;
    this.actionClick;
    this.actionHref = "#";

    this.render = function (p_oHtml) {
        var a = document.createElement("a");
        a.href = this.actionHref;
        a.innerHTML = this.strText;
        p_oHtml.appendChild(a);
        p_oHtml.oUp = this;
        if (this.actionClick) {
            a.onclick = function (e) {
                this.parentNode.oUp.actionClick(e, this.parentNode.oUp);
            };
        }
    };
}

function upSettingCustom(p_strKey) {
    this.strKey = p_strKey;
    this.oCustomHtml;

    this.render = function (p_oHtml) {
        if (this.oCustomHtml) {
            $(p_oHtml).append(this.oCustomHtml);
        }
    };
}

function upSettingsControl(p_strKey) {
    this.strKey = p_strKey;
    this.oHtml = document.getElementById(p_strKey);

    this.getContainer = function (p_bCreate) {
        if (typeof p_bCreate != "boolean") {
            p_bCreate = false;
        }

        var oHtmlUl = false;
        for (var i = 0, l = this.oHtml.childNodes.length; i < l; i++) {
            if (this.oHtml.childNodes[i].tagName == "UL") {
                oHtmlUl = this.oHtml.childNodes[i];
                break;
            }
        }

        if (!oHtmlUl && p_bCreate) {
            oHtmlUl = document.createElement("ul");
            this.oHtml.appendChild(oHtmlUl);
        }

        return oHtmlUl;
    };

    this.hasContainer = function () {
        return this.getContainer(false);
    };

    this.addSetting = function (p_oSetting) {
        var oHtmlUl = this.getContainer(true);
        if (oHtmlUl) {
            var oHtmlLi = document.createElement("li");
            oHtmlLi.id = p_oSetting.strKey;
            oHtmlUl.appendChild(oHtmlLi);
            p_oSetting.render(oHtmlLi);
            $(oHtmlLi).prevAll("li.Header").first().show();
            this.enable();
        }
    };

    this.removeSetting = function (p_oSetting) {
        var ul = $(this.getContainer(false));
        if (ul.length > 0) {
            var li = ul.find("#" + p_oSetting.strKey);
            var header = li.prevAll("li.Header").first();
            // remove _ALL_ li with id from current ul
            li.remove();
            // no more settings?
            if (!ul.has("li")) {
                this.disable();
            }
            // no more settings under current li.Header?
            if (header.nextAll("li:visible").length === 0) {
                header.hide();
            }
        }
    };

    this.enable = function () {
        if (this.oHtml) {
            this.oHtml.classList.remove("Action_Settings_Disabled");
            this.oHtml.disabled = false;
            this.oHtml.enabled = true;
        }
    };

    this.disable = function () {
        if (this.oHtml) {
            this.oHtml.classList.add("Action_Settings_Disabled");
            this.oHtml.disabled = true;
            this.oHtml.enabled = false;
        }
    };
}

g_WaitAnimRegistry = {
    aHtml: [],
    register: function (key) {
        this.aHtml.push(key);
    },
    unregister: function (key) {
        for (var i = this.aHtml.length - 1; i >= 0; i--) {
            if (this.aHtml[i] === key) {
                this.aHtml.splice(i, 1);
            }
        }
    },
    removeAll: function () {
        for (var i = this.aHtml.length - 1; i >= 0; i--) {
            stopWaitAnimation(this.aHtml[i]);
        }
        this.aHtml = [];
    },
};

function startWaitAnimation(p_oHtml, p_strType) {
    try {
        if (
            document.getElementById("loadindicator") &&
            document.getElementById("loadindicator").hasAttribute("status")
        ) {
            return;
        }
        if (!p_oHtml || typeof p_oHtml == "undefined") {
            return false;
        }
        if (typeof p_strType != "string") {
            p_strType = "overlay";
        }

        if (typeof p_oHtml.waitAnim != "undefined" && p_oHtml.waitAnim != null) {
            stopWaitAnimation(p_oHtml);
        }

        // Kontrolle ist kein HTML Objekt, oder Position konnte nicht korrekt ermittelt werden
        if (
            $(p_oHtml).offset() === undefined ||
            $(p_oHtml).offset() === null ||
            ($(p_oHtml).offset().left == 0 && $(p_oHtml).offset().top == 0)
        ) {
            p_strType = "mouse";
        }

        p_oHtml.waitAnim = {};

        if (
            typeof p_oHtml.oUp == "object" &&
            (p_oHtml.oUp.upType == "upTextActionControl" ||
                p_oHtml.oUp.upType == "upButtonControl" ||
                p_oHtml.oUp.upType == "upImageActionControl")
        ) {
            p_oHtml.oUp.disable();
            p_oHtml.waitAnim.bActionDisabled = true;
        }

        if (p_oHtml && p_oHtml.oUp && p_oHtml.oUp.upType && p_oHtml.oUp.upType === "upDropdownControl") {
            p_strType = "beside";
        }
        p_oHtml.waitAnim.type = p_strType;
        switch (p_strType) {
            case "beside":
                _startWaitAnimationBeside(p_oHtml, "element");
                break;
            case "mouse":
                _startWaitAnimationBeside(p_oHtml, "mouse");
                break;
            default:
                _startWaitAnimationOverlay(p_oHtml);
        }

        g_WaitAnimRegistry.register(p_oHtml);
    } catch (e) {
        console.log(e);
        return;
    }
}

function _startWaitAnimationOverlay(p_oHtml) {
    p_oHtml.classList.add("WaitWhileLoading");

    var oDivFg = document.createElement("div");
    var oImg = document.createElement("div");
    oImg.className = "Image";
    var oText = document.createElement("span");
    oText.innerHTML = self.oUp.oMessage.ASYNCHRONOUS_LOADING;
    oDivFg.appendChild(oImg);
    oDivFg.appendChild(oText);
    oDivFg.className = "WaitWhileLoading_Fg";

    var oDivBg = document.createElement("div");
    oDivBg.className = "WaitWhileLoading_Bg";

    var strPos = p_oHtml.style.position;
    if (strPos != "relative" && strPos != "absolute") {
        p_oHtml.waitAnim.bSetPosition = true;
        p_oHtml.classList.add("PositionRelative");
    }
    p_oHtml.appendChild(oDivBg);
    p_oHtml.appendChild(oDivFg);

    p_oHtml.waitAnim.oDivBg = oDivBg;
    p_oHtml.waitAnim.oDivFg = oDivFg;
}

function _startWaitAnimationBeside(p_oHtml, p_strPositioning) {
    var oDivBg = document.createElement("div");
    oDivBg.className = "WaitWhileLoadingBeside";

    var oImg = document.createElement("div");
    oImg.className = "Image";

    var oText = document.createElement("span");
    oText.innerHTML = self.oUp.oMessage.ASYNCHRONOUS_LOADING;

    oDivBg.appendChild(oImg);
    oDivBg.appendChild(oText);

    var position = p_strPositioning === "mouse" ? "mouse" : "element";
    var alignment = position === "mouse" ? "beside_right" : "beside_right_middle";

    ix.tooltip.show({
        alignment: alignment,
        closeByButton: false,
        content: oDivBg,
        className: "TT_Blank",
        htmlTarget: p_oHtml,
        key: "waitbeside",
        position: "element",
        title: false,
    });
    p_oHtml.waitAnim.tooltipKey = "waitbeside";
}

function stopWaitAnimation(p_oHtml) {
    try {
        if (
            !p_oHtml ||
            p_oHtml === undefined ||
            p_oHtml === null ||
            p_oHtml.waitAnim === undefined ||
            p_oHtml.waitAnim === null
        ) {
            g_WaitAnimRegistry.removeAll();
            return false;
        }

        switch (p_oHtml.waitAnim.type) {
            case "beside":
            case "mouse":
                _stopWaitAnimationBeside(p_oHtml);
                break;
            default:
                _stopWaitAnimationOverlay(p_oHtml);
        }

        if (p_oHtml.waitAnim.bActionDisabled && typeof p_oHtml.oUp === "object" && p_oHtml.oUp.enable !== undefined) {
            p_oHtml.oUp.enable();
        }

        p_oHtml.waitAnim = null;

        g_WaitAnimRegistry.unregister(p_oHtml);
    } catch (e) {
        /*console.log("stopWaitAnimation Error: " + e.message) */
        return;
    }
}

function _stopWaitAnimationOverlay(p_oHtml) {
    p_oHtml.classList.remove("WaitWhileLoading");
    if (p_oHtml.waitAnim.bSetPosition) {
        p_oHtml.classList.remove("PositionRelative");
    }

    p_oHtml.removeChild(p_oHtml.waitAnim.oDivBg);
    p_oHtml.removeChild(p_oHtml.waitAnim.oDivFg);
}

function _stopWaitAnimationBeside() {
    ix.tooltip.close("waitbeside");
}

function upObjectSetProperties(properties) {
    for (var property in properties) {
        this[property] = properties[property];
    }
}

/**
 * http://www.openjs.com/scripts/events/keyboard_shortcuts/
 * Version : 2.01.B
 * By Binny V A
 * License : BSD
 */
shortcut = {
    all_shortcuts: {}, //All the shortcuts are stored in this array
    add: function (shortcut_combination, callback, opt) {
        //Provide a set of default options
        var default_options = {
            type: "keydown",
            propagate: false,
            disable_in_input: false,
            target: document,
            keycode: false,
        };
        if (!opt) {
            opt = default_options;
        } else {
            for (var dfo in default_options) {
                if (typeof opt[dfo] == "undefined") {
                    opt[dfo] = default_options[dfo];
                }
            }
        }

        var ele = opt.target;
        if (typeof opt.target == "string") {
            ele = document.getElementById(opt.target);
        }
        // var ths = this;
        shortcut_combination = shortcut_combination.toLowerCase();

        //The function to be called at keypress
        var func = function (e) {
            e = e || window.event;

            if (opt.disable_in_input) {
                //Don't enable shortcut keys in Input, Textarea fields
                var element;
                if (e.target) {
                    element = e.target;
                } else if (e.srcElement) {
                    element = e.srcElement;
                }
                if (element.nodeType == 3) {
                    element = element.parentNode;
                }

                if (element.tagName == "INPUT" || element.tagName == "TEXTAREA") {
                    return;
                }
            }

            //Find Which key is pressed
            if (e.keyCode) {
                code = e.keyCode;
            } else if (e.which) {
                code = e.which;
            } else {
                code = null;
            }
            var character = code ? String.fromCharCode(code).toLowerCase() : null;

            if (code == 188) {
                character = ",";
            } //If the user presses , when the type is onkeydown
            if (code == 190) {
                character = ".";
            } //If the user presses , when the type is onkeydown

            var keys = shortcut_combination.split("+");
            //Key Pressed - counts the number of valid keypresses - if it is same as the number of keys, the shortcut function is invoked
            var kp = 0;

            //Work around for stupid Shift key bug created by using lowercase - as a result the shift+num combination was broken
            var shift_nums = {
                "`": "~",
                1: "!",
                2: "@",
                3: "#",
                4: "$",
                5: "%",
                6: "^",
                7: "&",
                8: "*",
                9: "(",
                0: ")",
                "-": "_",
                "=": "+",
                ";": ":",
                "'": '"',
                ",": "<",
                ".": ">",
                "/": "?",
                "\\": "|",
            };
            //Special Keys - and their codes
            var special_keys = {
                esc: 27,
                escape: 27,
                tab: 9,
                space: 32,
                return: 13,
                enter: 13,
                backspace: 8,

                scrolllock: 145,
                scroll_lock: 145,
                scroll: 145,
                capslock: 20,
                caps_lock: 20,
                caps: 20,
                numlock: 144,
                num_lock: 144,
                num: 144,

                pause: 19,
                break: 19,

                insert: 45,
                home: 36,
                delete: 46,
                end: 35,

                pageup: 33,
                page_up: 33,
                pu: 33,

                pagedown: 34,
                page_down: 34,
                pd: 34,

                left: 37,
                up: 38,
                right: 39,
                down: 40,

                f1: 112,
                f2: 113,
                f3: 114,
                f4: 115,
                f5: 116,
                f6: 117,
                f7: 118,
                f8: 119,
                f9: 120,
                f10: 121,
                f11: 122,
                f12: 123,
            };

            var modifiers = {
                shift: {
                    wanted: false,
                    pressed: false,
                },
                ctrl: {
                    wanted: false,
                    pressed: false,
                },
                alt: {
                    wanted: false,
                    pressed: false,
                },
                meta: {
                    wanted: false,
                    pressed: false,
                }, //Meta is Mac specific
            };

            if (e.ctrlKey) {
                modifiers.ctrl.pressed = true;
            }
            if (e.shiftKey) {
                modifiers.shift.pressed = true;
            }
            if (e.altKey) {
                modifiers.alt.pressed = true;
            }
            if (e.metaKey) {
                modifiers.meta.pressed = true;
            }

            for (var i = 0; (k = keys[i]), i < keys.length; i++) {
                //Modifiers
                if (k == "ctrl" || k == "control") {
                    kp++;
                    modifiers.ctrl.wanted = true;
                } else if (k == "shift") {
                    kp++;
                    modifiers.shift.wanted = true;
                } else if (k == "alt") {
                    kp++;
                    modifiers.alt.wanted = true;
                } else if (k == "meta") {
                    kp++;
                    modifiers.meta.wanted = true;
                } else if (k.length > 1) {
                    //If it is a special key
                    if (special_keys[k] == code) {
                        kp++;
                    }
                } else if (opt.keycode) {
                    if (opt.keycode == code) {
                        kp++;
                    }
                } else {
                    //The special keys did not match
                    if (character == k) {
                        kp++;
                    } else {
                        if (shift_nums[character] && e.shiftKey) {
                            //Stupid Shift key bug created by using lowercase
                            character = shift_nums[character];
                            if (character == k) {
                                kp++;
                            }
                        }
                    }
                }
            }

            if (
                kp == keys.length &&
                modifiers.ctrl.pressed == modifiers.ctrl.wanted &&
                modifiers.shift.pressed == modifiers.shift.wanted &&
                modifiers.alt.pressed == modifiers.alt.wanted &&
                modifiers.meta.pressed == modifiers.meta.wanted
            ) {
                callback(e);

                if (!opt.propagate) {
                    //Stop the event
                    //e.cancelBubble is supported by IE - this will kill the bubbling process.
                    e.cancelBubble = true;
                    e.returnValue = false;

                    //e.stopPropagation works in Firefox.
                    if (e.stopPropagation) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                    return false;
                }
            }
        };
        this.all_shortcuts[shortcut_combination] = {
            callback: func,
            target: ele,
            event: opt.type,
        };
        //Attach the function with the event
        if (ele.addEventListener) {
            ele.addEventListener(opt.type, func, false);
        } else if (ele.attachEvent) {
            ele.attachEvent("on" + opt.type, func);
        } else {
            ele["on" + opt.type] = func;
        }
    },

    //Remove the shortcut - just specify the shortcut and I will remove the binding
    remove: function (shortcut_combination) {
        shortcut_combination = shortcut_combination.toLowerCase();
        var binding = this.all_shortcuts[shortcut_combination];
        delete this.all_shortcuts[shortcut_combination];
        if (!binding) {
            return;
        }
        var type = binding.event;
        var ele = binding.target;
        var callback = binding.callback;

        if (ele.detachEvent) {
            ele.detachEvent("on" + type, callback);
        } else if (ele.removeEventListener) {
            ele.removeEventListener(type, callback, false);
        } else {
            ele["on" + type] = false;
        }
    },
};

/**
 * process request for specific wiki topic
 * @private
 */
function WiKiLink(p_strAppGuid, p_strTargetPageGuid, p_strRecId, p_Headline, p_object) {
    var url = ix.util.getBaseUrl();
    url = ix.util.setUrlValue("rq_RecId", p_strRecId, url);
    url = ix.util.setUrlValue("rq_AppGuid", p_strAppGuid, url);
    url = ix.util.setUrlValue("rq_TargetPageGuid", p_strTargetPageGuid, url);
    url = ix.util.setUrlValue("rq_wikiheadline", p_Headline, url);

    ix.loader.load({
        url: url,
        data: {},
        html: p_object,
    });
}
/**
 * @private
 */
function WiKiToggleHistory(p_strActionId, p_strContainerId) {
    var l_oHtmlAction = document.getElementById(p_strActionId) ? document.getElementById(p_strActionId) : false;
    var l_oHtmlContainer = document.getElementById(p_strContainerId)
        ? document.getElementById(p_strContainerId)
        : false;
    if (l_oHtmlAction && l_oHtmlContainer) {
        // var l_oNotifier = Notifier.element;
        // l_oNotifier.close(p_strActionId);

        // var l_oEntry = new upNotifierEntry_Element_Node();
        // l_oEntry.strKey = p_strActionId;
        // l_oEntry.oNode = l_oHtmlContainer;
        // l_oEntry.oPosition.oElement = l_oHtmlAction;
        // l_oNotifier.show(l_oEntry);

        Notifier.element.notify(l_oHtmlAction, l_oHtmlContainer, "", p_strActionId);
    }
}

/**
 * @deprecated use ix.util.tableRecordInfo(oHtml).getRecord()
 * @ignore
 * @description Get the shaped table record which the given element belongs to.
 * @param {Object} oHtml
 * @return {upShapedTableRecord}
 * @example
 * alert(getTableRecordByRecordHtml(this).getRecId());
 * @example
 * alert($(this).getTableRecord().getRecId());
 */

function getTableRecordByRecordHtml(oHtml) {
    var ret = null;

    if (typeof oHtml === "object" && oHtml !== null) {
        var tableRecInfo = ix.util.tableRecordInfo(oHtml);

        if (typeof tableRecInfo !== "undefined" && tableRecInfo.isBelongsToTable()) {
            ret = tableRecInfo.getRecord();
        }
    }
    return ret;
}

/**
 * @deprecated use ix.util.tableRecordInfo(oHtml).getTable()
 * @ignore
 * @description Get the shaped table which the given element belongs to.
 * @param {Object} oHtml
 * @return {upShapedTable}
 */
function getTableByRecordHtml(oHtml) {
    var ret = null;

    if (typeof oHtml === "object" && oHtml !== null) {
        var tableRecInfo = ix.util.tableRecordInfo(oHtml);

        if (typeof tableRecInfo !== "undefined" && tableRecInfo.isBelongsToTable()) {
            ret = tableRecInfo.getTable();
        }
    }
    return ret;
}
