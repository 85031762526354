/**
 * @file ErrorHandler for Desktop
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ErrorHandler, Notifier, oFupLoader: true, ix, oHtmlRoot, evalResponseJs, DEBUG */

(function (window) {
    // not possible due to "arguments.callee.caller"
    // "use strict";

    /**
     * @ignore
     * @param {Object} error
     * @return {Array<String>} callstack
     * @description Show full error message in case of gently error.
     */
    window.getStackTraceArray = function (error) {
        var callstack = [];
        var lines;

        if (error.stack) {
            lines = error.stack.split("\n");
            for (var i = 0, len = lines.length; i < len; i++) {
                // eslint-disable-next-line no-useless-escape
                if (lines[i].match(/^\s*[A-Za-z0-9\-_\$]+\(/)) {
                    callstack.push(lines[i]);
                }
            }
        } else {
            // IE < 10 and Safari < 6
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack
            var currentFunction = arguments.callee.caller; // eslint-disable-line no-caller
            while (currentFunction) {
                var fn = currentFunction.toString();
                var fname = fn.substring(fn.indexOf("function") + 8, fn.indexOf("")) || "anonymous";
                callstack.push(fname);
                currentFunction = currentFunction.caller;
            }
        }
        return callstack;
    };
})(window);

(function (window, handler) {
    "use strict";

    /**
     * @ignore
     * @description Used for compatibility - maybe useless.
     */
    handler.upType = "upErrorHandler";
    /**
     * @ignore
     * @description If DEBUG is <code>true</code>, then some more informations are given in case of an error.
     */
    handler.DEBUG = false;

    /**
     * @ignore
     * @private
     * @description Waits until the fuploader finishes, then nullifys the fuploader.
     */
    var _nullifyFup = function () {
        if (typeof oFupLoader !== "undefined" && oFupLoader !== null && typeof oFupLoader.onAfterLoad === "function") {
            oFupLoader.onAfterLoad();
            oFupLoader = null;
        }
    };

    /**
     * @ignore
     * @private
     * @param {HTML} errorData
     * @return {Boolean}
     * @description Displays an Intrexx-Error (HTML) in the frontend.
     */
    var _handleHTMLError = function (errorData) {
        var showGently = typeof DEBUG === "boolean" ? !DEBUG : true;
        if (showGently === true) {
            handler.notify(errorData.message, errorData.title, "", {}, errorData, true);
        } else {
            var content = $(errorData.doc).find("body").html();
            if (content.length > 0) {
                var current = document.getElementById("Container_Stage") || document.getElementsByTagName("BODY")[0];
                current.innerHTML = content;
                evalResponseJs(content);
            }
        }
        return false;
    };

    /**
     * @ignore
     * @private
     * @param {Exception} exception
     * @return {Boolean}
     * @description Displays an Javascript Exception in the frontend.
     */
    var _handleJSError = function (exception) {
        handler.notify(exception.description, exception.title);
        return false;
    };

    /**
     * @ignore
     * @private
     * @param {ErrorHandler~ErrorData} errorData
     * @return {JQuery}
     * @description Prepares the error-message for secure output.
     */
    var _prepareMessage = function (errorData) {
        var message = errorData.message || "";
        if (errorData.useAsHtml === true) {
            return message;
        } else {
            var $node = $("<div>");

            // safely prepare error content
            if (typeof message === "string") {
                $node.text(message);
            } else if (message instanceof Object) {
                if (errorData.type === "ValidatorException") {
                    $node.append($("<p>").text(ix.text.i18n.get("ERROR_VALIDATOR_INTRO")));
                }
                var $ul = $("<ul>");
                for (var key in message) {
                    if (message.hasOwnProperty(key)) {
                        var ctrlName = key;
                        var ctrl = $("[name='" + key + "']:first");
                        if (typeof ctrl[0] !== "undefined" && typeof ctrl[0].oUp !== "undefined") {
                            ctrlName = ctrl[0].oUp.userName;
                        }
                        $ul.append($("<li>").append($("<div>").text(ctrlName), $("<div>").text(message[key])));
                    }
                }
                $node.append($ul);
            }

            // append timestamp
            if (errorData.timestamp) {
                $node.append($("<p>").text(errorData.timestamp));
            }

            // append download link
            if (errorData.type === "default" && errorData.silent === false) {
                var url = ix.util.getBaseUrl();
                var UrlHelper = ix.util.UrlHelper;
                url = UrlHelper.setPath("internal/system/vm/html/errorhandler/error_export.vm", url);
                $node.append(
                    $("<a>", {
                        id: "ID_ErrorSave",
                        href: url,
                        class: "Link_Strong",
                        text: ix.text.i18n.get("ERROR_SAVE_AS_TEXTFILE_XML"),
                    }),
                    $("<div>").text(ix.text.i18n.get("ERROR_REPORT_INFORMATIONS"))
                );
            }

            return $node;
        }
    };

    /**
     * @ignore
     * @private
     * @param {ErrorHandler~ErrorData} errorData
     * @return {Boolean}
     * @description Displays an Intrexx-Error (JSON|XML) in the frontend.
     */
    var _handleErrorObject = function (errorData) {
        var message = _prepareMessage(errorData);
        var title = errorData.title || "Java/VTL exception in " + errorData.module.name + " response handler";
        var showEmbedded = typeof errorData.showEmbedded === "boolean" ? errorData.showEmbedded : true;
        var cssClass = errorData.cssClass || "TT_ERROR";
        var type = errorData.type || "default";

        // redirects
        if (errorData.redirectUrl) {
            var redirectUrl = errorData.redirectUrl;
            if (
                typeof errorData.redirectDelay === "number" &&
                !isNaN(errorData.redirectDelay) &&
                errorData.redirectDelay > 0
            ) {
                window.setTimeout(function () {
                    window.location.href = redirectUrl;
                }, errorData.redirectDelay);
            } else {
                window.location.href = redirectUrl;
                return true;
            }
        } else {
            if (type !== "default") {
                var ttpProps = {
                    ixApp: {},
                    data: {},
                    windowSettings: {
                        modal: true,
                    },
                };
                switch (type) {
                    case "LoginRequired":
                        $.extend(true, ttpProps, {
                            data: {
                                rq_VM: "internal/system/vm/html/login/login.vm",
                            },
                            windowSettings: {
                                key: "oTTP_Login",
                                title: "Login",
                                width: 300,
                            },
                        });
                        break;
                    case "ExchangeCfgRequiredException":
                        $.extend(true, ttpProps, {
                            data: {
                                rq_VM: "internal/system/vm/html/exchange/noconfig.vm",
                            },
                            windowSettings: {
                                key: "oTTP_ExcLogin",
                                title: "Exchange Login",
                            },
                        });
                        break;
                    case "ExchangeLoginRequired":
                        $.extend(true, ttpProps, {
                            data: {
                                rq_VM: "internal/system/vm/html/exchange/login.vm",
                            },
                            windowSettings: {
                                key: "oTTP_ExcLogin",
                                title: "Exchange Login",
                            },
                        });
                        break;
                    case "ODataLoginRequired":
                        $.extend(true, ttpProps, {
                            data: {
                                rq_VM: "internal/system/vm/html/odata/login.vm",
                                rq_oCfg: errorData.odatacfgguid,
                                rq_oSvc: errorData.odatasvcguid,
                            },
                            windowSettings: {
                                key: "oTTP_ODataLogin",
                                title: "OData Service Login",
                            },
                        });
                        break;
                    case "ODataKeyStoreRequired":
                        $.extend(true, ttpProps, {
                            data: {
                                rq_VM: "internal/system/vm/html/odata/keystore.vm",
                                rq_oCfg: errorData.odatacfgguid,
                                rq_oSvc: errorData.odatasvcguid,
                            },
                            windowSettings: {
                                key: "oTTP_ODataLogin",
                                title: "OData X.509 Certificate Upload",
                            },
                        });
                        break;
                    case "MFilesLoginRequired":
                        $.extend(true, ttpProps, {
                            data: {
                                rq_VM: "internal/system/vm/html/mfiles/login.vm",
                                rq_oCfg: errorData.mfilescfgguid,
                                rq_oSvc: errorData.mfilessvcguid,
                            },
                            windowSettings: {
                                key: "oTTP_MFilesLogin",
                                title: "M-Files Vault Login",
                            },
                        });
                        break;
                    case "HypArchiveLoginRequired":
                        $.extend(true, ttpProps, {
                            data: {
                                rq_VM: "internal/system/vm/html/hyparchive/login.vm",
                                rq_TooltipTitle: "dghyparchive Login",
                                rq_oCfg: errorData.hyparchivecfgguid,
                            },
                            windowSettings: {
                                key: "oTTP_HypArchiveLogin",
                                title: "HypArchive Login",
                            },
                        });
                        break;
                    default:
                        break;
                }

                if (typeof ttpProps.windowSettings.key !== "undefined") {
                    if (document.getElementById("ID_password")) {
                        document.getElementById("ID_password").focus();
                    } else {
                        ix.loader.tooltip(ttpProps);
                    }
                    return false;
                }
            }
        }

        // embedded
        if (showEmbedded) {
            ErrorHandler.notify(message, title, errorData.functionName, {
                strClassContainer: cssClass,
            });
        } else {
            Notifier.status.error(message, title, errorData.functionName, {
                strClassContainer: cssClass,
            });
        }

        // finish
        _nullifyFup();
        return false;
    };

    /**
     * @ignore
     * @deprecated
     * @description Stores deprecated methods
     */
    var _deprecated = {
        Ajax: {
            /**
             * @ignore
             * @deprecated Use ErrorHandler.handle instead
             * @param {String} functionName
             * @param {Object} request
             * @return {Boolean}
             * @description Checks for and handles a invalid XML response of an AJAX request,
             * which is either an error node or broken XML.
             */
            checkXMLResponse: function (functionName, request) {
                return ErrorHandler.handle.call(this, request, functionName);
            },
            /**
             * @ignore
             * @deprecated Use ErrorHandler.handle instead
             * @param {String} functionName
             * @param {Object} request
             * @return {Boolean}
             * @description Checks for and handles a invalid JSON response of an AJAX request,
             * which is either an error object or invalid JSON.
             */
            checkJSONResponse: function (functionName, request) {
                return ErrorHandler.handle.call(this, request, functionName);
            },
            /**
             * @ignore
             * @deprecated Use ErrorHandler.handle instead
             * @param {String} functionName
             * @param {Object} request
             * @return {Boolean}
             * @description Checks for and handles a invalid javascript response of an AJAX request.
             */
            checkJsResponse: function (functionName, request) {
                return ErrorHandler.handle.call(this, request, functionName);
            },
            /**
             * @ignore
             * @deprecated Use ErrorHandler.handle instead
             * @param {String} functionName
             * @param {Object} request
             * @param {Object} exc
             * @return {Promise}
             * @description Handles javascript exceptions thrown by an AJAX responsehandler.
             */
            handleJsException: function (functionName, request, exc) {
                if (typeof exc !== "undefined" && exc instanceof Error) {
                    console.warn("ErrorHandler.Ajax.handleJsException is deprecated.");
                    console.error(exc);
                }
                return ErrorHandler.handle.call(this, request, functionName);
            },
            /**
             * @ignore
             * @description This prototype handles JSON-Response-Errors.
             */
            JSONResponse: {
                /**
                 * @param {Object} errorData The JSON-Error-Object.
                 * @param {String} functionName The name of the failing function.
                 * @return {Promise}
                 * @description Handles a JSON-Error-Object.
                 */
                handleErrorObject: function (errorData, functionName) {
                    return ErrorHandler.handle.call(this, errorData, functionName);
                },
            },
            /**
             * @ignore
             * @description This prototype handles JSON-Response-Errors.
             */
            HTMLResponse: {
                /**
                 * @param {String} functionName
                 * @param {Object} request
                 * @return {Promise}
                 * @description Handles html errorpages, which are in the response (e.g. "Portalserver down").
                 */
                handleErrorPage: function (functionName, request) {
                    return ErrorHandler.handle.call(this, request, functionName);
                },
            },
            /**
             * @ignore
             * @description This prototype handles XML-Response-Errors.
             */
            XMLResponse: {
                /**
                 * @param {Object} xml
                 * @param {String} functionName
                 * @return {Promise}
                 * @description Handles the errornode in response XML.
                 * If an errorhandler is defined in the handler attribute, this one is executed.
                 * Otherwise the method brings up a tooltip which shows the exception message.
                 */
                handleErrorNode: function (xml, functionName) {
                    return ErrorHandler.handle.call(this, xml, functionName);
                },
            },
        },
        Http: {
            /**
             * @param {String} functionName
             * @param {Object} request
             * @return {Boolean}
             * @description Handles http status codes other than 200.
             */
            handleStatus: function (functionName, request) {
                return ErrorHandler.handle.call(this, request, functionName);
            },
        },
        /**
         * @ignore
         * @description Handles javascript exceptions.
         */
        Javascript: {
            /**
             * @ignore
             * @deprecated Use ErrorHandler.handle instead
             * @param {String} functionName Name of the failing function as a string.
             * @param {Object} exception Exception.
             * @param {String} [description] Closer description e.g. "in onload".
             * @param {String} [code] Code, which is evaluated.
             * @description Handles javascript exceptions thrown by an eval.
             */
            handleEvalError: function (functionName, exception, description, code) {
                var title = "Javascript Eval exception";
                var message = "Error detected while evaluating javascript code";
                if (arguments.length > 2) {
                    message += " " + description;
                }
                message += "<br/><br/>cause:<br/>" + exception.message + "<br />";
                if (exception.stack) {
                    message += exception.stack.substring(0, 200) + " .......  <br />";
                }
                if (arguments.length > 3) {
                    if (code.length > 100) {
                        message += '<br />code:<br /><textarea style="width: 100%; height:100px;">';
                        message += code + "</textarea><br />";
                    } else {
                        message += "<br />code:<br />" + code + "<br />";
                    }
                }
                ErrorHandler.notify(message, title, functionName);
                console.error(exception);
                return;
            },
        },
    };

    var _modules = {
        json: {
            handle: function (errorData) {
                var _deferred = errorData.deferred || $.Deferred();
                _deferred.then(_handleErrorObject(errorData));
                return !_deferred.resolve();
            },
        },
        xml: {
            handle: function (errorData) {
                var _deferred = errorData.deferred || $.Deferred();
                _deferred.then(_handleErrorObject(errorData));
                return !_deferred.resolve();
            },
        },
        html: {
            handle: function (errorData) {
                var _deferred = errorData.deferred || $.Deferred();
                _deferred.then(_handleHTMLError(errorData));
                return !_deferred.resolve();
            },
        },
        js: {
            handle: function (errorData) {
                var _deferred = errorData.deferred || $.Deferred();
                _deferred.then(_handleJSError(errorData));
                return !_deferred.resolve();
            },
        },
    };

    /**
     * @deprecated Use console.log() instead.
     * @ignore
     * @param {String} message The Message to log.
     * @param {String} functionName Name of the failing function as a string.
     * @description Writes logs in the console.
     */
    handler.log = function (message, functionName) {
        var out = "Intrexx: " + message + "\n" + functionName;
        console.log(out);
    };

    /**
     * @deprecated Use console.warn() instead.
     * @ignore
     * @param {Object} exc The Exception to log.
     * @description Writes warnings in the console.
     */
    handler.warn = function (exc) {
        console.warn(exc);
    };

    /**
     * @ignore
     * @param {String|JQuery|HTMLElement} [message=""]
     * @param {String} title
     * @param {String} [key]
     * @param {Object} [properties={"width":25,"widthUnit":"%"}]
     * @param {Object} [error]
     * @param {Boolean} [showGently=!DEBUG]
     * @description Displays the error message.
     */
    handler.notify = function (message, title, key, properties, error, showGently) {
        properties = $.extend(
            true,
            {
                width: window.innerWidth <= 576 ? 100 : window.innerWidth <= 768 ? 75 : 25,
                widthUnit: "%",
            },
            properties
        );
        if (!key) {
            key = "errortooltip";
        }
        if (typeof showGently !== "boolean" && typeof DEBUG === "boolean") {
            showGently = !DEBUG;
        } else {
            showGently = true;
        }
        if (typeof error === "object" && error !== null) {
            if (typeof message !== "undefined" && message instanceof jQuery) {
                message.append(
                    $("<p>", {
                        text: error.message,
                    })
                );
            }
            if (typeof error.stack !== "undefined") {
                console.log(error.stack);
            }
        }

        if (showGently) {
            var gentlyTitle = ix.text.i18n.get("ERROR_GENTLY_TITLE");
            gentlyTitle += '<span style="float:right; margin-right:30px; margin-left:10px;">';
            gentlyTitle += new ix.text.SimpleDateFormat(
                oHtmlRoot.oUp.oFormatInfo.dateFormat + " " + oHtmlRoot.oUp.oFormatInfo.timeFormat
            ).format(new Date());
            gentlyTitle += "</span>";
            var gentlyMessage = $("<div>", {
                css: {
                    "text-align": "center",
                    padding: "10px 0",
                },
            });
            gentlyMessage.append(
                $("<img>", {
                    src: "/images/assets/common/gently_error.png",
                    css: {
                        width: "60px",
                        height: "60px",
                        border: "0",
                    },
                }).attr("aria-hidden", "true"),

                $("<div>", {
                    text: ix.text.i18n.get("ERROR_GENTLY_MESSAGE"),
                    css: {
                        "padding-top": "10px",
                    },
                    id: "ErrorMsg",
                }),
                $("<a>", {
                    text: ix.text.i18n.get("ERROR_GENTLY_LINK"),
                    css: {
                        cursor: "pointer",
                    },
                }).on("click", function () {
                    ix.tooltip.close(key);
                    Notifier.status.error(message, title, "error_" + key, properties);
                })
            );
            Notifier.status.error(gentlyMessage, gentlyTitle, key, properties);
        } else {
            Notifier.status.error(message, title, key, properties);
        }
    };

    /**
     * @ignore
     * @param {ErrorHandler~ErrorData} errorData The error data object.
     * @return {Boolean}
     * @description Handles ErrorData directly, i.e. for Non-Ajax-Calls.
     */
    handler.show = function (errorData) {
        return _handleErrorObject(errorData);
    };

    $.extend(true, handler.modules, _modules);
    $.extend(true, handler, _deprecated);
})(window, ErrorHandler);
