/**
 * @file Prototype upBrowser - additional methods
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals Browser, trim, ErrorHandler, ix */
/**
 * @ignore
 * @param {upControl} ctrl
 * @return {String|Boolean}
 * @description Reads value of a ViewControl or EditControl and returns the value unformatted.
 */
Browser.getRawValue = function (ctrl) {
    if (!ctrl || !ctrl.oHtml) {
        return false;
    }
    var val = this.getValue(ctrl);
    if (typeof val === "string" && val.length === 0) {
        return "";
    }
    if (!val) {
        return false;
    }

    var raw = "";
    var dt;
    switch (ctrl.upType) {
        case "upIntegerControl":
        case "upFloatControl":
        case "upCurrencyControl":
            raw = ctrl.toJSNumber(val);
            break;
        case "upDateControl":
            dt = ctrl.setDateObjectFromLocal(val);
            raw = ctrl.toISODateString(dt);
            break;
        case "upTimeControl":
            dt = ctrl.setDateObjectFromLocal(val);
            raw = ctrl.toISOTimeString(dt);
            break;
        case "upDateTimeControl":
            dt = ctrl.setDateObjectFromLocal(val);
            raw = ctrl.toISODateTimeString(dt);
            break;
        default:
            raw = val;
    }
    return raw;
};

/**
 * @ignore
 * @param {HTMLElement|upControl} ctrl
 * @param {String} attribute
 * @description Reads (internal) value of a control from ViewControl or EditControl like viewcontrols browserspec.
 */
Browser.getValueByElementId = function (ctrl, attribute) {
    if (arguments.length < 1) {
        return false;
    }
    var element = Browser.getRef(ctrl);
    if (element.oUp) {
        return Browser.getValue(element.oUp, attribute);
    } else {
        return Browser.getValue(element, attribute);
    }
};

/**
 * @ignore
 * @param {String} id Id of the select.
 * @param {String} text Displayed text for the option.
 * @param {String} [value=""] Value for the option.
 * @param {Document} [doc=document]
 * @return {Boolean}
 * @description Adds an option to a select.
 */
Browser.insertOptByElementId = function (id, text, value, doc) {
    if (arguments.length < 2) {
        return false;
    }
    if (arguments.length === 2) {
        value = "";
    }
    if (arguments.length < 4) {
        doc = document;
    }
    var element = null;
    element = Browser.getRef(id, doc);
    if (!element || !(element.oUp.upType === "upListboxControl" || element.oUp.upType === "upDropdownControl")) {
        return false;
    } else {
        var newOpt = new Option(text, value);
        element.options[element.options.length] = newOpt;
    }
};

/**
 * @ignore
 * @param {String} target
 * @param {Document} doc
 * @return {Boolean}
 * @description Jumps in document to given anchor.
 */
Browser.gotoAnchor = function (target, doc) {
    if (typeof doc !== "object" || doc === null) {
        doc = document;
    }

    try {
        // liegt der Anchor im nicht sichtbaren Bereich (Viewport) dann soll der Anchor angesprungen werden
        // Achtung: Der Browser versucht den Anchor immer an den Anfang des Viewports zu schieben (wenn die Seite gross genug ist). Eventuell fix positionierte Container können den Anchor überlagern.
        if (!$('[name="' + target + '"],[id="' + target + '"]').visible()) {
            doc.location.hash = target;
        }
    } catch (e) {
        ErrorHandler.warn(e, "Browser#gotoAnchor");
        return false;
    }
    return true;
};

/**
 * @ignore
 * @param {HTMLElement|upObject} ctrl
 * @param {Object} value
 * @return {Boolean}
 * @description Inserts value to a TextArea control at current cursor position.
 */
Browser.insertValue = function (ctrl, value) {
    var done = false;
    if (typeof ctrl !== "undefined") {
        if (ctrl instanceof HTMLElement) {
            ctrl = ctrl.oUp;
        }
    } else {
        return false;
    }
    var element = ctrl.oHtml;
    if (arguments.length < 2) {
        value = "";
    }
    var valueType;
    var valueBefore;
    var valueAfter;
    var selectedValue;
    var defaultValue;

    if (typeof value == "string") {
        valueType = "string";
        while (value.indexOf("=0D") > -1) {
            value = value.replace(/=0D/, "\n");
        }
    } else if (typeof value === "object" && typeof value.length === "number" && value.length >= 2) {
        /* TagBefore TagAfter */
        valueType = "array";
        valueBefore = value[0];
        valueAfter = value[1];
        defaultValue = "";
        selectedValue = Browser.getValue(ctrl, "selection");

        if (value.length == 3) {
            defaultValue = value[2];
        }

        if (typeof selectedValue !== "string" || trim(selectedValue).length === 0) {
            if (defaultValue.length > 0) {
                selectedValue = defaultValue;
            } else {
                selectedValue = "";
            }
        }
    }

    if (ctrl.upType === "UpTinyMCE") {
        if (valueBefore !== undefined && valueAfter !== undefined) {
            value = valueBefore + valueAfter;
        }
        ctrl.Editor.execCommand("mceInsertContent", false, value);
    }

    if (ctrl.bEdit) {
        if (ctrl.upType == "upTextareaEditControl") {
            if (ctrl.editor && ctrl.editor == "1") {
                //ETT2015
                var fm = ix.util.getForm(ctrl.oHtml);
                if (typeof fm.oUp.oFuncPart !== "undefined" && fm.oUp.oFuncPart !== null) {
                    while (value.indexOf("=0D") > -1) {
                        value = value.replace(/=0D/, "<br>");
                    }
                    //ETT2015
                    if (ctrl.editorType == "tinymce") {
                        if (valueType == "array") {
                            if (valueBefore !== undefined && valueAfter !== undefined) {
                                value = valueBefore + selectedValue + valueAfter;
                            }
                        }
                        try {
                            $(ctrl.oHtml).tinymce().execCommand("mceReplaceContent", false, value);
                            done = true;
                        } catch (e) {
                            console.trace(e);
                        }
                    }
                }
            }
            if (!done) {
                element.focus();
                var insText;
                /* fuer Internet Explorer */
                if (typeof document.selection != "undefined") {
                    /* Einfuegen des Formatierungscodes */
                    /* pruefen ob default selektierung einen wert hat */
                    var range;
                    if ($(element).data("selection")) {
                        range = $(element).data("selection");
                    } else {
                        range = document.selection.createRange();
                    }

                    insText = range.text;

                    if (valueType == "array") {
                        if (range.text.length === 0) {
                            insText = defaultValue;
                        }
                        if (valueBefore !== undefined && valueAfter !== undefined) {
                            range.text = valueBefore + insText + valueAfter;
                        }
                    } else {
                        range.text = value;
                    }
                    /* Anpassen der Cursorposition */
                    range = document.selection.createRange();
                    if (insText.length === 0) {
                        //range.move('character', -range.text.length);
                    } else {
                        range.moveStart("character", range.text.length);
                    }
                    range.select();
                } else if (typeof element.selectionStart != "undefined") {
                    /* fuer neuere auf Gecko basierende Browser */
                    /* Einfuegen des Formatierungscodes */
                    var start = element.selectionStart;
                    var end = element.selectionEnd;
                    insText = element.value.substring(start, end);

                    if (valueType == "array") {
                        if (insText.length === 0) {
                            insText = defaultValue;
                        }
                        if (valueBefore !== undefined && valueAfter !== undefined) {
                            value = valueBefore + insText + valueAfter;
                        }
                    }

                    element.value = element.value.substr(0, start) + value + element.value.substr(end);
                    /* Anpassen der Cursorposition */
                    var pos;
                    if (value.length === 0) {
                        pos = start + value.length;
                    } else {
                        pos = start + value.length;
                    }
                    element.selectionStart = pos;
                    element.selectionEnd = pos;
                } else {
                    /* fuer die uebrigen Browser */
                    element.value += value;
                }
            }
        }
    }
    return true;
};

/**
 * @ignore
 * @param {String} id
 * @description Support for Geolocation.
 */
Browser.changeMapsUrl = function (id) {
    if (typeof navigator.geolocation == "undefined") {
        alert("No Geolocation Support!");
    }
    navigator.geolocation.getCurrentPosition(
        function (position) {
            var loc = position.coords.latitude + "," + position.coords.longitude;
            location.href = document.getElementById(id).href.replace(/geolocation/, loc);
        },
        function (e) {
            alert(e.message);
        }
    );
};
