/**
 * @file Generic Browser class and methods.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals
   Browser:true, Helper, DEBUG, InvalidArgumentException, isUndefinedOrNull, ErrorHandler,
   oHtmlRoot, upObject, upActionControl,
   UpTimeEditControl, UpDateEditControl, UpDateTimeEditControl
*/

/* exported Browser */

/**
 * @ignore
 * @description Check if Microsoft's Pointer API is available and if Browser supports a minimum
 * of one touchPoint Event (i.e. at least one-figer-gestures).
 */
var isMsTouchDevice = function () {
    var hasPointers = false;
    // old style pointer api with browser prefix
    if (typeof window.navigator.msPointerEnabled !== "undefined" && window.navigator.msPointerEnabled) {
        if (typeof navigator.msMaxTouchPoints !== "undefined" && navigator.msMaxTouchPoints > 0) {
            hasPointers = true;
        }
    } else if (typeof window.PointerEvent !== "undefined" && window.PointerEvent) {
        // new style pointer api without prefixes
        if (typeof navigator.maxTouchPoints !== "undefined" && navigator.maxTouchPoints > 0) {
            hasPointers = true;
        }
    }
    return hasPointers;
};

/**
 * @namespace Browser
 * @description Encapsulates cross-browser-methods.
 */
Browser = {
    /**
     * @ignore
     * @type {String}
     */
    upType: "Browser",

    /**
     * @ignore
     * @param {JSON} json
     */
    setBrowserAgents: function (json) {
        // agents vom server setzen
        for (var agent in json) {
            if (json.hasOwnProperty(agent)) {
                this[agent] = json[agent];
            }
        }
        /**
         * @ignore
         */
        this.dom = !!document.documentElement;
    },

    /**
     * @property {Object} supports
     * @property {Boolean} supports.touch
     * @property {Boolean} supports.orientation
     * @property {Boolean} supports.fileApi
     * @property {Boolean} supports.geolocation
     * @property {Boolean} supports.webWorkers
     * @property {Boolean} supports.offline
     * @property {Boolean} supports.historyApi
     * @property {Boolean} supports.localStorage
     * @description Object containing information about the support for different technologies.
     */
    supports: {
        touch: !!("ontouchstart" in window || isMsTouchDevice()),
        /* jshint -W018 */
        orientation: !!("orientation" in window),
        fileApi: !!(typeof FileReader !== "undefined"),
        geolocation: !!("geolocation" in navigator),
        webWorkers: !!window.Worker,
        offline: !!window.applicationCache,
        historyApi: !!(window.history && history.pushState),
        /* jshint +W018 */
        localStorage: (function () {
            try {
                /* jshint -W069 */
                return "localStorage" in window && window.localStorage !== null;
                /* jshint +W069 */
            } catch (e) {
                return false;
            }
        })(),
        /**
         * @ignore
         * @description Cross-Origin resource sharing.
         */
        cors: false,
        corsDetail: "",

        /**
         * @ignore
         * @description Check Browser support for a specific Html5 input type.
         * @example
         * alert(Browser.supports.inputType.range)
         */
        inputType: (function (types) {
            var f = document.createElement("input");
            var it = {};
            var type;
            for (var i in types) {
                if (types.hasOwnProperty(i)) {
                    type = types[i];
                    f.setAttribute("type", type);
                    /* jshint -W018 */
                    it[type] = !!(f.type !== "text");
                    /* jshint +W018 */
                }
            }
            return it;
        })("search tel url email datetime date month week time datetime-local number range color".split(" ")),

        /**
         * @ignore
         * @description Check Browser support for a specific Html5 input attribute.
         * @example
         * alert(Browser.supports.inputAttribute.placeholder)
         */
        inputAttribute: (function (props) {
            var f = document.createElement("input");
            var ia = {};
            var attribute;
            for (var i in props) {
                if (props.hasOwnProperty(i)) {
                    attribute = props[i];
                    ia[attribute] = !!(attribute in f);
                }
            }
            return ia;
        })("autocomplete autofocus list placeholder max min multiple pattern required step".split(" ")),
    },
};

// check if Cross-Origin Resource Sharing is possible
if ("withCredentials" in new XMLHttpRequest()) {
    Browser.supports.cors = true;
    Browser.supports.corsDetail = "XHR";
} else if (typeof XDomainRequest !== "undefined") {
    Browser.supports.cors = true;
    Browser.supports.corsDetail = "XDR";
} else {
    Browser.supports.cors = false;
}

/**
 * @ignore
 * @description Reads value of a HtmlControl.
 * Sub-method, called by BrowserGetValue
 */
Browser._getValueUpObject = function (control, attribute) {
    var ret = false;
    var items;
    var settings = {
        type: "String",
    };
    if (typeof attribute !== "undefined") {
        settings.attr = attribute;
    }

    var controlType = !isUndefinedOrNull(control.upType) ? control.upType.toString() : "";

    if (
        control instanceof (UpTimeEditControl || UpDateEditControl || UpDateTimeEditControl) &&
        attribute === "offset" &&
        typeof attribute.value === "string"
    ) {
        ret = control.getDateObject();
        if (ret !== null) {
            ret = Browser.addOffset(attribute.value, ret);
            ret = control.toLocalFormat(ret);
        }
    } else if (
        controlType === "upDropdownControl" ||
        controlType === "upListboxControl" ||
        controlType === "upExtendedListboxControl"
    ) {
        // Spezifischen Wert aus einem zusammengesetzten PK ermitteln
        if (
            typeof attribute == "object" &&
            attribute !== null &&
            typeof attribute.attr != "undefined" &&
            attribute.attr == "key" &&
            typeof attribute.partKey != "undefined"
        ) {
            ret = control.getPartKeyValue(attribute.partKey);
        } else if (typeof attribute != "string" || attribute == "value") {
            // Wert der an Server geschickt wird
            ret = control.getValue();
        } else if (attribute === "key") {
            // RecId
            ret = control.getKeyValue();
        } else if (attribute == "store") {
            // gespeicherter Wert (unformatierter value)
            ret = control.getRawValue();
        } else {
            // angezeigte Wert
            ret = control.getDisplayValue();
        }

        // Hinweis: Historisch bedingt muss die Methode bei mehr als einem
        // selektierten Eintrag einen String mit den konkatenierten Einträgen zurückliefern
        if ($.isArray(ret)) {
            /*jshint -W053 */
            ret = new String(Helper.doCharStuffing(ret));
            ret.bStuffed = true;
            /*jshint +W053 */
        }
    } else if (controlType === "upTextareaControl") {
        if (
            typeof control.editor !== "undefined" &&
            control.editor == "1" &&
            typeof attribute !== "undefined" &&
            attribute.toString() == "selection"
        ) {
            ret = control.getSelection();
        } else {
            ret = control.getValue();
        }
    } else if (controlType === "upFileEditControl") {
        items = [];
        items = control.getValue();
        if (items.length === 1) {
            ret = items[0];
        } else {
            ret = Helper.doCharStuffing(items);
        }
    } else if (controlType === "upCheckControl") {
        if (typeof attribute == "string" && attribute == "text") {
            ret = control.isChecked() ? self.oUp.oMessage.CHECK_ON : self.oUp.oMessage.CHECK_OFF;
        } else {
            ret = control.isChecked() ? "1" : "0";
        }
    } else if (controlType === "upCheckControlGroup") {
        items = [];
        if (attribute === "text") {
            items = control.getSelectedTitles();
        } else {
            items = control.getSelectedValues();
        }

        /*jshint -W053 */
        ret = new String(Helper.doCharStuffing(items));
        ret.bStuffed = true;
        /*jshint +W053 */
    } else if (controlType === "upTagControl") {
        ret = control.getValue();
    } else if (attribute === "text" && control.getDisplayValue) {
        ret = control.getDisplayValue();
    } else {
        // @Todo settings muss herausgenommen werden!
        ret = control.getValue(settings);
    }

    // Kompatibiliät zu alten Browser Methoden sicherstellen
    if (typeof ret === "undefined" || ret === null) {
        ret = false;
    }
    return ret;
};

/**
 * @ignore
 * @description Reads value of a HtmlControl.
 * Sub-method, called by BrowserGetValue
 */
Browser._setValueUpObject = function (control, value, attr, isTriggerOnChange) {
    var ret = false;
    var values = null;
    var settings = {
        triggerOnchange: "always",
    };
    var ignoreStuffedValueCtrlClasses = [
        window.upTextMainControl,
        window.UpTextareaEditControl,
        window.UpTextareaVControl,
    ];

    try {
        if (typeof control === "undefined") {
            throw new InvalidArgumentException("Element must not be undefined");
        }

        if (
            value !== null &&
            (typeof value === "string" || (typeof value === "object" && value.bStuffed)) &&
            // kann die aktuelle Kontrolle mit Arrays umgehen?
            (function (control, ignoreStuffedValueCtrlClasses) {
                for (var i = 0, l = ignoreStuffedValueCtrlClasses.length; i < l; i++) {
                    if (control instanceof ignoreStuffedValueCtrlClasses[i]) {
                        return false;
                    }
                }
                return true;
            })(control, ignoreStuffedValueCtrlClasses)
        ) {
            // Stuffed String in Array umwandeln
            value = Helper.undoCharStuffing(value);
            if ($.isArray(value) && value.length === 1) {
                value = value[0];
            }
        }

        if (typeof isTriggerOnChange === "boolean" && !isTriggerOnChange) {
            settings.triggerOnchange = "disabled";
        }

        var controlType = !isUndefinedOrNull(control.upType) ? control.upType.toString() : "";

        if (
            controlType === "upDropdownControl" ||
            controlType === "upListboxControl" ||
            controlType === "upExtendedListboxControl"
        ) {
            if (typeof control.settings.triggerOnchange !== "undefined") {
                settings.triggerOnchange = control.settings.triggerOnchange;
            }
            if (typeof attr !== "string") {
                attr = control.compareText === "1" ? "text" : "value";
            }

            if (attr == "text") {
                ret = control.setDisplayValue(value, settings);
            } else if (attr == "store") {
                ret = control.setRawValue(value, settings);
            } else if (attr == "key") {
                ret = control.setKeyValue(value, settings);
            } else {
                if (isNaN(value)) {
                    ret = control.setValue(value, settings);
                } else {
                    // convert number to string due to ix-8908
                    ret = control.setValue("" + value, settings);
                }
            }
        } else if (control instanceof (UpTimeEditControl || UpDateEditControl || UpDateTimeEditControl)) {
            if (typeof value == "string" && value.split(",").length == 6) {
                var dt = new Date();
                dt = Browser.addOffset(value, dt);
                ret = control.setDateObject(dt, settings);
            } else if (typeof value === "object" && typeof value.getTime == "function") {
                ret = control.setDateObject(value, settings);
            } else {
                ret = control.setValue(value, settings);
            }
        } else if (controlType === "upTextareaControl") {
            ret = control.setValue(value, settings);
        } else if (controlType === "upCheckControl") {
            var checkStatus = false;
            settings.triggerOnchange = isTriggerOnChange || typeof isTriggerOnChange === "undefined";
            if (typeof isTriggerOnChange === "undefined") {
                settings.triggerOnchange = "always";
            } else if (
                (typeof isTriggerOnChange === "boolean" && !isTriggerOnChange) ||
                isTriggerOnChange === 0 ||
                isTriggerOnChange === "0" ||
                isTriggerOnChange === "false"
            ) {
                settings.triggerOnchange = "disabled";
            }
            if ((typeof value === "boolean" && value) || value === "1" || value === "true" || value === 1) {
                checkStatus = true;
            }
            if (typeof value === "undefined") {
                checkStatus = true;
            }
            ret = control.setValue(checkStatus, settings);
        } else if (controlType === "upCheckControlGroup") {
            values = null;
            if ($.isArray(value)) {
                values = value;
            } else if (
                (typeof value === "object" && value.bStuffed) ||
                typeof value === "string" ||
                (typeof value === "object" && value instanceof String)
            ) {
                values = Helper.undoCharStuffing(value);
            }
            ret = control.setValue(values, settings);
        } else if (controlType === "upRadioControlGroup") {
            values = null;
            if ($.isArray(value)) {
                values = value;
            } else if (
                (typeof value === "object" && value.bStuffed) ||
                typeof value === "string" ||
                (typeof value === "object" && value instanceof String)
            ) {
                values = Helper.undoCharStuffing(value);
            }
            ret = control.setValue(values, settings);
        } else if (controlType === "upTagControl") {
            if (typeof value === "string") {
                ret = control.setValue(value);
            }
        } else if (controlType === "upRangeFilterControl") {
            var valueFrom;
            var valueTo;
            if (attr == "from") {
                valueFrom = value;
                valueTo = control.valueTo;
            } else if (attr == "to") {
                valueFrom = control.valueFrom;
                valueTo = value;
            } else if (typeof value == "object" && value.from && value.to) {
                valueFrom = value.from;
                valueTo = value.to;
            }
            control.setValue(valueFrom, valueTo, settings);
            ret = true;
        } else {
            // @TODO Herausnehmen, attr darf nicht in die ctrl methoden durchgeschleust werden
            if (typeof attr !== "undefined") {
                settings.attr = attr;
            }
            ret = control.setValue(value, settings);
        }
    } catch (ex) {
        if (typeof DEBUG !== "undefined" && !!DEBUG) {
            console.log(ex);
        }
    }
    return ret;
};

/**
 * @private
 * @ignore
 */
Browser.addOffset = function (offset, date) {
    /*
     * y,m,d,h,m,s
     */
    var aOffset = offset.split(",");
    var d = date;
    if (aOffset && aOffset.length == 6) {
        if (aOffset[0].length > 0) {
            if (aOffset[0].substr(0, 1) == "+" || aOffset[0].substr(0, 1) == "-") {
                d = Helper.dateAdd("y", parseInt(aOffset[0]), d);
            } else if (aOffset[0].length == 4) {
                d.setFullYear(parseInt(aOffset[0]));
            }
        }
        if (aOffset[1].length > 0) {
            if (aOffset[1].substr(0, 1) == "+" || aOffset[1].substr(0, 1) == "-") {
                d = Helper.dateAdd("mo", parseInt(aOffset[1]), d);
            } else {
                d.setMonth(parseInt(aOffset[1]) - 1);
            }
        }
        if (aOffset[2].length > 0) {
            if (aOffset[2].substr(0, 1) == "+" || aOffset[2].substr(0, 1) == "-") {
                d = Helper.dateAdd("d", parseInt(aOffset[2]), d);
            } else {
                d.setDate(parseInt(aOffset[2]));
            }
        }
        if (aOffset[3].length > 0) {
            if (aOffset[3].substr(0, 1) == "+" || aOffset[3].substr(0, 1) == "-") {
                d = Helper.dateAdd("h", parseInt(aOffset[3]), d);
            } else {
                d.setHours(parseInt(aOffset[3]));
            }
        }
        if (aOffset[4].length > 0) {
            if (aOffset[4].substr(0, 1) == "+" || aOffset[4].substr(0, 1) == "-") {
                d = Helper.dateAdd("mi", parseInt(aOffset[4]), d);
            } else {
                d.setMinutes(parseInt(aOffset[4]));
            }
        }
        if (aOffset[5].length > 0) {
            if (aOffset[5].substr(0, 1) == "+" || aOffset[5].substr(0, 1) == "-") {
                d = Helper.dateAdd("s", parseInt(aOffset[5]), d);
            } else {
                d.setSeconds(parseInt(aOffset[5]));
            }
        }
        d.setMilliseconds(0);
    }
    return d;
};
/**
 * @ignore
 * @param {String} code Javascript code as string.
 * @description Evaluates javascript code in global context.<br>
 * Variables are declared inside the window namespace and not only in the eval namespace.
 */
Browser.evalInGlobalContext = function (code) {
    /* @TODO Please this whole method needs to be removed, due to security concerns. */
    if (typeof code !== "string" || code.length < 1) {
        return;
    }
    try {
        var that = oHtmlRoot ? oHtmlRoot : window;
        if (that.eval) {
            that.eval(code);
        } else {
            eval(code); // eslint-disable-line no-eval
        }
    } catch (e) {
        console.log("Browser#evalInGlobalContext: An error occurred during evaluating JavaScript Code:");
        console.log(code);
        throw e;
    }
};
/**
 * @ignore
 * @param {Window} win
 * @return {Number}
 * @description Returns the inner width of a window.
 */
Browser.getClientWidth = function (win) {
    var docRoot = win.document.documentElement;
    return parseInt(docRoot.clientWidth, 10);
};

/**
 * @ignore
 * @param {Window} win
 * @return {Number}
 * @description Returns the inner height of a window.
 */
Browser.getClientHeight = function (win) {
    var docRoot = win.document.documentElement;
    return parseInt(docRoot.clientHeight, 10);
};

/**
 * @ignore
 * @param {upWindow} win
 * @return {Number}
 * @description Returns the outer height of a window.
 */
Browser.getWinHeight = function (win) {
    var height = 0;
    // Mozilla und Opera
    if (win.oHtml.outerHeight) {
        height = win.oHtml.outerHeight;
    } else {
        height = win.height;
    }
    return height;
};

/**
 * @ignore
 * @param {upWindow} win
 * @return {Number}
 * @description Returns the outer width of a window.
 */
Browser.getWinWidth = function (win) {
    var width = 0;
    // Mozilla und Opera
    if (win.oHtml.outerWidth) {
        width = win.oHtml.outerWidth;
    } else {
        width = win.width;
    }
    return width;
};

/**
 * @ignore
 * @param {Window} win
 * @return {Number}
 * @description Returns the horizontal scrolling offset.
 */
Browser.getScrollOffsetLeft = function (win) {
    if (!isNaN(win.pageXOffset)) {
        return win.pageXOffset;
    } else {
        return Browser.getScrollLeft(win);
    }
};

/**
 * @ignore
 * @param {Window} win
 * @return {Number}
 * @description Returns the vertical scrolling offset.
 */
Browser.getScrollOffsetTop = function (win) {
    if (!isNaN(win.pageYOffset)) {
        return win.pageYOffset;
    } else {
        return Browser.getScrollTop(win);
    }
};

/**
 * @ignore
 * @param {Window} win
 * @return {Number}
 * @description Returns the outer height of a window.
 */
Browser.getOffsetHeight = function (win) {
    var docRoot = win.document.documentElement;
    return parseInt(docRoot.offsetHeight, 10);
};

/**
 * @ignore
 * @param {Window} win
 * @return {Number}
 * @description Returns the outer width of a window.
 */
Browser.getOffsetWidth = function (win) {
    var docRoot = win.document.documentElement;
    return parseInt(docRoot.offsetWidth, 10);
};

/**
 * @ignore
 * @param {Window|HTMLElement} element
 * @param {String} [containerType="offset"]
 * @return {Number}
 * @description Returns the vertical scrolling offset.
 */
Browser.getScrollTop = function (element, containerType) {
    if (arguments.length < 2) {
        containerType = "offset";
    }
    if (typeof element.tagName === "undefined") {
        return $(window).scrollTop();
    } else {
        var scroll = 0;
        var parent = element;
        var pos;
        var overflow;
        var stop;
        var isParentScrollable = false;

        while (parent) {
            stop = false;
            if (containerType == "scroll") {
                overflow = parent.style.overflow;
                isParentScrollable = overflow == "hidden" || overflow == "auto" || overflow == "scroll";
                if (!isParentScrollable) {
                    overflow = parent.style.overflowX;
                    isParentScrollable = overflow == "hidden" || overflow == "auto" || overflow == "scroll";
                    if (!isParentScrollable) {
                        overflow = parent.style.overflowY;
                        isParentScrollable = overflow == "hidden" || overflow == "auto" || overflow == "scroll";
                    }
                }
                if (isParentScrollable) {
                    scroll += parent.scrollTop;
                    stop = true;
                    break;
                }
            } else if (containerType == "overlow") {
                pos = parent.style.position;
                if (pos == "fixed" || pos == "absolute" || pos == "relative") {
                    scroll += parent.scrollTop;
                    stop = true;
                    break;
                }
            }
            // DenisK: ???
            if (!stop && parent.tagName === "DIV" && parent.scrollTop) {
                scroll += parent.scrollTop;
            }
            parent = parent.offsetParent;
            if (stop) {
                break;
            }
        }

        if (containerType == "scroll" && !isParentScrollable) {
            return this.getScrollTop(self);
        } else {
            return scroll;
        }
    }
};

/**
 * @ignore
 * @param {Window|HTMLElement} element
 * @param {String} [containerType="offset"]
 * @return {Number}
 * @description Returns the horizontal scrolling offset.
 */
Browser.getScrollLeft = function (element, containerType) {
    if (arguments.length < 2) {
        containerType = "offset";
    }

    if (typeof element.tagName === "undefined") {
        return $(window).scrollLeft();
    } else {
        var scroll = 0;
        var parent = element;
        var pos;
        var overflow;
        var stop;
        var isParentScrollable = false;

        while (parent) {
            stop = false;
            if (containerType == "scroll") {
                overflow = parent.style.overflow;
                isParentScrollable = overflow == "hidden" || overflow == "auto" || overflow == "scroll";
                if (!isParentScrollable) {
                    overflow = parent.style.overflowX;
                    isParentScrollable = overflow == "hidden" || overflow == "auto" || overflow == "scroll";
                    if (!isParentScrollable) {
                        overflow = parent.style.overflowY;
                        isParentScrollable = overflow == "hidden" || overflow == "auto" || overflow == "scroll";
                    }
                }
                if (isParentScrollable) {
                    scroll += parent.scrollLeft;
                    stop = true;
                    break;
                }
            } else if (containerType == "overlow") {
                pos = parent.style.position;
                if (pos == "fixed" || pos == "absolute" || pos == "relative") {
                    scroll += parent.scrollLeft;
                    stop = true;
                    break;
                }
            }
            if (!stop && parent.tagName == "DIV" && parent.scrollTop) {
                scroll += parent.scrollLeft;
            }
            parent = parent.offsetParent;
            if (stop) {
                break;
            }
        }
        if (containerType == "scroll" && !isParentScrollable) {
            return this.getScrollLeft(self);
        } else {
            return scroll;
        }
    }
};

/**
 * @ignore
 * @param {String} query
 * @return {Boolean}
 * @description Execute a media query to determine what the browser supports.
 */
Browser.testMediaQuery = function (query) {
    var style = document.createElement("style");
    var div = document.createElement("div");
    var id = "";
    do {
        id = ("x" + Math.random()).replace(/\./, "");
    } while (document.getElementById(id));
    div.id = id;
    style.innerText = "@media " + query + " { #" + id + " { display:none !important; } }";
    document.documentElement.appendChild(style);
    document.documentElement.appendChild(div);
    var result = getComputedStyle(div, null).getPropertyValue("display") == "none";
    style.parentNode.removeChild(style);
    div.parentNode.removeChild(div);
    return result;
};

/**
 * @ignore
 * @return {String}
 * @description Retrieve ASCII symbol for global key event.
 */
Browser.getEventKeyChar = function () {
    var keyCode = Browser.getEventKeyCode();
    var keyChar = String.fromCharCode(keyCode).toLowerCase();
    return keyChar;
};

/**
 * @ignore
 * @return {HTMLElement|Boolean}
 * @description Retrieve target for global event.
 */
Browser.getEventTarget = function () {
    var element;
    if (typeof self.oUp.oEvent.target !== "undefined" && self.oUp.oEvent.target !== null) {
        element = self.oUp.oEvent.target;
    } else if (typeof self.oUp.oEvent.srcElement !== "undefined" && self.oUp.oEvent.srcElement !== null) {
        element = self.oUp.oEvent.srcElement;
    } else {
        return false;
    }
    return element;
};

/**
 * @ignore
 * @return {String}
 * @description Retrieve message for global exception.
 */
Browser.getExceptionMessage = function (exception) {
    var message = "";
    if (exception.description) {
        message = exception.description;
    } else if (exception.message) {
        message = exception.message;
    }
    return message;
};

/**
 * @ignore
 * @param {upActionControl} ctrl
 * @description Determines x and y coordinates of control (upper left) relative to screen.
 * @return {{posX: Number, posY: Number}|Boolean} Object with x and y coordinates.
 */
Browser.getPosition2Screen = function (ctrl) {
    if (typeof ctrl === "undefined" || !(ctrl instanceof upActionControl)) {
        return false;
    }

    var position = {
        posX: 0,
        posY: 0,
    };
    var win = self;

    // screenTop kennt IE, aber nicht Mozilla. screenY von NS/Mozilla entspricht ihm NICHT,
    // da screenTop immer das Offset des Framefensters zum Screen(links oben) beinhaltet, screenY aber das des TOP Fensters.
    // Deswegen laeuft die Funktion fuer Mozilla ueber das Event Objekt.

    if (
        (typeof win.screenTop === "undefined" || win.screenTop === null) &&
        self.oUp.oRootWin.eventScreenX &&
        !isNaN(self.oUp.oRootWin.eventScreenX)
    ) {
        // Mozilla
        position.posX = self.oUp.oRootWin.eventScreenX;
        position.posY = self.oUp.oRootWin.eventScreenY;
    } else if (window.event && window.event.screenX && win.top !== win.oHtmlRoot) {
        // IE IFrame
        position.posX = parseInt(window.event.screenX, 10);
        position.posY = parseInt(window.event.screenY, 10);
    } else if (typeof win.screenTop !== "undefined" && win.screenTop !== null) {
        position = this.getPosition2Page(ctrl, true);
        position.posX = position.posX - Browser.getScrollOffsetLeft(self) + win.screenLeft;
        position.posY = position.posY - Browser.getScrollOffsetTop(self) + win.screenTop;
    } else {
        position = this.getPosition2Page(ctrl, true);
        position.posY = position.posY - Browser.getScrollOffsetTop(self);
        position.posX = position.posX - Browser.getScrollOffsetLeft(self);
    }

    return position;
};

/**
 * @ignore
 * @deprecated since Intrexx 8.0 - use jQuery.offset() instead
 * @param {HTMLElement|upObject} element
 * @return {{posX: Number, posY: Number}|null}
 * @description Determines x and y coordinates of the element relative to the document
 */
Browser.getPosition2Page = function (element) {
    if (typeof element === "undefined") {
        return null;
    }

    if (!(element instanceof upObject) && !(element instanceof HTMLElement) && !(element instanceof SVGElement)) {
        return null;
    }

    var offset;
    if (element instanceof upObject) {
        offset = $(element.oHtml).offset();
    } else if (element instanceof SVGElement) {
        offset = $(element).offset();
    } else {
        offset = $(element).offset();
    }

    return Object.create(Object.prototype, {
        posX: {
            value: offset.left,
        },
        posY: {
            value: offset.top,
        },
    });
};

/**
 * @ignore
 * @param {Window} win
 * @return {Number}
 * @description Determines y coordinate of the window relative to the top.
 */
Browser.getHtmlRootTop = function (win) {
    if (!oHtmlRoot) {
        return 0;
    }
    var posY = 0;
    if (win.screenTop) {
        posY = win.screenTop - oHtmlRoot.screenTop;
    } else if (win.screenY) {
        posY = win.screenY - oHtmlRoot.screenY;
    }
    return posY;
};

/**
 * @ignore
 * @param {Window} win
 * @return {Number}
 * @description Determines y coordinate of the window relative to the top.
 */
Browser.getHtmlRootLeft = function (win) {
    if (!oHtmlRoot) {
        return 0;
    }
    var posX = 0;
    if (win.screenTop) {
        posX = win.screenLeft - oHtmlRoot.screenLeft;
    } else if (win.screenX) {
        posX = win.screenX - oHtmlRoot.screenX;
    }
    return posX;
};

/**
 * @ignore
 * @param {HTMLElement} element
 * @return {{posX: Number, posY: Number}|null}
 * @description Determines x and y coordinates of the element relative to the viewport.
 */
Browser.getPosition2Viewport = function BrowserGetPosition2Viewport(element) {
    if (typeof element === "undefined" || !element) {
        return null;
    }

    var pos2Doc = this.getPosition2Page(element);
    var scrollLeft = Browser.getScrollLeft(self);
    var scrollTop = Browser.getScrollTop(self);

    return {
        posX: pos2Doc.posX - scrollLeft,
        posY: pos2Doc.posY - scrollTop,
    };
};

/**
 * @ignore
 * @param {HTMLElement} element
 * @param {Boolean} [useScrollPosition]
 * @param {String} [containerType="offset"]
 * @return {{posX: Number, posY: Number}|null}
 * @description Determines x and y coordinates of the control (upper left) relative to the surrounding container.
 */
Browser.getPosition2Container = function (element, useScrollPosition, containerType) {
    var oPos = {};
    var hasDifference = false;
    var posX = 0;
    var posY = 0;
    var offsetPosX = 0;
    var offsetPosY = 0;
    var scrollTop = 0;
    var scrollLeft = 0;
    var offsetParent = element;
    var container = false;
    var isParentScrollable = false;

    if (typeof element == "undefined" || !element) {
        return;
    }

    if (arguments.length < 3) {
        containerType = "offset";
    }

    if (element.offsetParent) {
        offsetPosX = this.getPosLeftAbs(element, false);
        offsetPosY = this.getPosTopAbs(element, false);
        posX = offsetPosX;
        posY = offsetPosY;
        offsetParent = offsetPosX.oContainer;
        var parent = offsetParent;
        var pos;
        var overflow;
        while (parent) {
            container = parent;
            if (containerType == "scroll") {
                if (parent.tagName == "BODY") {
                    isParentScrollable = true;
                } else {
                    overflow = parent.style.overflow;
                    isParentScrollable = overflow == "hidden" || overflow == "auto" || overflow == "scroll";
                    if (!isParentScrollable) {
                        overflow = parent.style.overflowX;
                        isParentScrollable = overflow == "hidden" || overflow == "auto" || overflow == "scroll";
                        if (!isParentScrollable) {
                            overflow = parent.style.overflowY;
                            isParentScrollable = overflow == "hidden" || overflow == "auto" || overflow == "scroll";
                        }
                    }
                }
                if (isParentScrollable) {
                    if (parent.tagName == "BODY") {
                        scrollTop = $(window).scrollTop();
                        scrollLeft = $(window).scrollLeft();
                    } else {
                        scrollTop = parent.scrollTop;
                        scrollLeft = parent.scrollLeft;
                    }
                    break;
                }

                pos = parent.style.position;

                if (pos == "fixed") {
                    break;
                }
            } else {
                pos = parent.style.position;
                if (pos == "absolute" || pos == "fixed" || pos == "relative") {
                    scrollTop = parent.scrollTop;
                }
                scrollLeft = parent.scrollLeft;
                break;
            }

            hasDifference = true;
            container = parent;
            posX += parent.offsetLeft;
            posY += parent.offsetTop;
            parent = container.offsetParent;
        }

        if (useScrollPosition) {
            if (containerType == "scroll" && !isParentScrollable && pos != "fixed") {
                container = window;
                scrollTop = $(window).scrollTop();
                scrollLeft = $(window).scrollLeft();
            }
            posX -= scrollLeft;
            posY -= scrollTop;
            if (!hasDifference) {
                offsetPosX -= scrollLeft;
                offsetPosY -= scrollTop;
            }
        }
    } else if (element.offsetLeft && element.offsetTop) {
        posX = element.offsetLeft;
        posY = element.offsetTop;
        if (useScrollPosition && element.tagName == "DIV" && element.scrollTop && element.scrollLeft) {
            posX -= element.scrollLeft;
            posY -= element.scrollTop;

            scrollTop = element.scrollTop;
            scrollLeft = element.scrollLeft;
        }
    } else if (element.posX && element.posY) {
        posX = element.posX; //oder oHtml.x ?
        posY = element.posY;
    }
    //alert("scrollTop: " + scrollTop + "\np_strContainerType: " + p_strContainerType)
    oPos.posX = posX;
    oPos.posY = posY;
    oPos.scrollTop = scrollTop;
    oPos.scrollLeft = scrollLeft;
    oPos.offsetParent = offsetParent;
    oPos.offsetPosX = offsetPosX;
    oPos.offsetPosY = offsetPosY;
    oPos.container = container;
    oPos.offsetEqualsContainer = !hasDifference;

    return oPos;
};

/**
 * @ignore
 * @param {HTMLElement} element
 * @param {Boolean} useScrollPosition
 * @return {Number|Boolean}
 * @description Determines x (left) position of an html element.
 */
Browser.getPosLeft = function (element, useScrollPosition) {
    var posX;
    var body;
    var doc;

    if (typeof element.ownerDocument !== "undefined") {
        doc = element.ownerDocument;
        body = doc.body;
    } else {
        body = document.getElementsByTagName("body")[0];
    }

    if ("getBoundingClientRect" in document.documentElement && useScrollPosition) {
        var box;
        try {
            box = element.getBoundingClientRect();
        } catch (e) {
            return false;
        }
        var docElem = doc.documentElement;
        var clientLeft = docElem.clientLeft || body.clientLeft || 0;

        posX = box.left + (self.pageXOffset || docElem.scrollLeft || body.scrollLeft) - clientLeft;
    } else {
        posX = element.offsetLeft;
        var parent = element.offsetParent;
        while (parent && parent != body) {
            posX = posX + parent.offsetLeft;
            if (useScrollPosition && parent.scrollLeft) {
                posX -= parent.scrollLeft;
            }
            parent = parent.offsetParent;
        }
    }
    return posX;
};

/**
 * @ignore
 * @param {HTMLElement} element
 * @param {Boolean} useScrollPosition
 * @return {Number|Boolean}
 * @description Determines y position of an html element.
 */
Browser.getPosTop = function (element, useScrollPosition) {
    var posY;
    var body;
    var doc;
    if (typeof element.ownerDocument != "undefined") {
        doc = element.ownerDocument;
        body = doc.body;
    } else {
        body = document.getElementsByTagName("body")[0];
    }

    if ("getBoundingClientRect" in document.documentElement && useScrollPosition) {
        var box;
        try {
            box = element.getBoundingClientRect();
        } catch (e) {
            return false;
        }

        var docElem = doc.documentElement;
        var clientTop = docElem.clientTop || body.clientTop || 0;

        posY = box.top + (self.pageYOffset || docElem.scrollTop || body.scrollTop) - clientTop;
    } else {
        posY = element.offsetTop;
        var parent = element.offsetParent;
        while (parent && parent != body) {
            posY = posY + parent.offsetTop;
            if (useScrollPosition && parent.scrollTop) {
                posY -= parent.scrollTop;
            }
            parent = parent.offsetParent;
        }
    }
    return posY;
};

/**
 * @ignore
 * @param {HTMLElement} element
 * @param {Boolean} useScrollPosition
 * @return {Number|Boolean}
 * @description Determines y (top) position of an html element on absolute positioning.
 */
Browser.getPosTopAbs = function (element, useScrollPosition) {
    var posY = element.offsetTop;
    if (useScrollPosition && element.tagName === "DIV" && element.scrollTop) {
        posY -= element.scrollTop;
    }
    var parent = element.offsetParent;
    var parentContainer = element;
    var pos;
    while (parent) {
        parentContainer = parent;
        pos = parent.style.position;
        if (pos != "fixed" && pos != "absolute" && pos != "relative") {
            posY = posY + parent.offsetTop;
            parent = parent.offsetParent;
        } else {
            break;
        }
    }
    if (useScrollPosition) {
        posY -= this.scrollTop(element);
    }

    var result = new Number(posY);
    result.oContainer = parentContainer;

    return result;
};

/**
 * @ignore
 * @param {HTMLElement} element
 * @param {Boolean} useScrollPosition
 * @return {Number|Boolean}
 * @description Determines x (left) position of an html element on absolute positioning.
 */
Browser.getPosLeftAbs = function (element, useScrollPosition) {
    var posX = element.offsetLeft;
    if (useScrollPosition && element.tagName === "DIV" && element.scrollLeft) {
        posX -= element.scrollLeft;
    }
    var parent = element.offsetParent;
    var parentContainer = element;
    var pos;
    while (parent) {
        parentContainer = parent;
        pos = parent.style.position;
        if (pos != "fixed" && pos != "absolute" && pos != "relative") {
            posX = posX + parent.offsetLeft;
            parent = parent.offsetParent;
        } else {
            break;
        }
    }
    if (useScrollPosition) {
        posX -= this.scrollLeft(element);
    }

    var result = new Number(posX);
    result.oContainer = parentContainer;

    return result;
};

/**
 * @ignore
 * @param {HTMLElement} element
 * @return {Number}
 * @description Determines width of an HTML element.
 */
Browser.getWidth = function (element) {
    var width;
    if (element instanceof SVGElement) {
        width = element.getBBox().width;
    } else {
        width = element.offsetWidth;
    }
    if (isNaN(width)) {
        width = parseInt(element.width, 10);
    }
    return width;
};

/**
 * @ignore
 * @param {HTMLElement} element
 * @return {Number}
 * @description Determines height of an HTML element.
 */
Browser.getHeight = function (element) {
    var height;
    if (element instanceof SVGElement) {
        height = element.getBBox().height;
    } else {
        height = element.offsetHeight;
    }
    if (isNaN(height)) {
        height = parseInt(element.height, 10);
    }
    return height;
};
/**
 * @ignore
 * @deprecated
 * @param {String} id
 * @param {Document} doc Document, where the element is.
 * @return {HTMLElement|Boolean} An html element or false.
 * @description Returns an HTMLElement.
 */
Browser.getRef = function (id, doc) {
    if (!id) {
        return false;
    }
    var ref = null;
    if (!doc) {
        doc = document;
    }
    ref = doc.getElementById(id);
    // falls ref = null
    if (!ref) {
        if (ErrorHandler.DEBUG) {
            alert(self.oUp.oMessage.BROWSER_NO_FLD_REF + "\n(" + id + ")");
        }
        return false;
    } else {
        return ref;
    }
};
