/* globals ErrorHandler */
/**
 * @file upGenericEventListenerRegistry
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/**
 * @class
 */
function upGenericEventListenerRegistry(p_oSettings) {
    this.oContainer = {};
    this.bReverseProcessing = false;

    for (var name in p_oSettings) {
        this[name] = p_oSettings[name];
    }
}
upGenericEventListenerRegistry.prototype.addEventListenerEntry = GenericEventListenerRegistryAddEventListenerEntry;
upGenericEventListenerRegistry.prototype.removeEventListener = GenericEventListenerRegistryRemoveEventListener;
upGenericEventListenerRegistry.prototype.removeEventListenerEntry =
    GenericEventListenerRegistryRemoveEventListenerEntry;
upGenericEventListenerRegistry.prototype.getEventListener = GenericEventListenerRegistryGetEventListener;
upGenericEventListenerRegistry.prototype.getEventListenerByKey = GenericEventListenerRegistryGetEventListenerByKey;
upGenericEventListenerRegistry.prototype.hasEventListener = GenericEventListenerRegistryHasEventListener;
upGenericEventListenerRegistry.prototype.triggerEventListener = GenericEventListenerRegistryTriggerEventListener;

/**
 * Eventlistener
 * @param {String} p_strType eventlistener type, e.g. match
 * @return list with eventlistener
 * @type Array
 */
function GenericEventListenerRegistryGetEventListener(p_strType) {
    if (typeof this.oContainer[p_strType] == "undefined" || this.oContainer[p_strType] == null) {
        this.oContainer[p_strType] = [];
    }
    return this.oContainer[p_strType];
}

/**
 * Eventlistener
 * @param {String} p_strKey eventlistener key, e.g. match
 * @return eventlistener entry
 * @type Array
 */
function GenericEventListenerRegistryGetEventListenerByKey(p_strKey) {
    var container;
    for (var type in this.oContainer) {
        container = this.oContainer[type];
        for (var i = 0; i < container.length; i++) {
            if (container[i].strKey == p_strKey) return container[i];
        }
    }

    return false;
}

/**
 * Add a new Eventlistener
 * @param {String} p_strType eventlistener type, e.g. match
 * @param {function|string} p_ListenerAction eventlistener
 * @return status
 * @type Boolean
 */
function GenericEventListenerRegistryAddEventListenerEntry(p_strKey, p_strType, p_ListenerAction) {
    var l_bRet = false;
    var l_aListener;
    l_aListener = this.getEventListener(p_strType);
    if (l_aListener) {
        l_bRet = true;
        var l = new upGenericEventListenerEntry();
        l.strType = p_strType;
        l.strKey = p_strKey;
        l.action = p_ListenerAction;
        l_aListener.push(l);
        this.oContainer[p_strType] = l_aListener;
    }
    return l_bRet;
}

/**
 * Remove Eventlistener
 * @param {String} p_strType eventlistener type, e.g. match
 * @param {function} p_Listener eventlistener
 * @return status
 * @type Boolean
 */
function GenericEventListenerRegistryRemoveEventListener(p_strType) {
    var l_oNewContainer = {};
    for (var type in this.oContainer) {
        if (type != p_strType) {
            l_oNewContainer[type] = this.oContainer[type];
        }
    }
    this.oContainer = l_oNewContainer;
}

/**
 * Remove Eventlistener Entry
 * @param {String} p_strType eventlistener type, e.g. match
 * @param {function} p_Listener eventlistener
 * @return status
 * @type Boolean
 */
function GenericEventListenerRegistryRemoveEventListenerEntry(p_strKey, p_strType) {
    var l_aListener = this.getEventListener(p_strType);
    if (l_aListener) {
        for (var i = 0; i < l_aListener.length; i++) {
            if (l_aListener[i].strKey == p_strKey) {
                l_aListener.splice(i, 1);
                return true;
            }
        }
    }
    return false;
}

/**
 * Execute eventlistener of the same type
 * @param {String} p_strType eventlistener type, e.g. match
 */
function GenericEventListenerRegistryTriggerEventListener(p_strType, p_oContext, p_Argument) {
    var l_aListener;
    l_aListener = this.getEventListener(p_strType);
    if (l_aListener) {
        var bRet;
        if (!this.bReverseProcessing) {
            for (var i = 0; i < l_aListener.length; i++) {
                // Abbruch der Ausfuehrung bei Return false
                bRet = l_aListener[i].trigger(p_oContext, p_Argument);
                if (!bRet) return false;
            }
        } else {
            for (var j = l_aListener.length - 1; j >= 0; j--) {
                // Abbruch der Ausfuehrung bei Return false
                bRet = l_aListener[j].trigger(p_oContext, p_Argument);
                if (!bRet) return false;
            }
        }
    }
    return true;
}

/**
 * Returns if eventlistener of given type are registered
 * @param {String} p_strType eventlistener type, e.g. match
 */
function GenericEventListenerRegistryHasEventListener(p_strType) {
    var l_aListener;
    l_aListener = this.getEventListener(p_strType);
    return l_aListener && l_aListener.length > 0;
}

/**
 * @class
 * Execute eventlistener of the same type
 * @param {String} p_strType eventlistener type, e.g. match
 */
function upGenericEventListenerEntry() {
    this.strKey = null;
    this.action = null;
    this.strType;
}
upGenericEventListenerEntry.prototype.trigger = upGenericEventListenerEntryTrigger;

function upGenericEventListenerEntryTrigger(p_oContext, p_Argument) {
    var l_bRet = true;

    if (typeof p_oContext == "undefined" || p_oContext == null) {
        p_oContext = this;
    }

    p_oContext.tmpAction = this.action;

    try {
        switch (typeof p_oContext.tmpAction) {
            case "string":
                var f = new Function(p_oContext.tmpAction);
                l_bRet = f();
                if (typeof l_bRet != "boolean") {
                    l_bRet = true;
                }
                break;
            case "function":
                l_bRet = p_oContext.tmpAction(p_Argument);
                break;
            case "object":
                l_bRet = eval(p_oContext.tmpAction + "(" + p_Argument + ");");
                break;
        }
        if (typeof l_bRet != "boolean") {
            l_bRet = true;
        }
    } catch (e) {
        var strErrTitle = "Javascript exception";
        var strErrDesc = "Error while running 'on" + this.strType + "' event script.";
        strErrDesc += "<br/><br/>cause:<br/>" + e.message + "<br />";

        var strStack = "";
        if (e.stack) strStack = e.stack;
        else if (e.StackTrace) strStack = e.StackTrace;

        if (strStack.length > 100) {
            strErrDesc +=
                '<br />code:<br /><textarea style="width: 100%; height:100px;">' + strStack + "</textarea><br />";
        } else {
            strErrDesc += "<br />code:<br />" + strStack + "<br />";
        }

        strErrDesc += "<br />function: upGenericEventListenerEntryTrigger";

        ErrorHandler.notify(strErrDesc, strErrTitle);

        if (console) {
            console.log(e);
        }
    }
    return l_bRet;
}
